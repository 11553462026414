export function formatTimeSeconds(rawTime)
{
	if (!isFinite(rawTime))
	{
		return '00:00';
	}

	let time = Math.round(rawTime);
	let seconds = time % 60;

	time = (time - seconds) / 60;

	if (seconds < 10)
	{
		seconds = '0' + seconds;
	}

	if (time === 0)
	{
		return `00:${seconds}`;
	}

	let minutes = time % 60;
	let hours = (time - minutes) / 60;

	if (minutes < 10)
	{
		minutes = '0' + minutes;
	}

	if (hours === 0)
	{
		return `${minutes}:${seconds}`;
	}

	return `${hours}:${minutes}:${seconds}`;
}