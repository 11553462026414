<template>
	<lobster-layout-section :loading="isGalleryListLoading">
		<lobster-table full-screen>
			<lobster-table-row header>
				<lobster-table-column-title>
					Name
				</lobster-table-column-title>
				<lobster-table-column-title tr>
					Description
				</lobster-table-column-title>
				<lobster-table-column-title>
					Privacy
				</lobster-table-column-title>
				<lobster-table-column-title>
					Created On
				</lobster-table-column-title>
				<lobster-table-column-title small/>
			</lobster-table-row>
			<lobster-table-row
				v-for="gallery in currentGalleryList"
				clickable
				:key="gallery.Id"
				@click="handleRowClick(gallery)">
				<lobster-table-cell truncate :tooltip="gallery.name">
					<lobster-button
						inline-link
						:to="{
								name: 'gallery',
								params: {
									id: gallery.id
								}
							}">
						<lobster-search-highlight :queries="search">
							{{ gallery.name }}
						</lobster-search-highlight>
					</lobster-button>
				</lobster-table-cell>
				<lobster-table-cell truncate :tooltip="gallery.description">
					{{ gallery.description }}
				</lobster-table-cell>
				<lobster-table-cell class="gallery-table__privacy-column">
					{{ gallery.visibility }}
				</lobster-table-cell>
				<lobster-table-cell>
					{{ gallery.created }}
				</lobster-table-cell>
				<template v-if="canUpdate" #actions-cell>
					<lobster-action-button icon-name="edit" @click.stop="handleEditClick(gallery)"/>
					<lobster-action-button deny icon-name="trash-can" @click.stop="handleDeleteClick(gallery)"/>
				</template>
			</lobster-table-row>
		</lobster-table>
	</lobster-layout-section>
</template>

<script>
import { mapState } from 'vuex';
import GalleryModal from './GalleryModal';
import { getAdminRegion } from '../../../consts/StartupData';


export default {
	name: 'GalleryTable',
	components: {
		GalleryModal
	},
	props: {
		search: String,
	},
	data()
	{
		return {}
	},
	computed: {
		...mapState({
			galleryList: state => state.reportGallery.galleryList,
			isGalleryListLoading: state => state.reportGallery.isGalleryListLoading
		}),
		currentGalleryList()
		{
			if (this.search)
			{
				return this.galleryList.filter((gallery) => gallery.name?.toLowerCase().includes(this.search?.toLowerCase()));
			}

			return this.galleryList;
		},
		canUpdate()
		{
			return getAdminRegion() === 'US_APP_1';
		}
	},
	methods: {
		async deleteGallery(id)
		{
			await this.$store.dispatch('reportGallery/deleteGallery', id);

			this.$toaster.showSuccess('Group deleted');
		},
		handleEditClick(gallery)
		{
			this.$showModal({
				component: GalleryModal,
				bind: {
					gallery
				}
			});
		},
		async handleDeleteClick(gallery)
		{
			let confirmed = await this.$dialog(`Are you sure you want delete ${gallery.name} gallery?`)

			if (confirmed)
			{
				this.deleteGallery(gallery.id);
			}
		},
		handleRowClick(gallery)
		{
			this.$router.push({
				name: 'gallery',
				params: {
					id: gallery.id
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.gallery-table__privacy-column {
	text-transform: capitalize;
}
</style>