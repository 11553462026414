<template>
	<lobster-dynamic-modal
		v-on="$listeners"
		v-bind="$attrs"
		size="medium"
		:modal-title="title">
		<lobster-form
			modal-view
			:submit-button-text="submitButtonText"
			:is-loading="isGallerySubmitting"
			@submit="handleSubmitForm">
			<lobster-input
				v-model.trim="currentSettings.name"
				label="Name"
				maxlength="100"
				validators="required"/>
			<lobster-textarea
				v-model="currentSettings.description"
				label="Description"
				maxlength="200"/>
			<lobster-entities-container
				no-wrap
				column
				size="small">
				<lobster-checkbox
					type="radio"
					v-model="currentSettings.visibility"
					name="visibility"
					val="unpublished">
					<template #label>
						<lobster-icon name="view--off" class="gallery-modal__private-icon"/>
						<b>Not Published</b>
						-  group won’t be available within the reports gallery.
					</template>
				</lobster-checkbox>
				<lobster-checkbox
					type="radio"
					v-model="currentSettings.visibility"
					name="visibility"
					val="published">
					<template #label>
						<lobster-icon name="view" class="gallery-modal__private-icon"/>
						<b>Published</b>
						- group will be available to users within the reports gallery.
					</template>
				</lobster-checkbox>
			</lobster-entities-container>
		</lobster-form>
	</lobster-dynamic-modal>
</template>

<script>
import { mapState } from 'vuex';


export default {
	name: 'GalleryModal',
	props: {
		gallery: {
			type: Object,
			default: () => ({})
		}
	},
	data()
	{
		return {
			currentSettings: {
				name: this.gallery.name || '',
				description: this.gallery.description || '',
				visibility: this.gallery.visibility || 'unpublished'
			},
		}
	},
	computed: {
		...mapState({
			isGallerySubmitting: state => state.reportGallery.isGallerySubmitting,
		}),
		isEdit()
		{
			return is(this.gallery)
		},
		title()
		{
			return this.isEdit ? 'Edit Report Gallery' : 'New Report Group';
		},
		submitButtonText()
		{
			return this.isEdit ? 'Save' : 'Add';
		}
	},
	methods: {
		async createGallery()
		{
			await this.$store.dispatch('reportGallery/createNewGallery', this.currentSettings);

			await this.$store.dispatch('reportGallery/loadGalleryList');

			this.$toaster.showSuccess('New group added');
		},
		async updateGallery()
		{
			await this.$store.dispatch('reportGallery/updateGallery', {...this.currentSettings, groupId: this.gallery.id});

			await this.$store.dispatch('reportGallery/loadGalleryList');

			this.$toaster.showSuccess('Group updated');
		},
		async handleSubmitForm()
		{
			if (this.isEdit)
			{
				await this.updateGallery();
			}
			else
			{
				await this.createGallery();
			}

			this.$emit('input', false);
		}
	}
}
</script>

<style lang="scss" scoped>
.gallery-modal__private-icon {
	vertical-align: bottom;
}
</style>