<template>
	<div class="sidebar-menu">
		<lobster-abstract-button
			class="sidebar-menu__tab"
			:class="{ active: !filterView }"
			:disabled="!filterView"
			@click="handleClick(false)">
			Outline
		</lobster-abstract-button>
		<lobster-abstract-button
			class="sidebar-menu__tab"
			:class="{active: filterView}"
			:disabled="filterView"
			@click="handleClick(true)">
			Filters
			<b v-show="filtersCount" class="tabs__item-counter">{{ filtersCount }}</b>
		</lobster-abstract-button>
	</div>
</template>

<script>
export default {
	name: 'SidebarMenu',
	props: {
		filterView: Boolean,
		filtersCount: Number
	},
	data()
	{
		return {}
	},
	computed: {},
	methods: {
		handleClick(isFilterView)
		{
			this.$emit('update:filter-view', isFilterView);
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebar-menu {
	border-bottom: 1px solid $colorBorder;
	display: flex;
}

.sidebar-menu__tab:hover {
	color: $colorTextPrimary;
}

.sidebar-menu__tab {
	flex-grow: 1;
	flex-basis: 10%;
	height: 45px;
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-right: 1px solid $colorBorder;
	cursor: pointer;

	&.active {
		color: $colorTextPrimary;
		font-weight: 600;
		border-bottom: 2px solid $colorPrimary;
	}

	&:last-child {
		border-right: none;
	}
}
</style>