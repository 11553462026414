import { render, staticRenderFns } from "./TwitterToggle.vue?vue&type=template&id=67e0a170&scoped=true&"
import script from "./TwitterToggle.vue?vue&type=script&setup=true&lang=js&"
export * from "./TwitterToggle.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TwitterToggle.vue?vue&type=style&index=0&id=67e0a170&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e0a170",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yegor/Code/oktopost/admin/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67e0a170')) {
      api.createRecord('67e0a170', component.options)
    } else {
      api.reload('67e0a170', component.options)
    }
    module.hot.accept("./TwitterToggle.vue?vue&type=template&id=67e0a170&scoped=true&", function () {
      api.rerender('67e0a170', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/QATools/Twitter/TwitterToggle.vue"
export default component.exports