/**
 * @typedef Criterion
 * @property {(datum: any) => boolean } matcher
 * @property { number } allowedCount
 */
/**
 * @param {any[]} data
 * @param {Criterion[]} criteria
 */
export function criteriaFiltrator(data, criteria)
{
	let res = [];
	let criteriaCount = criteria.length;
	let foundMatched = {};

	for (let i = 0; i < criteriaCount; i++)
	{
		foundMatched[i] = 0;
	}
	
	for (let datum of data)
	{
		let matches = [];
		let isCriteriaExceeded = false;
		
		for (let index = 0; index < criteriaCount; index++)
		{
			let criterion = criteria[index];
			
			if (!criterion.matcher(datum))
			{
				continue;
			}
			
			if (foundMatched[index] >= criterion.allowedCount)
			{
				isCriteriaExceeded = true;
				break;
			}
			
			matches.push(index);
		}
		
		if (isCriteriaExceeded)
		{
			continue;
		}
		
		for (let match of matches)
		{
			foundMatched[match]++;
		}
		
		res.push(datum);
	}
	
	return res;
}