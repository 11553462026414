var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { staticClass: "text-editor", attrs: { column: "", "no-wrap": "" } },
    [
      _c("div", { ref: "editor" }),
      _vm._v(" "),
      _vm.$is(_vm.quill)
        ? _c("text-editor-toolbar", {
            attrs: {
              quill: _vm.quill,
              "text-length": _vm.textLength,
              "max-text-length": _vm.maxTextLength,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }