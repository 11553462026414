<template>
	<lobster-entities-container
		no-wrap
		center
		size="small">
		<lobster-network-icon
			class="no-shrink"
			size="large"
			:name="network"/>
		<lobster-text-entity
			emojify
			smart-tooltip
			:network="network"
			:text="message"
			line-clamp="1"/>
	</lobster-entities-container>
</template>

<script>
export default {
	name: 'MessageEntity',
	props: {
		network: String,
		message: String
	}
}
</script>