var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    {
      attrs: {
        placement: "bottom-start",
        trigger: "manual",
        open: _vm.isDatepickerOpen,
      },
      on: {
        "auto-hide": function ($event) {
          _vm.isDatepickerOpen = false
        },
        "apply-hide": function ($event) {
          _vm.isDatepickerOpen = false
        },
      },
      scopedSlots: _vm._u([
        {
          key: "popover",
          fn: function () {
            return [
              _vm.isDatepickerOpen
                ? _c("date-picker", {
                    attrs: { value: _vm.value },
                    on: { input: _vm.handleDatePickerInput },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("date-select", {
        attrs: { value: _vm.value, options: _vm.datePeriodOptions },
        on: { input: _vm.handleDateSelectInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }