import { Network } from '../../utils';


/**
 * @typedef MetricConfig
 * @property {string} name
 * @property {boolean} [percent]
 * @property {boolean} [isCurrency]
 * @property {boolean} [isSeconds]
 * @property {boolean} [isNumericEmoji]
 * @property {boolean} [isUniquePageView]
 * @property {boolean} [isPageView]
 * @property {boolean} [isFloatNumber]
 * @property {string} [network]
 * @property {boolean} [isZeroValuesFillerNeeded] force to fill tabla with additional data
 */
/**
 * @type {Object<string, MetricConfig>}
 */

export const MetricsConfig = {
	IsMessageModified: {
		name: "Advocate Edited Posts",
	},
	AdvocateEditRatio: {
		name: "Advocate Edit Ratio",
		percent: true
	},
	ClickThroughRate: {
		name: "Click-Through Rate",
		percent: true
	},
	LinkClicks: {
		name: "Link Clicks",
	},
	AggregatedClicks: {
		name: "Clicks",
	},
	CommentsAdded: {
		name: "Comments",
	},
	Conversions: {
		name: "Conversions",
	},
	EngagementAdded: {
		name: "Engagements",
	},
	TotalEngagementRate: {
		name: "Engagement Rate",
		percent: true
	},
	Followers: {
		name: "Followers",
		isZeroValuesFillerNeeded: true
	},
	ImpressionsAdded: {
		name: "Impressions",
	},
	LikesAdded: {
		name: "Likes",
	},
	FollowersAdded: {
		name: "New Followers",
	},
	ReachAdded: {
		name: "Reach",
	},
	SharesAdded: {
		name: "Shares",
	},
	IsPost: {
		name: 'Posts',
	},
	IsClick: {
		name: 'Link Clicks',
	},
	IsConversion: {
		name: 'Conversions',
	},
	ConversionValue: {
		name: 'Conversion Value',
		isCurrency: true
	},
	ProfileFollowers: {
		name: 'Profile Followers',
	},
	CommentsCount: {
		name: 'Comments',
	},
	LikesCount: {
		name: 'Likes',
	},
	DislikesCount: {
		name: 'Dislikes',
	},
	SharesCount: {
		name: 'Shares',
	},
	ViewsCount: {
		name: 'Views',
	},
	Engagement: {
		name: 'Engagements',
	},
	Reach: {
		name: 'Reach',
	},
	AvgEngagement: {
		name: 'Avg. Engagement',
	},
	IsSession: {
		name: 'Sessions',
	},
	DetailExpandsAdded: {
		name: 'Post Expansion',
		network: Network.TWITTER,
		twitterOnly: true
	},
	MediaClicksAdded: {
		name: 'Media Clicks',
		network: Network.TWITTER,
		twitterOnly: true
	},
	UserFollowsAdded: {
		name: 'User Follows',
		network: Network.TWITTER,
		twitterOnly: true
	},
	IsFirstDomainSession: {
		name: 'Unique Sessions',
	},
	IsMention: {
		name: 'Mentions',
	},
	Mentions: {
		name: 'Mentions',
	},
	Count: {
		name: 'Activities',
	},
	Clicks: {
		name: "Clicks",
	},
	Comments: {
		name: "Comments",
	},
	CommentLikes: {
		name: "Comment Likes",
	},
	CostUsd: {
		name: "Cost (USD)",
		isCurrency: true,
		currencyCode: 'USD',
		currencySymbol: '$'
	},
	Impressions: {
		name: "Impressions",
	},
	Likes: {
		name: "Likes",
	},
	Shares: {
		name: "Shares",
	},
	VideoViewersAdded: {
		name: "Video Viewers",
	},
	VideoAverageWatchtimeAdded: {
		name: "Video Average Watch Time",
		isSeconds: true
	},
	VideoViews: {
		name: "Video Views",
	},
	VideoViewsAdded: {
		name: "Video Views",
	},
	VideoViewsFirstQ: {
		name: "Video First Quartile Completions",
	},
	VideoViewsMid: {
		name: "Video Midpoint Completions",
	},
	VideoViewsThirdQ: {
		name: "Video Third Quartile Completions",
	},
	VideoViewsComplete: {
		name: "Video Completions",
	},
	Engagements: {
		name: "Engagements",
	},
	FullScreenPlays: {
		name: "FullScreen Plays",
	},
	Reactions: {
		name: "Reactions",
	},
	EngagementRate: {
		name: 'Engagement Rate',
		percent: true
	},
	Amount: {
		name: 'Amount',
		isCurrency: true
	},
	IsOpportunity: {
		name: 'Opportunities',
	},
	IsSocialActivity: {
		name: 'Social Activities',
	},
	Follows: {
		name: "Follows",
	},
	AvgCPC: {
		name: "Avg CPC",
		isCurrency: true,
		currencyCode: 'USD',
		currencySymbol: '$'
	},
	AvgCPF: {
		name: "Avg CPF",
		isCurrency: true,
		currencyCode: 'USD',
		currencySymbol: '$'
	},
	AvgCPM: {
		name: "Avg CPM",
		isCurrency: true,
		currencyCode: 'USD',
		currencySymbol: '$'
	},
	AvgCTR: {
		name: "Avg CTR",
		percent: true
	},
	AvgVPI: {
		name: "Avg VPI",
	},
	ProfileFriends: {
		name: 'Profile Friends'
	},
	ProfilePosts: {
		name: 'Profile Posts'
	},
	ProfileLikedPosts: {
		name: 'Profile Liked Posts'
	},
	ItemCount: {
		name: 'Posts'
	},
	ProfileCount: {
		name: 'Authors'
	},
	NumericalSentiment: {
		name: 'Sentiment',
		isNumericEmoji: true
	},
	PageViews: {
		name: 'Page Views',
		isPageView: true
	},
	PageViewsUnique: {
		name: 'Unique Page Views',
		isUniquePageView: true
	},
	PageViewsUniqueAdded: {
		name: 'Unique Page Views',
		isUniquePageView: true
	},
	PageViewsMobile: {
		name: 'Mobile Page Views',
		isPageView: true
	},
	PageViewsMobileAdded: {
		name: 'Mobile Page Views',
		isPageView: true
	},
	PageViewsDesktop: {
		name: 'Desktop Page Views',
		isPageView: true
	},
	PageViewsDesktopAdded: {
		name: 'Desktop Page Views',
		isPageView: true
	},
	VideoWatchtimeAdded:
	{
		name: 'Total Video Watch Time',
		network: Network.TIKTOK,
		isSeconds: true
	},
	FullVideoWatchedRateAdded:
	{
		name: 'Full Video Watch Rate',
		network: Network.TIKTOK,
		percent: true
	},
	HashtagClicksAdded: {
		name: 'Hashtag Clicks',
		network: Network.TWITTER,
	},
	UserProfileClicksAdded: {
		name: 'User Profile Clicks',
		network: Network.TWITTER,
	},
	UrlClicksAdded: {
		name: 'X (Twitter) Link Click',
		network: Network.TWITTER
	},
	LinkedinClicks: {
		name: 'Post Clicks',
		network: Network.LINKED_IN
	},
	DislikesAdded: {
		name: 'Dislikes',
		network: Network.YOUTUBE
	},
	SubscribersGainedAdded: {
		name: 'Subscribers Gained',
		network: Network.YOUTUBE
	},
	SubscribersLostAdded: {
		name: 'Subscribers Lost',
		network: Network.YOUTUBE
	},
	VideosAddedToPlaylistsAdded: {
		name: 'Videos Added to Playlist',
		network: Network.YOUTUBE
	},
	VideosRemovedFromPlaylistsAdded: {
		name: 'Videos Removed from Playlist',
		network: Network.YOUTUBE
	},
	SavedAdded: {
		name: 'Saved',
		network: Network.INSTAGRAM
	},
	Conversations: {
		name: 'Conversations'
	},
	Replies: {
		name: 'Replies',
		network: Network.INSTAGRAM,
		isInstagramStories: true
	},
	AvgReplies: {
		name: 'Avg Replies'
	},
	CloseRate: {
		name: 'Close Rate',
		percent: true
	},
	ReOpenCount: {
		name: 'Re-opens'
	},
	ReOpensRate: {
		name: 'Re-opens Rate',
		percent: true
	},
	AvgTimeToFirstReply: {
		name: 'Avg Time to First Reply',
		isSeconds: true
	},
	AvgTimeToClose: {
		name: 'Avg Time to Close',
		isSeconds: true
	},
	InteractionsAdded: {
		name: 'Interactions'
	},
	AvgInteractions: {
		name: 'Avg Interactions',
		isFloatNumber: true,
	},
	PlaysAdded: {
		name: 'Plays',
		network: Network.INSTAGRAM
	}
};