import yuri from '../utils/yuri/AdminYuri';
import { map_dashboard, map_dashboard_for_list, map_dashboards, map_widget } from '../helpers/AnalyticsHelper';


export default {
	namespaced: true,
	state: {
		dashboards: [],
		currentDashboard: {},
		currentWidget: {},
		isCurrentDashboardChanged: false
	},
	getters: {
		currentDashboardId: state => state.currentDashboard.id
	},
	mutations: {
		setDashboards(state, dashboards)
		{
			state.dashboards = dashboards;
		},
		updateDashboard(state, dashboard)
		{
			let dashboards = [];
			
			for (let oldDashboard of state.dashboards)
			{
				if (dashboard.id === oldDashboard.id)
				{
					dashboards.push(dashboard);
				}
				else
				{
					dashboards.push(oldDashboard);
				}
			}
			
			state.dashboards = dashboards;
		},
		deleteDashboard(state, id)
		{
			state.dashboards = state.dashboards.filter(item => item.id !== id)
		},
		setCurrentDashboard(state, currentDashboard)
		{
			state.currentDashboard = currentDashboard;
		},
		deleteWidget(state, widgetId)
		{
			state.currentDashboard.widgets = state.currentDashboard.widgets.filter(item => item.id !== widgetId)
		},
		updateWidget(state, widget)
		{
			let widgets = state.currentDashboard.widgets;
			let newWidgets = [];
			
			for (let oldWidget of widgets)
			{
				if (widget.id === oldWidget.id)
				{
					newWidgets.push(widget);
				}
				else
				{
					newWidgets.push(oldWidget);
				}
			}
			
			state.currentDashboard.widgets = newWidgets;
		},
		addWidget(state, widget)
		{
			state.currentDashboard.widgets = [
				...state.currentDashboard.widgets,
				widget
			]
		},
		setIsCurrentDashboardChanged(state, isCurrentDashboardChanged)
		{
			state.isCurrentDashboardChanged = isCurrentDashboardChanged;
		},
		setCurrentWidget(state, widget)
		{
			state.currentWidget = widget;
		}
	},
	actions: {
		async loadDashboards({commit})
		{
			let res = await yuri.get('/analytics-dashboard/index');
			
			commit('setDashboards', map_dashboards(res));
		},
		async loadDashboard({commit}, {id})
		{
			let res = await yuri.get('/analytics-dashboard/get', {id});
			
			commit('setCurrentDashboard', map_dashboard(res));
		},
		async createDashboard({ dispatch}, {name, description, visibility})
		{
			let res = await yuri.post('/analytics-dashboard/create', {name, description, visibility});
			
			await dispatch('loadDashboards');
		},
		async saveCurrentDashboard({state, commit}, params = {})
		{
			commit('setIsCurrentDashboardChanged', false);

			let res = await yuri.post('/analytics-dashboard/update', {
				...state.currentDashboard,
				...params
			});
			
			commit('setCurrentDashboard', map_dashboard(res));
			commit('setIsCurrentDashboardChanged', false);
		},
		async updateDashboard({state, commit}, {id, name, description, visibility})
		{
			let res = await yuri.post('/analytics-dashboard/update', {id, name, description, visibility});
			
			commit('updateDashboard', map_dashboard_for_list(res));
			
			if (res.Id === state.currentDashboard.id)
			{
				commit('setCurrentDashboard', map_dashboard(res));
			}
		},
		async copyDashboard({dispatch}, id)
		{
			let res = await yuri.post('/analytics-dashboard/copy', {id});
			
			await dispatch('loadDashboards');
		},
		async deleteDashboard({commit}, id)
		{
			await yuri.post('/analytics-dashboard/delete', {id});
			
			commit('deleteDashboard', id)
		},
		async queryWidget({}, {
			id = '',
			widgetId = '', 
			datePeriod,
			groups = [],
			metrics = [],
			additionalColumns = [],
			orderBy = [],
			limit = null,
			subjectType,
			chartType,
			filter = {},
			withCursor = null,
			withTotals = null,
			prev = null,
			next = null
		})
		{
			return await yuri.get('/analytics-query/query', {id, widgetId, datePeriod, groups, metrics, additionalColumns, orderBy, limit, subjectType, chartType, filter, withCursor, withTotals, prev, next});
		},
		async createWidget({state, commit, dispatch}, {
			id,
			name,
			description,
			x,
			y,
			height,
			width,
			groups = [],
			metrics = [],
			additionalColumns = [],
			orderBy = [],
			limit = null,
			subjectType,
			chartType,
			filter
		})
		{
			let res = await yuri.get('/analytics-dashboard/create-widget', {id, name, description, x, y, height, width, groups, metrics, additionalColumns, orderBy, limit, subjectType, chartType, filter});
			
			if (res.Id !== state.currentDashboard.id)
			{
				return;
			}		
			
			commit('addWidget', map_widget(res.Widget));
			
			setTimeout(() =>
			{
				if (state.isCurrentDashboardChanged)
				{
					dispatch('saveCurrentDashboard');
				}
			});
		},
		async updateWidget({state, commit, dispatch}, {
			dashboardId,
			widgetId,
			name,
			description,
			x,
			y,
			height,
			width,
			groups = [],
			metrics = [],
			additionalColumns = [],
			orderBy = [],
			limit = null,
			subjectType,
			chartType,
			filter
		})
		{
			let res = await yuri.get('/analytics-dashboard/update-widget', {id: dashboardId, widgetId, name, description, x, y, height, width, groups, metrics, additionalColumns, orderBy, limit, subjectType, chartType, filter});
			
			if (is(state.currentWidget) && res.Widget.Id === state.currentWidget.id)
			{
				commit('setCurrentWidget', {
					...state.currentWidget, 
					...map_widget(res.Widget)
				});
			}
			
			if (res.Id !== state.currentDashboard.id)
			{
				return;
			}
			
			await commit('updateWidget', map_widget(res.Widget));
			
			setTimeout(() =>
			{
				if (state.isCurrentDashboardChanged)
				{
					dispatch('saveCurrentDashboard');
				}
			});
		},
		async deleteWidget({state, commit, dispatch}, {dashboardId, widgetId})
		{
			await yuri.get('/analytics-dashboard/delete-widget', {id: dashboardId, widgetId});
			
			if (dashboardId !== state.currentDashboard.id)
			{
				return;
			}
					
			commit('deleteWidget', widgetId);
			
			setTimeout(() =>
			{
				if (state.isCurrentDashboardChanged)
				{
					dispatch('saveCurrentDashboard');
				}
			});
		},
		async getWidget({state, commit, dispatch}, {dashboardId, widgetId})
		{
			let res = await yuri.get('/analytics-dashboard/get-widget', {id: dashboardId, widgetId});
			
			commit('setCurrentWidget', map_widget(res));
		},
		updateCurrentWidgets({state, commit}, widgets)
		{
			commit('setCurrentDashboard', {
				...state.currentDashboard,
				widgets
			});
			commit('setIsCurrentDashboardChanged', true);
		}
	}
}