export const Exports = {
	Type: {
		DASHBOARD: 'dashboard',
		REPORT: 'report',
		ROW_DATA: 'row_data'
	},
	QueryType: {
		ANALYTICS: 'analytics',
		LISTENING: 'listening',
		MEDIA_LIBRARY: 'media_library',
		POST_REPORT: 'csv_post_report',
		CLICK_REPORT: 'csv_click_report',
		PROFILE_REPORT: 'csv_profile_report',
		PROFILE_FOLLOWERS_REPORT: 'csv_profile_followers_report'
	},
	Destination: {
		AMAZON_S3: 'S3_bucket',
		AZURE: 'Azure',
		SFTP: 'SFTP',
		FTP: 'FTP',
		EMAIL: 'email',
		SLACK: 'Slack'
	},
	Frequency: {
		DAILY: 0,
		WEEKLY: 1,
		MONTHLY: 2,
		ONE_TIME: 3
	}
};