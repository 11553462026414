export default class FunctionHelpers
{
	
	/**
	 * @template DebounceFunction
	 * @param {DebounceFunction} func
	 * @param {number} wait
	 * @return {DebounceFunction}
	 */
	static debounce(func, wait)
	{
		let timeout;

		return (...args) =>
		{
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		}
	}
	
	/**
	 * @template DebounceFunction
	 * @param {DebounceFunction} func
	 * @param {number} wait
	 */
	static asyncDebounce(func, wait)
	{
		let timeout;

		return (...args) => new Promise(resolve =>
		{
			const later = () => {
				clearTimeout(timeout);
				resolve(func(...args));
			};
			
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		});
	}
	
	static getAutocompleteLoadFunctions(ajax, url, params, fieldName = 'q')
	{
		return function (query, page = 0)
		{
			let requestParams;
			
			if (typeof query === 'object' && !Array.isArray(query))
			{
				requestParams = {
					count: 20,
					...params,
					page,
					...query
				}
			}
			else
			{
				requestParams = {
					count: 20,
					...params,
					page
				};
				requestParams[fieldName] = query;
			}
			
			return new Promise(resolve => {
				ajax.get(url, requestParams)
					.onDone(response => {
						let res = {};
						res.options = response.Items;
						res.hasMore = (page + 1) * requestParams.count < response.Total;
						resolve(res);
					});
			});
		}
	}
	
	static isEqual(obj1, obj2)
	{
		if (obj1 === null || obj2 === null)
		{
			return obj1 === obj2;
		}
		
		if (Array.isArray(obj1))
		{
			if (!Array.isArray(obj2))
			{
				return false;
			}

			if (obj1.length !== obj2.length)
			{
				return false;
			}
			
			for (let i = 0; i < obj1.length; i++)
			{
				if (!this.isEqual(obj1[i], obj2[i]))
				{
					return  false;
				}
			}
			
			return true;
		}

		if (Array.isArray(obj2))
		{
			return false;
		}
		
		
		if (typeof obj1 === 'object')
		{
			if (typeof obj2 !== 'object')
			{
				return false;
			}
			
			if (!this.isEqual(Object.keys(obj1).sort(), Object.keys(obj2).sort()))
			{
				return false;
			}
			
			for (let key in obj1)
			{
				if (!obj1.hasOwnProperty(key))
				{
					if (obj2.hasOwnProperty(key))
					{
						return false;
					}
					
					continue;
				}
				
				if (!this.isEqual(obj1[key], obj2[key]))
				{
					return false;
				}
			}
			
			return true;
		}
		
		if (typeof obj2 === 'object')
		{
			return false;
		}
		
		return obj1 === obj2;
	}
	
	static isValue(val)
	{
		return !(is.null(val) || is.undefined(val));
	}
	
	static getFunctionIfExists(func, ...additionalParams)
	{
		if (typeof func === 'function')
		{
			return (...params) =>  func(...params, ...additionalParams);
		}
		
		return undefined;
	}
}

export function isEqual(obj1, obj2) { return FunctionHelpers.isEqual(obj1, obj2) }
export function sleep(timeout = 0)
{
	return new Promise(resolve =>
	{
		setTimeout(resolve, timeout);
	});
}


/**
 * @template T
 * @param {T} obj
 * @return {T}
 */
export function deepCopy(obj)
{
	return JSON.parse(JSON.stringify(obj));
}