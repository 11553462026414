<template>
	<lobster-layout-section :loading="isLoading">
			<lobster-table full-screen>
				<lobster-table-row header>
					<lobster-table-column-title>
						Name
					</lobster-table-column-title>
					<lobster-table-column-title>
						Description
					</lobster-table-column-title>
					<lobster-table-column-title>
						Privacy
					</lobster-table-column-title>
					<lobster-table-column-title>
						Created By
					</lobster-table-column-title>
					<lobster-table-column-title>
						Created On
					</lobster-table-column-title>
					<lobster-table-column-title small/>
				</lobster-table-row>
				<lobster-table-row 
					v-for="dashboard in dashboards"
					clickable
					:key="dashboard.Id"
					@click="handleRowClick(dashboard)">
					<lobster-table-cell>
						<lobster-button
							inline-link
							:to="{
								name: 'dashboard',
								params: {
									id: dashboard.id
								}
							}">
							{{ dashboard.name }}
						</lobster-button>
					</lobster-table-cell>
					<lobster-table-cell>
						{{ dashboard.description }}
					</lobster-table-cell>
					<lobster-table-cell>
						{{ dashboard.visibility }}
					</lobster-table-cell>
					<lobster-table-cell>
						<img 
							class="dashboard-list__user-avatar"
							:src="$gravatar(dashboard.createdBy.email)"/>
						{{ dashboard.createdBy.name }}
					</lobster-table-cell>
					<lobster-table-cell>
						{{ dashboard.createdOn }}
					</lobster-table-cell>
					<template #actions-cell>
						<lobster-action-button icon-name="edit" @click.stop="handleEditClick(dashboard)"/>
						<lobster-action-button icon-name="copy" @click.stop="handleCopyClick(dashboard)"/>
						<lobster-action-button deny icon-name="trash-can" @click.stop="handleDeleteClick(dashboard)"/>
					</template>
				</lobster-table-row>
			</lobster-table>
		</lobster-layout-section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardModal from './DashboardModal';


export default {
	name: "DashboardsList",
	components: {DashboardModal},
	data()
	{
		return {
			isLoading: false
		}
	},
	computed: {
		...mapState({
			dashboards: state => state.analytics.dashboards
		})
	},
	methods: {
		...mapActions({
			loadDashboards: 'analytics/loadDashboards',
			deleteDashboard: 'analytics/deleteDashboard',
			copyDashboard: 'analytics/copyDashboard'
		}),
		async load()
		{
			this.isLoading = true;
			
			try
			{
				await this.loadDashboards();
			}
			finally
			{
				this.isLoading = false;
			}
		},
		handleEditClick(dashboard)
		{
			this.$showModal({
				component: DashboardModal,
				bind: {
					dashboard
				}
			});
		},
		async handleDeleteClick(dashboard)
		{
			let confirmed = await this.$dialog(`Are you sure you want delete ${dashboard.name} dashboard?`)

			if (confirmed)
			{
				this.handleConfirm({
					id: dashboard.id,
					isDelete: true
				});
			}
		},
		async handleCopyClick(dashboard)
		{
			let confirmed = await this.$dialog(`Are you sure you want copy ${dashboard.name} dashboard?`)

			if (confirmed)
			{
				this.handleConfirm({
					id: dashboard.id,
					isCopy: true
				});
			}
		},
		handleConfirm({id, isCopy, isDelete})
		{
			if (isCopy)
			{
				this.copyDashboard(id);
			}
			else if (isDelete)
			{
				this.deleteDashboard(id);
			}
		},
		handleRowClick(dashboard)
		{
			this.$router.push({
				name: 'dashboard',
				params: {
					id: dashboard.id
				}
			})
		}
	},
	created()
	{
		this.load();
	}
}
</script>

<style lang="scss" scoped>
.dashboard-list__user-avatar {
	border-radius: 50%;
	height: 24px;
	width: 24px;
	margin-right: 6px;
}
</style>