<template>
	<draggable-select
		ref="order"
		:value="currentSelectOrder"
		label="Order"
		:options="options"
		class="draggable-order-select"
		@input="handleOrderSelect">
		<template #image="{value}">
			<lobster-abstract-button
				class="draggable-order-select__icon-button"
				aria-label="Change order"
				@click="handleOrderClick(value)">
				<lobster-icon
					size="small"
					:name="getIcon(value)"/>
			</lobster-abstract-button>
		</template>
	</draggable-select>
</template>

<script>
import { is } from 'plankton-esm';
import DraggableSelect from './DraggableSelect';


export default {
	name: "DraggableOrderSelect",
	components: {DraggableSelect},
	props: {
		value: {
			type: Array,
			required: true
		},
		options: Array
	},
	data()
	{
		return {
		}
	},
	computed: {
		currentSelectOrder()
		{
			return this.value.map(val => val.field);
		},
		valueByField()
		{
			let valueByField = {};
			
			for (let val of this.value)
			{
				valueByField[val.field] = val;
			}
			
			return valueByField;
		}
	},
	methods: {
		handleOrderSelect(value)
		{
			let currentValue = [];
			
			for (let orderValue of value)
			{
				if (is(this.valueByField[orderValue]))
				{
					currentValue.push(this.valueByField[orderValue]);
				}
				else
				{
					currentValue.push({
						field: orderValue,
						direction: 'ascending'
					});
				}
			}
			
			this.$emit('input', currentValue);
		},
		getIcon(value)
		{
			let val = this.valueByField[value];
			
			if (val.direction === 'ascending')
			{
				return 'arrow--up';
			}
			else
			{
				return 'arrow--down';
			}
		},
		handleOrderClick(value)
		{
			let currentValue = [];

			for (let orderValue of this.value)
			{
				if (orderValue.field === value)
				{
					let newDirection;

					if (orderValue.direction === 'ascending')
					{
						newDirection = 'descending';
					}
					else
					{
						newDirection = 'ascending';
					}

					
					currentValue.push({
						...orderValue,
						direction: newDirection
					});
				}
				else
				{
					currentValue.push(orderValue);
				}
			}

			this.$emit('input', currentValue);
		}
		
	}
}
</script>

<style lang="scss" scoped>
.draggable-order-select__icon-button {
	background-color: $colorLight;
	border: 1px solid $colorBorder;
	transition: all 0.1s;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: $colorPrimary;
			background-color: $colorPrimaryTint80;
		}

		&:active {
			color: $colorPrimary;
			background-color: $colorPrimaryTint90;
		}
	}
}
</style>