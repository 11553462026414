export const DatePeriodType = Object.freeze({
	LAST_7_DAYS: 'last_7_days',
	LAST_30_DAYS: 'last_30_days',
	LAST_60_DAYS: 'last_60_days',
	LAST_90_DAYS: 'last_90_days',
	LAST_120_DAYS: 'last_120_days',

	CURRENT_WEEK_SUNDAY: 'current_week_sunday',
	LAST_WEEK_SUNDAY: 'last_week_sunday',
	CURRENT_LAST_WEEK_SUNDAY: 'current_last_week_sunday',

	CURRENT_MONTH: 'current_month',
	LAST_MONTH: 'last_month',
	LAST_3_MONTHS: 'last_3_months',
	LAST_6_MONTHS: 'last_6_months',
	LAST_12_MONTHS: 'last_12_months',
	CURRENT_LAST_MONTH: 'current_last_month',
	CURRENT_LAST_3_MONTHS: 'current_last_3_months',
	CURRENT_LAST_6_MONTHS: 'current_last_6_months',
	CURRENT_LAST_12_MONTHS: 'current_last_12_months',

	CURRENT_QUARTER: 'current_quarter',
	LAST_QUARTER: 'last_quarter',
	CURRENT_LAST_QUARTER: 'current_last_quarter',

	CURRENT_FISCAL_QUARTER: 'current_fiscal_quarter',
	LAST_FISCAL_QUARTER: 'last_fiscal_quarter',
	CURRENT_LAST_FISCAL_QUARTER: 'current_last_fiscal_quarter',

	CURRENT_YEAR: 'current_year',
	LAST_YEAR: 'last_year',
	CURRENT_LAST_YEAR: 'current_last_year',

	CURRENT_FISCAL_YEAR: 'current_fiscal_year',
	LAST_FISCAL_YEAR: 'last_fiscal_year',
	CURRENT_LAST_FISCAL_YEAR: 'current_last_fiscal_year',
});