var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-body",
    [
      _c("lobster-layout-action-bar", {
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function () {
              return [
                _c("lobster-button", { attrs: { to: _vm.navLink } }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.navMessage) + "\n\t\t\t"),
                ]),
                _vm._v(" "),
                _c("lobster-action-button", {
                  attrs: { accept: "", "icon-name": "filter" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "pagination",
            fn: function () {
              return [
                _c("lobster-paginator", {
                  attrs: { "items-per-page": 20, "total-items": 500 },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "lobster-layout-section",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "lobster-table",
            [
              _c(
                "lobster-table-row",
                { attrs: { header: "" } },
                [
                  _c("lobster-table-column-title", [_vm._v("Column 1")]),
                  _vm._v(" "),
                  _c("lobster-table-column-title", [_vm._v("Column 2")]),
                  _vm._v(" "),
                  _c("lobster-table-column-title", [_vm._v("Column 3")]),
                  _vm._v(" "),
                  _c("lobster-table-column-title", [_vm._v("Column 4")]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.tableData, function (row, index) {
                return _c(
                  "lobster-table-row",
                  { key: index },
                  [
                    _c("lobster-table-cell", [_vm._v(_vm._s(row.column1))]),
                    _vm._v(" "),
                    _c("lobster-table-cell", [_vm._v(_vm._s(row.column2))]),
                    _vm._v(" "),
                    _c("lobster-table-cell", [_vm._v(_vm._s(row.column3))]),
                    _vm._v(" "),
                    _c("lobster-table-cell", [_vm._v(_vm._s(row.column4))]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }