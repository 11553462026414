var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-form",
    {
      staticClass: "report-form",
      attrs: { "native-validate": "", "modal-view": "" },
      on: { submit: _vm.handleSubmit },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.withBack
                ? _c(
                    "lobster-button",
                    {
                      attrs: { secondary: "" },
                      on: { click: _vm.handleBackClick },
                    },
                    [_vm._v("\n\t\t\tBack\n\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "lobster-button",
                {
                  staticClass: "report-form__submit-button",
                  attrs: {
                    type: "submit",
                    loading: _vm.isSubmitting,
                    disabled: !_vm.canSave,
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.isEdit ? "Save" : "Add") + "\n\t\t"
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "report-form__container" },
        [
          _c("report-form-sidebar", {
            staticClass: "report-form__sidebar",
            attrs: {
              "with-visibility": "",
              filter: _vm.filter,
              "chart-type": _vm.chartType,
              "subject-type": _vm.subjectType,
              groups: _vm.groups,
              metrics: _vm.metrics,
              "additional-columns": _vm.additionalColumns,
              limit: _vm.limit,
              "order-by": _vm.orderBy,
              metadata: _vm.metadata,
              visibility: _vm.visibility,
            },
            on: {
              "update:filter": function ($event) {
                _vm.filter = $event
              },
              "update:chartType": function ($event) {
                _vm.chartType = $event
              },
              "update:chart-type": function ($event) {
                _vm.chartType = $event
              },
              "update:groups": function ($event) {
                _vm.groups = $event
              },
              "update:metrics": function ($event) {
                _vm.metrics = $event
              },
              "update:additionalColumns": function ($event) {
                _vm.additionalColumns = $event
              },
              "update:additional-columns": function ($event) {
                _vm.additionalColumns = $event
              },
              "update:limit": function ($event) {
                _vm.limit = $event
              },
              "update:orderBy": function ($event) {
                _vm.orderBy = $event
              },
              "update:order-by": function ($event) {
                _vm.orderBy = $event
              },
              "update:metadata": function ($event) {
                _vm.metadata = $event
              },
              "update:visibility": function ($event) {
                _vm.visibility = $event
              },
            },
          }),
          _vm._v(" "),
          _c("report-form-chart", {
            staticClass: "report-form__chart",
            attrs: {
              name: _vm.name,
              description: _vm.description,
              config: _vm.currentConfig,
              "chart-type": _vm.chartType,
              "subject-type": _vm.subjectType,
              metrics: _vm.metrics,
              groups: _vm.groups,
              "additional-columns": _vm.additionalColumns,
              limit: _vm.limit,
              "order-by": _vm.orderBy,
              metadata: _vm.metadata,
              labels: _vm.labels,
              filter: _vm.filter,
              "date-period": _vm.datePeriod,
            },
            on: {
              "update:name": function ($event) {
                _vm.name = $event
              },
              "update:description": function ($event) {
                _vm.description = $event
              },
              "edit-metric": _vm.handleEditMetric,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }