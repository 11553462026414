<template>
	<lobster-dynamic-modal
		v-on="$listeners"
		v-bind="$attrs"
		modal-title="Create Dashboard">
		<lobster-form
			modal-view
			submit-button-text="Save"
			:is-loading="isLoading"
			@submit="handleSubmit">
			<lobster-input
				label="Name"
				required
				v-model="name"
				:validators="RequiredValidator"/>
			<lobster-input
				label="Description"
				v-model="description"/>
			<lobster-select
				field-label="Privacy"
				v-model="visibility"
				:options="visibilityOptions"
				:reduce="option => option.value"/>
		</lobster-form>
	</lobster-dynamic-modal>
</template>

<script>
import { RequiredValidator } from 'lobster-ui';
import { mapActions } from 'vuex';


export default {
	name: "DashboardModal",
	props: {
		dashboard: {
			type: Object,
			default: () => ({})
		}
	},
	data()
	{
		return {
			isLoading: false,
			name: this.dashboard.name,
			description: this.dashboard.description,
			visibility: this.dashboard.visibility || 'private',
			id: this.dashboard.id,
			visibilityOptions: [
				{
					label: 'Public',
					value: 'shared'
				},
				{
					label: 'Private',
					value: 'private'
				}
			], 
			RequiredValidator,
		}
	},
	methods: {
		...mapActions({
			createDashboard: 'analytics/createDashboard',
			updateDashboard: 'analytics/updateDashboard'
		}),
		async create()
		{
			await this.createDashboard({
				name: this.name,
				description: this.description,
				visibility: this.visibility
			});
		},
		async update()
		{
			await this.updateDashboard({
				id: this.id,
				name: this.name,
				description: this.description,
				visibility: this.visibility
			});
		},
		async handleSubmit()
		{
			this.isLoading = true;
			
			try
			{
				if (this.$is(this.id))
				{
					await this.update();
				}
				else
				{
					await this.create();
				}
				
				this.$emit('input', false);
			}
			finally
			{
				this.isLoading = false;
			}
		}
	}
}
</script>

<style scoped>

</style>