import { SourceConfig } from '../configs/SourceConfig';
import { is } from 'plankton-esm';
import { ChartType } from 'kraken-charts';
import { DimensionsConfig, MetricsConfig, ReportDataSourcesConfig } from '@analytics';
import { ChartTypesConfig } from '../configs/ChartTypesConfig';
import { NETWORKS } from '../consts/Networks';
import { deepCopy } from './FunctionHelpers';
import { SocialBIDatePeriodTypes } from '../configs/DatePeriodTypes';
import { IncompatibleGroupsByMetric, IncompatibleMetricsByGroup } from '../configs/GroupMetricCompatibilityConfig';


function _merge_dimensions_filters(filters1, filters2)
{
	if (!is(filters1))
	{
		filters1 = [];
	}
	
	if (!is(filters2))
	{
		filters2 = [];
	}
	
	let filtersByColumn = {};
	
	for (let filter of filters1)
	{
		filtersByColumn[filter.column] = filter;
	}
	
	for (let filter of filters2)
	{
		filtersByColumn[filter.column] = filter;
	}
	
	return Object.values(filtersByColumn);
}

function _merge_metrics_filters(filters1, filters2)
{
	if (!is(filters1))
	{
		filters1 = [];
	}
	
	if (!is(filters2))
	{
		filters2 = [];
	}
	
	let filtersByMetric = {};
	
	for (let filter of filters1)
	{
		filtersByMetric[filter.metric] = filter;
	}
	
	for (let filter of filters2)
	{
		filtersByMetric[filter.metric] = filter;
	}
	
	return Object.values(filtersByMetric);
}

function _merge_tags_filters(filters1, filters2)
{
	if (!is(filters1))
	{
		filters1 = [];
	}
	
	if (!is(filters2))
	{
		filters2 = [];
	}
	
	let filters1ByColumn = {};
	let filters2ByColumn = {};
	let filtersByColumn = {};
	let filters = [];
	
	for (let filter of filters1)
	{
		if (!is.defined(filters1ByColumn[filter.column]))
		{
			filters1ByColumn[filter.column] = [];
		}
		
		filters1ByColumn[filter.column].push(filter);
	}
	
	for (let filter of filters2)
	{
		if (!is.defined(filters2ByColumn[filter.column]))
		{
			filters2ByColumn[filter.column] = [];
		}
		
		filters2ByColumn[filter.column].push(filter);
	}
	
	for (let column in filters1ByColumn)
	{
		filtersByColumn[column] = filters1ByColumn[column];
	}
	
	for (let column in filters2ByColumn)
	{
		filtersByColumn[column] = filters2ByColumn[column];
	}
	
	for (let column in filtersByColumn)
	{
		filters.push(...filtersByColumn[column]);
	}
	
	return filters;
}

function _map_dashboard_filters(data)
{
	let filters = {};
	
	for (let dataSource in data.Filters)
	{
		if (!data.Filters.hasOwnProperty(dataSource))
		{
			continue;
		}
		
		filters[dataSource] = {
			dimensions: data.Filters[dataSource].Dimensions.map(item => ({
				column:	item.Column,
				values:	item.Values
			})),
			tags: data.Filters[dataSource].Tags.map(item => (is(item.Value) ? ({
				column:   item.Column,
				operator: item.Operator,
				value:    item.Value
			}) : ({
				column:   item.Column,
				operator: item.Operator
			})))
		}
	}
	
	return filters;
}

function _map_dashboard_filter(data)
{
	let filters = {};
	let dataSources = Array.from(new Set(data.Widgets.map(item => item.SubjectType)));
	
	for (let dataSource of dataSources)
	{
		filters[dataSource] = {
			dimensions: data.Filter.Dimensions
				.filter(item => SourceConfig[dataSource].groups.indexOf(item.Column) !== -1)
				.map(item => ({
					column:	item.Column,
					values:	item.Values
				})),
			tags: data.Filter.Tags
				.filter(item => SourceConfig[dataSource].groups.indexOf(item.Column) !== -1)
				.map(item => (is(item.Value) ? ({
					column:   item.Column,
					operator: item.Operator,
					value:    item.Value
				}) : ({
					column:   item.Column,
					operator: item.Operator
				})))
		}
	}
	
	return filters;
}


export function get_report_filters(data)
{
	if (!is(data) || !is(data.Filter))
	{
		return {
			dimensions: [],
			tags: [],
			metrics: []
		}
	}
	
	return {
		dimensions: data.Filter.Dimensions.map(item => ({
			column:	item.Column,
			values:	item.Values
		})),
		tags: data.Filter.Tags.map(item => (is(item.Value) ? ({
			column:   item.Column,
			operator: item.Operator,
			value:    item.Value
		}) : ({
			column:   item.Column,
			operator: item.Operator
		}))),
		metrics: data.Filter.Metrics.map(item => ({
			metric:   item.Metric,
			operator: item.Operator,
			value:    item.Value
		}))
	}
}

export function get_dashboard_filters(data)
{
	if (is(data.Filters))
	{
		return _map_dashboard_filters(data);
	}
	else if (is(data.Filter))
	{
		return _map_dashboard_filter(data);
	}
	else
	{
		return {};
	}
}

export function map_widget(data)
{
	let widget = {
		x: data.X,
		y: data.Y,
		width: data.Width,
		height: data.Height,
		id: data.Id,
		name: data.Name,
		description: data.Description,
		type: data.Type,
		subjectType: data.SubjectType || data.Source,
		chartType: data.ChartType,
		groups: data.Groups,
		metrics: data.Metrics,
		labels: is(data.Labels) ? data.Labels : {},
		additionalColumns: data.AdditionalColumns,
		orderBy: is(data.OrderBy) ? data.OrderBy.map(item => ({
			field: item.Field,
			direction: item.Direction
		})) : [],
		limit: data.Limit,
		filter: get_report_filters(data),
		metadata: is(data.Metadata) ? JSON.parse(data.Metadata) : {}
	};
	
	if (is(data.DatePeriod))
	{
		widget.datePeriod = {
			to: data.DatePeriod.To,
			from: data.DatePeriod.From,
			type: data.DatePeriod.Type
		}
	}
	
	return widget;
}

export function map_dashboard(data)
{
	let dashboard = {
		id: data.Id,
		name: data.Name,
		description: data.Description,
		type: data.Type,
		visibility: data.Visibility,
		datePeriod: {
			to: data.DatePeriod.To,
			from: data.DatePeriod.From,
			type: data.DatePeriod.Type
		},
		teams: data.Teams,
		widgets: data.Widgets.map(item => map_widget(item)),
		status: data.Status,
		filters: get_dashboard_filters(data)
	};
	
	if (is(data.Dimensions))
	{
		dashboard.dimensions = data.Dimensions;
	}
	
	return dashboard;
}

export function map_dashboard_for_list(item)
{
	return {
		id: item.Id,
		name: item.Name,
		description: item.Description,
		visibility: item.Visibility,
		createdOn: item.Created,
		userId: item.UserId,
		createdBy: {
			name: item.CreatedBy.Name,
			email: item.CreatedBy.Email
		}
	}
}

export function map_dashboards(data)
{
	if (!is(data))
	{
		return [];
	}
	
	return data.map(item => map_dashboard_for_list(item));
}

export function merge_filters(filters1, filters2)
{
	return {
		dimensions: _merge_dimensions_filters(filters1.dimensions, filters2.dimensions),
		metrics: _merge_metrics_filters(filters1.metrics, filters2.metrics),
		tags: _merge_tags_filters(filters1.tags, filters2.tags),
	}
}

export function map_reports(data)
{
	if (!is(data))
	{
		return [];
	}

	return data.map(item => map_report(item));
}

export function map_report(report)
{
	let metadata = is(report.Metadata)
		? JSON.parse(report.Metadata) || {}
		: {};

	return {
		accountId: report.AccountId,
		additionalColumns: report.AdditionalColumns,
		appSource: report.AppSource,
		chartType: report.ChartType,
		created: report.created,
		createdBy: report.CreatedBy,
		datePeriod: report.DatePeriod,
		description: report.Description,
		extraColumns: report.ExtraColumns,
		filter: get_report_filters(report),
		groupId: report.GroupId,
		groups: report.Groups,
		id: report.Id,
		isTemplate: report.IsTemplate,
		labels: report.Labels,
		limit: report.Limit,
		metadata,
		metrics: report.Metrics,
		modified: report.Modified,
		modifiedBy: report.ModifiedBy,
		name: report.Name,
		operators: report.Operators,
		orderBy: report.OrderBy,
		source: report.Source,
		status: report.Status,
		type: report.Type,
		userId: report.UserId,
		visibility: report.Visibility
	}
}

export function map_group(group)
{
	return {
		created: group.Created,
		description: group.Description,
		id: group.ID,
		modified: group.Modified,
		name: group.Name,
		visibility: group.Visibility,
	}
}

function processLinkedinDemographicsMetrics(metricsOptions, subjectType, currentGroups, currentMetrics, currentFilter)
{
	let noTypeMetricSelected = false;
	let isPageViewSelected = false;
	let isUniquePageViewSelected = false;

	if (is.array.notEmpty(currentMetrics))
	{
		let metricConfig = MetricsConfig[currentMetrics[0]];

		if (metricConfig.isPageView)
		{
			isPageViewSelected = true;
		}
		else if (metricConfig.isUniquePageView)
		{
			isUniquePageViewSelected = true;
		}
		else
		{
			noTypeMetricSelected = true;
		}
	}
	else if (is.array.notEmpty(currentFilter.metrics))
	{
		let metricConfig = MetricsConfig[currentFilter.metrics[0]];

		if (metricConfig.isPageView)
		{
			isPageViewSelected = true;
		}
		else if (metricConfig.isUniquePageView)
		{
			isUniquePageViewSelected = true;
		}
		else
		{
			noTypeMetricSelected = true;
		}
	}

	let blockedMetrics = [];

	for (let group of currentGroups)
	{
		if (is.array.notEmpty(IncompatibleMetricsByGroup[group]))
		{
			blockedMetrics.push(...IncompatibleMetricsByGroup[group]);
		}
	}

	if (is.array.notEmpty(currentFilter.dimensions))
	{
		for (let dimension of currentFilter.dimensions)
		{
			if (is.array.notEmpty(IncompatibleMetricsByGroup[dimension]))
			{
				blockedMetrics.push(...IncompatibleMetricsByGroup[dimension]);
			}
		}
	}

	let blockedMetricsSet = new Set(blockedMetrics);

	for (let option of metricsOptions)
	{
		let metricConfig = MetricsConfig[option.value];

		if (blockedMetricsSet.has(option.value))
		{
			option.isSelectable = false;
		}
		else if (isPageViewSelected && !metricConfig.isPageView)
		{
			option.isSelectable = false;
		}
		else if (isUniquePageViewSelected && !metricConfig.isUniquePageView)
		{
			option.isSelectable = false;
		}
		else if (noTypeMetricSelected && (metricConfig.isPageView || metricConfig.isUniquePageView))
		{
			option.isSelectable = false;
		}
		else
		{
			option.isSelectable = true;
		}
	}
}
function processLinkedinDemographicsGroups(groupsOptions, subjectType, currentGroups, currentMetrics, currentFilter)
{
	let disableLinkedInFacet = is(currentGroups.find(group => DimensionsConfig[group].isLinkedInFacet));

	if (!disableLinkedInFacet && is.array.notEmpty(currentFilter.dimensions))
	{
		disableLinkedInFacet = is(currentFilter.dimensions.find(filter => DimensionsConfig[filter.column].isLinkedInFacet));
	}

	let blockedGroups = [];

	for (let metric of currentMetrics)
	{
		if (is.array.notEmpty(IncompatibleGroupsByMetric[metric]))
		{
			blockedGroups.push(...IncompatibleGroupsByMetric[metric]);
		}
	}

	let blockedGroupsSet = new Set(blockedGroups);

	for (let option of groupsOptions)
	{
		if (blockedGroupsSet.has(option.value))
		{
			option.isSelectable = false;
		}
		else if (disableLinkedInFacet && DimensionsConfig[option.value].isLinkedInFacet)
		{
			option.isSelectable = false;
		}
	}
}

export function getDateFilterSelectOptions()
{
	const datePeriodTypes = Object.entries(SocialBIDatePeriodTypes).map(([key, value]) => ({
		value: key,
		label:value
	}));

	return [
		{
			isGroup: true,
			groupLabel: 'Days',
			options: datePeriodTypes.filter(item => item.value.indexOf('days') !== -1)
		},
		{
			isGroup: true,
			groupLabel: 'Weeks',
			options: datePeriodTypes.filter(item => item.value.indexOf('week') !== -1)
		},
		{
			isGroup: true,
			groupLabel: 'Months',
			options: datePeriodTypes.filter(item => item.value.indexOf('month') !== -1)
		},
		{
			isGroup: true,
			groupLabel: 'Quarters',
			options: datePeriodTypes.filter(item => item.value.indexOf('quarter') !== -1 && item.value.indexOf('fiscal') === -1)
		},
		{
			isGroup: true,
			groupLabel: 'Fiscal Quarters (FQ)',
			options: datePeriodTypes.filter(item => item.value.indexOf('quarter') !== -1 && item.value.indexOf('fiscal') !== -1)
		},
		{
			isGroup: true,
			groupLabel: 'Years',
			options: datePeriodTypes.filter(item => item.value.indexOf('year') !== -1 && item.value.indexOf('fiscal') === -1)
		},
		{
			isGroup: true,
			groupLabel: 'Fiscal Years (FY)',
			options: datePeriodTypes.filter(item => item.value.indexOf('year') !== -1 && item.value.indexOf('fiscal') !== -1)
		}];
}

export function getGroupsOptions(subjectType, currentGroups, currentMetrics, currentFilter)
{
	if (!ReportDataSourcesConfig[subjectType]?.dimensions)
	{
		return [];
	}

	let groupsOptions = ReportDataSourcesConfig[subjectType].dimensions
		.filter(option => is(DimensionsConfig[option]))
		.filter(option => !DimensionsConfig[option].isHidden)
		.map(dimension => ({
			value: dimension,
			label: DimensionsConfig[dimension].name,
			isSelectable: true
		}));

	if (!is(groupsOptions))
	{
		return [];
	}

	if (subjectType === 'linkedInDemographics')
	{
		processLinkedinDemographicsGroups(groupsOptions, subjectType, currentGroups, currentMetrics, currentFilter);
	}

	let isDateRelated = item => is(DimensionsConfig[item.value].isDateRelated);
	let isActivityRelated = item => is(DimensionsConfig[item.value].isActivityRelated);
	let isMetricDate = item => is(DimensionsConfig[item.value].isMetricDate);
	let isPublishDate = item => is(DimensionsConfig[item.value].isPublishDate);
	let isClosedDate = item => is(DimensionsConfig[item.value].isCloseDate);
	let isConversion = item => is(DimensionsConfig[item.value].isConversion);
	let isInferred = item => is(DimensionsConfig[item.value].isInferred);
	let isBoardOnly = item => is(DimensionsConfig[item.value].isBoardOnly);

	let hasMetricDate = ['engagement', 'linkedInAds', 'profile'].indexOf(subjectType) !== -1;

	switch (subjectType)
	{
		case 'post':
			return [
				{
					isGroup: true,
					groupLabel: 'Publish Date',
					options: groupsOptions.filter(item => isPublishDate(item))
				},
				{
					isGroup: true,
					groupLabel: 'Dimensions',
					options: groupsOptions.filter(item => !isDateRelated(item) && !isBoardOnly(item))
				},
				{
					isGroup: true,
					groupLabel: 'Board Only',
					options: groupsOptions.filter(item => !isDateRelated(item) && isBoardOnly(item))
				}
			];
		case 'engagement':
			return [
				{
					isGroup: true,
					groupLabel: 'Metric Date',
					options: groupsOptions.filter(item => isMetricDate(item))
				},
				{
					isGroup: true,
					groupLabel: 'Dimensions',
					options: groupsOptions.filter(item => !isDateRelated(item) && !isBoardOnly(item))
				},
				{
					isGroup: true,
					groupLabel: 'Board Only',
					options: groupsOptions.filter(item => !isDateRelated(item) && isBoardOnly(item))
				}
			];

		case 'conversion':
			return [
				{
					isGroup: true,
					groupLabel: 'Conversion Date',
					options: groupsOptions.filter(item => isDateRelated(item))
				},
				{
					isGroup: true,
					groupLabel: 'Conversion Data',
					options: groupsOptions.filter(item => isConversion(item))
				},
				{
					isGroup: true,
					groupLabel: 'Inferred Data',
					options: groupsOptions.filter(item => isInferred(item))
				},
				{
					isGroup: true,
					groupLabel: 'Post Data',
					options: groupsOptions.filter(item => !isDateRelated(item) && !isConversion(item) &&
						!isInferred(item) && (item !== 'CredentialTeamIDs'))

				},
			];

		case 'clicks':
			return [
				{
					isGroup: true,
					groupLabel: 'Click Date',
					options: groupsOptions.filter(item => isDateRelated(item))
				},
				{
					isGroup: true,
					groupLabel: 'Inferred Data',
					options: groupsOptions.filter(item => isInferred(item))
				},
				{
					isGroup: true,
					groupLabel: 'Post Data',
					options: groupsOptions.filter(item => !isDateRelated(item) && !isConversion(item) &&
						!isInferred(item) && (item !== 'CredentialTeamIDs'))
				},
			];

		case 'opportunity':
			return [
				{
					isGroup: true,
					groupLabel: 'Social Activity',
					options: [
						...groupsOptions.filter(item => isDateRelated(item) && isActivityRelated(item)),
						...groupsOptions.filter(item => !isDateRelated(item) && isActivityRelated(item)),
					]
				},
				{
					isGroup: true,
					groupLabel: 'Opportunity',
					options: [
						...groupsOptions.filter(item => isDateRelated(item) && !isActivityRelated(item)),
						...groupsOptions.filter(item => !isDateRelated(item) && !isActivityRelated(item))
					]
				}
			];

		case 'inbox':
			return [
				{
					isGroup: true,
					groupLabel: 'Open Date',
					options: groupsOptions.filter(item => isPublishDate(item))
				},
				{
					isGroup: true,
					groupLabel: 'Close Date',
					options: groupsOptions.filter(item => isClosedDate(item))
				},
				{
					isGroup: true,
					groupLabel: 'Dimensions',
					options: groupsOptions.filter(item => !isDateRelated(item))
				}
			];

		default:
			return [
				{
					isGroup: true,
					groupLabel: hasMetricDate ? 'Metric Date' : 'Date',
					options: groupsOptions.filter(item => isDateRelated(item))
				},
				{
					isGroup: true,
					groupLabel: 'Dimensions',
					options: groupsOptions.filter(item => !isDateRelated(item))
				}
			];
	}
}

export function getMetricsOptions(subjectType, currentGroups, currentMetrics, currentFilter)
{
	if (!ReportDataSourcesConfig[subjectType]?.metrics)
	{
		return [];
	}

	let metricsOptions = ReportDataSourcesConfig[subjectType].metrics
		.filter(option => is(MetricsConfig[option]))
		.map(metric => ({
			value: metric,
			label: MetricsConfig[metric].name,
			icon: MetricsConfig[metric].percent ? 'percentage' : 'hashtag',
			isSelectable: true
		}));

	if (!is(metricsOptions))
	{
		return [];
	}

	if (subjectType === 'linkedInDemographics')
	{
		processLinkedinDemographicsMetrics(metricsOptions, subjectType, currentGroups, currentMetrics, currentFilter);
	}

	if (subjectType === 'post' || subjectType === 'engagement')
	{
		let videoMetrics = new Set(['VideoViewsAdded', 'VideoViewersAdded', 'VideoAverageWatchtimeAdded']);

		metricsOptions = metricsOptions.filter(metric => !videoMetrics.has(metric));
	}

	return metricsOptions;
}

export function getGroupedMetricsOptions(subjectType, currentGroups, currentMetrics, currentFilter)
{
	let metricsOptions = getMetricsOptions(subjectType, currentGroups, currentMetrics, currentFilter);

	if (subjectType === 'post' || subjectType === 'engagement')
	{
		let res = [];

		res.push({
			isGroup: true,
			groupLabel: 'General',
			options: metricsOptions.filter(item => !MetricsConfig[item.value].network)
		});

		for (let network of NETWORKS)
		{
			res.push({
				isGroup: true,
				groupLabel:  `${network} Only`,
				options:  metricsOptions.filter(item =>
					MetricsConfig[item.value].network === network && !is(MetricsConfig[item.value].isInstagramStories))
			});

			if (network === 'Instagram')
			{
				res.push({
					isGroup: true,
					groupLabel: 'Instagram Stories',
					options:  metricsOptions.filter(item => is(MetricsConfig[item.value].isInstagramStories))
				});
			}
		}

		return res;
	}
	else
	{
		return metricsOptions;
	}
}

export function filterValidMetrics(metrics, groups, chartType)
{
	let chartConfig = ChartTypesConfig[chartType];

	if (groups.indexOf('Date') === -1)
	{
		metrics = metrics.filter(item => (item !== 'IsFirstDomainSession'));
	}

	if (chartConfig.isList || chartType === ChartType.BUBBLE)
	{
		return deepCopy(metrics);
	}

	if (chartType === ChartType.DONUT)
	{
		metrics = metrics.filter(item => !MetricsConfig[item].percent);
	}

	if (is.defined(chartConfig.maxMetrics))
	{
		return metrics.slice(0, chartConfig.maxMetrics);
	}

	if (groups.length > 1)
	{
		return metrics.slice(0, 1);
	}

	if (MetricsConfig[metrics[0]]?.percent)
	{
		return metrics.filter(item => MetricsConfig[item].percent);
	}
	else if (MetricsConfig[metrics[0]]?.isCurrency)
	{
		return metrics.filter(item => MetricsConfig[item].isCurrency);
	}
	else if (MetricsConfig[metrics[0]]?.isSeconds)
	{
		return metrics.filter(item => MetricsConfig[item].isSeconds);
	}
	else
	{
		return metrics.filter(item => !MetricsConfig[item].percent &&
			!MetricsConfig[item].isCurrency &&
			!MetricsConfig[item].isSeconds);
	}
}
