var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-body",
    { staticClass: "dashboards-list-page" },
    [
      _c(
        "lobster-layout-action-bar",
        [
          _c("lobster-button", { on: { click: _vm.handleCreateDashboard } }, [
            _vm._v("\n\t\t\tCreate Dashboard\n\t\t"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("dashboards-list"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }