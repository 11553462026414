import { render, staticRenderFns } from "./DateFilterSelect.vue?vue&type=template&id=08dd7ada&scoped=true&"
import script from "./DateFilterSelect.vue?vue&type=script&lang=js&"
export * from "./DateFilterSelect.vue?vue&type=script&lang=js&"
import style0 from "./DateFilterSelect.vue?vue&type=style&index=0&id=08dd7ada&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08dd7ada",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yegor/Code/oktopost/admin/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08dd7ada')) {
      api.createRecord('08dd7ada', component.options)
    } else {
      api.reload('08dd7ada', component.options)
    }
    module.hot.accept("./DateFilterSelect.vue?vue&type=template&id=08dd7ada&scoped=true&", function () {
      api.rerender('08dd7ada', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/SocialBI/Filters/DateFilterSelect.vue"
export default component.exports