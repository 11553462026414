var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.emojiIconName
    ? _c("lobster-icon", {
        staticClass: "emoji-entity",
        class: {
          m_positive: _vm.value === "😃",
          m_negative: _vm.value === "🙁",
          m_neutral: _vm.value === "😐",
        },
        attrs: { name: _vm.emojiIconName },
      })
    : _c("span", [_vm._v("\n\t-\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }