var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar-menu" },
    [
      _c(
        "lobster-abstract-button",
        {
          staticClass: "sidebar-menu__tab",
          class: { active: !_vm.filterView },
          attrs: { disabled: !_vm.filterView },
          on: {
            click: function ($event) {
              return _vm.handleClick(false)
            },
          },
        },
        [_vm._v("\n\t\tOutline\n\t")]
      ),
      _vm._v(" "),
      _c(
        "lobster-abstract-button",
        {
          staticClass: "sidebar-menu__tab",
          class: { active: _vm.filterView },
          attrs: { disabled: _vm.filterView },
          on: {
            click: function ($event) {
              return _vm.handleClick(true)
            },
          },
        },
        [
          _vm._v("\n\t\tFilters\n\t\t"),
          _c(
            "b",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.filtersCount,
                  expression: "filtersCount",
                },
              ],
              staticClass: "tabs__item-counter",
            },
            [_vm._v(_vm._s(_vm.filtersCount))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }