import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import stores from './store';
import routers from './router';
import { is } from 'plankton-esm';
import { LobsterUI } from 'lobster-ui';
import 'lobster-ui/icons';
import { AdminYuriPlugin, AjaxPlugin, AjaxPromisesPlugin, GravatarPlugin } from './Plugins.js';
import { CommonUtilsPlugin } from '@utils';
import { CommonAnalyticsPlugin } from '@analytics';

import AnalyticsApp from './components/Analytics/AnalyticsApp';
import QAToolsApp from './components/QATools/QAToolsApp.vue';
import TestApp from './components/Test/TestApp';
import SocialBIApp from './components/SocialBI/SocialBIApp';

import 'lobster-ui/index.scss';
import './scss';
import './icons';

import ReportChartWrapper from './components/Analytics/Report/ReportChartWrapper';
import ListeningApp from "./components/Listening/ListeningApp.vue";


Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(AjaxPlugin);
Vue.use(AjaxPromisesPlugin);
Vue.use(AdminYuriPlugin);
Vue.use(GravatarPlugin);
Vue.use(CommonUtilsPlugin);
Vue.use(CommonAnalyticsPlugin);
Vue.use(LobsterUI);

Vue.component('test-app', TestApp);
Vue.component('analytics-app', AnalyticsApp);
Vue.component('qa-tools-app', QAToolsApp);
Vue.component('social-bi-app', SocialBIApp);
Vue.component('listening-app', ListeningApp);
Vue.component('report-chart-wrapper', ReportChartWrapper);


window.vueRoots = {};

window.mountVueRoot = function (rootSelector, componentName)
{
	let router = is(routers[componentName]) ? new VueRouter(routers[componentName]()) :  null;
	let store = is(stores[componentName]) ? stores[componentName] : null;
	
	let root = new Vue({
		router,
		store,
		render: h => h(Vue.component(componentName)),
	});
	
	root.$mount(rootSelector);
	
	window.vueRoots[componentName] = root;
};

window.unmountVueRoot = function (componentName)
{
	window.vueRoots[componentName]?.$destroy();
	delete window.vueRoots[componentName]
};

window.mountVueComponent = function (rootSelector, componentName, props, listeners)
{
	let Component = Vue.component(componentName);
	
	let component = new Component({
		propsData: props,
	}).$mount(rootSelector);
	
	for (let key in listeners)
	{
		component.$on(key, listeners[key]);
	}
	
	return component;
}