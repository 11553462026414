<template>
	<lobster-layout-section class="gallery-section">
		<lobster-loader v-if="isFirstLoading"/>

		<template v-else-if="currentGroup && currentGallery">
			<div class="gallery-section__header">
				<h2>{{ currentGroup.name }}</h2>
				<h3>{{ currentGroup.description }}</h3>
			</div>

			<lobster-layout-action-bar>
				<date-period-select v-model="datePeriod" />

				<lobster-button
					v-if="canUpdate"
					@click="handleNewReportClick">
					New Report
				</lobster-button>
			</lobster-layout-action-bar>

			<div class="gallery-section__grid-wrapper">
				<kraken-dashboard
					:widgets="currentGallery"
					:margin-x="24"
					:margin-y="24"
					:config="additionalConfig"
					:row-height="270"
					@widgets-changed="handleWidgetsChanged">
					<template #widget="{widget}">
						<gallery-report
							:with-menu="canUpdate"
							:gallery-id="widget.groupId"
							:item="widget"
							:date-period="datePeriod"/>
					</template>
				</kraken-dashboard>
			</div>
		</template>

		<lobster-layout-nullstate
			v-else
			message="Build Your Group"
			description="Add the first report to your group to get started.">

			<lobster-button
				v-if="canUpdate"
				@click="handleNewReportClick">
				New Report
			</lobster-button>
		</lobster-layout-nullstate>
	</lobster-layout-section>
</template>

<script>
import { mapState } from 'vuex';
import GalleryReport from './GalleryReport';
import DatePeriodSelect from '../../Filters/DatePeriodSelect';
import { getAdminRegion } from '../../../../consts/StartupData';
import ReportSettingsModal from '../../Report/ReportSettingsModal';
import { DatePeriodType } from '../../../../consts/DatePeriodType';
import { DimensionsConfig, MetricsConfig } from '@analytics';
import { getReportHeight, getReportWidth } from '../../../../helpers/AnalyticsGridScale';


export default {
	name: 'GallerySection',
	components: {
		DatePeriodSelect,
		GalleryReport,
	},
	data()
	{
		return {
			isFirstLoading: true,
			datePeriod: {
				type: DatePeriodType.LAST_30_DAYS,
			},
			filters: {}
		}
	},
	computed: {
		...mapState({
			isGalleryLoading: state => state.reportGallery.isGalleryLoading,
			gallery: state => state.reportGallery.gallery,
			galleryList: state => state.reportGallery.galleryList,
		}),
		currentGallery()
		{
			return this.gallery.map((item, index) => {
				let width = getReportWidth(item.chartType, item.metrics.length);
				let height = getReportHeight(item.chartType);

				return {
					...item,
					x: 0,
					y: 0,
					width,
					height,
				}
			});
		},
		galleryId()
		{
			return this.$route.params.id;
		},
		additionalConfig()
		{
			return {
				galleryConfig: {
					columnsCount: +(this.gallery?.dimensions?.[0] ?? 4)
				},
				metricNameFormatter(metricName)
				{
					return MetricsConfig[metricName]?.name ?? metricName;
				},
				groupNameFormatter(groupName)
				{
					return DimensionsConfig[groupName]?.name ?? groupName;
				},
			}
		},
		canUpdate()
		{
			return getAdminRegion() === 'US_APP_1';
		},
		currentGroup()
		{
			let group = this.$store.getters['reportGallery/getGroupById'](this.galleryId);

			if (!group)
			{
				this.$router.push({name: 'report-gallery'});
			}
			else
			{
				return group;
			}
		},
	},
	methods: {
		async loadGallery()
		{
			await this.$store.dispatch('reportGallery/loadGallery', this.galleryId);
		},
		async loadGalleryList()
		{
			await this.$store.dispatch('reportGallery/loadGalleryList');
		},
		handleNewReportClick()
		{
			this.$showModal({
				component: ReportSettingsModal,
				initialSettings: {
					dashboardId: this.galleryId,
				},
				on: {
					'save': this.loadGallery
				}
			})
		},
		handleWidgetsChanged(widgets)
		{
			widgets.sort((a, b) => {
				return a.y - b.y;
			});

			this.$store.dispatch('reportGallery/updateGallery', {...this.currentGroup, groupId: this.galleryId, reports: widgets});
		},
		getReportLink(widget)
		{
			return {
				name: 'report',
				params: {
					dashboardId: this.gallery.id,
					widgetId: widget.id
				},
				query: this.$route.query
			}
		},
		handleChartClick(query, widget)
		{
			this.$router.push({
				...this.getReportLink(widget),
				query
			});
		}
	},
	async created()
	{
		try
		{
			await this.loadGallery();

			if (is.empty(this.galleryList))
			{
				await this.loadGalleryList();
			}
		}
		finally
		{
			this.isFirstLoading = false;
		}
	},
}
</script>

<style lang="scss" scoped>
.gallery-section {
	padding: 24px 0;
}

.gallery-section__header {
	padding: 0 24px 24px;
}

.gallery-section__grid-wrapper {
	align-self: center;
	width: 100%;
	max-width: 1200px;
	padding: 0 24px;
}
</style>