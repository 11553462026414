import { ChartType } from '@analytics';


export const DEFAULT_GRID_WIDTH = 840;
export const DEFAULT_GRID_HEIGHT = 420;
export const MAX_ELEMENTS_GRID_LINE = 4;
export const KPI_CHART_WIDTH = DEFAULT_GRID_WIDTH / MAX_ELEMENTS_GRID_LINE;

export const getReportWidth = (chartType, metricsLength) => {
	switch (chartType)
	{
		case ChartType.TOTAL_COMPARE:
		case ChartType.TOTAL_COMPARE_EXTENDED:
			return Math.min(KPI_CHART_WIDTH * metricsLength, DEFAULT_GRID_WIDTH);

		default:
			return DEFAULT_GRID_WIDTH;
	}
}

export const getReportHeight = (chartType) => {
	switch (chartType)
	{
		case ChartType.TOTAL_COMPARE:
		case ChartType.TOTAL_COMPARE_EXTENDED:
			return DEFAULT_GRID_HEIGHT / 2;

		default:
			return DEFAULT_GRID_HEIGHT;
	}
}