<template>
	<div class="dashboard-widget">
		<div class="dashboard-widget__header">
			<div class="dashboard-widget__title">
				<router-link v-if="reportLink" :to="reportLink">
					<h2>{{ widget.name }}</h2>
				</router-link>
				<h2 v-else>{{ widget.name }}</h2>
			</div>
			<report-menu
				class="dashboard-widget__menu"
				:date-period="datePeriod"
				:dashboard-id="dashboardId"
				:widget="widget"/>
		</div>
		<report-chart-wrapper
			class="dashboard-widget__report"
			:class="'m_' + widget.chartType"
			:dashboard-id="dashboardId"
			:widget-id="widget.id"
			:chart-type="widget.chartType"
			:groups="widget.groups"
			:metrics="widget.metrics"
			:additional-columns="widget.additionalData"
			:date-period="datePeriod"
			:limit="widget.limit"
			:order-by="widget.orderBy"
			:subject-type="widget.subjectType"
			:filter="widgetFilter"
		/>
	</div>
</template>

<script>
import ReportChartWrapper from '../Report/ReportChartWrapper';
import ReportMenu from '../Report/ReportMenu';
import {mapGetters} from 'vuex';
import { merge_filters } from '../../../helpers/AnalyticsHelper';
import { ChartType } from 'kraken-charts';

export default {
	name: "DashboardWidget",
	components: {ReportMenu, ReportChartWrapper},
	props: {
		widget: {
			type: Object,
			required: true
		},
		datePeriod: {
			type: Object,
			required: true
		},
		dashboardFilters: {
			type: Object,
			default()
			{
				return {}
			}
		}
	},
	computed: {
		...mapGetters({
			dashboardId: 'analytics/currentDashboardId'
		}),
		widgetFilter()
		{
			let widgetFilter = this.widget.filter;
			let dashboardFilter = this.dashboardFilters[this.widget.subjectType];
			
			if (!this.$is(dashboardFilter))
			{
				return widgetFilter;
			}
			
			if (!this.$is(widgetFilter))
			{
				return dashboardFilter;
			}

			return merge_filters(widgetFilter, dashboardFilter);
		},
		reportLink()
		{
			//if (this.widget.chartType === ChartType.TOTAL_COMPARE)
			//{
			//	return false;
			//}
			
			return {
				name: 'report',
				params: {
					dashboardId: this.dashboardId,
					id: this.widget.id
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.dashboard-widget {
	background-color: $colorLight;
	padding: 16px;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.dashboard-widget__header {
	display: flex;
	
}

.dashboard-widget__menu {
	margin-left: auto;
}

.dashboard-widget__report {
	flex-grow: 1;
	min-height: 0;
	
	&.m_total-compare {
		margin-top: 24px;
	}
	
	&.m_list {
		margin: 12px -16px -16px;
	}
}
</style>