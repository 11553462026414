<template>
	<lobster-entities-container
		center
		no-wrap
		size="small"
		class="lead-account-entity">
		<span>{{name || '-'}}</span>
		<lobster-status-icon
			v-if="isTargetAccount"
			icon-name="star--filled"
			color="warning"
			class="no-shrink"/>
	</lobster-entities-container>
</template>

<script>
export default {
	props: {
		name: String,
		isTargetAccount: {
			type: Boolean,
			default: false
		}
	}
}
</script>
