var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "stage-entity caption-text",
      class: {
        m_open: _vm.stageType === "Open",
        m_lost: _vm.stageType === "Lost",
        m_won: _vm.stageType === "Won",
      },
    },
    [_vm._v("\n\t" + _vm._s(_vm.stageName) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }