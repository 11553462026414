<script setup>
import {ref} from "vue";
import {useToaster} from "../../../hooks/toaster.js";
import AdminYuri from "../../../utils/yuri/AdminYuri.js";
import DatePeriodPicker from "../../Analytics/DatePeriodPicker/DatePeriodPicker.vue";


const toaster = useToaster();
const accountId = ref();
const pipelineId = ref();
const datePeriod = ref();
const isLoading = ref();


async function handleSubmit()
{
	if (!is(datePeriod.value))
	{
		toaster.showError('No backfill period specified');
		return;
	}
	
	isLoading.value = true;
	
	try
	{
		let res = await AdminYuri.post('/listening/backfill', {
			accountId: accountId.value,
			pipelineId: pipelineId.value,
			datePeriod: datePeriod.value
		});
		
		toaster.showSuccess(`Scheduled backfill for pipeline ${res.Pipeline.Name} (ID ${res.Pipeline.ID})`);
	}
	finally
	{
		isLoading.value = false;
	}
}

</script>

<template>
	<lobster-layout-section>
		<lobster-form
			:is-loading="isLoading"
			@submit="handleSubmit">
			<lobster-input
				label="Account ID"
				placeholder="001............"
				validators="required"
				v-model="accountId"/>
			<lobster-input
				label="Pipeline ID"
				placeholder="sl1............"
				validators="required"
				v-model="pipelineId"/>
			<lobster-field
				label="Backfill Period">
				<date-period-picker
					v-model="datePeriod"/>
			</lobster-field>
		</lobster-form>
	</lobster-layout-section>
</template>

<style scoped lang="scss">

</style>