import { render, staticRenderFns } from "./StageEntity.vue?vue&type=template&id=407c6366&scoped=true&"
import script from "./StageEntity.vue?vue&type=script&lang=js&"
export * from "./StageEntity.vue?vue&type=script&lang=js&"
import style0 from "./StageEntity.vue?vue&type=style&index=0&id=407c6366&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../admin/web/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407c6366",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yegor/Code/oktopost/admin/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('407c6366')) {
      api.createRecord('407c6366', component.options)
    } else {
      api.reload('407c6366', component.options)
    }
    module.hot.accept("./StageEntity.vue?vue&type=template&id=407c6366&scoped=true&", function () {
      api.rerender('407c6366', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/app/web/src/vue/common/analytics/components/Entities/StageEntity.vue"
export default component.exports