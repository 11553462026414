var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-field",
    {
      attrs: {
        label: _vm.label,
        "focus-targets": [
          "lobster-tag-checkbox__icon",
          "lobster-checkbox__input",
        ],
      },
    },
    [
      _c("lobster-select", {
        attrs: {
          options: _vm.currentOptions,
          label: "label",
          reduce: (item) => item,
        },
        on: { "option:selecting": _vm.handleSelect },
        model: {
          value: _vm.selectValue,
          callback: function ($$v) {
            _vm.selectValue = $$v
          },
          expression: "selectValue",
        },
      }),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "draggable-select__values",
          model: {
            value: _vm.currentValue,
            callback: function ($$v) {
              _vm.currentValue = $$v
            },
            expression: "currentValue",
          },
        },
        _vm._l(_vm.currentValue, function (val, index) {
          return _c(
            "lobster-tag",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.validationConfig[val.value],
                  expression: "validationConfig[val.value]",
                },
              ],
              key: val.value,
              staticClass: "draggable-select__value",
              class: { m_invalid: _vm.validationConfig[val.value] },
              attrs: { name: "draggableValue", deletable: "" },
              on: {
                "delete-click": function ($event) {
                  return _vm.handleDeleteTagClick(index)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "image",
                    fn: function () {
                      return [_vm._t("image", null, null, val)]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [_vm._v("\n\t\t\t" + _vm._s(val.label) + "\n\t\t")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }