import { is } from 'plankton-esm';
function getIncompatibleGroupsByMetric()
{
	let res = {}
	
	for (let group in IncompatibleMetricsByGroup)
	{
		let metrics = IncompatibleMetricsByGroup[group];

		for (let metric of metrics)
		{
			if (!is.defined(res[metric]))
			{
				res[metric] = [group];
			}
			else
			{
				res[metric].push(group);
			}
		}
	}
	
	return res;
}

/**
 * @type {Object<string, string[]>}
 */
export const IncompatibleMetricsByGroup = {
	LinkedinRegion: ['PageViewsUnique'],
	LinkedinCountry: ['PageViewsUnique'],
	Device: ['Followers'],
	Seniority: ['PageViewsUnique'],
	Function: ['PageViewsUnique'],
	Industry: ['PageViewsUnique'],
	Employees: ['PageViewsUnique'],
	PageType: ['FollowersAdded'],
}

export const IncompatibleGroupsByMetric = getIncompatibleGroupsByMetric();