var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-tag",
    {
      staticClass: "order-tickbox",
      attrs: { val: _vm.val, deletable: "" },
      on: {
        "delete-click": function ($event) {
          return _vm.$emit("delete-click")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "image",
          fn: function () {
            return [
              _c(
                "lobster-abstract-button",
                {
                  staticClass: "order-tickbox__order-button",
                  attrs: { "aria-label": "Change order" },
                  on: { click: _vm.handleOrderClick },
                },
                [
                  _c("lobster-icon", {
                    attrs: {
                      name:
                        _vm.val.direction === "ascending"
                          ? "arrow--up"
                          : "arrow--down",
                      size: "small",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v("\n\t" + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }