import { Yuri, YuriConfig } from 'yuri-js';

let config = new YuriConfig();

config.timeout = 30000;
config.headers['X-Requested-With'] = 'XMLHttpRequest';
config.headers['apiSession'] = localStorage.getItem('sessionId');

let AdminYuri = new Yuri(config);


export default AdminYuri;