<template>
	<lobster-entities-container
		column
		no-wrap>
		<draggable-select
			v-if="withGroups"
			class="sidebar-settings__field"
			label="Group by"
			info-tip="Select the dimensions you want to report on"
			:grayscale-tip="getGroupGrayscaleTip"
			:is-grayscale="group => groups.indexOf(group) === -1"
			:options="groupOptions"
			v-model="currentGroups"/>

		<lobster-entities-container
			column
			size="small"
			class="sidebar-settings__field">
			<b>Chart Type</b>
			<lobster-entities-container size="small">
				<iconed-tickbox
					v-for="chart in chartOptions"
					:key="chart"
					type="radio"
					name="chartType"
					:val="chart"
					v-model="currentChartType"
					v-tooltip="ChartTypesConfig[chart].name">
					<lobster-icon :name="ChartIcon[chart]" size="medium--l"/>
				</iconed-tickbox>
			</lobster-entities-container>
		</lobster-entities-container>

		<draggable-select
			class="sidebar-settings__field"
			label="Metrics"
			info-tip="Select the metrics that you want to report on"
			:options="metricsOptions"
			:grayscale-tip="getMetricGrayscaleTip"
			:is-grayscale="metric => metrics.indexOf(metric) === -1"
			v-model="currentMetrics">
			<template #option="{option}">
				<lobster-icon
					size="small"
					:name="option.icon"/>
				{{ option.label }}
			</template>
		</draggable-select>

		<draggable-select
			v-if="withAdditionalColumns"
			class="sidebar-settings__field"
			label="Columns"
			info-tip="Choose the columns you want to see in the tabular view of this report"
			:options="additionalColumnsOptions"
			v-model="currentAdditionalColumns"/>

		<draggable-select
			v-if="withOrder"
			is-order
			class="sidebar-settings__field"
			label="Order By"
			:options="orderByOptions"
			v-model="currentOrderBy"/>

		<lobster-input
			v-if="withLimit"
			class="sidebar-settings__field"
			type="number"
			blur-validation
			label="Limit"
			:disabled="isLimitDisabled"
			:min="1"
			:max="100"
			:attributes="{
				step: 1
			}"
			:validators="NumberValidator"
			v-model.number="currentLimit"/>

		<lobster-entities-container
			v-if="showTotalCompareSettings"
			class="sidebar-settings__field"
			size="small">
			<lobster-checkbox
				label="Show report headline"
				v-model="currentShowHeadline"/>

			<lobster-checkbox
				label="Show time-series"
				v-model="currentIsExtended"/>
		</lobster-entities-container>

		<lobster-checkbox
			v-if="showLineChartSettings"
			class="sidebar-settings__field"
			label="Show Total Compare"
			v-model="currentIsExtended"/>

		<privacy-select
			v-if="withVisibility"
			class="sidebar-settings__field"
			v-model="currentVisibility"/>

	</lobster-entities-container>
</template>

<script>
import { is } from 'plankton-esm';
import { NumberValidator } from 'lobster-ui/src/validators/NumberValidator';
import { ReportDataSourcesConfig, MetricsConfig, DimensionsConfig, ChartTypesConfig, ChartType } from '@analytics';
import { criteriaFiltrator } from '../../../helpers/CriteriaFiltrator';
import { deepCopy, isEqual } from '../../../helpers/FunctionHelpers';
import IconedTickbox from '../../Form/IconedTickbox.vue';
import { ChartIcon } from '../../../consts/ChartIcon';
import DraggableSelect from '../Entities/DraggableSelect';
import PrivacySelect from './PrivacySelect.vue';
import {
	filterValidMetrics,
	getGroupedMetricsOptions,
	getGroupsOptions,
	getMetricsOptions
} from '../../../helpers/AnalyticsHelper';
import { DimensionsFiltersConfig } from '../../../configs/DimensionsFiltersConfig';
import { FilterTypes } from '../../../consts/FilterTypes';


export default {
	name: 'SidebarSettings',
	components: { PrivacySelect, IconedTickbox, DraggableSelect },
	props: {
		withVisibility: Boolean,
		subjectType: {
			type: String,
			required: true
		},
		metrics: {
			type: Array,
			required: true
		},
		groups: {
			type: Array,
			required: true
		},
		additionalColumns: {
			type: Array,
			required: true
		},
		orderBy: {
			type: Array,
			default: () => ([])
		},
		metadata: {
			type: Object,
			default: () => ({})
		},
		filter: {
			type: Object,
			default: () => ({})
		},
		limit: [Number, String],
		chartType: String,
		visibility: String
	},
	data()
	{
		return {
			NumberValidator: new NumberValidator(1, 100),
			currentGroups: deepCopy(this.groups),
			currentMetrics: deepCopy(this.metrics),
			ChartIcon,
			ChartTypesConfig
		}
	},
	computed: {
		currentChartType: {
			get()
			{
				return this.chartType;
			},
			set(val)
			{
				this.$emit('update:chart-type', val);
			}
		},
		currentAdditionalColumns: {
			get()
			{
				return this.additionalColumns;
			},
			set(val)
			{
				this.$emit('update:additional-columns', val);
			}
		},
		currentOrderBy: {
			get()
			{
				return this.orderBy;
			},
			set(val)
			{
				this.$emit('update:order-by', val);
			}
		},
		currentLimit: {
			get()
			{
				return this.limit;
			},
			set(val)
			{
				this.$emit('update:limit', val);
			}
		},
		currentMetadata: {
			get()
			{
				return this.metadata;
			},
			set(val)
			{
				this.$emit('update:metadata', val);
			}
		},
		currentIsExtended: {
			get()
			{
				return +this.currentMetadata.isExtended === 1;
			},
			set(val)
			{
				this.currentMetadata = {
					...this.currentMetadata,
					isExtended: val ? 1 : 0
				}
			}
		},
		currentShowHeadline: {
			get()
			{
				return +this.currentMetadata.showHeadline === 1;
			},
			set(val)
			{
				this.currentMetadata = {
					...this.currentMetadata,
					showHeadline: val ? 1 : 0
				}
			}
		},
		currentVisibility: {
			get()
			{
				return this.visibility;
			},
			set(val)
			{
				this.$emit('update:visibility', val);
			}
		},
		chartConfig()
		{
			return ChartTypesConfig[this.chartType];
		},
		withGroups()
		{
			return !is.defined(this.chartConfig.maxGroups) || this.chartConfig.maxGroups > 0;
		},
		showTotalCompareSettings()
		{
			return this.chartType === ChartType.TOTAL_COMPARE
		},
		showLineChartSettings()
		{
			return this.subjectType === 'socialListening' && this.chartType === ChartType.LINE;
		},
		withAdditionalColumns()
		{
			return this.chartConfig.hasTableView;
		},
		withOrder()
		{
			return this.chartConfig.withOrderBy;
		},
		withLimit()
		{
			return this.chartConfig.withOrderBy;
		},
		groupOptions()
		{
			if (!this.withGroups)
			{
				return [];
			}

			return getGroupsOptions(this.subjectType, this.validGroups, this.validMetrics, this.filter);
		},
		metricsOptions()
		{
			return getGroupedMetricsOptions(this.subjectType, this.validGroups, this.validMetrics, this.filter);
		},
		additionalColumnsOptions()
		{
			if (!this.withAdditionalColumns)
			{
				return [];
			}

			let groupsSet = new Set();

			for (let group of this.validGroups)
			{
				if (!is.array.notEmpty(DimensionsConfig[group]?.columns))
				{
					continue;
				}

				for (let column of DimensionsConfig[group]?.columns)
				{
					groupsSet.add(column);
				}
			}

			for (let group of this.validGroups)
			{
				groupsSet.delete(group);
			}

			let groupsColumns = Array.from(groupsSet);
			let metricsColumns = getMetricsOptions(this.subjectType, this.validGroups, this.validMetrics, this.filter);
			let currentMetrics = new Set(this.validMetrics);

			metricsColumns = metricsColumns.filter(metric => !currentMetrics.has(metric.value));
			groupsColumns = groupsColumns.map(group => ({ value: group, label: DimensionsConfig[group].name }));

			return [
				...groupsColumns,
				{
					isGroup: true,
					groupLabel: 'Metrics',
					options: metricsColumns
				}
			];
		},
		orderByOptions()
		{
			if (!this.withOrder)
			{
				return [];
			}

			let orderBy = [];

			for (let group of this.validGroups)
			{
				if (DimensionsConfig[group]?.orderBy)
				{
					orderBy.push({
						value: DimensionsConfig[group].orderBy,
						label: DimensionsConfig[group].name,
						defaultDirection: 'ascending'
					});
				}
			}

			for (let metric of this.validMetrics)
			{
				orderBy.push({
					value: metric,
					label: MetricsConfig[metric].name,
					defaultDirection: 'descending'
				});
			}

			return orderBy;
		},
		chartOptions()
		{
			let types = Object.values(ChartType);

			types = types.filter(item => !ChartTypesConfig[item].isDeprecated && !ChartTypesConfig[item].isTemplate)

			types = types.filter(item => {
				return item !== ChartType.ITEM_LIST && item !== ChartType.BAR_LIST;
			})

			if (ReportDataSourcesConfig[this.subjectType]?.metrics.length === 1)
			{
				types = types.filter(item => item !== ChartType.FUNNEL);
			}

			return types;
		},
		validGroups()
		{
			let chartConfig = ChartTypesConfig[this.chartType];

			if (chartConfig.maxGroups === 0)
			{
				return [];
			}

			/** @var {Criterion[]} criteria */
			let criteria = [];

			criteria.push({
				matcher: group => DimensionsFiltersConfig[group]?.type === FilterTypes.TAGS,
				allowedCount: 1
			}, {
				matcher: group => false,
				allowedCount: 1
			});

			if (!this.chartType.isList)
			{
				criteria.push({
					matcher: group => DimensionsConfig[group]?.isDate,
					allowedCount: 1
				});

				if (this.subjectType === 'profile')
				{
					let specificProfileGroups = new Set(['CredentialTeamIDs', 'ProfileSource']);

					criteria.push({
						matcher: group => specificProfileGroups.has(group),
						allowedCount: 1
					});
				}
			}

			let groups = criteriaFiltrator(this.currentGroups, criteria);

			if (!is.defined(chartConfig.maxGroups) || groups.length < chartConfig.maxGroups)
			{
				return groups;
			}

			return groups.slice(0, chartConfig.maxGroups);
		},
		validMetrics()
		{
			return filterValidMetrics(this.currentMetrics, this.validGroups, this.currentChartType);
		},
		isFirstGroupDate()
		{
			return DimensionsConfig[this.validGroups[0]]?.isDate;
		},
		isLimitDisabled()
		{
			return this.isFirstGroupDate && this.chartType !== ChartType.LIST;
		},
	},
	watch: {
		isLimitDisabled: {
			immediate: true,
			handler(newValue)
			{
				if (newValue)
				{
					this.setLimit(true);
				}
			}
		},
		validGroups(newValue, oldValue)
		{
			this.$emit('update:groups', newValue);

			this.setSorting();
			this.setLimit(!isEqual(newValue, oldValue));
		},
		validMetrics(val)
		{
			this.$emit('update:metrics', val);

			if (this.currentChartType === ChartType.WORD_CLOUD)
			{
				this.currentOrderBy = [{
					field: val[0],
					direction: 'descending'
				}];
			}

			this.setSorting();
		},
		withGroups(val)
		{
			if (!val)
			{
				this.currentGroups = [];
			}
		},
		withAdditionalColumns(val)
		{
			if (!val)
			{
				this.currentAdditionalColumns = [];
			}
		},
		currentChartType(val, prevVal)
		{
			if (val === ChartType.TOTAL_COMPARE)
			{
				this.currentMetadata = {
					showHeadline: 1
				}
			}
			else
			{
				this.currentMetadata = {}
			}

			if (val === ChartType.WORD_CLOUD)
			{
				this.currentOrderBy = [{
					field: this.currentMetrics[0],
					direction: 'descending'
				}];
			}
			else if (!this.withOrder)
			{
				this.currentOrderBy = [];
			}
			else if (prevVal === ChartType.WORD_CLOUD)
			{
				this.currentOrderBy = [];
			}

			this.setLimit(!isEqual(val, prevVal));
		}
	},
	methods: {
		getGroupGrayscaleTip(group)
		{
			if (DimensionsConfig[group]?.isDate)
			{
				return 'You can use only one time-series in this report';
			}

			if (DimensionsFiltersConfig[group]?.type === FilterTypes.TAGS)
			{
				return 'You can use only one tag group in this report';
			}

			if (this.subjectType === 'profile' && ['CredentialTeamIDs', 'ProfileSource'].indexOf(group) !== -1)
			{
				return "The system doesn't support reports by the groups you selected";
			}

			if (this.subjectType === 'linkedInDemographics' && !DimensionsConfig[group]?.isDate)
			{
				return 'You can use only one group with this report source';
			}

			if (this.chartType === ChartType.DONUT || this.chartType === ChartTypesConfig.WORD_CLOUD)
			{
				return 'You can use only one group with this chart type';
			}

			return 'You cannot create a report with more than 2 groups';
		},
		getMetricGrayscaleTip(metric)
		{
			if ((metric === 'IsFirstDomainSession') && this.currentGroups.indexOf('Date') === -1)
			{
				return "You must select the Date group to use this metric";
			}
			if (this.chartType === ChartType.DONUT)
			{
				if (MetricsConfig[metric].percent)
				{
					return "This chat type doesn't support rate metrics.";
				}
				else
				{
					return 'You can use only one metric with this chart type'
				}
			}
			else if (this.currentGroups.length === 1 && this.currentMetrics.length < this.validMetrics.length)
			{
				return 'This chart doesn’t support additional metric types'
			}
			else
			{
				return 'You cannot create a report with multiple metrics when more than one group is selected';
			}
		},
		setLimit(isResetLimit)
		{
			if (this.currentChartType === ChartType.WORD_CLOUD)
			{
				this.currentLimit = isResetLimit ? 150 : this.limit || 150;
			}
			else if (this.withLimit && !this.isLimitDisabled)
			{
				this.currentLimit = isResetLimit ? 7 : this.limit || 7;
			}
			else
			{
				this.currentLimit = isResetLimit ? null : this.limit || null;
			}
		},
		setSorting()
		{
			let hasNoOrder = is(this.validMetrics) && is.array.empty(this.currentOrderBy);
			let isWordCloudChart = this.currentChartType === ChartType.WORD_CLOUD;

			let isDefaultSorting = this.withOrder && !this.isEdit && !this.isFirstGroupDate && is(this.validGroups) && hasNoOrder;

			if (isDefaultSorting || isWordCloudChart)
			{
				this.currentOrderBy = [{
					field: this.validMetrics[0],
					direction: 'descending'
				}];
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.sidebar-settings__field {
	width: 265px
}
</style>