<template>
	<v-popover
		placement="bottom-start"
		trigger="manual"
		:open="isOpen"
		@auto-hide="isOpen = false"
		@apply-hide="isOpen = false">
		<date-filter-select
			:date-period="value"
			:options="options"
			@option-click="handleSelect"/>

		<template #popover>
			<date-picker
				:date-period="value"
				@dates-selected="handleCustomDateSelect"/>
		</template>
	</v-popover>
</template>

<script>
import DatePicker from './DatePicker.vue';
import DateFilterSelect from './DateFilterSelect.vue';
import { getDateFilterSelectOptions } from '../../../helpers/AnalyticsHelper';


export default {
	name: 'DatePeriodSelect',
	components: { DateFilterSelect, DatePicker },
	props: {
		value: {
			type: Object,
			default: () => ({})
		},
	},
	data()
	{
		return {
			isOpen: false,
			options: getDateFilterSelectOptions(),
		}
	},
	computed: {},
	methods: {
		handleSelect(val)
		{
			if (val === 'custom')
			{
				this.isOpen = true;
			}
			else
			{
				this.$emit('input', {
					type: val
				});
			}
		},
		handleCustomDateSelect(fromDate, toDate)
		{
			this.isOpen = false;

			if (!is(fromDate) || !is(toDate))
			{
				return;
			}

			fromDate = moment(fromDate).format('YYYY-MM-DD');
			toDate = moment(toDate).format('YYYY-MM-DD');
			
			this.$emit('input', {
				from: fromDate,
				to: toDate
			});
		}
	}
}
</script>

<style lang="scss" scoped>

</style>