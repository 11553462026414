var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { staticClass: "draggable-select", attrs: { size: "small" } },
    [
      _c("lobster-select", {
        attrs: {
          searchable: "",
          label: "label",
          "field-label": _vm.label,
          "info-tip": _vm.infoTip,
          options: _vm.selectOptions,
          reduce: (option) => option.value,
          selectable: _vm.isSelectable,
        },
        on: { "option:selecting": _vm.handleSelect },
        scopedSlots: _vm._u(
          [
            {
              key: "option",
              fn: function (data) {
                return [_vm._t("option", null, null, data)]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectValue,
          callback: function ($$v) {
            _vm.selectValue = $$v
          },
          expression: "selectValue",
        },
      }),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "draggable-select__tags-list",
          model: {
            value: _vm.currentValue,
            callback: function ($$v) {
              _vm.currentValue = $$v
            },
            expression: "currentValue",
          },
        },
        [
          _vm.isOrder
            ? _vm._l(_vm.currentValue, function (val, index) {
                return _c("order-tickbox", {
                  key: val.field,
                  staticClass: "draggable-select__tag",
                  attrs: {
                    name: "draggableValue",
                    type: "checkbox",
                    val: val,
                    text: _vm.getOptionText(val),
                    grayscale: _vm.getGrayscale(val),
                    "grayscale-tip": _vm.getGrayscaleTip(val),
                  },
                  on: {
                    "order-click": function ($event) {
                      return _vm.handleOrderClick(val)
                    },
                    "delete-click": function ($event) {
                      return _vm.handleDeleteTagClick(index)
                    },
                  },
                })
              })
            : _vm._l(_vm.currentValue, function (val, index) {
                return _c(
                  "lobster-tag",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getGrayscaleTip(val),
                        expression: "getGrayscaleTip(val)",
                      },
                    ],
                    key: val,
                    staticClass: "draggable-select__tag",
                    class: {
                      m_grayscaled: _vm.getGrayscale(val),
                    },
                    attrs: {
                      name: "draggableValue",
                      type: "checkbox",
                      deletable: "",
                    },
                    on: {
                      "delete-click": function ($event) {
                        return _vm.handleDeleteTagClick(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.getOptionText(val)) + "\n\t\t\t"
                    ),
                  ]
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }