var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-section",
    { attrs: { loading: _vm.isLoading } },
    [
      _c(
        "lobster-table",
        { attrs: { "full-screen": "" } },
        [
          _c(
            "lobster-table-row",
            { attrs: { header: "" } },
            [
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\t\tName\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\t\tDescription\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\t\tPrivacy\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\t\tCreated By\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\t\tCreated On\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", { attrs: { small: "" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.dashboards, function (dashboard) {
            return _c(
              "lobster-table-row",
              {
                key: dashboard.Id,
                attrs: { clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.handleRowClick(dashboard)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions-cell",
                      fn: function () {
                        return [
                          _c("lobster-action-button", {
                            attrs: { "icon-name": "edit" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEditClick(dashboard)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("lobster-action-button", {
                            attrs: { "icon-name": "copy" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleCopyClick(dashboard)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("lobster-action-button", {
                            attrs: { deny: "", "icon-name": "trash-can" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDeleteClick(dashboard)
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "lobster-table-cell",
                  [
                    _c(
                      "lobster-button",
                      {
                        attrs: {
                          "inline-link": "",
                          to: {
                            name: "dashboard",
                            params: {
                              id: dashboard.id,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(dashboard.name) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("lobster-table-cell", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(dashboard.description) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("lobster-table-cell", [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(dashboard.visibility) + "\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("lobster-table-cell", [
                  _c("img", {
                    staticClass: "dashboard-list__user-avatar",
                    attrs: { src: _vm.$gravatar(dashboard.createdBy.email) },
                  }),
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(dashboard.createdBy.name) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("lobster-table-cell", [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(dashboard.createdOn) + "\n\t\t\t\t"
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }