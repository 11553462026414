<template>
	<lobster-menu
		placement="left-start"
		title="Report Actions">
		<slot/>
		<template #popover>
			<lobster-menu-list>
				<lobster-menu-item
					icon-name="edit"
					v-close-popover
					@click="$emit('edit-widget-click')">
					Edit
				</lobster-menu-item>
				<lobster-menu-item
					deny
					icon-name="trash-can"
					v-close-popover
					@click="$emit('delete-widget-click')">
					Delete
				</lobster-menu-item>
			</lobster-menu-list>
		</template>
	</lobster-menu>
</template>

<script>
export default {
}
</script>
