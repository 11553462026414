<template>
<lobster-layout-body>
	<lobster-layout-action-bar>
		<template #actions>
			<lobster-button :to="navLink">
				{{navMessage}}
			</lobster-button>
			<lobster-action-button accept icon-name="filter" />
		</template>
		<template #pagination>
			<lobster-paginator
				:items-per-page="20"
				:total-items="500"/>
		</template>
	</lobster-layout-action-bar>
	<lobster-layout-section :loading="loading">
		<lobster-table>
			<lobster-table-row header>
				<lobster-table-column-title>Column 1</lobster-table-column-title>
				<lobster-table-column-title>Column 2</lobster-table-column-title>
				<lobster-table-column-title>Column 3</lobster-table-column-title>
				<lobster-table-column-title>Column 4</lobster-table-column-title>
			</lobster-table-row>
			<lobster-table-row v-for="(row, index) in tableData" :key="index">
				<lobster-table-cell>{{row.column1}}</lobster-table-cell>
				<lobster-table-cell>{{row.column2}}</lobster-table-cell>
				<lobster-table-cell>{{row.column3}}</lobster-table-cell>
				<lobster-table-cell>{{row.column4}}</lobster-table-cell>
			</lobster-table-row>
		</lobster-table>
	</lobster-layout-section>
</lobster-layout-body>
</template>

<script>
export default {
	name: "TestPage",
	data()
	{
		return {
			tableData: [],
			loading: true
		}
	},
	computed: {
		navLink()
		{
			return this.$route.name === 'root' ? {name: 'test-page'}: {name: 'root'}
		},
		navMessage()
		{
			return this.$route.name === 'root' ? 'Go to test page' : 'Go to root'
		}
	},
	methods: {
		generateTable()
		{
			this.loading = false;
			for (let i = 0; i < 20; i++)
			{
				this.tableData.push({
					column1: `Column ${i} 1`,
					column2: `Column ${i} 2`,
					column3: `Column ${i} 3`,
					column4: `Column ${i} 4`
				});
			}
		}
	},
	created()
	{
		setTimeout(this.generateTable, 2000);
	},
}
</script>

<style scoped>

</style>