<template>
	<lobster-tag
		class="order-tickbox"
		:val="val"
		deletable
		@delete-click="$emit('delete-click')">
		<template #image>
			<lobster-abstract-button
				class="order-tickbox__order-button"
				aria-label="Change order"
				@click="handleOrderClick">
				<lobster-icon
					:name="val.direction === 'ascending' ? 'arrow--up' : 'arrow--down'"
					size="small"/>
			</lobster-abstract-button>
		</template>
		{{ text }}
	</lobster-tag>
</template>


<script>
export default {
	props: {
		type: {
			type: String,
			default: 'checkbox'
		},
		name: String,
		val: Object,
		text: String,
		disabled: {
			type: Boolean,
			default: false
		},
		ariaLabel: String
	},
	methods: {
		handleOrderClick()
		{
			this.$emit('order-click', this.val);
		}
	}
}
</script>


<style lang="scss" scoped>
.order-tickbox {
	&:deep(.lobster-tag__image) {
		width: 28px;
		height: 28px;
		margin: -4px 4px -4px -4px;
	}
}

.order-tickbox__order-button {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $colorLight;
	border: 1px solid $colorBorder;
	border-radius: 50%;
	transition: all 0.1s;

	@media (hover: hover) {
		&:hover {
			color: $colorPrimary;
			background-color: $colorPrimaryTint80;
		}

		&:active {
			color: $colorPrimary;
			background-color: $colorPrimaryTint90;
		}
	}
}
</style>