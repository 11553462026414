export const METRIC_OPERATOR_OPTIONS = [
	{
		value: '==',
		label: 'Equal to'
	},
	{
		value: '>',
		label: 'Greater than'
	},
	{
		value: '<',
		label: 'Less than'
	},
	{
		value: '!=',
		label: 'Not equal to'
	}
]

export const METRIC_PERIODS_OPTIONS = [
	{
		value: 'minutes',
		label: 'Minutes'
	},
	{
		value: 'hours',
		label: 'Hours'
	},
	{
		value: 'days',
		label: 'Days'
	}
]
