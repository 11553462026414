import { ChartType } from '../consts/ChartType';


export const SourceConfig = {
	trelloCard: {
		name: 'Trello',
		groups: [
			'ID', 'BoardID', 'Labels', 'ListID', 'MemberIDs', 'IsDeleted', 'DueDate', 'IsDueDateComplete', 
			'AllTags', 'BoardTags', 'ListTags', 'CardTags', 'UserTags', 
			'ClosedDate', 'ClosedMonth', 'ClosedWeek', 'ClosedWeekDay', 'ClosedYear', 'ClosedQuarter', 
			'OpenedDate', 'OpenedMonth', 'OpenedWeek', 'OpenedWeekDay', 'OpenedYear', 'OpenedQuarter', 
			'CardCreatedDate', 'CardCreatedMonth', 'CardCreatedWeek', 'CardCreatedWeekDay', 'CardCreatedYear',
			'CardCreatedQuarter', 'Member'],
		metrics: ['Count', 'Score', 'OpenTime', 'AvgOpenTime', 'AvgOpenTimeHour', 'AvgOpenTimeDay'],
		additionalColumns: [],
		filters: [
			'BoardID', 'ListID', 'MemberIDs', 'Labels', 
			'AllTags', 'BoardTags', 'ListTags', 'CardTags', 'UserTags', 'IsDueDateComplete', 'IsDeleted', 'Count',
			'Score', 'OpenTime', 
			// 'ClosedDate', 'ClosedMonth', 'ClosedWeek', 'ClosedWeekDay', 'ClosedYear', 'ClosedQuarter',  'Score',
			// 'OpenedDate', 'OpenedMonth', 'OpenedWeek', 'OpenedWeekDay', 'OpenedYear', 'OpenedQuarter', 
			// 'CardCreatedDate', 'CardCreatedMonth', 'CardCreatedWeek', 'CardCreatedWeekDay', 'CardCreatedYear', 'CardCreatedQuarter'
		],
		chartTypes: [ChartType.AREA_STACKED, ChartType.BUBBLE, ChartType.COMBO, ChartType.DONUT, ChartType.FUNNEL, ChartType.GROUPED_BAR, ChartType.GROUPED_COLUMN, ChartType.LINE, ChartType.LIST, ChartType.EXTENDED_LIST, ChartType.STACKED_BAR, ChartType.STACKED_COLUMN, ChartType.TOTAL_COMPARE, ChartType.WORD_CLOUD]
	}
}

export function has_metric(source, metric)
{
	if (!is.defined(SourceConfig[source]))
	{
		return false;
	}
	
	return SourceConfig[source].metrics.indexOf(metric) !== -1
}

export function has_group(source, group)
{
	if (!is.defined(SourceConfig[source]))
	{
		return false;
	}
	
	return SourceConfig[source].groups.indexOf(group) !== -1
}

