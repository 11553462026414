<template>
	<lobster-entities-container
		center
		no-wrap
		size="small"
		class="ads-entity">
		<lobster-media-preview
			v-if="$is(mediaType)"
			class="no-shrink"
			size="small"
			:message-type="mediaType"
			:preview="imgUrl"/>
		<div class="ads-entity__message-wrapper">
			<lobster-entities-container
				v-if="$is(content)"
				column
				no-wrap
				size="small">
				<lobster-text-entity
					v-if="contentTitle"
					:text="contentTitle"
					class="h3"
					line-clamp="1"/>
				<lobster-text-entity
					emojify
					:text="contentMessage || '-'"
					class="caption-text"
					line-clamp="2"/>
			</lobster-entities-container>
			<p
				v-else
				class="ads-entity__message">
				<b>{{ $t('commonAnalytics.adsNullstate.title') }}</b>.
				{{ $t('commonAnalytics.adsNullstate.caption') }}
				<lobster-button
					inline-link
					:href="$newKnowledgebaseLink('159')"
					data-elevio-article="159"
					data-elevio-style="nothing">
					{{ $t('commonAnalytics.adsNullstate.linkText') }}
				</lobster-button>
			</p>
		</div>
		<lobster-tag class="ads-entity__status">
			{{status.slice(0, 1).toUpperCase() + status.slice(1).toLowerCase()}}
		</lobster-tag>
	</lobster-entities-container>
</template>

<script>
export default {
	name: 'AdsEntity',
	props: {
		content: {
			type: Object,
			default()
			{
				return {};
			}
		},
		status: String
	},
	computed: {
		isV2()
		{
			return +this.content?.OktopostMappingVersion === 2;
		},
		hasPost()
		{
			return is(this.content?.Post);
		},
		postType()
		{
			if (!this.hasPost)
			{
				return '';
			}

			return this.content?.Post.Type;
		},
		contentTitle()
		{
			if (this.isV2)
			{
				if (!this.hasPost)
				{
					return;
				}

				return this.content?.Post.Content[0]?.Title;
			}
			else
			{
				return this.content?.title;
			}
		},
		contentMessage()
		{
			if (this.isV2)
			{
				if (this.hasPost)
				{
					return this.content?.Post?.Message;
				}
				else
				{
					return this.content?.HtmlBody || this.content?.Headline;
				}
			}
			else
			{
				return this.content?.htmlBody ||
					this.content?.text ||
					this.content?.description ||
					this.content?.headline?.custom;
			}
		},
		imgUrl()
		{
			if (this.isV2)
			{
				if (!this.hasPost)
				{
					return;
				}

				if (this.postType === 'video')
				{
					return this.content?.Post?.Content[0]?.VideoThumbnailURL;
				}
				else
				{
					return this.content?.Post?.Content[0]?.ImageURL;
				}
			}
			else
			{
				if (!is(this.content?.entities) ||
					!is(this.content?.entities[0].thumbnails))
					return null;

				let resolvedUrl = this.content?.entities[0].thumbnails[0]?.resolvedUrl;
				let localUrl = this.content?.entities[0].thumbnails[0]?.localUrl;

				return [resolvedUrl, localUrl];
			}
		},
		mediaType()
		{
			if (this.isV2)
			{
				if (this.postType === 'video')
				{
					return 'video';
				}
				else if (this.postType === 'link')
				{
					return 'link-attachment';
				}
				else if (is(this.imgUrl))
				{
					return 'image';
				}

				return null;
			}
			else
			{
				if (this.content?.shareMediaCategory === 'VIDEO')
				{
					return 'video';
				}
				else if (is(this.imgUrl))
				{
					return 'image';
				}

				return null;
			}
		}
	}
}
</script>

<style scoped lang="scss">
.ads-entity__message-wrapper {
	min-width: 150px;
	flex-grow: 1;
}

.ads-entity__status {
	flex-shrink: 0;
}
</style>