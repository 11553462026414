export const ChartIcon = {
	'line': 'chart--line--data',
	'pie': 'chart--pie',
	'donut': 'chart--ring',
	'grouped-bar': 'chart--bar',
	'stacked-bar': 'chart--stacked',
	'grouped-column': 'chart--column',
	'stacked-column': 'chart--stacked-column',
	'area-stacked': 'chart--area',
	'funnel': 'filter',
	'new-funnel': 'filter',
	'list': 'table--split',
	'item-list': 'table--split',
	'bar-list': 'table--split',
	'post-list': 'table--split',
	'media-list': 'table--split',
	'word-cloud': 'word-cloud',
	'bubble': 'quadrant-plot',
	//'bubble': 'chart--bubble',
	'total-compare': 'summary--KPI',
	'total-compare-extended': 'summary--KPI',
	'combo': 'chart--combo-stacked'
};