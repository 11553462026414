var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("report-chart-wrapper", {
    attrs: {
      "is-embedded": "",
      "with-pagination": "",
      "with-order": "",
      "chart-type": _vm.chartType,
      "subject-type": _vm.subjectType,
      groups: _vm.currentGroups,
      metrics: _vm.metrics,
      "additional-columns": _vm.additionalColumns,
      "date-period": _vm.currentDatePeriod,
      filter: _vm.currentFilter,
      "order-by": _vm.currentOrderBy,
      limit: _vm.limit,
      config: _vm.config,
    },
    on: { "table-chart-click": _vm.handleTableChartClick },
    scopedSlots: _vm._u(
      [
        _vm.withExportButton
          ? {
              key: "kraken-table-action-bar__actions",
              fn: function (data) {
                return [
                  _c("lobster-action-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Export",
                        expression: "'Export'",
                      },
                    ],
                    attrs: {
                      loading: _vm.isExportLoading,
                      "icon-name": "export",
                      "aria-label": "Export",
                    },
                    on: { click: _vm.handleExportClick },
                  }),
                ]
              },
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }