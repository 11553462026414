import { getSourceDimensions } from '../helpers/ChartHelper';
import { WidgetType } from '../consts/WidgetType';


function mapDatePeriod(data)
{
	if (data.Type)
	{
		return {
			type: data.Type
		}
	}
	else
	{
		return {
			to: data.To,
			from: data.From,
		}
	}
}

function mapFilterDimensions(data)
{
	return data.map(item => ({
		column:	item.Column,
		values:	item.Values
	}))
}

function mapFilterTags(data)
{
	return data.map(item => (is(item.Value) ? ({
		column: item.Column,
		operator: item.Operator,
		value: item.Value
	}) : ({
		column:   item.Column,
		operator: item.Operator
	})))
}

function mapFilterMetrics(data)
{
	return data.map(item => ({
		metric: item.Metric,
		operator: item.Operator,
		value: item.Value,
		metaData: item.MetaData || {}
	}))
}

export function mapDashboardFilter(data = {})
{
	let filters = {};

	if (is(data.Filters))
	{
		for (let dataSource in data.Filters)
		{
			if (!data.Filters.hasOwnProperty(dataSource))
				continue;

			filters[dataSource] = {
				dimensions: mapFilterDimensions(data.Filters[dataSource].Dimensions),
				tags: mapFilterTags(data.Filters[dataSource].Tags)
			}
		}
	}
	else if (is(data.Filter))
	{
		let reportsSubjectTypes = Array.from(new Set(data.Widgets
			.filter(item => item.Type !== WidgetType.TEXT)
			.map(item => (item.SubjectType || item.Source))));

		for (let dataSource of reportsSubjectTypes)
		{
			let dataSourceColumns = getSourceDimensions(dataSource);
			let dataSourceDimensions = data.Filter.Dimensions.filter(item => dataSourceColumns.includes(item.Column));
			let dataSourceTags = data.Filter.Tags.filter(item => dataSourceColumns.includes(item.Column));
			
			filters[dataSource] = {
				dimensions: mapFilterDimensions(dataSourceDimensions),
				tags: mapFilterTags(dataSourceTags)
			}
		}
	}

	return filters;
}

export function mapReportFilter(data)
{
	if (!is(data))
	{
		return {
			dimensions: [],
			tags: [],
			metrics: []
		}
	}

	return {
		dimensions: mapFilterDimensions(data.Filter.Dimensions),
		tags: mapFilterTags(data.Filter.Tags),
		metrics: mapFilterMetrics(data.Filter.Metrics)
	}
}

export function mapDashboard(data)
{
	let dashboard = {
		id: data.Id,
		name: data.Name,
		description: data.Description,
		type: data.Type,
		visibility: data.Visibility,
		datePeriod: mapDatePeriod(data.DatePeriod),
		teams: data.Teams,
		widgets: data.Widgets.map(mapWidget),
		status: data.Status,
		filters: mapDashboardFilter(data),
		isFavorite: data.IsFavorite,
		isTemplate: data.IsTemplate,
		createdBy: data.CreatedBy,
		createdOn: data.Created,
		creatorName: data.CreatorName,
		modifiedOn: data.Modified,
		userId: data.UserId,
	}

	if (is(data.Dimensions))
	{
		dashboard.dimensions = data.Dimensions;
	}

	return dashboard;
}

export function mapWidget(data)
{
	let widget = {
		id: data.Id,
		name: data.Name,
		description: data.Description,
		type: data.Type,
		status: data.Status
	};

	if (data.Type === WidgetType.TEXT)
	{
		widget.content = data.Content;
	}
	else
	{
		widget.subjectType = data.SubjectType || data.Source;
		widget.chartType = data.ChartType;
		widget.groups = data.Groups;
		widget.metrics = data.Metrics;
		widget.labels = is(data.Labels) ? data.Labels : {};
		widget.additionalColumns = data.AdditionalColumns;
		widget.orderBy = is(data.OrderBy) ? data.OrderBy.map(item => ({
			field: item.Field,
			direction: item.Direction
		})) : [];
		widget.limit = data.Limit;
		widget.filter = mapReportFilter(data);
		widget.metadata = is(data.Metadata) ? JSON.parse(data.Metadata) : {};
	}

	if (is.defined(data.Width) && is.defined(data.Height))
	{
		widget.x = data.X;
		widget.y = data.Y;
		widget.width = data.Width;
		widget.height = data.Height;
	}

	if (is(data.DashboardId))
	{
		widget.dashboardId = data.DashboardId;
	}
	else
	{
		widget.visibility = data.Visibility;
	}

	if (is(data.DatePeriod))
	{
		widget.datePeriod = mapDatePeriod(data.DatePeriod);
	}

	return widget;
}