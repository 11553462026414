<template>
	<lobster-layout-body class="single-report-section">
		<lobster-loader v-if="isLoading"/>
		<template v-else>
			<div class="single-report-section__header">
				<lobster-action-button 
					class="single-report-section__back-button"
					icon-name="arrow--left"
					:to="dashboardLink"/>
				<h1>
					{{ widget.name }}
				</h1>

				<report-menu
					class="single-report-section__menu"
					:dashboard-id="dashboardId"
					:widget="widget"
					:date-period="widget.datePeriod"
					@delete="handleDelete"/>
			</div>
			<lobster-layout-action-bar>
				<template #actions>
					<analytics-filter
						is-report-filter
						:subject-type="widget.subjectType"
						v-model="filter"
					/>
				</template>
			</lobster-layout-action-bar>
			<lobster-layout-section class="single-report-section__main-section">
				<report-chart-wrapper
					v-if="!isTable"
					class="single-report-section__main-chart"
					:class="{ 'm_total-compare': isTotalCompare}"
					:chart-type="widget.chartType"
					:groups="widget.groups"
					:metrics="widget.metrics"
					:additional-columns="widget.additionalData"
					:date-period="widget.datePeriod"
					:limit="widget.limit"
					:order-by="widget.orderBy"
					:subject-type="widget.subjectType"
					:filter="filter"
				/>
				<drill-down-wrapper
					v-if="isTotalCompare" 
					:date-period="widget.datePeriod"
					:subject-type="widget.subjectType"
					:filter="filter"
					:limit="16"
				/>
				<report-chart-wrapper
					v-else
					class="single-report-section__table-chart"
					with-pagination
					with-order
					with-totals
					:chart-type="secondChartType"
					:groups="widget.groups"
					:metrics="widget.metrics"
					:additional-columns="widget.additionalData"
					:date-period="widget.datePeriod"
					:limit="20"
					:order-by="widget.orderBy"
					:subject-type="widget.subjectType"
					:filter="filter"
					:config="config"
				/>
			</lobster-layout-section>
		</template>
	</lobster-layout-body>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AnalyticsFilter from '../Filter/AnalyticsFilter';
import ReportChartWrapper from '../Report/ReportChartWrapper';
import { ChartType } from 'kraken-charts';
import { ChartTypesConfig } from '../../../configs/ChartTypesConfig';
import ReportMenu from '../Report/ReportMenu';
import WidgetModal from '../WidgetModal/WidgetModal';
import DrillDownWrapper from '../Report/DrillDownWrapper';


export default {
	name: "SingleReportSection",
	components: {DrillDownWrapper, WidgetModal, ReportMenu, ReportChartWrapper, AnalyticsFilter},
	props: {
		dashboardId: String,
		id: [Number, String]
	},
	data()
	{
		return {
			isLoading: false,
			ChartType,
			config: {
				tableConfig: {
					withDrilldown(columns)
					{
						return true;
					}
				}
			}
		}
	},
	computed: {
		...mapState({
			widget: state => state.analytics.currentWidget
		}),
		isTable()
		{
			return ChartTypesConfig[this.widget.chartType]?.isTable
		},
		isTotalCompare()
		{
			return this.widget.chartType === ChartType.TOTAL_COMPARE;
		},
		secondChartType()
		{
			return this.isTable ? this.widget.chartType : ChartType.LIST
		},
		dashboardLink()
		{
			return {
				name: 'dashboard',
				params: {
					id: this.dashboardId,
				}
			}
		},
		filter: {
			get()
			{
				if (this.$is(this.$route.query.filter))
				{
					let currentFilter = JSON.parse(this.$route.query.filter);
					
					return {
						...currentFilter,
						metrics: this.widget.filter.metrics
					}
				}
				else
				{
					return this.widget.filter;
				}
			},
			set(val)
			{
				this.$router.push({query: {filter: JSON.stringify(val)}});
			}
		},
	},
	methods: {
		...mapActions({
			getWidget: 'analytics/getWidget'
		}),
		async loadReport()
		{
			this.isLoading = true;
			
			try
			{
				await this.getWidget({
					dashboardId: this.dashboardId,
					widgetId: this.id
				});
			}
			catch (e)
			{
				if (e.error.code === 404)
				{
					this.$toaster.showError('Report not found');
					this.$router.push(this.dashboardLink);
				}
			}
			finally
			{
				this.isLoading = false;
			}
		},
		handleDelete()
		{
			this.$router.push(this.dashboardLink);
		}
	},
	created()
	{
		this.loadReport()
	}
}
</script>

<style lang="scss" scoped>

.single-report-section {
	background-color: $colorBgLight;
	padding: 24px 24px 30px;
}

.single-report-section__main-section {
	background-color: $colorLight;
	padding: 24px 0;
}

.single-report-section__header {
	display: flex;
	margin-bottom: 30px;
}

.single-report-section__back-button {
	margin-right: 6px;
}

.single-report-section__menu {
	margin-left: auto;
}

.single-report-section__main-chart {
	height: 520px;
	margin-bottom: 50px;
	
	&.m_total-compare {
		height: auto;
	}
}

.single-report-section__table-chart {
	
}
</style>