var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-search-filter", {
    attrs: {
      "search-disabled": "",
      "menu-view": _vm.menuView,
      "show-group-labels": _vm.menuView,
      "show-flat-groups": _vm.$is(_vm.subjectType),
      filters: _vm.filters,
    },
    model: {
      value: _vm.currentFilters,
      callback: function ($$v) {
        _vm.currentFilters = $$v
      },
      expression: "currentFilters",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }