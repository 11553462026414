import AdminYuri from './utils/yuri/AdminYuri';
import md5 from 'md5';
import { is } from 'plankton-esm';

export const AjaxPlugin = {
	install(Vue, options) {
		Vue.prototype.$getAjaxModule = function ()
		{
			return window.Admin.App.modules('AjaxModule').builder(null);
		};
	}
};

export const AjaxPromisesPlugin = {
	install(Vue, options) {
		Vue.prototype.$getAjaxPromisesModule = function ()
		{
			let ajax = window.Admin.App.modules('AjaxModule').builder(null);
			
			return {
				promises: {
					get: function (url, params)
						 {
							 return new Promise((resolve, reject) =>
							 {
								 ajax.get(url, params)
									 .onDone(resolve)
									 .onFail(reject);
							 });
						 },
					post: function (url, params)
						 {
							 return new Promise((resolve, reject) =>
							 {
								 ajax.post(url, params)
									 .onDone(resolve)
									 .onFail(reject);
							 });
						 },
					raw: function (url, data)
						 {
							 return new Promise((resolve, reject) =>
							 {
								 ajax.raw(url, data)
									 .onDone(resolve)
									 .onFail(reject);
							 });
						 },
					getRaw: function(url, type)
						 {
							 return new Promise((resolve, reject) =>
							 {
								 ajax.getRaw(url, type)
									 .onDone(resolve)
									 .onFail(reject);
							 });
						 },
					postRaw: function(url, type, params)
						 {
							 return new Promise((resolve, reject) =>
							 {
								 ajax.postRaw(url, type, params)
									 .onDone(resolve)
									 .onFail(reject);
							 });
						 }
				}
			}
		};
	}
};

export const AdminYuriPlugin = {
	install(Vue)
	{
		Vue.prototype.$yuri = AdminYuri;
	}
}

export const GravatarPlugin = {
	install(Vue)
	{
		Vue.prototype.$gravatar = email =>
		{
			if (!is.string(email))
			{
				return '';
			}
			
			email = email.trim().toLowerCase();
				
			let hash = md5(email);
			
			return `https://www.gravatar.com/avatar/${hash}?s=160&d=identicon`;
		}
	}
}