var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notEnoughData
    ? _c("div", { staticClass: "report-chart-wrapper__nullstate" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "134",
              height: "134",
              viewBox: "0 0 134 134",
            },
          },
          [
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("circle", {
                attrs: { cx: "67", cy: "67", r: "67", fill: "#F3F5F8" },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#102248",
                  d: "M84.5 77.938h4.375v10.937H84.5V77.937zM75.75 67h4.375v21.875H75.75V67zM56.062 88.875c-6.037-.007-10.93-4.9-10.937-10.938H49.5c0 3.625 2.938 6.563 6.563 6.563 3.624 0 6.562-2.938 6.562-6.563 0-3.624-2.938-6.562-6.563-6.562V67C62.103 67 67 71.897 67 77.938c0 6.04-4.897 10.937-10.938 10.937zm37.188-52.5h-52.5c-2.415.002-4.373 1.96-4.375 4.375v52.5c.002 2.415 1.96 4.373 4.375 4.375h52.5c2.415-.004 4.371-1.96 4.375-4.375v-52.5c-.002-2.415-1.96-4.373-4.375-4.375zm0 19.688H62.625V40.75H93.25v15.313zm-35-15.313v15.313h-17.5V40.75h17.5zm-17.5 52.5V60.437h52.5l.004 32.813H40.75z",
                  opacity: ".2",
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "report-chart__nullstate-text" }, [
          _vm._v("No metrics are available for this time frame."),
        ]),
      ])
    : _vm.isLoading
    ? _c("lobster-loader", { staticClass: "report-chart-wrapper__loader" })
    : _c(
        "div",
        { staticClass: "report-chart-wrapper" },
        [
          _vm.isPaginationLoading
            ? _c("lobster-loader", {
                staticClass: "report-chart-wrapper__pagination-loader",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("kraken-report", {
            ref: "krakenReport",
            staticClass: "report-chart-wrapper__chart",
            class: { m_loading: _vm.isPaginationLoading },
            attrs: {
              "report-data": _vm.reportData,
              "chart-type": _vm.chartType,
              groups: _vm.groups,
              metrics: _vm.metrics,
              "additional-columns": _vm.additionalColumns,
              metadata: _vm.currentMetadata,
              limit: _vm.limit,
              "with-pagination": _vm.withPagination,
              "with-order": _vm.withOrder,
              page: _vm.currentPage,
              "order-by": _vm.currentOrderBy,
              "order-dir": _vm.currentOrderDir,
              config: _vm.additionalConfig,
            },
            on: {
              "update:page": function ($event) {
                _vm.currentPage = $event
              },
              prev: _vm.handlePrev,
              next: _vm.handleNext,
              sort: _vm.handleSort,
              "edit-metric": _vm.handleEditMetric,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "kraken-item-list__item",
                  fn: function (data) {
                    return [_vm._t("item-list__item", null, null, data)]
                  },
                },
                {
                  key: "kraken-table-chart__drill-down",
                  fn: function ({ row, columns, entities }) {
                    return [
                      _c("drill-down-wrapper", {
                        attrs: {
                          row: row,
                          columns: columns,
                          entities: entities,
                          "date-period": _vm.datePeriod,
                          "subject-type": _vm.subjectType,
                          filter: _vm.filter,
                          "parent-groups": _vm.groups,
                          "parent-metrics": _vm.metrics,
                          "order-by": _vm.orderBy,
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "chart",
                  fn: function ({}) {
                    return [
                      _vm.chartType === _vm.ChartType.EXTENDED_LIST
                        ? _c("kraken-report", {
                            ref: "extendedListReport",
                            staticClass: "report-chart-wrapper__extended-list",
                            attrs: {
                              "report-data": _vm.reportData,
                              "chart-type": _vm.ChartType.LIST,
                              groups: _vm.groups,
                              metrics: _vm.metrics,
                              "additional-columns": _vm.additionalColumns,
                              limit: _vm.limit,
                              "with-pagination": _vm.withPagination,
                              "with-order": _vm.withOrder,
                              page: _vm.currentPage,
                              "order-by": _vm.currentOrderBy,
                              "order-dir": _vm.currentOrderDir,
                              config: _vm.extendedListConfig,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.currentPage = $event
                              },
                              prev: _vm.handlePrev,
                              next: _vm.handleNext,
                              sort: _vm.handleSort,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "kraken-item-list__item",
                                  fn: function (data) {
                                    return [
                                      _vm._t(
                                        "item-list__item",
                                        null,
                                        null,
                                        data
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "kraken-table-chart__drill-down",
                                  fn: function ({ row, columns, entities }) {
                                    return [
                                      _c("drill-down-wrapper", {
                                        attrs: {
                                          row: row,
                                          columns: columns,
                                          entities: entities,
                                          "date-period": _vm.datePeriod,
                                          "subject-type": _vm.subjectType,
                                          filter: _vm.filter,
                                          "parent-groups": _vm.groups,
                                          "parent-metrics": _vm.metrics,
                                          "order-by": _vm.orderBy,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }