var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fragment", [
    _c(
      "aside",
      [
        _c(
          "lobster-abstract-button",
          { attrs: { to: "/report-gallery", "data-tab": "report-gallery" } },
          [_vm._v("\n\t\t\tReport Gallery\n\t\t")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "social-bi-app inner" },
      [
        _c("router-view", { staticClass: "social-bi-app__content" }),
        _vm._v(" "),
        _c("lobster-provider"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }