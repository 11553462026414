import { HttpMethod } from 'yuri-js';
import yuri from '../../utils/yuri/AdminYuri';
import { map_group, map_reports } from '../../helpers/AnalyticsHelper';


export default {
	namespaced: true,
	state: {
		isGalleryListLoading: false,
		galleryList: [],
		isGallerySubmitting: false,
		isGalleryLoading: false,
		gallery: null
	},
	getters: {
		getGroupById: state => id => {
			return state.galleryList.find((item) => item.id === id);
		}
	},
	mutations: {
		setIsGalleryListLoading(state, isGalleryListLoading)
		{
			state.isGalleryListLoading = isGalleryListLoading;
		},
		setGalleryList(state, galleryList)
		{
			state.galleryList = galleryList;
		},
		setIsGallerySubmitting(state, isGallerySubmitting)
		{
			state.isGallerySubmitting = isGallerySubmitting;
		},
		setGallery(state, gallery)
		{
			state.gallery = gallery;
		},
		setIsGalleryLoading(state, isGalleryLoading)
		{
			state.isGalleryLoading = isGalleryLoading;
		},
		deleteWidget(state, widgetId)
		{
			state.gallery = state.gallery.filter(item => item.id !== widgetId)
		},
		removeReport(state, reportId)
		{
			state.gallery = state.gallery.filter(report => report.id !== reportId);
		}
	},
	actions: {
		async loadGalleryList({ commit })
		{
			try
			{
				commit('setIsGalleryListLoading', true);

				let res = await yuri.get('/reports-gallery/load-gallery');

				let galleryList = Object.values(res.Groups).map((group) =>
				{
					return map_group(group);
				});

				commit('setGalleryList', galleryList);
			}
			finally
			{
				commit('setIsGalleryListLoading', false);
			}
		},
		async createNewGallery({ commit }, payload)
		{
			try
			{
				commit('setIsGallerySubmitting', true);

				await yuri.post('/reports-gallery/create-group', payload);
			}
			finally
			{
				commit('setIsGallerySubmitting', false);
			}
		},
		async updateGallery({ commit }, payload)
		{
			try
			{
				commit('setIsGallerySubmitting', true);

				await yuri.connector()
					.setUrl('/reports-gallery/update-group')
					.setJsonBody(payload)
					.setMethod(HttpMethod.POST)
					.request();
			}
			finally
			{
				commit('setIsGallerySubmitting', false);
			}
		},
		async deleteGallery({ state, commit }, id)
		{
			await yuri.post('/reports-gallery/delete-group', { groupId: id });

			let filteredList = state.galleryList.filter((gallery) => gallery.id !== id);

			commit('setGalleryList', filteredList);
		},
		async loadGallery({ commit }, id)
		{
			try
			{
				commit('setIsGalleryLoading', true);

				commit('setGallery', []);
				let res = await yuri.post('/reports-gallery/load-reports', { groupId: id });

				commit('setGallery', map_reports(res?.Reports || []));
			}
			finally
			{
				commit('setIsGalleryLoading', false);
			}
		},
		async createReport({ commit }, payload)
		{
			try
			{
				commit('setIsGallerySubmitting', true);

				await yuri.post('/reports-gallery/create-report', payload);
			}
			finally
			{
				commit('setIsGallerySubmitting', false);
			}
		},
		async queryWidget({}, {
			id = '',
			widgetId = '',
			datePeriod,
			groups = [],
			metrics = [],
			additionalColumns = [],
			orderBy = [],
			limit = null,
			metadata = {},
			subjectType,
			chartType,
			filter = {},
			withCursor = null,
			withTotals = null,
			prev = null,
			next = null
		})
		{
			return await yuri.get('/analytics-query/query', {id, widgetId, datePeriod, groups, metrics, additionalColumns, orderBy, limit, metadata, subjectType, chartType, filter, withCursor, withTotals, prev, next});
		},
		async deleteReport({ state, commit, dispatch }, { widgetId })
		{
			await yuri.get('/reports-gallery/delete-report', { reportId: widgetId });

			commit('removeReport', widgetId);
		},
		async updateReport({ commit, state }, payload)
		{
			try
			{
				commit('setIsGallerySubmitting', true);

				await yuri.post('/reports-gallery/update-report', payload);

				let updatedGallery = state.gallery.map((report) => {

					if (report.id !== payload.reportId) {
						return report;
					}

					return {
						...report,
						...payload.reportData,
					}
				})

				commit('setGallery', updatedGallery);
			}
			finally
			{
				commit('setIsGallerySubmitting', false);
			}
		},
	}
}