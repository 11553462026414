<template>
	<v-date-picker 
		is-range
		is-required
		v-model="currentValue"
		:max-date="maxDate"/>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';


export default {
	name: "DatePicker",
	components: {
		VDatePicker
	},
	props: {
		value: {
			type: Object,
			default()
			{
				return {}
			}
		},
	},
	computed: {
		maxDate()
		{
			return new Date();
		},
		currentValue: {
			get()
			{
				if (this.$is(this.value.from) && this.$is(this.value.to))
				{
					return {
						start: new Date(this.value.from),
						end: new Date(this.value.to),
					}
				}
				
				return {
					start: 0,
					end: 0
				}
			},
			set(val)
			{
				let from = moment(val.start).format('YYYY-MM-DD');
				let to = moment(val.end).format('YYYY-MM-DD');
				
				this.$emit('input', {
					from,
					to
				})
			}
		}
	}
}
</script>

<style scoped>

</style>