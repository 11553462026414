export const COMPANYSIZES = [
	{value: 'SIZE_1', label: '1 employee'},
	{value: 'SIZE_2_TO_10', label: '2-10 employees'},
	{value: 'SIZE_11_TO_50', label: '11-50 employees'},
	{value: 'SIZE_51_TO_200', label: '51-200 employees'},
	{value: 'SIZE_201_TO_500', label: '201-500 employees'},
	{value: 'SIZE_501_TO_1000', label: '501-1000 employees'},
	{value: 'SIZE_1001_TO_5000', label: '1001-5000 employees'},
	{value: 'SIZE_5001_TO_10000', label: '5001-10,000 employees'},
	{value: 'SIZE_10001_OR_MORE', label: '10,001+ employees'}
];

export const LEAD_COMPANY_SIZES = Object.freeze([
	'1 employee',
	'2-10 employees',
	'11-50 employees',
	'51-200 employees',
	'201-500 employees',
	'501-1000 employees',
	'1001-5000 employees',
	'5001-10,000 employees',
	'10,001+ employees'
]);

export const COMPANY_SIZES = Object.freeze([
	'SIZE_1',
	'SIZE_2_TO_10',
	'SIZE_11_TO_50',
	'SIZE_51_TO_200',
	'SIZE_201_TO_500',
	'SIZE_501_TO_1000',
	'SIZE_1001_TO_5000',
	'SIZE_5001_TO_10000',
	'SIZE_10001_OR_MORE'
]);