<template>
	<lobster-menu class="dashboard-widget-menu">
		<lobster-action-button icon-name="overflow-menu--vertical"/>

		<template #popover>
			<lobster-menu-list>
				<lobster-menu-item
					v-close-popover
					icon-name="edit"
					@click="handleEdit">
					Edit
				</lobster-menu-item>
				<lobster-menu-item
					v-close-popover
					deny
					icon-name="trash-can"
					@click="handleDeleteClick">
					Delete
				</lobster-menu-item>
			</lobster-menu-list>
		</template>
	</lobster-menu>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetModal from "../WidgetModal/WidgetModal";


export default {
	name: "ReportMenu",
	props: {
		datePeriod: {
			type: Object,
			required: true
		},
		widget: {
			type: Object,
			required: true
		},
		dashboardId: {
			type: String,
			required: true
		}
	},
	methods: {
		...mapActions({
			deleteWidget: 'analytics/deleteWidget'
		}),
		async handleDelete()
		{
			await this.deleteWidget({dashboardId: this.dashboardId, widgetId: this.widget.id});
			
			this.$toaster.showSuccess('Widget deleted');
			
			this.$emit('delete');
		},
		handleEdit()
		{
			this.$showModal({
				component: WidgetModal,
				bind: {
					datePeriod: this.datePeriod,
					dashboardId: this.dashboardId,
					widget: this.widget
				}
			})
		},
		async handleDeleteClick()
		{
			let confirmed = await this.$dialog('Are you sure you want delete this report?');

			if (confirmed)
			{
				await this.handleDelete();
			}
		}
	}
}
</script>