var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { attrs: { "no-wrap": "", center: "", size: "small" } },
    [
      _c("lobster-network-icon", {
        staticClass: "no-shrink",
        attrs: { size: "large", name: _vm.network },
      }),
      _vm._v(" "),
      _c("lobster-text-entity", {
        attrs: {
          emojify: "",
          "smart-tooltip": "",
          network: _vm.network,
          text: _vm.message,
          "line-clamp": "1",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }