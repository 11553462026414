var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "lobster-layout-section",
    [
      _c(
        "lobster-entities-container",
        { attrs: { column: "", size: "large" } },
        [_c(_setup.LinkedInCrawler), _vm._v(" "), _c(_setup.AnalyticsCrawler)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }