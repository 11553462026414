<template>
	<lobster-form
		class="source-form"
		modal-view
		submit-button-text="Next"
		:submit-disabled="!$is(currentSubjectType)"
		@submit="handleSubmit">
		<p class="no-margin">
			Select the source you want to use for the report.
		</p>

		<lobster-entities-container>
			<template v-for="(item, index) in ReportDataSourcesConfig">
				<iconed-tickbox
					:key="index"
					card
					violet
					name="subjectType"
					type="radio"
					:val="index"
					:title="item.name"
					:disabled="item.disabled"
					v-model="currentSubjectType">
					<template #title>
						<lobster-icon
							class="report-source-icon"
							:name="item.iconName"/>
						{{ index }}
					</template>
				</iconed-tickbox>
			</template>
		</lobster-entities-container>
	</lobster-form>
</template>

<script>
import IconedTickbox from '../../Form/IconedTickbox.vue';
import { ReportDataSourcesConfig } from '@analytics';


export default {
	name: 'SourceForm',
	components: {
		IconedTickbox
	},
	props: {
		subjectType: String
	},
	data()
	{
		return {
			ReportDataSourcesConfig
		}
	},
	computed: {
		currentSubjectType: {
			get()
			{
				return this.subjectType;
			},
			set(val)
			{
				this.$emit('update:subject-type', val);
			}
		}
	},
	watch: {
		subjectType(newVal)
		{
			if (this.currentSubjectType !== newVal)
			{
				this.currentSubjectType = newVal;
			}
		}
	},
	methods: {
		handleSubmit()
		{
			this.$emit('submit', this.currentSubjectType);
		},
		getIconName(sourceType) {
			switch (sourceType) {
				case 'post':
					return 'align-box--top-left';
				case 'engagement':
					return 'thumbs-up';
				case 'profile':
					return 'user--avatar';
				case 'clicks':
					return 'cursor--1';
				case 'conversion':
					return 'money';
				case 'userSessions':
					return 'hourglass';
				case 'leadActivity':
					return 'user--activity';
				case 'brandMentions':
					return 'at';
				case 'linkedInAds':
				case 'linkedInDemographics':
					return 'logo--linkedin';
				case 'opportunity':
					return 'currency';
				case 'inbox':
					return 'box';
				case 'socialListening':
					return 'social--listening';
				default:
					return null;
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.source-form {
	min-width: 100%;
}
</style>