var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-table-cell",
    {
      staticClass: "common-table-cell",
      class: { m_link: _vm.isLink },
      attrs: {
        tooltip: _vm.cellLabel,
        truncate: _vm.truncate,
        right: _vm.column.isMetric,
        "no-container": _vm.isCustomView,
      },
    },
    [
      _vm.isNumericEmoji
        ? _c("sentiment-entity", { attrs: { value: _vm.emojiValue } })
        : _vm.column.isMetric
        ? [_vm._v("\n\t\t" + _vm._s(_vm.cellLabel) + "\n\t")]
        : _vm.isCustomView
        ? [
            _vm.isNetwork
              ? _c("network-entity", {
                  attrs: { "icon-size": "large", network: _vm.cellValue },
                })
              : _vm.isCampaign
              ? _c("campaign-entity", {
                  attrs: { name: _vm.entity.Name, color: _vm.entity.Color },
                })
              : _vm.isMessage
              ? _c("message-entity", {
                  staticClass: "common-table-cell__message",
                  attrs: {
                    message: _vm.entity.ChildMessage
                      ? _vm.entity.ChildMessage
                      : _vm.entity.Message,
                    network: _vm.entity.Network,
                  },
                })
              : _vm.isPost
              ? _c("lobster-post-entity", {
                  staticClass: "common-table-cell__post",
                  attrs: {
                    message: _vm.postEntityOptions.message,
                    network: _vm.postEntityOptions.network,
                    "display-name": _vm.postEntityOptions.displayName,
                    thumbnail: _vm.postEntityOptions.thumbnail,
                    "message-type": _vm.postEntityOptions.messageType,
                    "image-url": _vm.postEntityOptions.imageUrl,
                    media: _vm.postEntityOptions.media,
                    "video-preview": _vm.postEntityOptions.videoPreview,
                    "hide-thumbnail": _vm.postEntityOptions.hideThumbnail,
                    "user-name": _vm.postEntityOptions.userName,
                    "user-url": _vm.postEntityOptions.userUrl,
                    "show-username": _vm.postEntityOptions.showUsername,
                  },
                })
              : _vm.isProfile
              ? _c("lobster-profile-entity", {
                  attrs: {
                    network: _vm.profileEntityOptions.network,
                    name: _vm.profileEntityOptions.displayName,
                    subtitle: _vm.profileEntityOptions.subtitle,
                    thumbnail: _vm.profileEntityOptions.thumbnail,
                    gravatar: _vm.profileEntityOptions.gravatar,
                  },
                })
              : _vm.cellName === "AdCreativeID"
              ? _c("ads-entity", {
                  attrs: {
                    content: _vm.entity.Content,
                    status: _vm.entity.Status,
                  },
                })
              : _vm.cellName === "LeadStatus"
              ? _c("lead-status-entity", { attrs: { status: _vm.cellValue } })
              : _vm.cellName === "LeadCompany"
              ? _c("lead-account-entity", {
                  attrs: {
                    name: _vm.cellValue,
                    "is-target-account": +_vm.cells.IsTargetAccount.value === 1,
                  },
                })
              : _vm.cellName === "OpportunityStageName"
              ? _c("stage-entity", {
                  attrs: {
                    "stage-name": _vm.cellLabel,
                    "stage-type": _vm.cells.OpportunityStageType.value,
                  },
                })
              : [_vm._v("\n\t\t\t" + _vm._s(_vm.cellLabel) + "\n\t\t")],
          ]
        : _vm.isLink
        ? _c("link-entity", {
            attrs: {
              "cell-value": _vm.cellValue,
              "cell-name": _vm.cellName,
              "cell-label": _vm.cellLabel,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          })
        : [_vm._v("\n\t\t" + _vm._s(_vm.cellLabel) + "\n\t")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }