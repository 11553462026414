import { ChartType } from '../consts/ChartType.js';


/**
 * @typedef ChartTypeConfig
 * @property {string} name
 * @property {number} [maxGroups]
 * @property {number} minGroups
 * @property {number} [maxMetrics]
 * @property {number} minMetrics
 * @property {boolean} [isTemplate]
 * @property {boolean} [isDeprecated]
 * @property {boolean} [isList]
 * @property {boolean} [hasTableView]
 * @property {boolean} [withOrderBy]
 * @property {boolean} [acceptsAdditionalData]
 */

/**
 * @type {Object<string, ChartTypeConfig>}
 */
export const ChartTypesConfig = {
	[ChartType.AREA_STACKED]: {
		name: 'Area',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		acceptsAdditionalData: true
	},
	[ChartType.BAR_LIST]: {
		name: 'Bar List',
		isTable: true,
		minGroups: 1,
		maxGroups: 1,
		minMetrics: 1,
		isTemplate: true,
		hasTableView: true,
		withOrderBy: true
	},
	[ChartType.BUBBLE]: {
		name: 'Bubble',
		maxGroups: 2,
		minGroups: 2,
		minMetrics: 1,
		hasTableView: true,
		acceptsAdditionalData: true
	},
	[ChartType.COMBO]: {
		name: 'Combo',
		maxMetrics: 2,
		maxGroups: 1,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		acceptsAdditionalData: true
	},
	[ChartType.DONUT]: {
		name: 'Donut',
		minGroups: 1,
		minMetrics: 1,
		maxGroups: 2,
		maxMetrics: 1,
		hasTableView: true
	},
	[ChartType.FUNNEL]: {
		name: 'Funnel',
		maxGroups: 1,
		minGroups: 0,
		minMetrics: 1,
		maxMetrics: 5
	},
	[ChartType.GROUPED_BAR]: {
		name: 'Grouped Bar',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		withOrderBy: true,
		acceptsAdditionalData: true
	},
	[ChartType.GROUPED_COLUMN]: {
		name: 'Grouped Column',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		withOrderBy: true,
		acceptsAdditionalData: true
	},
	[ChartType.ITEM_LIST]: {
		name: 'Items List',
		isTable: true,
		minGroups: 1,
		minMetrics: 0,
		isTemplate: true,
		hasTableView: true,
		withOrderBy: true,
		isList: true
	},
	[ChartType.POST_LIST]: {
		name: 'Post List',
		isTable: true,
		minGroups: 1,
		minMetrics: 0,
		isTemplate: true,
		hasTableView: true,
		withOrderBy: true,
		isList: true,
		acceptsAdditionalData: true
	},
	[ChartType.MEDIA_LIST]: {
		name: 'Post List',
		isTable: true,
		minGroups: 1,
		minMetrics: 0,
		isTemplate: true,
		hasTableView: true,
		withOrderBy: true,
		isList: true,
		acceptsAdditionalData: true
	},
	[ChartType.LINE]: {
		name: 'Line',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		acceptsAdditionalData: true
	},
	[ChartType.EXTENDED_LIST]: {
		name: 'Extended List',
		isTable: true,
		minGroups: 1,
		minMetrics: 0
	},
	[ChartType.LIST]: {
		name: 'List',
		isTable: true,
		minGroups: 1,
		minMetrics: 0,
		hasTableView: true,
		withOrderBy: true,
		isList: true
	},
	[ChartType.STACKED_BAR]: {
		name: 'Stacked Bar',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		withOrderBy: true,
		acceptsAdditionalData: true
	},
	[ChartType.STACKED_COLUMN]: {
		name: 'Stacked Column',
		maxGroups: 2,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true,
		withOrderBy: true,
		acceptsAdditionalData: true
	},
	[ChartType.TOTAL_COMPARE]: {
		name: 'Total Compare',
		maxGroups: 0,
		minGroups: 0,
		minMetrics: 1
	},
	[ChartType.TOTAL_COMPARE_EXTENDED]: {
		name: 'Total Compare',
		maxGroups: 0,
		minGroups: 0,
		minMetrics: 1,
		isDeprecated: true
	},
	[ChartType.WORD_CLOUD]: {
		name: 'Word Cloud',
		maxGroups: 1,
		minGroups: 1,
		minMetrics: 1,
		hasTableView: true
	}
}