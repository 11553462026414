<template>
	<div class="drill-down-wrapper" ref="root">
		<report-chart-wrapper
			class="drill-down-wrapper__chart"
			:subject-type="subjectType"
			:date-period="datePeriod"
			:chart-type="chartType"
			:groups="groups"
			:filter="currentFilter"
			:limit="limit"
			:order-by="orderBy"
			with-pagination>
			<template #item-list__item="{row, columns, entities}">
				<div class="drill-down-wrapper__trello-card">
					<trello-card :id="row.cells.ID.value"/>
				</div>
			</template>
		</report-chart-wrapper>
	</div>
</template>

<script>
import { DrillDownsConfig } from '../../../configs/DrillDownsConfig';
import { merge_filters } from '../../../helpers/AnalyticsHelper';
import { is } from 'plankton-esm';
import { FunctionHelpers } from 'lobster-ui';
import TrelloCard from '../Entities/TrelloCard';

export default {
	name: "DrillDownWrapper",
	components: {TrelloCard},
	props: {
		datePeriod: {
			type: Object,
		},
		subjectType: {
			type: String,
		},
		parentGroups: {
			type: Array,
		},
		parentMetrics: {
			type: Array,
		},
		filter: Object,
		orderBy: Array,
		row: {
			type: Object,
			default()
			{
				return {};
			}
		},
		columns: {
			type: Array,
			default()
			{
				return [];
			}
		},
		entities: {
			type: Object,
			default()
			{
				return {};
			}
		},
		limit: {
			type: Number,
			default: 8
		}
	},
	computed: {
		chartType()
		{
			if (!is(this.drillDownConfig))
			{
				return;
			}
			
			return this.drillDownConfig.chartType;
		},
		groups()
		{
			if (!is(this.drillDownConfig))
			{
				return;
			}
			
			return this.drillDownConfig.groups;
		},
		drillDownConfig()
		{
			return DrillDownsConfig[this.subjectType];
		},
		currentFilter()
		{
			let additionalFilter = {
				dimensions: []
			};
			
			for (let column of this.columns)
			{
				if (!column.isGroup || column.isAdditionalColumn)
				{
					continue;
				}
				
				let filterColumn = column.value;
				
				if (!filterColumn)
				{
					continue;
				}

				let filterColumnValue = this.row.cells[filterColumn]?.value;
				
				if (!filterColumnValue)
				{
					continue;
				}
				
				additionalFilter.dimensions.push({
					column: filterColumn,
					values: [ filterColumnValue ]
				});
			}
			
			return merge_filters(this.filter, additionalFilter);
		},
	},
	methods: {
		getCardUrl(row, entities)
		{
			this.debouncedRenderCards()
			return entities.ID[row.cells.ID.value].TrelloFields.shortUrl;
		},
		renderCards()
		{
			window.TrelloCards.load(this.$refs.root, { compact: true, allAnchors: false });
		}
	},
	created()
	{
		this.debouncedRenderCards = FunctionHelpers.debounce(this.renderCards, 0);
	}
}
</script>

<style lang="scss" scoped>
.drill-down-wrapper {
	margin: 0 24px;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.drill-down-wrapper__chart {
}

.drill-down-wrapper__trello-card {
	padding: 16px;
}

.drill-down-wrapper__chart::v-deep .item-list-chart {
	align-items: flex-start;
}
</style>