export const TextEditorConfig = Object.freeze({
	formats: [ 'bold', 'italic', 'header', 'strike', 'list', 'link' ],
	sanitizeOptions: {
		allowedTags: ['p', 'span', 'br', 'h1', 'h2', 'strong', 'em', 's', 'ol', 'li', 'a'],
		allowedAttributes: {
			'*': ['class', 'data-*'],
			'a': ['href', 'target']
		}
	},
	warningTextLengthCounter: 20
});