var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    {
      attrs: { open: _vm.isOpened },
      on: {
        "update:open": function ($event) {
          _vm.isOpened = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "popover",
          fn: function () {
            return [
              _vm.isOpened
                ? _c(
                    "lobster-form",
                    {
                      staticClass: "format-link-menu__form",
                      attrs: {
                        "modal-view": "",
                        "submit-button-text": _vm.isEditMode
                          ? _vm.$t("commonUtils.textEditor.toolbar.update")
                          : _vm.$t("commonUtils.textEditor.toolbar.insert"),
                      },
                      on: { submit: _vm.handleFormatLinkUpdated },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "additional-actions",
                            fn: function () {
                              return [
                                _vm.isEditMode
                                  ? _c(
                                      "lobster-button",
                                      {
                                        attrs: { secondary: "" },
                                        on: {
                                          click: _vm.handleFormatLinkDeleted,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t(
                                                "commonUtils.textEditor.toolbar.unlink"
                                              )
                                            ) +
                                            "\n\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        937328842
                      ),
                    },
                    [
                      _c("lobster-input", {
                        ref: "urlInput",
                        attrs: {
                          label: _vm.$t(
                            "commonUtils.textEditor.toolbar.linkUrl"
                          ),
                          validators: "required",
                        },
                        model: {
                          value: _vm.linkUrl,
                          callback: function ($$v) {
                            _vm.linkUrl = $$v
                          },
                          expression: "linkUrl",
                        },
                      }),
                      _vm._v(" "),
                      _c("lobster-input", {
                        attrs: {
                          label: _vm.$t(
                            "commonUtils.textEditor.toolbar.linkTitle"
                          ),
                          validators: "required",
                        },
                        model: {
                          value: _vm.linkTitle,
                          callback: function ($$v) {
                            _vm.linkTitle = $$v
                          },
                          expression: "linkTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("lobster-action-button", {
        attrs: {
          "icon-name": "link",
          selected: _vm.$is(_vm.activeFormat.link) || _vm.isOpened,
        },
        on: { click: _vm.handleTriggerButtonClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }