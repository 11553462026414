<script setup>
import { ref } from 'vue';
import { useToaster } from '../../../hooks/toaster';
import AdminYuri from '../../../utils/yuri/AdminYuri';


const linkedInId = ref();
const toaster = useToaster();


async function handleSubmit()
{
	await AdminYuri.post('/qa-tools/schedule-linked-in-crawlers', { id: linkedInId.value });
	
	toaster.showSuccess('LinkedIn crawlers scheduled. Good luck and have a fun!');
	
	setTimeout(() =>
	{
		toaster.showInfo('Do this again in a few minutes for better confidence in results');
	}, 1000)
}
</script>

<template>
	<fragment>
		<h2>LinkedIn Crawler</h2>
		<lobster-form
			submit-button-text="Schedule"
			@submit="handleSubmit">
			
			<lobster-input
				label="Profile/Page id"
				validators="required"
				caption="Only ID, without urn part"
				v-model="linkedInId"/>
		</lobster-form>
	</fragment>
</template>

<style scoped lang="scss">

</style>