var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-widget" },
    [
      _c(
        "div",
        { staticClass: "dashboard-widget__header" },
        [
          _c(
            "div",
            { staticClass: "dashboard-widget__title" },
            [
              _vm.reportLink
                ? _c("router-link", { attrs: { to: _vm.reportLink } }, [
                    _c("h2", [_vm._v(_vm._s(_vm.widget.name))]),
                  ])
                : _c("h2", [_vm._v(_vm._s(_vm.widget.name))]),
            ],
            1
          ),
          _vm._v(" "),
          _c("report-menu", {
            staticClass: "dashboard-widget__menu",
            attrs: {
              "date-period": _vm.datePeriod,
              "dashboard-id": _vm.dashboardId,
              widget: _vm.widget,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("report-chart-wrapper", {
        staticClass: "dashboard-widget__report",
        class: "m_" + _vm.widget.chartType,
        attrs: {
          "dashboard-id": _vm.dashboardId,
          "widget-id": _vm.widget.id,
          "chart-type": _vm.widget.chartType,
          groups: _vm.widget.groups,
          metrics: _vm.widget.metrics,
          "additional-columns": _vm.widget.additionalData,
          "date-period": _vm.datePeriod,
          limit: _vm.widget.limit,
          "order-by": _vm.widget.orderBy,
          "subject-type": _vm.widget.subjectType,
          filter: _vm.widgetFilter,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }