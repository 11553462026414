<template>
	<lobster-search-filter
		search-disabled
		not-strict
		v-model="currentFilters"
		:disabled="disabled"
		:menu-view="menuView"
		:show-group-labels="menuView"
		:show-flat-groups="$is(subjectType)"
		:filters="filters">
		<template #option="{option, options, filterName, filter}">
			<network-entity
				v-if="profileFilters.includes(filterName)"
				:network="option.Network"
				:label="option.DisplayName || option.Name || 'Unknown'"/>
			<network-entity
				v-else-if="filterName === 'Network' || filterName ==='SocialActivityNetwork' || filterName === 'ListeningChannel'"
				:network="option.value"
				:label="option.label"/>
			<network-entity
				v-else-if="filterName === 'SDBID'"
				network="linkedin"
				:label="option.Name"/>
			<campaign-entity
				v-else-if="filterName === 'CampaignID'"
				:name="option.Name"
				:color="option.Color"/>
		</template>
	</lobster-search-filter>
</template>

<script>
import {
	ReportDataSourcesConfig,
	DimensionsConfig,
	MetricsConfig,
	getSourceDimensions,
	getSourceMetrics
} from '@analytics';
import { CampaignEntity, deepCopy, NetworkEntity } from '@utils';
import { FilterTypes } from '../../../consts/FilterTypes';
import { FunctionHelpers } from 'lobster-ui';
import { IncompatibleMetricsByGroup } from '../../../configs/GroupMetricCompatibilityConfig';
import { is } from 'plankton-esm';
import { METRIC_OPERATOR_OPTIONS, METRIC_PERIODS_OPTIONS } from '../../../consts/FilterMetrics';
import { TagsOperators } from '../../../consts/Operators';
import { DimensionsFiltersConfig } from '../../../configs/DimensionsFiltersConfig';
import { convertFromSeconds, convertToSeconds } from '../../../helpers/DateHelper';
import { SOCIAL_MEDIA_POST_TYPE } from '../../../consts/SocialMediaPostType';


export default {
	name: 'AnalyticsFilters',
	components: {
		NetworkEntity,
		CampaignEntity
	},
	props: {
		value: {
			type: Object,
			default: () => ({})
		},
		isCampaignAnalytics: {
			type: Boolean,
			default: false
		},
		isSocialListening: {
			type: Boolean,
			default: false
		},
		menuView: {
			type: Boolean,
			default: true
		},
		isReportFilter: {
			type: Boolean,
			default: false
		},
		isReportBuilder: {
			type: Boolean,
			default: false
		},
		subjectType: String,
		reportsSubjectTypes: {
			type: Array,
			default: () => ([])
		},
		disabled: {
			type: Boolean,
			default: false
		},
		groups: {
			type: Array,
			default: ()=>[]
		},
		additionalColumns: {
			type: Array,
			default: ()=>[]
		},
		metrics: {
			type: Array,
			default: ()=>[]
		},
	},
	data()
	{
		return {
			profileFilters: ['ProfileId', 'ProfileID', 'CredentialID', 'LinkedinProfileId', 'MentionedCredentialID', 'SDBAuthorID'],
		}
	},
	computed: {
		filters()
		{
			let filters = {};
			let dimensionsFilters = [];
			let metricsFilters = [];

			if (this.subjectType)
			{
				dimensionsFilters = Object.keys(DimensionsFiltersConfig)
					.filter(item => getSourceDimensions(this.subjectType).includes(item));

				if (this.subjectType === 'opportunity')
				{
					filters.socialActivity = {
						group: true,
						filterLabel: 'Social Activity',
						children: {}
					}

					filters.opportunity = {
						group: true,
						filterLabel: 'Opportunity',
						children: {}
					}
				}
				else if (this.isReportBuilder)
				{
					filters.dimensions = {
						group: true,
						filterLabel: 'Dimensions',
						children: {}
					}
				}
			}
			else
			{
				dimensionsFilters = Object.keys(DimensionsFiltersConfig)
					.filter(item =>
					{
						if (DimensionsConfig[item].isDateRelated)
						{
							return false;
						}

						for (let subjectType of this.reportsSubjectTypes)
						{
							if (getSourceDimensions(subjectType).includes(item))
							{
								return true;
							}
						}

						return false;
					});

				for (let subjectType of this.reportsSubjectTypes)
				{
					filters[subjectType] = {
						group: true,
						filterLabel: subjectType,
						children: {}
					}
				}
			}

			if (this.isCampaignAnalytics)
			{
				dimensionsFilters = dimensionsFilters.filter(item => item !== 'CampaignID')
			}

			if (this.isSocialListening)
			{
				dimensionsFilters = dimensionsFilters.filter(item => DimensionsFiltersConfig[item].isListeningFilter);
			}

			for (let filterName of dimensionsFilters)
			{
				let filter = {};

				let dimensionFilter = DimensionsFiltersConfig[filterName];

				filter.filterLabel = DimensionsConfig[filterName].name;
				filter.type = dimensionFilter.type;

				if (is(this.value?.dimensions) && filterName === 'ProfileRemoteID')
				{
					let item = this.value.dimensions.find(item => item.column === 'TrackerID');

					if (is(item))
					{
						filter.params = { ...dimensionFilter.params, trackerID: item.values[0]};
					}
				}

				if (dimensionFilter.type === FilterTypes.AUTOCOMPLETE)
				{
					let dimensionParams = dimensionFilter.params;
					let labelParam = dimensionFilter.labelParam ?? 'Name';
					let valueParam = dimensionFilter.valueParam ?? 'Id';

					if (filterName === 'Country' || filterName === 'City')
					{
						dimensionParams = { ...dimensionParams, source: 'userSessions'}
					}

					filter = {
						...filter,
						getOptions: FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), dimensionFilter.url, dimensionParams),
						loadElementsByValue: dimensionFilter.loadElementsByValue || FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), dimensionFilter.url, dimensionParams, 'id'),
						getLabel: option => option[labelParam],
						getValue: option => option[valueParam]
					}
				}
				else if (dimensionFilter.type === FilterTypes.SELECT)
				{
					if (typeof dimensionFilter.options === 'function')
					{
						filter.options = dimensionFilter.options();
					}
					else
					{
						filter.options = deepCopy(dimensionFilter.options);
					}
				}
				else if (dimensionFilter.type === FilterTypes.FREE_TEXT)
				{
					filter.inputType = dimensionFilter.inputType;
				}

				filter.multiple = dimensionFilter.multiple;
				filter.searchable = dimensionFilter.searchable;
				filter.infinityScroll = dimensionFilter.infinityScroll;

				if (dimensionFilter.getValue)
				{
					filter.getValue = dimensionFilter.getValue;
				}

				if (dimensionFilter.getLabel)
				{
					filter.getLabel = dimensionFilter.getLabel;
				}

				if (dimensionFilter.icon)
				{
					filter.icon = dimensionFilter.icon;
				}

				if (dimensionFilter.numeric)
				{
					filter.numeric = dimensionFilter.numeric;
				}

				if (is.defined(dimensionFilter.filter))
				{
					filter.filter = dimensionFilter.filter;
				}

				if (this.subjectType === 'linkedInDemographics')
				{
					if (this.groups.length)
					{
						for (let gg of this.groups)
						{
							if (DimensionsConfig[gg].isDate)
								continue;
							if (gg !== filterName)
							{
								filter.disabled = true;
								filter.tooltip  = '...';
								break;
							}
						}
					}

					if (this.metrics.length)
					{
						for ( let mm of this.metrics)
						{
							if (IncompatibleMetricsByGroup[filterName]?.includes(mm))
							{
								filter.disabled = true;
								filter.tooltip = 'This filter is not compatible with selected metric';
							}
						}
					}

					let filteredUsedDimension = this.value.dimensions.filter(a => (a.column !== 'LinkedinProfileId' && a.column !== filterName));

					if (is.notEmpty(filteredUsedDimension) && filter.filterLabel !== 'Profile')
					{
						filter.disabled = true;
						filter.tooltip = 'You can select max 1 dimension filter';
					}
				}

				if (this.subjectType === 'socialListening')
				{
					if (filterName === 'Keywords')
					{
						filter.options = [{
							label: 'Contains',
							value: TagsOperators.CONTAINS
						}, {
							label: 'Not Contains',
							value: TagsOperators.NOT_CONTAINS
						}];
					}
					if (filterName === 'PostType')
					{
						filter.options = SOCIAL_MEDIA_POST_TYPE.map(value => ({
							value,
							label:  I18n.t('commonAnalytics.postType')[value] ?? value
						}));
					}
					if (filterName === 'ProfileID')
					{
						let filterUrl = '/api/search/social-listening-profile';
						filter.getOptions = FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), filterUrl, dimensionFilter.params);
						filter.loadElementsByValue = dimensionFilter.loadElementsByValue || FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), filterUrl, dimensionFilter.params, 'id');
					}
				}

				if (this.subjectType === 'opportunity')
				{
					if (DimensionsConfig[filterName].isActivityRelated)
					{
						filters.socialActivity.children[filterName] = filter;
					}
					else
					{
						filters.opportunity.children[filterName] = filter;
					}
				}
				else if (this.isReportBuilder)
				{
					filters.dimensions.children[filterName] = filter;
				}
				else if (this.reportsSubjectTypes.length)
				{
					for (let subjectType of this.reportsSubjectTypes)
					{
						if (getSourceDimensions(subjectType).includes(filterName))
						{
							if (subjectType === 'linkedInDemographics')
							{
								if (is.notEmpty(this.value?.linkedInDemographics?.dimensions) && filter.filterLabel !== 'Profile')
								{
									filter.disabled = true;
									filter.tooltip = 'You can select max 1 dimension filter';
								}
							}

							if (filterName === 'Keywords')
							{
								if (subjectType === 'socialListening')
								{
									filter.options = [{
										label: 'Contains',
										value: TagsOperators.CONTAINS
									}, {
										label: 'Not Contains',
										value: TagsOperators.NOT_CONTAINS
									}];
								}
								else
								{
									filter.options = null;
								}
							}

							if (filterName === 'PostType')
							{
								if (subjectType === 'socialListening')
								{
									filter.options = SOCIAL_MEDIA_POST_TYPE.map(value => ({
										value,
										label: I18n.t('commonAnalytics.postType')[value] ?? value
									}));
								}
								else if (typeof dimensionFilter.options === 'function')
								{
									filter.options = dimensionFilter.options();
								}
								else
								{
									filter.options = deepCopy(dimensionFilter.options);
								}
							}

							if (filterName === 'ProfileID')
							{
								let filterUrl = '';

								if (subjectType === 'socialListening')
								{
									filterUrl = '/api/search/social-listening-profile';
								}
								else
								{
									filterUrl = dimensionFilter.url;
								}

								filter.getOptions = FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), filterUrl, dimensionFilter.params);
								filter.loadElementsByValue = dimensionFilter.loadElementsByValue || FunctionHelpers.getAutocompleteLoadFunctions(this.$getAjaxModule(), filterUrl, dimensionFilter.params, 'id');
							}

							filters[subjectType].children[filterName] = { ...filter };
						}
					}
				}
				else
				{
					filters[filterName] = filter;
				}
			}

			if (this.isReportBuilder)
			{
				filters.metrics = {
					group: true,
					filterLabel: 'Metrics',
					children: {}
				}

				metricsFilters = getSourceMetrics(this.subjectType);

				if (this.subjectType === 'socialListening')
				{
					metricsFilters = metricsFilters.filter(item => item !== 'ProfileCount');
				}

				for (let filterName of metricsFilters)
				{
					let filter = {};
					let metricsFilter = MetricsConfig[filterName];

					filter.filterLabel = metricsFilter.name;
					filter.type = FilterTypes.COMPARE;
					filter.icon = metricsFilter.percent ? 'percentage' : 'hashtag';
					filter.rows = {};

					if (filterName === 'AvgTimeToClose' || filterName === 'AvgTimeToFirstReply')
					{
						filter.rows.period = {
							dropdownValue: 'days',
							dropdownOptions: METRIC_PERIODS_OPTIONS
						};
					}

					filter.rows.operator = {
						dropdownValue: '==',
						inputValue: '',
						dropdownOptions: METRIC_OPERATOR_OPTIONS
					}

					filters.metrics.children[filterName] = filter;

					if (this.subjectType === 'linkedInDemographics' && this.metrics.length && !this.metrics.includes(filterName) )
					{
						filter.disabled = true;
						filter.tooltip = 'You can filter by selected metrics only';
					}
				}
			}

			return filters;
		},
		currentFilters:	{
			get()
			{
				let filters = {};

				if (this.subjectType)
				{
					if (this.subjectType === 'opportunity')
					{
						filters = this.unmapReportGroupsFilterValue(this.value, ['socialActivity', 'opportunity', 'metrics'])
					}
					else if (this.isReportBuilder)
					{
						filters = this.unmapReportBuilderFilterValue(this.value);
					}
					else
					{
						filters = this.unmapReportFilterValue(this.value);
					}
				}
				else
				{
					for (let reportType of this.reportsSubjectTypes)
					{
						filters[reportType] = this.unmapDashboardFilterValue(this.value[reportType], reportType);
					}
				}

				return {
					filters
				}
			},
			set(val)
			{
				let filters = {};

				if (this.subjectType)
				{
					filters = this.mapFilterValue(val.filters, this.subjectType);
				}
				else
				{
					for (let reportType of this.reportsSubjectTypes)
					{
						filters[reportType] = this.mapFilterValue(val.filters[reportType], reportType);
					}
				}

				this.$emit('input', filters);
			}
		}
	},
	methods: {
		unmapReportBuilderFilterValue(value)
		{
			let hasFilter = (column, type) =>
			{
				type = type === 'tags' ? 'dimensions' : type;

				return this.filters[type]?.children?.hasOwnProperty(column);
			};

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapReportFilterValue(value)
		{
			let hasFilter = (column) => this.filters.hasOwnProperty(column);

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapReportGroupsFilterValue(value, groups)
		{
			let hasFilter = (column) =>
			{
				for (let group of groups)
				{
					if (this.filters[group]?.children?.hasOwnProperty(column))
						return true;
				}

				return false;
			}

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapDashboardFilterValue(value, subjectType)
		{
			let hasFilter = (column) => this.filters[subjectType]?.children?.hasOwnProperty(column);

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapFilterValue(value, hasFilter)
		{
			let filters = {};
			let {dimensions, tags, metrics} = this.sanitizeFilterValue(value);

			for (let filter of dimensions)
			{
				if (!hasFilter(filter.column, 'dimensions'))
					continue;

				filters[filter.column] = filter.values;
			}

			for (let filter of tags)
			{
				if (!hasFilter(filter.column, 'tags'))
					continue;

				if (!filters.hasOwnProperty(filter.column))
				{
					filters[filter.column] = []
				}

				filters[filter.column].push(this.unmapTagsFilter(filter));
			}

			for (let filter of metrics)
			{
				if (!hasFilter(filter.metric, 'metrics'))
					continue;

				filters[filter.metric] = this.unmapMetricsFilter(filter);
			}

			return filters;
		},
		mapFilterValue(value, subjectType)
		{
			let filters = {
				dimensions: [],
				tags: [],
				metrics: []
			};

			for (let name in value)
			{
				if (!value.hasOwnProperty(name))
				{
					continue;
				}

				if (is.notEmpty(value[name]) && is(MetricsConfig[name]) && getSourceMetrics(subjectType).includes(name))
				{
					filters.metrics.push(this.mapMetricsFilter(name, value[name]));
				}
				else if (is.notEmpty(value[name]) && DimensionsFiltersConfig[name]?.type === FilterTypes.TAGS)
				{
					filters.tags = filters.tags.concat(this.mapTagsFilter(name, value[name]));
				}
				else if (is.notEmpty(value[name]) && is(DimensionsFiltersConfig[name]))
				{
					filters.dimensions.push(this.mapDimensionFilter(name, value[name]));
				}
			}

			return filters;
		},
		sanitizeFilterValue(value)
		{
			let dimensions = [];
			let tags = [];
			let metrics = [];

			try {
				dimensions = JSON.parse(JSON.stringify(value.dimensions));
			}
			catch (e) {}

			try {
				tags = JSON.parse(JSON.stringify(value.tags));
			}
			catch (e) {}

			try {
				metrics = JSON.parse(JSON.stringify(value.metrics));
			}
			catch (e) {}

			return {dimensions, tags, metrics};
		},
		unmapTagsFilter(filter)
		{
			if (is(filter.value))
			{
				return {
					operator: filter.operator,
					value: filter.value.join(', ')
				};
			}
			else
			{
				return {
					operator: filter.operator
				};
			}
		},
		unmapMetricsFilter(filter)
		{
			let rows = {};
			let inputValue = filter.value;
			let dropdownValue = filter.operator;

			if (filter.metric === 'AvgTimeToClose' || filter.metric === 'AvgTimeToFirstReply')
			{
				rows.period = {
					dropdownValue: filter.metaData.period || 'days',
					dropdownOptions: METRIC_PERIODS_OPTIONS
				}

				inputValue = inputValue === '' ? '' : convertFromSeconds(filter.value, filter.metaData.period || 'days');
			}

			if (MetricsConfig[filter.metric].percent)
			{
				inputValue = Math.round((inputValue * 100));
			}

			rows.operator = {
				dropdownValue,
				inputValue,
				dropdownOptions: METRIC_OPERATOR_OPTIONS
			}

			return rows;
		},
		mapMetricsFilter(name, value)
		{
			let metricsFilter = {
				metric:   name,
				operator: value.operator.dropdownValue,
				value:    value.operator.inputValue
			}

			if (name === 'AvgTimeToClose' || name === 'AvgTimeToFirstReply')
			{
				let period = value.period.dropdownValue;
				let inputValue = value.operator.inputValue;

				metricsFilter.metaData = {
					period: period
				}

				metricsFilter.value = inputValue === '' ? '' : convertToSeconds(inputValue, period);
			}

			if (MetricsConfig[name].percent)
			{
				metricsFilter.value = +value.operator.inputValue / 100;
			}

			return metricsFilter;
		},
		mapTagsFilter(name, value)
		{
			return value.map(filter => is(filter.value) ? ({
				column: name,
				operator: filter.operator,
				value: is.array(filter.value) ? filter.value : filter.value.split(', ')
			}) : ({
				column: name,
				operator: filter.operator
			}));
		},
		mapDimensionFilter(name, value)
		{
			return {
				column: name,
				values: array(value)
			}
		}
	}
}
</script>