var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-body",
    { staticClass: "single-report-section" },
    [
      _vm.isLoading
        ? _c("lobster-loader")
        : [
            _c(
              "div",
              { staticClass: "single-report-section__header" },
              [
                _c("lobster-action-button", {
                  staticClass: "single-report-section__back-button",
                  attrs: { "icon-name": "arrow--left", to: _vm.dashboardLink },
                }),
                _vm._v(" "),
                _c("h1", [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.widget.name) + "\n\t\t\t"),
                ]),
                _vm._v(" "),
                _c("report-menu", {
                  staticClass: "single-report-section__menu",
                  attrs: {
                    "dashboard-id": _vm.dashboardId,
                    widget: _vm.widget,
                    "date-period": _vm.widget.datePeriod,
                  },
                  on: { delete: _vm.handleDelete },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("lobster-layout-action-bar", {
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c("analytics-filter", {
                        attrs: {
                          "is-report-filter": "",
                          "subject-type": _vm.widget.subjectType,
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "lobster-layout-section",
              { staticClass: "single-report-section__main-section" },
              [
                !_vm.isTable
                  ? _c("report-chart-wrapper", {
                      staticClass: "single-report-section__main-chart",
                      class: { "m_total-compare": _vm.isTotalCompare },
                      attrs: {
                        "chart-type": _vm.widget.chartType,
                        groups: _vm.widget.groups,
                        metrics: _vm.widget.metrics,
                        "additional-columns": _vm.widget.additionalData,
                        "date-period": _vm.widget.datePeriod,
                        limit: _vm.widget.limit,
                        "order-by": _vm.widget.orderBy,
                        "subject-type": _vm.widget.subjectType,
                        filter: _vm.filter,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTotalCompare
                  ? _c("drill-down-wrapper", {
                      attrs: {
                        "date-period": _vm.widget.datePeriod,
                        "subject-type": _vm.widget.subjectType,
                        filter: _vm.filter,
                        limit: 16,
                      },
                    })
                  : _c("report-chart-wrapper", {
                      staticClass: "single-report-section__table-chart",
                      attrs: {
                        "with-pagination": "",
                        "with-order": "",
                        "with-totals": "",
                        "chart-type": _vm.secondChartType,
                        groups: _vm.widget.groups,
                        metrics: _vm.widget.metrics,
                        "additional-columns": _vm.widget.additionalData,
                        "date-period": _vm.widget.datePeriod,
                        limit: 20,
                        "order-by": _vm.widget.orderBy,
                        "subject-type": _vm.widget.subjectType,
                        filter: _vm.filter,
                        config: _vm.config,
                      },
                    }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }