<template>
	<fragment>
		<aside>
			<lobster-abstract-button
				to="/report-gallery"
				data-tab="report-gallery">
				Report Gallery
			</lobster-abstract-button>
		</aside>

		<div class="social-bi-app inner">
			<router-view class="social-bi-app__content"/>
			<lobster-provider/>
		</div>
	</fragment>
</template>

<script>
import { ErrorHandler } from '../../utils/yuri/ErrorHandler';
import ReportGalleryModule from '../../store/SocialBI/ReportGalleryModule';


export default {
	name: 'SocialBIApp',
	methods: {
		handleNavMenuClick() {
			if (this.$route.name !== 'report-gallery')
			{
				this.$router.push({name: 'report-gallery'});
			}
		}
	},
	beforeCreate()
	{
		if (this.$store.hasModule('reportGallery'))
		{
			this.$store.unregisterModule('reportGallery');
		}

		this.$store.registerModule('reportGallery', ReportGalleryModule);
	},
	beforeDestroy()
	{
		if (this.$store.hasModule('reportGallery'))
		{
			this.$store.unregisterModule('reportGallery');
		}
	},
	mounted()
	{
		this.$yuri.registerHelper(new ErrorHandler(this.$toaster));
		$('a[data-menu="social-bi"]').on('click', this.handleNavMenuClick);
	},
	destroyed()
	{
		$('a[data-menu="social-bi"]').off('click', this.handleNavMenuClick);
	}
}
</script>

<style lang="scss" scoped>
.social-bi-app {
	padding: 0;
}

.social-bi-app__content {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
</style>