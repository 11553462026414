import adminYuri from '../utils/yuri/AdminYuri';


export function get_autocomplete_load_function(url, params = {}, fieldName = 'q')
{
	return async function(query, page)
	{
		let requestParams = {
			...params,
			page,
			[fieldName]: query
		};
		
		let res = await adminYuri.get(url, requestParams);
		
		return {
			options: res.Data
		};
	}
}

export function get_load_by_id_function(url, params = {}, fieldName = 'id')
{
	return async function(query, page)
	{
		let requestParams = {
			...params,
			page,
			[fieldName]: query
		};

		let res = await adminYuri.get(url, requestParams);

		return {
			options: res.Data
		};
	}
}

export function applyDimensionFilter(dimensionsFilter, group, value)
{
	const filterIndex = dimensionsFilter.findIndex(item => item.column === group);

	if (filterIndex === -1)
	{
		dimensionsFilter.push({
			column: group,
			values: [value]
		})
	}
	else
	{
		dimensionsFilter[filterIndex].values = [value];
	}
}
