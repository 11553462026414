<template>
	<lobster-layout-section class="report-gallery-section">
		<lobster-loader v-if="isFirstLoading"/>

		<template v-else-if="galleryList.length">
			<lobster-layout-action-bar>
				<lobster-button
					v-if="canUpdate"
					@click="handleNewGalleryClick">
					New Report Group
				</lobster-button>

				<lobster-search-filter
					v-model="searchFilter"
					filters-disabled
					placeholder="Search"/>
			</lobster-layout-action-bar>

			<gallery-table :search="searchFilter.search"/>
		</template>

		<lobster-layout-nullstate
			v-else
			message="Groups"
			:description="nullstateText">
			<lobster-button
				v-if="canUpdate"
				@click="handleNewGalleryClick">
				New Report Group
			</lobster-button>
		</lobster-layout-nullstate>
	</lobster-layout-section>
</template>

<script>
import { mapState } from 'vuex';
import GalleryTable from './GalleryTable';
import GalleryModal from './GalleryModal';
import { getAdminRegion } from '../../../consts/StartupData';


export default {
	name: 'ReportGallerySection',
	components: {
		GalleryTable
	},
	data()
	{
		return {
			isFirstLoading: true,
			searchFilter: {
				search: ''
			}
		}
	},
	computed: {
		...mapState({
			galleryList: state => state.reportGallery.galleryList,
		}),
		canUpdate()
		{
			return getAdminRegion() === 'US_APP_1';
		},
		nullstateText()
		{
			return `It seems like you don't have any dashboards set up. ${ this.canUpdate ? "Fortunately, it's easy to get started. Click on the button below to add your first dashboard." : ""}`;
		}
	},
	methods: {
		async loadGalleryList()
		{
			await this.$store.dispatch('reportGallery/loadGalleryList');
		},
		handleNewGalleryClick()
		{
			this.$showModal({
				component: GalleryModal,
			})
		}
	},
	async created()
	{
		try
		{
			await this.loadGalleryList();
		}
		finally
		{
			this.isFirstLoading = false;
		}
	}
}
</script>

<style lang="scss" scoped>
.report-gallery-section {
	padding: 25px 0;
}
</style>