var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-dynamic-modal",
    _vm._g(
      _vm._b(
        {
          staticClass: "report-settings-modal",
          attrs: {
            "aria-label": "New report modal",
            size: "large",
            "modal-title": _vm.title,
          },
        },
        "lobster-dynamic-modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.isEditMode
        ? _c(
            "div",
            { staticClass: "report-settings-modal__tabs" },
            [
              _c(
                "lobster-abstract-button",
                {
                  staticClass: "report-settings-modal__tab",
                  class: { m_active: _vm.isSelection },
                  on: {
                    click: function ($event) {
                      _vm.isSelection = true
                    },
                  },
                },
                [
                  _c("b", [
                    _vm._v("\n\t\t\t\tSelect Source\n\t\t\t\t"),
                    !_vm.isSelection
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.subjectType) + ")"),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "lobster-abstract-button",
                {
                  staticClass: "report-settings-modal__tab",
                  class: { m_active: !_vm.isSelection },
                  attrs: { disabled: !_vm.subjectType },
                  on: {
                    click: function ($event) {
                      _vm.isSelection = false
                    },
                  },
                },
                [_c("b", [_vm._v("Configure Report and Add to Dashboard")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSelection
        ? _c("source-form", {
            attrs: { "subject-type": _vm.subjectType },
            on: {
              "update:subjectType": function ($event) {
                _vm.subjectType = $event
              },
              "update:subject-type": function ($event) {
                _vm.subjectType = $event
              },
              submit: _vm.handleSourceSubmit,
            },
          })
        : _c("report-form", {
            attrs: {
              config: _vm.config,
              "is-edit": _vm.isEditMode,
              "subject-type": _vm.subjectType,
              "initial-settings": _vm.initialSettings,
            },
            on: { back: _vm.handleBack, submit: _vm.handleReportSubmit },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }