import { CommonService } from '../services/CommonService';
import { KrakenPlugin } from 'kraken-charts';
import { KrakenPluginConfig } from '../configs/KrakenPluginConfig';
import { niceNumber } from '../../utils';


export default {
	install(Vue, config = {})
	{
		Vue.use(KrakenPlugin, KrakenPluginConfig);

		/* @todo Need to do it right, now it's just for getting it worked */
		CommonService.getReportingCalendarConfig = config.getReportingCalendarConfig || (() => ({}));
		CommonService.getQueryWidget = config.getQueryWidget || (() => ({}));
		CommonService.getCurrencySymbol = config.getCurrencySymbol || (value => niceNumber(value));
		CommonService.getCustomFieldsConfig = config.getCustomFieldsConfig || (() => ({}));
		CommonService.getIsTemplateAccount = config.getIsTemplateAccount || (() => false);
	}
}