<script setup>
import { ref } from 'vue';
import { useToaster } from '../../../hooks/toaster';
import AdminYuri from '../../../utils/yuri/AdminYuri';


const remoteId = ref();
const toaster = useToaster();


async function handleSubmit()
{
	await AdminYuri.post('/qa-tools/schedule-analytics-crawlers', { id: remoteId.value });
	
	toaster.showSuccess('Analytics crawlers scheduled. Good luck and have a fun!');
}
</script>

<template>
	<fragment>
		<h2> Analytics Crawler</h2>
		<lobster-form
			submit-button-text="Schedule"
			@submit="handleSubmit">
			<lobster-input
				label="Remote Id of target"
				validators="required"
				v-model="remoteId"/>
		</lobster-form>
	</fragment>
</template>

<style scoped lang="scss">

</style>