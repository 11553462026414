<template>
	<lobster-dynamic-modal
		v-on="$listeners"
		v-bind="$attrs"
		class="widget-modal"
		size="large"
		modal-title="Add Report">
		<div class="widget-modal__container">
			<div class="widget-modal__sidebar">
				<div class="widget-modal__sidebar-navigator">
					<lobster-button
						attached-left
						secondary
						class="widget-modal__sidebar-navigator-button"
						@click="showFilters = false">
						Settings
					</lobster-button>
					<lobster-button
						attached-right
						secondary
						class="widget-modal__sidebar-navigator-button"
						@click="showFilters = true">
						Filters
					</lobster-button>
				</div>
				<widget-settings
					v-show="!showFilters"
					:name.sync="name"
					:source.sync="source"
					:chart-type.sync="chartType"
					:groups.sync="groups"
					:metrics.sync="metrics"
					:additional-columns.sync="additionalColumns"
					:limit.sync="limit"
					:order-by.sync="orderBy"
				/>
				<analytics-filter
					v-show="showFilters"
					is-report-builder
					v-model="filter"
					:menu-view="false"
					:subject-type="source"/>
			</div>
			<div class="widget-modal__report">
				<report-chart-wrapper
					class="widget-modal__report-chart"
					:subject-type="source"
					:date-period="datePeriod"
					:metrics="metrics"
					:groups="groups"
					:additional-columns="additionalColumns"
					:limit="limit"
					:chart-type="chartType"
					:filter="filter"
					:order-by="orderBy"/>
			</div>
		</div>

		<footer class="widget-modal__footer">
			<lobster-button
				secondary
				:loading="isLoading"
				@click="handleSave">
				Save
			</lobster-button>
		</footer>
	</lobster-dynamic-modal>
</template>

<script>


import WidgetSettings from './WidgetSettings';
import ReportChartWrapper from '../Report/ReportChartWrapper';
import { mapActions, mapState } from 'vuex';
import AnalyticsFilter from '../Filter/AnalyticsFilter';
export default {
	name: "WidgetModal",
	components: {
		AnalyticsFilter,
		ReportChartWrapper,
		WidgetSettings,
	},
	props: {
		dashboardId: String,
		datePeriod: {
			type: Object,
			default: () => ({})
		},
		widget: {
			type: Object,
			default: () => ({})
		}
	},
	data()
	{
		return {
			name: this.widget.name || 'New Report',
			source: this.widget.subjectType || '',
			chartType: this.widget.chartType || '',
			groups: this.widget.groups || [],
			metrics: this.widget.metrics || [],
			additionalColumns: this.widget.additionalColumns || [],
			limit: this.widget.limit,
			orderBy: this.widget.orderBy || [],
			filter: this.widget.filter || {
				dimensions: [{
					column: 'IsDeleted',
					values: ['0']
				}]
			},
			x:  this.widget.x || 0,
			y: this.widget.y || 0,
			isLoading: false,
			isEditMode: this.$is(this.widget),
			showFilters: false,
		}
	},
	computed: {
		...mapState({
			dashboard: state => state.analytics.currentDashboard
		})
	},
	methods: {
		...mapActions({
			createWidget: 'analytics/createWidget',
			updateWidget: 'analytics/updateWidget',
		}),
		getNewY()
		{
			let y = 0;
			let height = 0;
			
			for (let widget of this.dashboard.widgets)
			{
				if (y < widget.y)
				{
					y = widget.y;
					height = widget.height;
				}
				else if (y === widget.y)
				{
					if (height < widget.height)
					{
						height = widget.height;
					}
				}
			}
			
			return y + height;
		},
		async save()
		{
			await this.createWidget({
				id: this.dashboardId,
				datePeriod: this.datePeriod,
				chartType: this.chartType,
				subjectType: this.source,
				limit: this.limit,
				orderBy: this.orderBy,
				additionalColumns: this.additionalColumns,
				metrics: this.metrics,
				groups: this.groups,
				name: this.name,
				x: this.x,
				y: this.y,
				height: this.$krakenConfig.dashboardConfig.getMinHeight({chartType: this.chartType}),
				width: this.$krakenConfig.dashboardConfig.columnsCount,
				filter: this.filter
			});
		},
		async update()
		{
			await this.updateWidget({
				dashboardId: this.dashboardId,
				widgetId: this.widget.id,
				datePeriod: this.datePeriod,
				chartType: this.chartType,
				subjectType: this.source,
				limit: this.limit,
				orderBy: this.orderBy,
				additionalColumns: this.additionalColumns,
				metrics: this.metrics,
				groups: this.groups,
				name: this.name,
				x: this.x,
				y: this.y,
				height: this.widget.height,
				width: this.widget.width,
				filter: this.filter
			});
		},
		async handleSave()
		{
			this.isLoading = true;
			
			try
			{
				if (this.isEditMode)
				{
					await this.update();
				}
				else
				{
					await this.save();
				}
				
				this.$emit('input', false);
			}
			finally
			{
				this.isLoading = false;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.widget-modal__container {
	display: flex;
	max-height: 100%;
	overflow: hidden;
	flex-grow: 1;
	min-height: 0;
	padding: 16px;
}

.widget-modal__sidebar {
	flex-basis: 300px;
	flex-shrink: 0;
	overflow: auto;
	padding-right: 24px;
}

.widget-modal__sidebar-navigator {
	display: flex;
	margin-bottom: 16px;
	position: relative;
	z-index: 1;
}

.widget-modal__sidebar-navigator-button {
	flex-grow: 1;
}

.widget-modal__report {
	flex-grow: 1;
	min-width: 0;
}

.widget-modal__report-nullstate {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.widget-modal__report-chart {
	height: 100%;
}

.widget-modal__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 16px;
}

.widget-modal ::v-deep .lobster-modal__content {
	width: 900px;
	height: 90%;
}


</style>