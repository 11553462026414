var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "gallery-report",
      class: { "m_with-hidden-title": _vm.hideTitle },
      attrs: { "data-id": _vm.item.id },
    },
    [
      _c(
        "header",
        { staticClass: "gallery-report__header" },
        [
          !_vm.hideTitle
            ? _c("div", { staticClass: "gallery-report__title" }, [
                _c(
                  "h2",
                  [
                    _vm.isTitleButton
                      ? _c(
                          "lobster-abstract-button",
                          {
                            staticClass: "gallery-report__title-link",
                            attrs: { to: _vm.currentReportLink },
                            on: { click: _vm.handleTitleClick },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.item.name) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        )
                      : _c("span", [_vm._v(_vm._s(_vm.item.name))]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltipConfig,
                        expression: "tooltipConfig",
                      },
                    ],
                    staticClass: "gallery-report__title-description",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.item.description) + "\n\t\t\t"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.withMenu
            ? _c(
                "report-actions-menu",
                {
                  staticClass: "gallery-report__actions",
                  attrs: { "with-detach": "" },
                  on: {
                    "edit-widget-click": _vm.handleEditWidget,
                    "delete-widget-click": _vm.handleDeleteWidget,
                  },
                },
                [
                  _c("lobster-action-button", {
                    attrs: {
                      "icon-name": "overflow-menu--vertical",
                      "aria-label": "Actions",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.chartType !== _vm.ChartType.TOTAL_COMPARE
        ? _c("hr", { staticClass: "gallery-report__line" })
        : _vm._e(),
      _vm._v(" "),
      _c("report-chart-wrapper", {
        ref: "reportChart",
        staticClass: "gallery-report__body",
        class: `m_${_vm.item.chartType}`,
        attrs: {
          "nullstate-class": "m_nullstate",
          "is-dashboard-view": "",
          dashboardId: _vm.galleryId || null,
          widgetId: _vm.galleryId ? _vm.item.id : null,
          datePeriod: _vm.datePeriod,
          chartType: _vm.item.chartType,
          metrics: _vm.item.metrics,
          groups: _vm.item.groups,
          orderBy: _vm.item.orderBy,
          filter: _vm.item.filter,
          labels: _vm.item.labels,
          limit: _vm.itemLimit,
          subjectType: _vm.item.source,
          metadata: _vm.item.metadata,
          "report-description": _vm.item.description,
          config: _vm.additionalConfig,
        },
        on: { "see-more": _vm.handleSeeMoreClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }