var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { attrs: { column: "", "no-wrap": "" } },
    [
      _vm.withGroups
        ? _c("draggable-select", {
            staticClass: "sidebar-settings__field",
            attrs: {
              label: "Group by",
              "info-tip": "Select the dimensions you want to report on",
              "grayscale-tip": _vm.getGroupGrayscaleTip,
              "is-grayscale": (group) => _vm.groups.indexOf(group) === -1,
              options: _vm.groupOptions,
            },
            model: {
              value: _vm.currentGroups,
              callback: function ($$v) {
                _vm.currentGroups = $$v
              },
              expression: "currentGroups",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "lobster-entities-container",
        {
          staticClass: "sidebar-settings__field",
          attrs: { column: "", size: "small" },
        },
        [
          _c("b", [_vm._v("Chart Type")]),
          _vm._v(" "),
          _c(
            "lobster-entities-container",
            { attrs: { size: "small" } },
            _vm._l(_vm.chartOptions, function (chart) {
              return _c(
                "iconed-tickbox",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.ChartTypesConfig[chart].name,
                      expression: "ChartTypesConfig[chart].name",
                    },
                  ],
                  key: chart,
                  attrs: { type: "radio", name: "chartType", val: chart },
                  model: {
                    value: _vm.currentChartType,
                    callback: function ($$v) {
                      _vm.currentChartType = $$v
                    },
                    expression: "currentChartType",
                  },
                },
                [
                  _c("lobster-icon", {
                    attrs: { name: _vm.ChartIcon[chart], size: "medium--l" },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("draggable-select", {
        staticClass: "sidebar-settings__field",
        attrs: {
          label: "Metrics",
          "info-tip": "Select the metrics that you want to report on",
          options: _vm.metricsOptions,
          "grayscale-tip": _vm.getMetricGrayscaleTip,
          "is-grayscale": (metric) => _vm.metrics.indexOf(metric) === -1,
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function ({ option }) {
              return [
                _c("lobster-icon", {
                  attrs: { size: "small", name: option.icon },
                }),
                _vm._v("\n\t\t\t" + _vm._s(option.label) + "\n\t\t"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.currentMetrics,
          callback: function ($$v) {
            _vm.currentMetrics = $$v
          },
          expression: "currentMetrics",
        },
      }),
      _vm._v(" "),
      _vm.withAdditionalColumns
        ? _c("draggable-select", {
            staticClass: "sidebar-settings__field",
            attrs: {
              label: "Columns",
              "info-tip":
                "Choose the columns you want to see in the tabular view of this report",
              options: _vm.additionalColumnsOptions,
            },
            model: {
              value: _vm.currentAdditionalColumns,
              callback: function ($$v) {
                _vm.currentAdditionalColumns = $$v
              },
              expression: "currentAdditionalColumns",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.withOrder
        ? _c("draggable-select", {
            staticClass: "sidebar-settings__field",
            attrs: {
              "is-order": "",
              label: "Order By",
              options: _vm.orderByOptions,
            },
            model: {
              value: _vm.currentOrderBy,
              callback: function ($$v) {
                _vm.currentOrderBy = $$v
              },
              expression: "currentOrderBy",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.withLimit
        ? _c("lobster-input", {
            staticClass: "sidebar-settings__field",
            attrs: {
              type: "number",
              "blur-validation": "",
              label: "Limit",
              disabled: _vm.isLimitDisabled,
              min: 1,
              max: 100,
              attributes: {
                step: 1,
              },
              validators: _vm.NumberValidator,
            },
            model: {
              value: _vm.currentLimit,
              callback: function ($$v) {
                _vm.currentLimit = _vm._n($$v)
              },
              expression: "currentLimit",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTotalCompareSettings
        ? _c(
            "lobster-entities-container",
            {
              staticClass: "sidebar-settings__field",
              attrs: { size: "small" },
            },
            [
              _c("lobster-checkbox", {
                attrs: { label: "Show report headline" },
                model: {
                  value: _vm.currentShowHeadline,
                  callback: function ($$v) {
                    _vm.currentShowHeadline = $$v
                  },
                  expression: "currentShowHeadline",
                },
              }),
              _vm._v(" "),
              _c("lobster-checkbox", {
                attrs: { label: "Show time-series" },
                model: {
                  value: _vm.currentIsExtended,
                  callback: function ($$v) {
                    _vm.currentIsExtended = $$v
                  },
                  expression: "currentIsExtended",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showLineChartSettings
        ? _c("lobster-checkbox", {
            staticClass: "sidebar-settings__field",
            attrs: { label: "Show Total Compare" },
            model: {
              value: _vm.currentIsExtended,
              callback: function ($$v) {
                _vm.currentIsExtended = $$v
              },
              expression: "currentIsExtended",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.withVisibility
        ? _c("privacy-select", {
            staticClass: "sidebar-settings__field",
            model: {
              value: _vm.currentVisibility,
              callback: function ($$v) {
                _vm.currentVisibility = $$v
              },
              expression: "currentVisibility",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }