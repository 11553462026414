import GallerySection from '../components/SocialBI/ReportGallery/Gallery/GallerySection';
import ReportGallerySection from '../components/SocialBI/ReportGallery/ReportGallerySection';


export default function ()
{
	return {
		base: '/social-bi/',
		mode: 'history',
		routes: [
			{
				path: '/report-gallery',
				component: ReportGallerySection,
				name: 'report-gallery'
			},
			{
				path: '/report-gallery/:id',
				component: GallerySection,
				name: 'gallery',
				props: true
			},
		],
		linkActiveClass: 'active'
	}
};