<script setup>
import TwitterToggle from './TwitterToggle.vue';
</script>

<template>
	<lobster-layout-section>
		<lobster-entities-container
			column
			size="large">
			<twitter-toggle/>
		</lobster-entities-container>
	</lobster-layout-section>
</template>