<template>
	<label class="icon-tickbox" :class="{m_card: card, 'm_icon-card': iconCard}">
		<input :type="type" :name="name" :value="val" v-model="checked" @change="onChange" :aria-label="ariaLabel" :disabled="disabled">
		<span class="icon-tickbox__label">
			<slot></slot>
			<b v-if="title" class="icon-tickbox__label-title">
				<slot name="title">
					{{title}}
				</slot>
			</b>
			<span v-if="caption" class="icon-tickbox__label-caption caption-text">{{caption}}</span>
		</span>
	</label>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'checkbox'
			},
			name: String,
			val: String,
			value: String,
			disabled: Boolean,
			ariaLabel: String,
			title: String,
			caption: String,
			card: {
				type: Boolean,
				default: false
			},
			iconCard: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				checkedProxy: false
			}
		},
		computed: {
			checked: {
				get () {
					return this.value
				},
				set (val) {
					this.checkedProxy = val
				}
			}
		},
		methods: {
			onChange: function(e) {
				this.$emit('input', this.checkedProxy)
			}
		}
	}
</script>

<style lang="scss" scoped>

	
	.icon-tickbox {
		display: block;
		position: relative;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		width: 46px;
		height: 46px;

		&.m_card,
		&.m_icon-card {
			input[type="checkbox"],
			input[type="radio"] {
				&:checked {
					& ~ .icon-tickbox__label {
						color: $colorPrimary;
					}
				}

				&:focus-visible {
					& ~ .icon-tickbox__label {
						color: $colorPrimary;
					}
				}
			}

			.icon-tickbox__label {
				display: block;
				padding: 12px;
				color: inherit;
			}
		}

		&.m_card {
			width: calc(100% / 3 - 16px);
			height: 84px;
			text-align: center;

			.icon-tickbox__label-title {
				margin-bottom: 2px;
			}

			.icon-tickbox__label-caption {
				font-size: $fontSizeCaption;
			}
		}

		&.m_icon-card {
			width: calc(100% / 3 - 16px);
			height: 210px;

			.icon-tickbox__label {
				text-align: center;
			}
		}

		input[type="checkbox"],
		input[type="radio"] {
			cursor: pointer;
			position: absolute !important;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			margin: 0;
			opacity: 0;

			&:checked {
				& ~ .icon-tickbox__label {
					background-color: $colorPrimaryTint90;
					border-color: $colorPrimary;
				}
			}

			&:focus-visible {
				& ~ .icon-tickbox__label {
					border-color: $colorPrimary;
				}
			}

			&:disabled {
				cursor: default;

				& ~ .icon-tickbox__label {
					color: $colorTextSecondary;
					border-color: $colorBorder;
				}
			}
		}

		&:hover {
			.icon-tickbox__label {
				background-color: $colorPrimaryTint90;
			}

			input[type="checkbox"],
			input[type="radio"] {
				&:disabled {
					& ~ .icon-tickbox__label {
						background-color: $colorLight;
					}
				}
			}
		}
	}

	.icon-tickbox__label {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: $colorLight;
		border: 1px solid $colorBorder;
		color: $colorPrimary;
		transition: background-color 0.2s;
	}

	.icon-tickbox__label-title {
		color: inherit;
		margin-bottom: 6px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > * {
			margin-left: 8px;
			margin-right: 8px;
		}
	}

	.icon-tickbox__label-caption {
		display: block;
	}

	@media screen and (max-width: $mobileWidth) {
		.icon-tickbox {
			&.m_card,
			&.m_icon-card {
				width: calc(100% - 16px);
				height: auto;
			}
		}
	}
</style>