<script>
import { is } from 'plankton-esm';
import { sanitize } from '../../../helpers/StringHelper';


export default {
	name: 'FormatLinkMenu',
	props: {
		quill: Object,
		activeFormat: {
			type: Object,
			default()
			{
				return {};
			}
		}
	},
	data()
	{
		return {
			isOpened: false,
			isEditMode: false,
			linkTitle: '',
			linkUrl: '',
		}
	},
	methods: {
		handleTriggerButtonClick()
		{
			let format = this.quill.getFormat();
			let selection = this.quill.getSelection();

			if (is(format.link))
			{
				let [leaf] = this.quill.getLeaf(selection.index);
				let currentLink = (is(leaf.next)) ? leaf.next : leaf.parent;
				let index = this.quill.getIndex(currentLink);
				let text = currentLink.domNode.innerHTML;
				let url = currentLink.domNode.getAttribute('href');

				this.quill.setSelection(index, text.length);

				this.linkTitle = text;
				this.linkUrl = url;

				this.isEditMode = true;
			}
			else
			{
				let selectedText = this.quill.getText(selection.index, selection.length);

				this.linkTitle = selectedText;
				this.linkUrl = selectedText;

				this.isEditMode = false;
			}
		},
		handleFormatLinkUpdated()
		{
			this.isOpened = false;

			this.quill.focus();

			let selection = this.quill.getSelection();
			let selectedText = this.quill.getText(selection.index, selection.length);

			if (is(selectedText))
			{
				this.quill.deleteText(selection.index, selection.length);
			}

			this.quill.insertText(selection.index, this.linkTitle, 'link', sanitize(this.linkUrl));
		},
		handleFormatLinkDeleted()
		{
			this.isOpened = false;

			this.quill.focus();
			this.quill.format('link', false);
		},
	}
}
</script>

<template>
	<v-popover :open.sync="isOpened">
		<lobster-action-button
			icon-name="link"
			:selected="$is(activeFormat.link) || isOpened"
			@click="handleTriggerButtonClick"/>

		<template #popover>
			<lobster-form
				v-if="isOpened"
				modal-view
				class="format-link-menu__form"
				:submit-button-text="isEditMode ? $t('commonUtils.textEditor.toolbar.update') : $t('commonUtils.textEditor.toolbar.insert')"
				@submit="handleFormatLinkUpdated">
				<lobster-input
					v-model="linkUrl"
					ref="urlInput"
					:label="$t('commonUtils.textEditor.toolbar.linkUrl')"
					validators="required"/>
				<lobster-input
					v-model="linkTitle"
					:label="$t('commonUtils.textEditor.toolbar.linkTitle')"
					validators="required"/>

				<template #additional-actions>
					<lobster-button
						v-if="isEditMode"
						secondary
						@click="handleFormatLinkDeleted">
						{{ $t('commonUtils.textEditor.toolbar.unlink') }}
					</lobster-button>
				</template>
			</lobster-form>
		</template>
	</v-popover>
</template>

<style scoped lang="scss">
.format-link-menu__form {
	width: 280px;
}
</style>