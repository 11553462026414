<template>
	<report-chart-wrapper
		is-embedded
		with-pagination
		with-order

		:chart-type="chartType"
		:subject-type="subjectType"
		:groups="currentGroups"
		:metrics="metrics"
		:additional-columns="additionalColumns"
		:date-period="currentDatePeriod"
		:filter="currentFilter"
		:order-by="currentOrderBy"
		:limit="limit"
		:config="config"

		@table-chart-click="handleTableChartClick">
		<template
			v-if="withExportButton"
			#kraken-table-action-bar__actions="data">
			<lobster-action-button
				:loading="isExportLoading"
				icon-name="export"
				v-tooltip="'Export'"
				aria-label="Export"
				@click="handleExportClick"/>
		</template>
	</report-chart-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import { applyDimensionFilter } from '../../../helpers/AnalyticsFilterHelper';
import { DimensionsConfig, MetricsConfig, ChartType } from '@analytics';
import { TagsOperators } from '../../../consts/Operators';
import { Exports } from '../../../consts/Exports';
import { getStartupData } from '../../../consts/StartupData';
import { DimensionsFiltersConfig } from '../../../configs/DimensionsFiltersConfig';
import { FilterTypes } from '../../../consts/FilterTypes';


export default {
	name: "DrilldownWrapper",
	props: {
		row: {
			type: Object,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		entities: {
			type: Object,
			default()
			{
				return {}
			}
		},
		index: Number,

		subjectType: String,
		metrics: {
			type: Array,
			default()
			{
				return [];
			}
		},
		groups: {
			type: Array,
			default()
			{
				return [];
			}
		},
		additionalColumns: {
			type: Array,
			default()
			{
				return [];
			}
		},
		datePeriod: Object,
		filter: {
			type: Object,
			default()
			{
				return {};
			}
		},
		orderBy: {
			type: Array,
			default()
			{
				return [];
			}
		},
		limit: {
			type: Number,
			default: 10
		},
		config: {
			type: Object,
			default()
			{
				return {};
			}
		},

		reportName: String
	},
	data()
	{
		return {
			currentDatePeriod: JSON.parse(JSON.stringify(this.datePeriod)),
			currentFilter: JSON.parse(JSON.stringify( this.filter)),
			currentOrderBy: JSON.parse(JSON.stringify( this.orderBy)),
		}
	},
	computed: {
		...mapState({
			isExportLoading: state => state.commonExport?.isLoading
		}),
		chartType()
		{
			return ChartType.LIST;
		},
		currentGroups()
		{
			switch (this.subjectType)
			{
				case 'inbox':
				case 'profile':
					return ['CredentialID'];

				case 'post':
				case 'engagement':
				case 'clicks':
					return ['PostlogID'];

				case 'socialListening':
					return ['ItemID'];

				case 'conversion':
					return ['Date', 'LeadName', 'LeadEmail', 'ConversionTagID', 'GeoCity', 'GeoCountry'];

				case 'listening':
					return ['RemoteID'];

				case 'userSessions':
					return ['StartDate', 'UserID'];

				case 'leadActivity':
					return ['ActivityId', 'LeadId', 'LeadStatus', 'ActivityType', 'ActivityDate'];

				case 'brandMentions':
					return ['SDBPostID'];

				case 'linkedInAds':
					return ['AdCreativeID'];

				case 'opportunity':
					return ['OpportunityID'];

				case 'linkedInDemographics':
					return ['LinkedinProfileId', ...this.groups];
			}
		},
		withExportButton()
		{
			return this.config.extraData?.withExportButton
		}
	},
	methods: {
		recalculateConfig()
		{
			for (let group of this.groups)
			{
				let value = this.row.cells[group].value;

				if (DimensionsConfig[group]?.isDateFormat)
				{
					let datePeriod = {
						to: value,
						from: value
					}

					if (group === 'SocialActivityDate' || group === 'CreatedDay')
					{
						this.currentFilter.dateRange = {
							datePeriod,
							column: group
						};
					}
					else
					{
						this.currentDatePeriod = datePeriod;
					}
				}
				else if (DimensionsFiltersConfig[group]?.type === FilterTypes.TAGS)
				{
					if (is(value))
					{
						this.currentFilter.tags = [{column: group, operator: TagsOperators.IS, value: [value]}];
					}
					else
					{
						this.currentFilter.tags = [{column: group, operator: TagsOperators.EMPTY}];
					}
				}
				else
				{
					applyDimensionFilter(this.currentFilter.dimensions, group, value);
				}
			}

			if (is(this.metrics) && !this.currentOrderBy.find(item => is(MetricsConfig[item])))
			{
				this.currentOrderBy.push({
					direction: 'descending',
					field: this.metrics[0]
				});
			}
		},
		handleExportClick()
		{
			this.$store.dispatch('commonExport/export', {
				params: {
					QueryType: Exports.QueryType.ANALYTICS,
					DatePeriod: this.currentDatePeriod,
					QueryConfig: {
						exportType: 'csv',
						reportName: this.reportName,
						datePeriod: this.currentDatePeriod,
						type: 'report',
						groups: this.currentGroups,
						metrics: this.metrics,
						orderBy: this.currentOrderBy,
						filter: this.currentFilter,
						subjectType: this.subjectType,
						chartType: ChartType.LIST
					},
					TargetType: Exports.Destination.EMAIL,
					TargetConfig: {
						Email: getStartupData().get().User.Email,
						FirstName: getStartupData().get().User.FirstName,
						LastName: getStartupData().get().User.LastName
					},
					ScheduleType: Exports.Frequency.ONE_TIME,

				},
				targetType: Exports.Type.REPORT
			});
		},
		handleTableChartClick(row, columns, entities)
		{
			this.$emit('table-chart-click', row, columns, entities)
		}
	},
	created()
	{
		this.recalculateConfig();
	}
}
</script>

<style lang="scss" scoped>

</style>
