<template>
	<lobster-entities-container column>
		<lobster-input v-model="currentName" label="Name"/> 
		
		<lobster-select
			v-model="currentSource"
			field-label="Source"
			:options="analyticsSources"
			:reduce="option => option.value"/>

		<lobster-select
			v-model="currentChartType"
			searchable
			field-label="Chart Type"
			:options="chartTypes"
			:reduce="option => option.value"/>
		
		<draggable-select
			ref="groups"
			v-show="hasGroups"
			v-model="currentGroups"
			label="Groups"
			:options="groupsOptions"
			:validate-value="validateGroups"/>
		
		<draggable-select
			ref="metrics"
			v-show="hasMetrics"
			v-model="currentMetrics"
			label="Metrics"
			:options="metricsOptions"
			:validate-value="validateMetrics"/>
		
		<draggable-select
			v-if="hadAdditionalColumns"
			ref="additionalColumns"
			v-model="currentAdditionalColumns"
			label="Additional Columns"
			:options="additionalColumnsOptions"
			:validate-value="validateAdditionalColumns"/>
		
		<draggable-order-select
			v-if="isTable"
			v-model="currentOrder"
			:options="orderOptions"/>
		
		<lobster-input 
			v-if="isTable"
			label="Limit"
			type="number"
			:min="0"
			v-model="currentLimit"/>
	</lobster-entities-container>
</template>

<script>
import { ChartType } from 'kraken-charts';
import DraggableSelect from './DraggableSelect';
import { GroupsConfig } from '../../../configs/GroupsConfig';
import { MetricsConfig } from '../../../configs/MetricsConfig';
import { SourceConfig } from '../../../configs/SourceConfig';
import { ChartTypesConfig } from '../../../configs/ChartTypesConfig';
import DraggableOrderSelect from './DraggableOrderSelect';


export default {
	name: 'WidgetSettings',
	components: {
		DraggableOrderSelect,
		DraggableSelect,
	},
	props: {
		name: {
			type: String,
			required: true
		},
		source: {
			type: String,
			required: true
		},
		chartType: {
			type: String,
			required: true
		},
		groups: {
			type: Array,
			required: true
		},
		metrics: {
			type: Array,
			required: true
		},
		additionalColumns: {
			type: Array,
			required: true
		},
		limit: [Number, String],
		orderBy: Array,
	},
	data()
	{
		return {
		}
	},
	computed: {
		analyticsSources()
		{
			return  Object.keys(SourceConfig).map(key => ({
				label: SourceConfig[key].name,
				value: key
			}));
		},
		chartTypes()
		{
			if (!this.source)
			{
				return [];
			}
			
			return SourceConfig[this.source].chartTypes.map(chartType => ({
				label: ChartTypesConfig[chartType].name,
				value: chartType,
			}));
		},
		groupsOptions()
		{
			if (!this.source)
			{
				return [];
			}
			
			return SourceConfig[this.source].groups.map(group => ({
				label: GroupsConfig[group].name,
				value: group
			}));
		},
		metricsOptions()
		{
			if (!this.source)
			{
				return [];
			}
			
			return SourceConfig[this.source].metrics.map(metric => ({
				label: MetricsConfig[metric].name,
				value: metric
			}));
		},
		additionalColumnsOptions()
		{
			if (!this.source)
			{
				return [];
			}
			
			return SourceConfig[this.source].additionalColumns.map(group => ({
				label: GroupsConfig[group].name,
				value: group
			}));
		},
		orderOptions()
		{
			if (!this.isTable)
			{
				return false;
			}
			
			let orderOptions = [];
			
			for (let group of this.currentGroups)
			{
				orderOptions.push({
					label: GroupsConfig[group].name,
					value: group,
				});
			}
			
			for (let metric of this.currentMetrics)
			{
				orderOptions.push({
					label: MetricsConfig[metric].name,
					value: metric,
				});
			}
			
			
			return orderOptions;
		},
		currentName: {
			get()
			{
				return this.name;
			},
			set(newVal)
			{
				this.$emit('update:name', newVal);
			}
		},
		currentSource: {
			get()
			{
				if (!this.$is(this.source))
				{
					this.currentSource = this.analyticsSources[0].value
				}
				
				return this.source;
			},
			set(newVal)
			{
				this.currentGroups = [];
				this.currentMetrics = [];
				this.currentAdditionalColumns = [];

				this.$nextTick(() =>
				{
					this.$refs.metrics?.reset();
					this.$refs.groups?.reset();
					this.$refs.additionalColumns?.reset();
				});
				
				this.$emit('update:source', newVal);
			}
		},
		currentChartType: {
			get()
			{
				if (!this.$is(this.chartType))
				{
					this.$emit('update:chart-type', 'area-stacked');
				}
				
				return this.chartType; 
			},
			set(newVal)
			{
				this.$emit('update:chart-type', newVal);

				this.$nextTick(() =>
				{
					this.$refs.metrics?.recalculateValue();
					this.$refs.groups?.recalculateValue();
					
					if (this.isTable)
					{
						if (this.$is.undefined(this.limit) || this.$is.null(this.limit))
						{
							this.currentLimit = 5;
						}
					}
					else
					{
						this.currentLimit = undefined;
						this.currentOrder = [];
					}
				});
			}
		},
		currentGroups: {
			get()
			{
				return this.groups;
			},
			set(newVal)
			{
				this.$emit('update:groups', newVal);

				this.$nextTick(() =>
				{
					this.$refs.metrics?.recalculateValue();
				});
			}
		},
		currentMetrics: {
			get()
			{
				return this.metrics;
			},
			set(newVal)
			{
				this.$emit('update:metrics', newVal);
			}
		},
		currentAdditionalColumns: {
			get()
			{
				return this.additionalColumns;
			},
			set(newVal)
			{
				this.$emit('update:additional-columns', newVal);
			}
		},
		currentLimit: {
			get()
			{
				return this.limit;
			},
			set(newVal)
			{
				this.$emit('update:limit', newVal);
			}
		},
		currentOrder: {
			get()
			{
				return this.orderBy;
			},
			set(newVal)
			{
				this.$emit('update:order-by', newVal);
			}
		},
		currentChartConfig()
		{
			return ChartTypesConfig[this.currentChartType] ?? {};
		},
		hasGroups()
		{
			return this.currentChartConfig.maxGroups !== 0;
		},
		hasMetrics()
		{
			return this.currentChartConfig.maxMetrics !== 0;
		},
		hadAdditionalColumns()
		{
			return this.$is(this.additionalColumnsOptions) && this.currentChartConfig.maxMetrics !== 0;
		},
		isTable()
		{
			return this.currentChartConfig.isTable;
		}
	},
	methods: {
		validateGroups(groups)
		{
			if (this.currentChartConfig.isTable)
			{
				return {};
			}
			
			let res = {};
			let validCount = 0;
			
			for (let group of groups)
			{
				if (this.$is.defined(this.currentChartConfig.maxGroups) && validCount >= this.currentChartConfig.maxGroups)
				{
					res[group.value] = `You can have max ${this.currentChartConfig.maxGroups} groups for this chart`;
				}
				else 
				{
					validCount++;
				}
			}
			
			return res;
		},
		validateMetrics(metrics)
		{
			if (this.currentChartType === ChartType.LIST)
			{
				return {};
			}

			let res = {};
			let validCount = 0;
			let maxMetrics = this.currentChartConfig.maxMetrics
			
			if (this.currentGroups.length > 1)
			{
				maxMetrics = 1;
			}
			
			for (let metric of metrics)
			{
				if (this.$is.defined(maxMetrics) && validCount >= maxMetrics)
				{
					res[metric.value] = `You can have max ${maxMetrics} metrics for this chart and groups`;
				}
				else
				{
					validCount++;
				}
			}
			
			return res
		},
		validateAdditionalColumns(group)
		{
			return {}
		}
	}
}
</script>