var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    {
      staticClass: "common-report-nullstate",
      class: { "m_with-background": _vm.withBackground },
      attrs: { column: "", "no-wrap": "", center: "" },
    },
    [
      _c(
        "div",
        { staticClass: "common-report-nullstate__image" },
        [
          _vm._t("image", function () {
            return [
              _vm.isFailedState
                ? _c("img", {
                    attrs: {
                      src: _vm.reportFailedState,
                      alt: "Failed state image",
                    },
                  })
                : _c("lobster-icon", { attrs: { name: "information" } }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "lobster-entities-container",
        {
          staticClass: "common-report-nullstate__description",
          attrs: { column: "", "no-wrap": "", center: "", size: "small--s" },
        },
        [
          _vm._t("default", function () {
            return [
              _vm.isFailedState
                ? _c("h2", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("commonAnalytics.report.failedState")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }