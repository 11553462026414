/**
 * @typedef DimensionConfig
 * @property {boolean} [isDateFormat] force to use date formatter in apex charts
 * @property {boolean} [isDate] is date
 * @property {boolean} [isAdditionalDataNeeded] force to fill tabla with additional data
 * @property {'asc'|'desc'} [numericSort] if defined apply to group value numeric sort in provided direction
 * @property {string} [orderBy] define order column for tables
 useEntity: true,
 * @property {boolean} [isCustomView] if true, will use custom view
 * @property {boolean} [useEntity] if true, will use entities
 * @property {boolean} [isLinkedInFacet] is LinkedIn facet. Affects possibility to select group in linkedInDemographics subject type
 */
/**
 * @type {Object<string, DimensionConfig>}
 */
export const DimensionsConfig = {
	Date: {
		name: 'Date',
		orderBy: 'Date',
		columns: ['Week', 'WeekDay', 'Month', 'Quarter', 'Year'],
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	ActivityDate: {
		name: 'Date',
		orderBy: 'ActivityDate',
		columns: ['Week', 'WeekDay', 'Month', 'Quarter', 'Year'],
		isDate: true,
		isDateRelated: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	MetricDate: {
		name: 'Date',
		orderBy: 'MetricDate',
		columns: ['Week', 'WeekDay', 'Month', 'Quarter', 'Year'],
		isDate: true,
		isDateRelated: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	RemoteCreated: {
		name: 'Date',
		orderBy: 'RemoteCreated',
		columns: ['Week', 'WeekDay', 'Month', 'Quarter', 'Year'],
		isDate: true,
		isDateRelated: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	WeekSunday: {
		name: 'Week',
		orderBy: 'WeekSunday',
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,
		isHidden: true,
		isAdditionalDataNeeded: true,
	},
	Week: {
		name: 'Week',
		orderBy: 'Week',
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isAdditionalDataNeeded: true,
	},
	Month: {
		name: 'Month',
		orderBy: 'Month',
		columns: ['Quarter', 'Year'],
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isAdditionalDataNeeded: true,
	},
	Quarter: {
		name: 'Quarter',
		orderBy: 'Quarter',
		columns: ['Year'],
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,
		isAdditionalDataNeeded: true,
	},
	FiscalQuarter: {
		name: 'Fiscal Quarter',
		orderBy: 'FiscalQuarter',
		columns: ['Year'],
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isAdditionalDataNeeded: true,
	},
	Year: {
		name: 'Year',
		orderBy: 'Year',
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isAdditionalDataNeeded: true,
	},
	FiscalYear: {
		name: 'Fiscal Year',
		orderBy: 'FiscalYear',
		columns: ['Year'],
		isDate: true,
		isDateRelated: true,
		isMetricDate: true,

		isAdditionalDataNeeded: true,
	},
	CreatedDay: {
		name: 'Date',
		orderBy: 'CreatedDay',
		columns: ['CreatedWeek', 'CreatedWeekDay', 'CreatedMonth', 'CreatedQuarter', 'CreatedYear'],
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	CreatedWeekSunday: {
		name: 'Week',
		orderBy: 'CreatedWeekSunday',
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,
		isHidden: true,
		isAdditionalDataNeeded: true,
	},
	CreatedWeek: {
		name: 'Week',
		orderBy: 'CreatedWeekMonday',
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,

		isAdditionalDataNeeded: true,
	},
	CreatedMonth: {
		name: 'Month',
		orderBy: 'CreatedMonth',
		columns: ['CreatedQuarter', 'CreatedYear'],
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,

		isAdditionalDataNeeded: true,
	},
	CreatedQuarter: {
		name: 'Quarter',
		orderBy: 'CreatedQuarter',
		columns: ['CreatedYear'],
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,
		isAdditionalDataNeeded: true,
	},
	CreatedFiscalQuarter: {
		name: 'Fiscal Quarter',
		orderBy: 'CreatedFiscalQuarter',
		columns: ['CreatedYear'],
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,

		isAdditionalDataNeeded: true,
	},
	CreatedFiscalYear: {
		name: 'Fiscal Year',
		orderBy: 'CreatedFiscalYear',
		columns: ['CreatedYear'],
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,

		isAdditionalDataNeeded: true,
	},
	CreatedYear: {
		name: 'Year',
		orderBy: 'CreatedYear',
		isDate: true,
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedDate: {
		name: 'Closed Date',
		orderBy: 'OpportunityClosedDate',
		columns: ['OpportunityClosedWeek', 'OpportunityClosedWeekDay', 'OpportunityClosedMonth', 'OpportunityClosedQuarter', 'OpportunityClosedYear'],
		isDate: true,
		isDateRelated: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	OpportunityClosedWeekSunday: {
		name: 'Closed Week',
		orderBy: 'OpportunityClosedWeekSunday',
		isDate: true,
		isDateRelated: true,
		isHidden: true,
		isAdditionalDataNeeded: true,
	},
	OpportunityClosedWeek: {
		name: 'Closed Week',
		orderBy: 'OpportunityClosedDate',
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedMonth: {
		name: 'Closed Month',
		orderBy: 'OpportunityClosedMonth',
		columns: ['OpportunityClosedQuarter', 'OpportunityClosedYear'],
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedQuarter: {
		name: 'Closed Quarter',
		orderBy: 'OpportunityClosedQuarter',
		columns: ['OpportunityClosedYear'],
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedFiscalQuarter: {
		name: 'Closed Fiscal Quarter',
		orderBy: 'OpportunityClosedFiscalQuarter',
		columns: ['OpportunityClosedYear'],
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedYear: {
		name: 'Closed Year',
		orderBy: 'OpportunityClosedYear',
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedFiscalYear: {
		name: 'Closed Fiscal Year',
		orderBy: 'OpportunityClosedFiscalYear',
		columns: ['OpportunityClosedYear'],
		isDate: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	OpportunityClosedWeekDay: {
		name: 'Closed Day of Week',
		orderBy: 'OpportunityClosedWeekDay',
		isDateRelated: true,
		isWeekDay: true,
		isAdditionalDataNeeded: true,
	},
	OpportunityClosedYearWeek: {
		name: 'Closed Week of Year',
		orderBy: 'OpportunityClosedYearWeek',
		isDateRelated: true,
		numericSort: 'asc',
	},
	OpportunityClosedYearMonth: {
		name: 'Closed Month of Year',
		orderBy: 'OpportunityClosedYearMonth',
		isDateRelated: true,
		isAdditionalDataNeeded: true,
	},
	SocialActivityDate: {
		name: 'Date',
		orderBy: 'SocialActivityDate',
		columns: ['SocialActivityWeek', 'SocialActivityWeekDay', 'SocialActivityMonth', 'SocialActivityQuarter', 'SocialActivityYear'],
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isDateFormat: true,
		isAdditionalDataNeeded: true,
	},
	SocialActivityWeekSunday: {
		name: 'Week',
		orderBy: 'SocialActivityWeekSunday',
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,
		isHidden: true,
		isAdditionalDataNeeded: true,
	},
	SocialActivityWeek: {
		name: 'Week',
		orderBy: 'SocialActivityDate',
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityMonth: {
		name: 'Month',
		orderBy: 'SocialActivityMonth',
		columns: ['SocialActivityQuarter', 'SocialActivityYear'],
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityQuarter: {
		name: 'Quarter',
		orderBy: 'SocialActivityQuarter',
		columns: ['SocialActivityYear'],
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityFiscalQuarter: {
		name: 'Fiscal Quarter',
		orderBy: 'SocialActivityFiscalQuarter',
		columns: ['SocialActivityYear'],
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityYear: {
		name: 'Year',
		orderBy: 'SocialActivityYear',
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityFiscalYear: {
		name: 'Fiscal Year',
		orderBy: 'SocialActivityFiscalYear',
		columns: ['SocialActivityYear'],
		isDate: true,
		isActivityRelated: true,
		isDateRelated: true,

		isAdditionalDataNeeded: true,
	},
	SocialActivityWeekDay: {
		name: 'Day of Week',
		orderBy: 'SocialActivityWeekDay',
		isDateRelated: true,
		isActivityRelated: true,
		isWeekDay: true,
		isAdditionalDataNeeded: true,
	},
	SocialActivityYearWeek: {
		name: 'Week of Year',
		orderBy: 'SocialActivityYearWeek',
		isDateRelated: true,
		isActivityRelated: true,
		numericSort: 'asc',
	},
	SocialActivityYearMonth: {
		name: 'Month of Year',
		orderBy: 'SocialActivityYearMonth',
		isDateRelated: true,
		isActivityRelated: true,
		isAdditionalDataNeeded: true,
	},
	BoardID: {
		name: 'Board',
		orderBy: 'BoardName',
		isBoardOnly: true,
	},
	BoardId: {
		name: 'Board',
		orderBy: 'BoardName',
		isBoardOnly: true,
	},
	BoardIDs: {
		name: 'Board',
		orderBy: 'BoardName',
		isBoardOnly: true,
	},
	CampaignID: {
		name: 'Campaign',
		orderBy: 'CampaignName',
		useEntity: true,
		isCustomView: true,
		isCampaign: true
	},
	CampaignId: {
		name: 'Campaign',
		orderBy: 'CampaignName',
		useEntity: true,
		isCustomView: true,
		isCampaign: true
	},
	Channel: {
		name: 'Channel',
		orderBy: 'Channel'
	},
	Thread: {
		name: 'Thread',
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	ContentType: {
		name: 'Content Type',
		orderBy: 'ContentType',
	},
	WeekDay: {
		name: 'Day of Week',
		orderBy: 'WeekDay',
		isDateRelated: true,
		isMetricDate: true,
		isWeekDay: true,
		isAdditionalDataNeeded: true,
	},
	YearWeek: {
		name: 'Week of Year',
		orderBy: 'YearWeek',
		isDateRelated: true,
		isMetricDate: true,
		numericSort: 'asc',
	},
	YearMonth: {
		name: 'Month of Year',
		orderBy: 'YearMonth',
		isDateRelated: true,
		isMetricDate: true,
		isAdditionalDataNeeded: true,
	},
	CreatedDayHour: {
		name: 'Hour of Day',
		orderBy: 'CreatedDayHour',
		isDateRelated: true,
		isPublishDate: true,
		isAdditionalDataNeeded: true,
		numericSort: 'asc'
	},
	CreatedWeekDay: {
		name: 'Day of Week',
		orderBy: 'CreatedWeekDay',
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,
		isWeekDay: true,
		isAdditionalDataNeeded: true,
	},
	CreatedYearWeek: {
		name: 'Week of Year',
		orderBy: 'CreatedYearWeek',
		isDateRelated: true,
		isPublishDate: true,
		isOpenDate: true,
		numericSort: 'asc',
	},
	CreatedYearMonth: {
		name: 'Month of Year',
		orderBy: 'CreatedYearMonth',
		isDateRelated: true,
		isPublishDate: true,
		isAdditionalDataNeeded: true,
	},
	MessageID: {
		name: 'Message',
		orderBy: 'Message',
		useEntity: true,
		isCustomView: true,
		isMessage: true
	},
	Network: {
		name: 'Network',
		orderBy: 'Network',
		isCustomView: true,
		isNetwork: true
	},
	PostlogID: {
		name: 'Post',
		orderBy: null,
		columns: ['Network', 'CredentialID', 'CampaignID', 'UserID', 'MessageID', 'Channel', 'BoardID'],
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	PostlogId: {
		name: 'Post',
		orderBy: null,
		columns: ['Network', 'CampaignId', 'PostCreatedBy', 'BoardId'],
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	CredentialID: {
		name: 'Profile',
		orderBy: 'CredentialName',
		columns: ['Network'],
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	Tags: {
		name: 'Tags',
		orderBy: 'Tags',
		isTagsRelated: true,
	},
	KeywordsAndHashtags: {
		name: 'Keywords',
		orderBy: 'KeywordsAndHashtags',
		isTagsRelated: true,
	},
	TopicIDs: {
		name: 'Topics',
		orderBy: null,
		isBoardOnly: true,
		isTagsRelated: true,
	},
	Topics: {
		name: 'Topics',
		isTagsRelated: true,
	},
	UserID: {
		name: 'User',
		orderBy: 'UserName',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	PostCreatedBy: {
		name: 'Created By',
		orderBy: 'PostCreatedByName',
	},
	PostSource: {
		name: 'Source',
		orderBy: 'PostSource',
	},
	PostSourceApp: {
		name: 'Source',
		orderBy: 'PostSourceApp',
	},
	SourceApplication: {
		name: 'Source',
		orderBy: 'SourceApplication',
	},
	Source: {
		name: 'Source',
		orderBy: 'Source',
	},
	CredentialTeamIDs: {
		name: 'Team (Profile-based)',
		orderBy: null,
		isTagsRelated: true,
	},
	LinkStripped: {
		name: 'Link',
		orderBy: 'LinkStripped',
		isLink: true
	},
	GeoCountry: {
		name: 'Country',
		orderBy: 'GeoCountry',
		isInferred: true,
	},
	GeoCity: {
		name: 'City',
		orderBy: 'GeoCity',
		isInferred: true,
	},
	GeoOrganization: {
		name: 'Organization',
		orderBy: 'GeoOrganization',
		isInferred: true,
		isTagsRelated: true,
	},
	DeviceType: {
		name: 'Device',
		orderBy: 'DeviceType',
		isInferred: true,
	},
	DeviceBrowserName: {
		name: 'Browser',
		orderBy: 'DeviceBrowserName',
		isInferred: true,
	},
	DeviceOSName: {
		name: 'Operating System',
		orderBy: 'DeviceOSName',
		isInferred: true,
	},
	ConversionTagID: {
		name: 'Conversion Tag',
		orderBy: 'ConversionTagName',
		isConversion: true,
	},
	ConversionUrl: {
		name: 'Conversion URL',
		orderBy: 'ConversionUrl',
		isConversion: true,
		isLink: true
	},
	LeadName: {
		name: 'Lead Name',
		orderBy: 'LeadName',
		isConversion: true
	},
	LeadEmail: {
		name: 'Lead Email',
		orderBy: 'LeadEmail',
		isConversion: true
	},
	LeadAccount: {
		name: 'Company',
		orderBy: 'LeadAccount',
		isConversion: true,
		isTagsRelated: true,
	},
	TrackerID: {
		name: 'TrackerID',
		orderBy: null,
		isHidden: true
	},
	Platform: {
		name: 'Platform',
		orderBy: 'Platform',
	},
	ProfileRemoteID: {
		name: 'Profile',
		orderBy: 'ProfileName',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	Sentiment: {
		name: 'Sentiment',
		orderBy: 'Sentiment',
	},
	SentimentEmoji: {
		name: 'Sentiment',
		orderBy: 'Sentiment',
		isSmall: true
	},
	Hashtags: {
		name: 'Hashtags',
		orderBy: 'Hashtags',
		isTagsRelated: true,
	},
	RemoteID: {
		name: 'Post',
		orderBy: 'Text',
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	Keywords: {
		name: 'Keywords',
		orderBy: 'Keywords',
		isTagsRelated: true,
	},
	Country: {
		name: 'Country',
		orderBy: 'Country',
	},
	City: {
		name: 'City',
		orderBy: 'City',
	},
	LinkedinRegion: {
		name: 'Region',
		isLinkedInFacet: true,
	},
	LinkedinCountry: {
		name: 'Country',
		isLinkedInFacet: true,
	},
	Device: {
		name: 'Device',
		orderBy: 'Device',
	},
	Browser: {
		name: 'Browser',
		orderBy: 'Browser',
	},
	OS: {
		name: 'Operating System',
		orderBy: 'OS',
	},
	Domain: {
		name: 'Domain',
		orderBy: 'Domain',
	},
	Application: {
		name: 'Application',
		orderBy: 'Application',
	},
	StartDate: {
		name: 'Start Date',
		orderBy: 'StartDate',
		isDate: true,
		isDateRelated: true,
		isHidden: true
	},
	ActivityType: {
		name: 'Activity Type',
		orderBy: 'ActivityType',
	},
	ActivityId: {
		name: 'ActivityId',
		orderBy: null,
		isHidden: true,
		isIgnored: true
	},
	LeadId: {
		name: 'Lead',
		orderBy: 'LeadName',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	LeadID: {
		name: 'Lead',
		orderBy: 'LeadName',
		isActivityRelated: true,
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	IsTargetAccount: {
		name: 'Target Account',
		orderBy: 'IsTargetAccount',
	},
	LeadCompany: {
		name: 'Account',
		orderBy: 'LeadCompany',
		isActivityRelated: true,
		isCustomView: true,
		isTagsRelated: true,
	},
	LeadStatus: {
		name: 'Lead Status',
		orderBy: 'LeadStatus',
		isActivityRelated: true,
		isCustomView: true,
	},
	LeadCompanySize: {
		name: 'Lead Company Size',
	},
	LeadCompanyIndustry: {
		name: 'Lead Company Industry',
		isLinkedInFacet: true,
	},
	SocialActivityType: {
		name: 'Activity Type',
		orderBy: 'SocialActivityType',
		isActivityRelated: true,
	},
	SocialActivityID: {
		name: 'SocialActivityID',
		orderBy: null,
		isHidden: true
	},
	SocialActivityNetwork: {
		name: 'Network',
		orderBy: 'SocialActivityNetwork',
		isActivityRelated: true,
		isCustomView: true,
		isNetwork: true
	},
	ProfileId: {
		name: 'Profile',
		orderBy: 'ProfileName',
		columns: ['Network'],
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	LinkedinProfileId: {
		name: 'Profile',
		orderBy: 'ProfileName',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	SDBPostID: {
		name: 'Post',
		orderBy: null,
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	ProfileID: {
		name: 'Profile',
		orderBy: null,
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	SDBID: {
		name: 'Profile',
		orderBy: null,
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	MentionedCredentialID: {
		name: 'Mentioned Profile',
		orderBy: null,
		columns: ['Network'],
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	DayHour: {
		name: 'Hour of Day',
		orderBy: 'DayHour',
		isMetricDate: true,
		isDateRelated: true,
		isAdditionalDataNeeded: true,
		numericSort: 'asc'
	},
	AdAccountID: {
		name: 'Ad Account',
		orderBy: 'AdAccountName',
	},
	AdCampaignID: {
		name: 'Ad Campaign',
		orderBy: 'AdCampaignName',
	},
	AdCampaignStatus: {
		name: 'Ad Campaign Status',
		orderBy: 'AdCampaignStatus',
	},
	AdGroupID: {
		name: 'Ad Group',
		orderBy: 'AdGroupName',
	},
	AdGroupStatus: {
		name: 'Ad Group Status',
		orderBy: 'AdGroupStatus',
	},
	CampaignObjective: {
		name: 'Campaign Objective',
		orderBy: 'CampaignObjective',
	},
	AdCreativeID: {
		name: 'Ad Content',
		orderBy: 'AdCreativeID',
		useEntity: true,
		isCustomView: true,
	},
	AdType: {
		name: 'Ad Type',
		orderBy: 'AdType',
	},
	AdStatus: {
		name: 'Ad Status',
		orderBy: 'AdStatus',
	},
	Currency: {
		name: 'Currency',
		orderBy: 'Currency'
	},
	OpportunityID: {
		name: 'Opportunity',
		orderBy: 'OpportunityName'
	},
	OpportunityOrganizationID: {
		name: 'Organization',
		orderBy: 'OpportunityOrganizationName',
	},
	OpportunityOwnerID: {
		name: 'Owner',
		orderBy: 'OpportunityOwnerName',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	OpportunityOwnerRole: {
		name: 'Owner Role',
		orderBy: 'OpportunityOwnerRole'
	},
	OpportunityAccountID: {
		name: 'Account',
	},
	OpportunityStageName: {
		name: 'Stage',
		orderBy: 'OpportunityStageName',
		isCustomView: true,
	},
	OpportunityStageType: {
		name: 'Status',
		orderBy: 'OpportunityStageType',
	},
	OpportunityType: {
		name: 'Type',
		orderBy: 'OpportunityType'
	},
	TeamIDs: {
		name: 'Team',
		orderBy: null,
		isTagsRelated: true,
	},
	MediaTags: {
		name: 'Media Tags',
		orderBy: null,
		isTagsRelated: true,
	},
	ProfileSource: {
		name: 'Source',
	},
	ItemID: {
		name: 'Post',
		useEntity: true,
		isCustomView: true,
		isPost: true
	},
	NullableCountry: {
		name: 'Country',
		isSmall: true
	},
	ItemType: {
		name: 'Item Type'
	},
	WebType: {
		name: 'Web Type',
	},
	WebDomain: {
		name: 'Web Domain',
		isHidden: true
	},
	URL: {
		name: 'URL'
	},
	Language: {
		name: 'Language',
		isCollection: true,
	},
	Text: {
		name: 'Text'
	},
	PostType: {
		name: 'Post Type',
	},
	AuthorName: {
		name: 'Author'
	},
	ProfileType: {
		name: 'Profile Type'
	},
	ProfileName: {
		name: 'Profile Name'
	},
	ProfileUsername: {
		name: 'Profile Username'
	},
	ProfileUri: {
		name: 'Profile Uri'
	},
	ProfileThumbnail: {
		name: 'Profile Thumbnail'
	},
	ProfileIsVerified: {
		name: 'Is Verified Profile'
	},
	ProfileGender: {
		name: 'Profile Gender'
	},
	Cashtags: {
		name: 'Cashtags'
	},
	Mentions: {
		name: 'Mentions'
	},
	Links: {
		name: 'Links',
		isLink: true,
		isCollection: true,
	},
	LinkDomains: {
		name: 'Website',
		isHidden: true
	},
	Emojis: {
		name: 'Emojis',
		isHidden: true
	},
	Medias: {
		name: 'Medias'
	},
	Themes: {
		name: 'Themes',
		isCollection: true
	},
	People: {
		name: 'People'
	},
	Organizations: {
		name: 'Organizations'
	},
	Locations: {
		name: 'Locations'
	},
	AuthorLocation: {
		name: 'Author Location',
		orderBy: null,
	},
	ListeningCountry: {
		name: 'Post Country',
		orderBy: 'Country',
	},
	ListeningChannel: {
		name: 'Channel',
		orderBy: 'ListeningChannel',
		isNetwork: true,
		isCustomView: true
	},
	AllTags: {
		name: 'Keywords',
		isTagsRelated: true,
		isCollection: true,
	},
	PipelineID: {
		name: 'Query Name',
		orderBy: null,
	},
	StoryID: {
		name: 'Story',
		orderBy: null,
		isBoardOnly: true,
		isCustomView: true,
		isPost: true
	},
	BoardRoleID: {
		name: 'Advocate Roles',
		isBoardOnly: true,
	},
	SuggestedByUserID: {
		name: 'Advocate Suggested Posts',
		isBoardOnly: true
	},
	IsAdvocateCreated: {
		name: 'Advocate Created Posts',
		isBoardOnly: true,
	},
	Seniority: {
		name: 'Seniority',
		orderBy: 'Seniority',
		isLinkedInFacet: true,
	},
	Function: {
		name: 'Job Function',
		isLinkedInFacet: true,
	},
	Industry: {
		name: 'Industry',
		isLinkedInFacet: true,
	},
	Employees: {
		name: 'Company Size',
		isLinkedInFacet: true,
	},
	PageType: {
		name: 'Page Type',
	},
	CustomFields: {
		name: 'Advocate Fields',
		isTagsRelated: true,
	},
	ConversationID: {
		name: 'Conversation',
		isCustomView: true,
		isHidden: true,
		isPost: true
	},
	ParentMessageID: {
		name: 'Advocacy Message',
		useEntity: true,
		isCustomView: true,
		isMessage: true
	},
	ClosedDay: {
		name: 'Date',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedMonth: {
		name: 'Month',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedQuarter: {
		name: 'Quarter',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedFiscalQuarter: {
		name: 'Fiscal Quarter',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedYear: {
		name: 'Year',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedFiscalYear: {
		name: 'Fiscal Year',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
	},
	ClosedWeekDay: {
		name: 'Day of Week',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
		isWeekDay: true,
	},
	ClosedYearWeek: {
		name: 'Week of Year',
		isDate: true,
		isDateRelated: true,
		isCloseDate: true,
		numericSort: 'asc',
	},
	IsAssigned: {
		name: 'Assigned Status',
	},
	AssigneeID: {
		name: 'Assignee',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	Status: {
		name: 'Status',
	},
	SDBAuthorID: {
		name: 'Author',
		useEntity: true,
		isCustomView: true,
		isProfile: true
	},
	TimeToFirstReplyCohort: {
		name: 'Time to First Reply',
	},
	TimeToFirstReplyGrade: {
		name: 'Time to First Reply Grade',
	},
	ConversationTopic: {
		name: 'Conversation Topic',
	},
	CannedResponses: {
		name: 'Canned Response',
		useEntity: true,
		isCustomView: true,
		isTagsRelated: true,
		isPost: true
	}
};