import { is } from 'plankton-esm';
import moment from 'moment';
import { MetricsConfig } from './MetricsConfig';
import { DimensionsConfig } from './DimensionsConfig';
import { ChartTypesConfig } from './ChartTypesConfig';
import { ChartType } from '../consts/ChartType';
import { getEntity, formatMetricValue } from '../helpers/StringHelper';
import { get_column_unique_values, group_by_key, multidimensional_filler } from 'tabla-js';
import { fillZeroMetricValues, getAllGroupValues, getConvertedDatePeriod } from '../helpers/ChartHelper';
import { CommonService } from '../services/CommonService';
import { WidgetType } from '../consts/WidgetType';


/**
 * @type {KrakenConfig}
 */
export const KrakenPluginConfig = {
	colors: [
		'#2060E9',
		'#ED7AED',
		'#35D5FB',
		'#FF876F',
		'#FFC766',
		'#19D1A3',
		'#FC798A',
		'#C6C500',
	],
	getColor(chartType, option)
	{
		if (option.groupName !== 'Sentiment')
		{
			return;
		}

		if (is.undefined(option.groupValue))
		{
			return;
		}

		if (option.groupValue.toLowerCase() === 'negative')
		{
			return '#F55555';
		}
		else if (option.groupValue.toLowerCase() === 'positive')
		{
			return '#19D1A3';
		}
		else if (option.groupValue.toLowerCase() === 'neutral')
		{
			return '#FFC766';
		}
		else
		{
			return '#4575B4';
		}
	},
	isGroup(target)
	{
		return is(DimensionsConfig[target]);
	},
	isMetric(target)
	{
		return is(MetricsConfig[target]);
	},
	isHidden(target)
	{
		return DimensionsConfig[target]?.isIgnored ?? false;
	},
	metricNameFormatter(metricName)
	{
		if (is(this.extraData?.labels?.[metricName]))
		{
			return this.extraData.labels[metricName];
		}

		if (!is(MetricsConfig[metricName]))
		{
			return metricName;
		}

		return MetricsConfig[metricName].name;
	},
	groupNameFormatter(groupName)
	{
		let customFields = CommonService.getCustomFieldsConfig();

		if (customFields && is(customFields[groupName]))
		{
			return customFields[groupName].name;
		}

		if (!is(DimensionsConfig[groupName]))
		{
			return groupName;
		}

		return DimensionsConfig[groupName].name;
	},
	groupValueFormatter(groupName, groupValue, entities)
	{
		return getEntity(groupName, groupValue, entities);
	},
	metricValueFormatter(metricName, metricValue)
	{
		return formatMetricValue(metricName, metricValue);
	},
	needAdditionalData(chartType, groups, metrics, additionalColumns)
	{
		if (chartType === ChartType.DONUT && groups[0] === 'Sentiment')
		{
			return true;
		}

		let customFields = CommonService.getCustomFieldsConfig();
		if (customFields && is(customFields[groups[0]]))
		{
			return false;
		}

		return ChartTypesConfig[chartType].acceptsAdditionalData &&
			(
				DimensionsConfig[groups[0]]?.isAdditionalDataNeeded ||
				(DimensionsConfig[groups[0]]?.isDate && MetricsConfig[metrics[0]]?.isAdditionalDataNeeded)
			);
	},
	fillAdditionalData(tabla, chartType, groups, metrics, additionalColumns)
	{
		if (!this.needAdditionalData(chartType, groups, metrics, additionalColumns))
		{
			return tabla;
		}

		let datePeriod = null;

		if (is(this.extraData?.datePeriod))
		{
			datePeriod = (is(this.extraData.datePeriod.from) && is(this.extraData.datePeriod.to)) ? {...this.extraData.datePeriod} : getConvertedDatePeriod(this.extraData.datePeriod.type);
		}

		let requiredColumns = getAllGroupValues(groups[0], datePeriod);

		let defaultLine = {};
		let configs = [];

		for (let metric of metrics)
		{
			defaultLine[metric] = this.getDefaultValue(metric);
		}

		for (let group of groups)
		{
			if (group === groups[0])
			{
				configs.push({
					columnName: group,
					values: requiredColumns
				});
			}
			else
			{
				configs.push({
					columnName: group,
					values: get_column_unique_values(tabla, group)
				});
			}
		}

		tabla = multidimensional_filler(tabla, configs, defaultLine);

		if (MetricsConfig[metrics[0]]?.isZeroValuesFillerNeeded)
		{
			if (groups.length === 1)
			{
				fillZeroMetricValues(tabla, groups[0], metrics[0])
			}
			else
			{
				let groupedTabla = group_by_key(tabla, groups[1]);

				for (let key in groupedTabla)
				{
					fillZeroMetricValues(groupedTabla[key], groups[0], metrics[0])
				}
			}
		}

		return tabla;
	},
	totalCompareConfig: {
		metricValueFormatter(metricName, metricValue)
		{
			return formatMetricValue(metricName, metricValue, true);
		},
		getChildGroups(childData)
		{
			if (is.array(childData?.Data) && is(childData?.Data))
			{
				let childDataKeys = Object.keys(childData.Data[0]);

				return [
					'Date',
					'Month',
					'SocialActivityMonth',
					'CreatedMonth',
					'CreatedDay',
					'Week',
					'SocialActivityWeek',
					'CreatedYearWeek'
				].filter(group => childDataKeys.includes(group));
			}

			return [];
		},
		chartType: ChartType.AREA_STACKED,
		childChartConfig: {
			areaChart: {
				strokeWidth: 2,
				fillType: 'gradient'
			}
		}
	},
	apexChart: {
		isDateFormat(target)
		{
			if (!is(DimensionsConfig[target]))
			{
				return false;
			}

			return DimensionsConfig[target].isDateFormat;
		},
		seriesSort(a, b)
		{
			let dimensionConfig = DimensionsConfig[a.group];

			if (!is(dimensionConfig))
			{
				return;
			}

			if (!dimensionConfig.numericSort)
			{
				return;
			}

			if (dimensionConfig.numericSort === 'asc')
			{
				return (+a.groupValue) - (+b.groupValue);
			}
			else
			{
				return (+b.groupValue) - (+a.groupValue);
			}
		},
		withSort(chartType, groups, metric)
		{
			let customFields = CommonService.getCustomFieldsConfig();

			for (let group of groups)
			{
				let dimensionConfig = null;

				if (DimensionsConfig[group])
				{
					dimensionConfig = DimensionsConfig[group];
				}
				else if (customFields && customFields[group])
				{
					dimensionConfig = customFields[group];
				}

				if (!dimensionConfig)
				{
					return false;
				}

				if (dimensionConfig.numericSort)
				{
					return true;
				}

				if (dimensionConfig.isDateRelated)
				{
					return true;
				}

			}
			return false;
		},
		xLabelsFormatter(label)
		{
			if (label?.length > 30)
			{
				label  = label.substring(0, 27) + '...';
			}

			return label;
		},
		gridDashArray: 5,
		gridColor: '#E5E5E5',
		strokeWidth: 2,
		xAxisBorder: false,
		yAxisBorder: false,
	},
	barChart: {
		borderRadius: 3,
		borderRadiusApplication: 'end',
		borderColor: '#ffffff',
		borderWidth: 0.5,
	},
	lineChartConfig: {
		curve: 'smooth',
		markerSize: 0,
		hoverMarkerSize: 8,
		markerBorderSize: 2,
		markerBorderColor: '#fff',
		getMarkerColors(colors, series)
		{
			return colors;
		}
	},
	areaChart: {
		fillType: 'solid',
		strokeWidth: 1,
		fillOpacity: 1
	},
	bubbleConfig: {
		sortGroupValues(group, groupValues)
		{
			if (!DimensionsConfig[group].isDateRelated)
			{
				return groupValues;
			}

			return groupValues.sort((a, b) =>
			{
				if (moment.isMoment(a) && moment.isMoment(b))
				{
					return moment(a).valueOf() - moment(b).valueOf();
				}

				if (is.string(a) && is.string(b) && !is.numeric(b - a))
				{
					return a.localeCompare(b);
				}

				return a - b;
			})
		},
	},
	e4Config: {
		donutRatio: 0.2,
		withLabels: false,
		withTotal: true,
		transparentizeChildren: false,
		totalTitleFormatter(total, metrics, groups)
		{
			return formatMetricValue(metrics[0], total, true);
		}
	},
	tableConfig: {
		isOrderable(target)
		{
			let customFields = CommonService.getCustomFieldsConfig();

			if (is(MetricsConfig[target]))
			{
				return true;
			}

			if (customFields && is(customFields[target]))
			{
				return false;
			}

			if (!DimensionsConfig[target])
			{
				return false;
			}

			return is(DimensionsConfig[target].orderBy);
		},
		getOrderBy(target)
		{
			let customFields = CommonService.getCustomFieldsConfig();

			if (customFields && is(customFields[target]))
			{
				return false;
			}

			if (is(MetricsConfig[target]))
			{
				return target;
			}

			if (!DimensionsConfig[target])
			{
				return false;
			}

			return DimensionsConfig[target].orderBy;
		},
		getDefaultOrderDir(target)
		{
			return 0;
		},
		isColumnSmall(column, index)
		{
			if (column.isMetric)
			{
				return true;
			}
			else if (column.isGroup)
			{
				let customFields = CommonService.getCustomFieldsConfig();

				if (customFields && is(customFields[column.value]))
				{
					return true;
				}
				return DimensionsConfig[column.value] ? !DimensionsConfig[column.value].isCustomView : false;
			}
			else
			{
				return true;
			}
		},
		columnsProcessor(columns)
		{
			let isAllColumnsSmall = true;

			for (let column of columns)
			{
				if (!column.isSmall)
				{
					isAllColumnsSmall = false;
					break;
				}
			}

			if (isAllColumnsSmall)
			{
				columns[0].isSmall = false;
			}

			return columns;
		}
	},
	dashboardConfig: {
		columnsCount: 4,
		unitsCount: 840,
		getMinWidth(widget)
		{
			if ((widget.type === WidgetType.TEXT) || (widget.chartType === ChartType.TOTAL_COMPARE))
			{
				return 1;
			}
			else
			{
				return 2;
			}
		},
		getMinHeight(widget)
		{
			if ((widget.type === WidgetType.TEXT) || (widget.chartType === ChartType.TOTAL_COMPARE))
			{
				return 1;
			}
			else
			{
				return 2;
			}
		}
	}
}
