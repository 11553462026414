import TestStore from './TestStore';
import AnalyticsStore from './AnalyticsStore';
import BaseStore from './BaseStore';


export default {
	'test-app': TestStore,
	'analytics-app': AnalyticsStore,
	'social-bi-app': BaseStore,
}
