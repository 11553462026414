var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("draggable-select", {
    ref: "order",
    staticClass: "draggable-order-select",
    attrs: {
      value: _vm.currentSelectOrder,
      label: "Order",
      options: _vm.options,
    },
    on: { input: _vm.handleOrderSelect },
    scopedSlots: _vm._u([
      {
        key: "image",
        fn: function ({ value }) {
          return [
            _c(
              "lobster-abstract-button",
              {
                staticClass: "draggable-order-select__icon-button",
                attrs: { "aria-label": "Change order" },
                on: {
                  click: function ($event) {
                    return _vm.handleOrderClick(value)
                  },
                },
              },
              [
                _c("lobster-icon", {
                  attrs: { size: "small", name: _vm.getIcon(value) },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }