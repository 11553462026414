<script>
import { sanitize } from '../../helpers/StringHelper';
import { TextEditorConfig } from '../../configs/TextEditorConfig';


export default {
	name: 'TextWidget',
	props: {
		textContent: String
	},
	methods: {
		sanitize(str)
		{
			return sanitize(str, TextEditorConfig.sanitizeOptions)
		}
	}
}
</script>

<template>
	<lobster-text-entity
		emojify
		save-breaks
		class="text-widget"
		:text="textContent"
		:sanitize="sanitize"/>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins";


.text-widget {
	&:deep {
		@include text-editor-typography;
	}
}
</style>