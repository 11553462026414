export const GroupsConfig = {
	ID: {
		name: 'Card'
	},
	BoardID: {
		name: 'Board',
	},
	ListID: {
		name: 'List'
	},
	MemberIDs: {
		name: 'Members'
	},
	//LabelIDs: {
	//	name: 'Label ids'
	//},
	Labels: {
		name: 'Labels'
	},
	IsDeleted: {
		name: 'Deleted'
	},
	//Position: {
	//	name: 'Position'
	//},
	//ShortLink: {
	//	name: 'Link'
	//},
	//URL: {
	//	name: 'URL'
	//},
	//Name: {
	//	name: 'Name'
	//},
	//Description: {
	//	name: 'Description'
	//},
	DueDate: {
		name: 'Due Date',
		isDate: true
	},
	IsDueDateComplete: {
		name: 'Is Due Date Complete'
	},
	ClosedDate: {
		name: 'Closed Date'
	},
	ClosedMonth: {
		name: 'Closed Month'
	},
	ClosedWeek: {
		name: 'Closed Week'
	},
	ClosedWeekDay: {
		name: 'Closed Week Day'
	},
	ClosedYear: {
		name: 'Closed Year'
	},
	ClosedQuarter: {
		name: 'Closed Quarter'
	},
	OpenedDate: {
		name: 'Opened Date'
	},
	OpenedMonth: {
		name: 'Opened Month'
	},
	OpenedWeek: {
		name: 'Opened Week'
	},
	OpenedWeekDay: {
		name: 'Opened Week Day'
	},
	OpenedYear: {
		name: 'Opened Year'
	},
	OpenedQuarter: {
		name: 'Opened Quarter'
	},
	CardCreatedDate: {
		name: 'Created Date'
	},
	CardCreatedMonth: {
		name: 'Created Month'
	},
	CardCreatedWeek: {
		name: 'Created Week'
	},
	CardCreatedWeekDay: {
		name: 'Created Week Day'
	},
	CardCreatedYear: {
		name: 'Created Year'
	},
	CardCreatedQuarter: {
		name: 'Created Quarter'
	},
	AllTags: {
		name: 'Tags'
	},
	BoardTags: {
		name: 'Board Tags'
	},
	ListTags: {
		name: 'List Tags'
	},
	CardTags: {
		name: 'Card Tags'
	},
	UserTags: {
		name: 'User Tags'
	},
	//Fields: {
	//	name: 'Fields'
	//},
	Member: {
		name: 'Member'
	},
}