import BackfillPage from "../components/Listening/Backfill/BackfillPage.vue";

export default function ()
{
	return {
		base: '/listening/',
		mode: 'history',
		routes: [
			{
				path: '/',
				redirect: 'backfill'
			},
			{
				path: `/backfill`,
				component: BackfillPage,
				name: 'backfill'
			},
		],
		linkActiveClass: 'm_active'
	}
}