var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "common-dashboard-report",
      class: { "m_with-hidden-title": _vm.hideTitle },
      attrs: { "data-id": _vm.item.id },
    },
    [
      _c("header", { staticClass: "common-dashboard-report__header" }, [
        !_vm.hideTitle
          ? _c(
              "div",
              { staticClass: "common-dashboard-report__title" },
              [
                _c(
                  "h2",
                  [
                    _vm._t("title", function () {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.item.name) + "\n\t\t\t\t"
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("lobster-text-entity", {
                  attrs: {
                    "smart-tooltip": "",
                    "line-clamp": 1,
                    text: _vm.item.description,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$slots.actions
          ? _c(
              "div",
              { staticClass: "common-dashboard-report__actions" },
              [_vm._t("actions")],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.item.type !== _vm.WidgetType.TEXT &&
      _vm.item.chartType !== _vm.ChartType.TOTAL_COMPARE
        ? _c("hr", { staticClass: "common-dashboard-report__line" })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default", function () {
        return [
          _vm.item.type === _vm.WidgetType.TEXT
            ? _c("text-widget", {
                staticClass:
                  "common-dashboard-report__body m_text kraken-report",
                attrs: { "text-content": _vm.item.content },
              })
            : _c("common-report-wrapper", {
                staticClass: "common-dashboard-report__body",
                class: `m_${_vm.item.chartType}`,
                attrs: {
                  "is-dashboard-view": "",
                  "nullstate-class": "m_nullstate",
                  dashboardId: _vm.dashboardId || null,
                  widgetId: _vm.dashboardId ? _vm.item.id : null,
                  filter: _vm.currentFilter,
                  datePeriod: _vm.datePeriod,
                  chartType: _vm.item.chartType,
                  metrics: _vm.item.metrics || {},
                  groups: _vm.item.groups,
                  orderBy: _vm.item.orderBy,
                  limit: _vm.itemLimit,
                  subjectType: _vm.item.subjectType,
                  metadata: _vm.item.metadata,
                  labels: _vm.item.labels,
                  "report-description": _vm.item.description,
                  config: _vm.additionalConfig,
                  "is-dashboard-saving": _vm.isDashboardSaving,
                },
                on: {
                  "table-chart-click": _vm.handleTableChartClick,
                  "chart-click": _vm.handleChartClick,
                  "bubble-chart-click": _vm.handleBubbleChartClick,
                  "wordcloud-chart-click": _vm.handleWordcloudChartClick,
                  "funnel-chart-click": _vm.handleFunnelChartClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "chart",
                      fn: function (data) {
                        return [_vm._t("chart", null, null, data)]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }