var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { attrs: { column: "" } },
    [
      _c("lobster-input", {
        attrs: { label: "Name" },
        model: {
          value: _vm.currentName,
          callback: function ($$v) {
            _vm.currentName = $$v
          },
          expression: "currentName",
        },
      }),
      _vm._v(" "),
      _c("lobster-select", {
        attrs: {
          "field-label": "Source",
          options: _vm.analyticsSources,
          reduce: (option) => option.value,
        },
        model: {
          value: _vm.currentSource,
          callback: function ($$v) {
            _vm.currentSource = $$v
          },
          expression: "currentSource",
        },
      }),
      _vm._v(" "),
      _c("lobster-select", {
        attrs: {
          searchable: "",
          "field-label": "Chart Type",
          options: _vm.chartTypes,
          reduce: (option) => option.value,
        },
        model: {
          value: _vm.currentChartType,
          callback: function ($$v) {
            _vm.currentChartType = $$v
          },
          expression: "currentChartType",
        },
      }),
      _vm._v(" "),
      _c("draggable-select", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasGroups,
            expression: "hasGroups",
          },
        ],
        ref: "groups",
        attrs: {
          label: "Groups",
          options: _vm.groupsOptions,
          "validate-value": _vm.validateGroups,
        },
        model: {
          value: _vm.currentGroups,
          callback: function ($$v) {
            _vm.currentGroups = $$v
          },
          expression: "currentGroups",
        },
      }),
      _vm._v(" "),
      _c("draggable-select", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasMetrics,
            expression: "hasMetrics",
          },
        ],
        ref: "metrics",
        attrs: {
          label: "Metrics",
          options: _vm.metricsOptions,
          "validate-value": _vm.validateMetrics,
        },
        model: {
          value: _vm.currentMetrics,
          callback: function ($$v) {
            _vm.currentMetrics = $$v
          },
          expression: "currentMetrics",
        },
      }),
      _vm._v(" "),
      _vm.hadAdditionalColumns
        ? _c("draggable-select", {
            ref: "additionalColumns",
            attrs: {
              label: "Additional Columns",
              options: _vm.additionalColumnsOptions,
              "validate-value": _vm.validateAdditionalColumns,
            },
            model: {
              value: _vm.currentAdditionalColumns,
              callback: function ($$v) {
                _vm.currentAdditionalColumns = $$v
              },
              expression: "currentAdditionalColumns",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTable
        ? _c("draggable-order-select", {
            attrs: { options: _vm.orderOptions },
            model: {
              value: _vm.currentOrder,
              callback: function ($$v) {
                _vm.currentOrder = $$v
              },
              expression: "currentOrder",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTable
        ? _c("lobster-input", {
            attrs: { label: "Limit", type: "number", min: 0 },
            model: {
              value: _vm.currentLimit,
              callback: function ($$v) {
                _vm.currentLimit = $$v
              },
              expression: "currentLimit",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }