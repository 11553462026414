<template>
	<lobster-entities-container
		size="small"
		class="draggable-select">
		<lobster-select
			searchable
			label="label"
			v-model="selectValue"
			:field-label="label"
			:info-tip="infoTip"
			:options="selectOptions"
			:reduce="option => option.value"
			:selectable="isSelectable"
			@option:selecting="handleSelect">
			<template #option="data">
				<slot name="option" v-bind="data"/>
			</template>
		</lobster-select>
		<draggable
			v-model="currentValue"
			class="draggable-select__tags-list">
			<template v-if="isOrder">
				<order-tickbox
					v-for="(val, index) in currentValue"
					:key="val.field"
					name="draggableValue"
					type="checkbox"
					:val="val"
					:text="getOptionText(val)"
					:grayscale="getGrayscale(val)"
					:grayscale-tip="getGrayscaleTip(val)"
					class="draggable-select__tag"
					@order-click="handleOrderClick(val)"
					@delete-click="handleDeleteTagClick(index)"/>
			</template>
			<template v-else>
				<lobster-tag
					v-for="(val, index) in currentValue"
					v-tooltip="getGrayscaleTip(val)"
					:key="val"
					name="draggableValue"
					type="checkbox"
					class="draggable-select__tag"
					:class="{
						m_grayscaled: getGrayscale(val)
					}"
					deletable
					@delete-click="handleDeleteTagClick(index)">
					{{ getOptionText(val) }}
				</lobster-tag>
			</template>
		</draggable>
	</lobster-entities-container>
</template>

<script>
import { is } from 'plankton-esm';
import Draggable from 'vuedraggable'
import OrderTickbox from '../Entities/OrderTickbox';
import { isEqual } from '../../../helpers/FunctionHelpers';


export default {
	name: 'DraggableSelect',
	components: { OrderTickbox, Draggable },
	props: {
		isOrder: Boolean,
		infoTip: String,
		options: {
			type: Array,
			required: true
		},
		value: {
			type: Array,
			required: true
		},
		label: String,
		isGrayscale: Function,
		grayscaleTip: Function
	},
	data()
	{
		return {
			selectValue: null
		}
	},
	computed: {
		currentValue: {
			get()
			{
				return this.value;
			},
			set(newVal)
			{
				this.$emit('input', newVal);

				this.$nextTick(() =>
				{
					this.selectValue = null;
				})
			}
		},
		selectOptions()
		{
			let selectOptions = [];

			for (let option of this.options)
			{
				if (option.isGroup)
				{
					let childOptions = option.options.filter(childOption => this.currentValue.indexOf(childOption.value) === -1);

					if (is(childOptions))
					{
						selectOptions.push({
							...option,
							options: childOptions
						});
					}
				}
				else
				{
					if (this.isOrder)
					{
						if (this.currentValue.findIndex(item => item.field === option.value) === -1)
						{
							selectOptions.push(option);
						}
					}
					else if (this.currentValue.indexOf(option.value) === -1)
					{
						selectOptions.push(option);
					}
				}
			}

			return selectOptions;
		},
		optionsByValue()
		{
			let options = [];

			for (let option of this.options)
			{
				if (option.isGroup)
				{
					options.push(...option.options);
				}
				else
				{
					options.push(option);
				}
			}

			let optionsByValue = {};

			for (let option of options)
			{
				optionsByValue[option.value] = option;
			}

			return optionsByValue;
		}
	},
	watch: {
		optionsByValue(val)
		{
			let newValue = [];

			for (let value of this.currentValue)
			{
				if (this.isOrder)
				{
					if (is.defined(val[value.field]))
					{
						newValue.push(value);
					}
				}
				else
				{
					if (is.defined(val[value]))
					{
						newValue.push(value);
					}
				}
			}

			if (!isEqual(this.currentValue, newValue))
			{
				this.currentValue = newValue;
			}
		}
	},
	methods: {
		isSelectable(option)
		{
			return option.isSelectable !== false;
		},
		handleSelect(option)
		{
			if (this.isOrder)
			{
				this.currentValue = [
					...this.currentValue,
					{
						field: option.value,
						direction: option.defaultDirection
					}
				];
			}
			else
			{
				this.currentValue = [
					...this.currentValue,
					option.value
				];
			}
		},
		getOptionText(val)
		{
			if (this.isOrder)
			{
				return this.optionsByValue[val.field].label;
			}
			else if (is(this.optionsByValue[val]))
			{
				return this.optionsByValue[val].label;
			}
			else
			{
				if (val.startsWith('CustomField'))
				{
					//Cutting off 'CustomField_' option prefix
					return `${val.slice(12)} (deleted)`;
				}

				return '*option deleted*'
			}
		},
		getGrayscale(val)
		{
			if (is.function(this.isGrayscale))
			{
				return this.isGrayscale(val);
			}

			return undefined;
		},
		getGrayscaleTip(val)
		{
			if (this.getGrayscale(val) && is.function(this.grayscaleTip))
			{
				return this.grayscaleTip(val);
			}
		},
		handleOrderClick(option)
		{
			let newDirection = option.direction === 'ascending' ? 'descending' : 'ascending';

			this.currentValue = this.currentValue.map(item =>
			{
				if (item.field === option.field)
				{
					return {
						...item,
						direction:newDirection
					}
				}
				else
				{
					return item;
				}
			});
		},
		handleDeleteTagClick(index)
		{
			let newValue = [...this.currentValue];

			newValue.splice(index, 1);

			this.currentValue = newValue;
		}
	}
}
</script>


<style lang="scss" scoped>
.draggable-select__tags-list {
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
}

.draggable-select__tag {
	cursor: move;

	&.m_grayscaled {
		color: $colorDisabled;
	}
}
</style>