<template>
	<lobster-entities-container
		no-wrap
		center
		class="network-entity"
		:size="(iconSize === 'small') ? 'small--s' :'small'"
		@click.native="$emit('click', $event)">
		<lobster-network-icon
			v-if="$is(network)"
			:name="network"
			:size="iconSize"/>
		<span class="network-entity__label">
			<slot name="label">
				{{ networkLabel }}
			</slot>
		</span>
	</lobster-entities-container>
</template>

<script>
import { networkNameFormatter } from '../../helpers/StringHelper';


export default {
	name: 'NetworkEntity',
	props: {
		network: String,
		iconSize: String,
		label: String
	},
	computed: {
		networkLabel()
		{
			return this.label || networkNameFormatter(this.network);
		}
	}
}
</script>

<style lang="scss" scoped>
.network-entity__label {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>