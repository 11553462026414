var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "root", staticClass: "drill-down-wrapper" },
    [
      _c("report-chart-wrapper", {
        staticClass: "drill-down-wrapper__chart",
        attrs: {
          "subject-type": _vm.subjectType,
          "date-period": _vm.datePeriod,
          "chart-type": _vm.chartType,
          groups: _vm.groups,
          filter: _vm.currentFilter,
          limit: _vm.limit,
          "order-by": _vm.orderBy,
          "with-pagination": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item-list__item",
            fn: function ({ row, columns, entities }) {
              return [
                _c(
                  "div",
                  { staticClass: "drill-down-wrapper__trello-card" },
                  [_c("trello-card", { attrs: { id: row.cells.ID.value } })],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }