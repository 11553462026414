import {
	floatToPercentString,
	formatTimeSeconds,
	formatUnderscoredToReadable,
	networkNameFormatter,
	niceNumber,
	roundFloat,
	sanitize,
	toReadable,
	Network
} from '../../utils';
import moment from 'moment';
import { MetricsConfig } from '../configs/MetricsConfig';
import { CommonService } from '../services/CommonService';
import { INDUSTRY_OPTIONS } from '../consts/IndustryOptions';


function getEntityDisplayName(columnName, property, entities, field)
{
	let item = entities[columnName]?.[property];
	let displayName;

	if (is(item) && is.array(field))
	{
		displayName = is(item[field[1]]) ? item[field[1]] : item[field[0]];
	}
	else if (is(item) && columnName === 'AdCreativeID')
	{
		if (!is(item[field]))
		{
			displayName = I18n.t('commonAnalytics.adsNullstate.title');
		}
		else if (+item[field].OktopostMappingVersion === 2)
		{
			if (is(item[field].Post))
			{
				displayName = item[field].Post.Message;
			}
			else
			{
				displayName = item[field].HtmlBody || item[field].Headline;
			}
		}
		else
		{
			displayName = item[field].htmlBody || item[field].text || item[field].description || item[field].headline?.custom;
		}
	}
	else if (is(item) && columnName === 'ItemID')
	{
		if (item.Channel === Network.LINKED_IN)
		{
			displayName = formatLinkedInText(item[field]);
		}
		else if (item.Channel === Network.TWITTER && item.IsRetweet)
		{
			displayName = item.Child[field];
		}
		else
		{
			displayName = item[field];
		}
	}
	else if (is(item))
	{
		displayName = item[field];
	}
	else
	{
		displayName = property;
	}

	return sanitize(displayName) || '-';
}

export function getEntity(columnName, property, entities)
{
	let reportingCalendarConfig = CommonService.getReportingCalendarConfig();

	switch (columnName)
	{
		case 'IsAdvocateCreated':
			return parseInt(property) === 0 ? 'Created by Board Admins' : 'Created by Advocates';

		case 'BoardRoleID':
			if (['champion', 'contributor', 'advocate'].indexOf(property) !== -1)
			{
				return property.slice(0, 1).toUpperCase() + property.slice(1).toLowerCase();
			}

			return property;

		case 'StoryID':
			return getEntityDisplayName(columnName, property, entities, 'Title');

		case 'BoardID':
		case 'BoardId':
		case 'BoardIDs':
		case 'UserID':
		case 'PostCreatedBy':
		case 'SuggestedByUserID':
		case 'CampaignID':
		case 'CampaignId':
		case 'TopicIDs':
		case 'MediaIDs':
		case 'LinkHashes':
		case 'CredentialTeamIDs':
		case 'TeamIDs':
		case 'AssigneeID':
		case 'SDBAuthorID':
		case 'PipelineID':
		case 'CannedResponses':
			return getEntityDisplayName(columnName, property, entities, 'Name');

		case 'CredentialID':
		case 'ProfileId':
		case 'LinkedinProfileId':
		case 'MentionedCredentialID':
			return getEntityDisplayName(columnName, property, entities, 'DisplayName');

		case 'LeadId':
		case 'LeadID':
			let name = getEntityDisplayName(columnName, property, entities, 'PrimaryName');

			if (!is(name))
			{
				name = '(unknown)';
			}

			return name;

		case 'ProfileID':
		case 'SDBID':
			return getEntityDisplayName(columnName, property, entities, 'Name');

		case 'SDBPostID':
			return getEntityDisplayName(columnName, property, entities, 'Text');

		case 'ProfileRemoteID':
			return getEntityDisplayName(columnName, property, entities, 'ProfileName');

		case 'Thread':
		case 'ItemID':
			return getEntityDisplayName(columnName, property, entities, 'Message');

		case 'PostlogID':
		case 'PostlogId':
		case 'MessageID':
		case 'ParentMessageID':
			return getEntityDisplayName(columnName, property, entities, ['Message', 'ChildMessage']);

		case 'ConversionTagID':
			return getEntityDisplayName(columnName, property, entities, 'Tag');

		case 'AdCreativeID':
			return getEntityDisplayName(columnName, property, entities, 'Content');

		case 'AdAccountID':
			return getEntityDisplayName(columnName, property, entities, 'AdAccountName');

		case 'AdCampaignID':
			return getEntityDisplayName(columnName, property, entities, 'AdCampaignName');

		case 'AdGroupID':
			return getEntityDisplayName(columnName, property, entities, 'AdGroupName');

		case 'Themes':
			return getEntityDisplayName(columnName, property, entities, 'Name');

		case 'Date':
		case 'CreatedDate':
		case 'CreatedDay':
		case 'ClosedDay':
		case 'RemoteCreated':
		case 'ActivityDate':
		case 'OpportunityClosedDate':
		case 'SocialActivityDate':
			return moment.utc(property).format('MMM D, YYYY');

		case 'WeekSunday':
		case 'WeekMonday':
		case 'Week':
		case 'CreatedWeekSunday':
		case 'CreatedWeekMonday':
		case 'CreatedWeek':
		case 'OpportunityClosedWeekSunday':
		case 'OpportunityClosedWeek':
		case 'SocialActivityWeekSunday':
		case 'SocialActivityWeek':
			return moment.utc(property).format('MMM D - ') + moment.utc(property).add(1, 'week').add(-1, 'day').format('MMM D, YYYY');

		case 'Month':
		case 'CreatedMonth':
		case 'ClosedMonth':
		case 'OpportunityClosedMonth':
		case 'SocialActivityMonth':
			return moment.utc(property).format('MMM YYYY');

		case 'Year':
		case 'CreatedYear':
		case 'ClosedYear':
		case 'OpportunityClosedYear':
		case 'SocialActivityYear':
			return moment.utc(property).format('YYYY');

		case 'FiscalYear':
		case 'CreatedFiscalYear':
		case 'ClosedFiscalYear':
		case 'OpportunityClosedFiscalYear':
		case 'SocialActivityFiscalYear':
			if (reportingCalendarConfig.fiscalYearStart === 1)
			{
				return moment.utc(property).format('YYYY');
			}
			if (reportingCalendarConfig.fiscalYearBase === 0)
			{
				return moment.utc(property).add(-reportingCalendarConfig.fiscalYearStart + 1, 'month') .format('YYYY');
			}
			else
			{
				return moment.utc(property).add(-reportingCalendarConfig.fiscalYearStart + 1, 'month').add(1, 'year').format('YYYY');
			}

		case 'Quarter':
		case 'CreatedQuarter':
		case 'ClosedQuarter':
		case 'OpportunityClosedQuarter':
		case 'SocialActivityQuarter':
			return moment.utc(property).format('[Q]Q YYYY');

		case 'FiscalQuarter':
		case 'CreatedFiscalQuarter':
		case 'ClosedFiscalQuarter':
		case 'OpportunityClosedFiscalQuarter':
		case 'SocialActivityFiscalQuarter':
			if (reportingCalendarConfig.fiscalYearStart === 1)
			{
				return moment.utc(property).format('[Q]Q YYYY');
			}
			if (reportingCalendarConfig.fiscalYearBase === 0)
			{
				return moment.utc(property).add(-reportingCalendarConfig.fiscalYearStart + 1, 'month').format('[Q]Q YYYY');
			}
			else
			{
				return moment.utc(property).add(-reportingCalendarConfig.fiscalYearStart + 1, 'month').add(1, 'year').format('[Q]Q YYYY');
			}

		case 'ContentType':
			return toReadable(property);

		case 'WeekDay':
		case 'CreatedWeekDay':
		case 'ClosedWeekDay':
		case 'OpportunityClosedWeekDay':
		case 'SocialActivityWeekDay':
			return moment.weekdaysShort(true)[property];

		case 'DayHour':
		case 'CreatedDayHour':
			return moment.utc(property, 'HH:mm').format('h a');

		case 'YearMonth':
		case 'CreatedYearMonth':
		case 'OpportunityClosedYearMonth':
		case 'SocialActivityYearMonth':
			return moment.months()[property - 1];

		case 'YearWeek':
		case 'CreatedYearWeek':
		case 'ClosedYearWeek':
		case 'OpportunityClosedYearWeek':
		case 'SocialActivityYearWeek':
			return +property;

		case 'Sentiment':
			return is(property) ? I18n.t(`commonAnalytics.sentiment.${property}`) : 'Unknown';

		case 'ActivityType':
		case 'SocialActivityType':
			return I18n.t('commonAnalytics.activityType')[property] ?? property;

		case 'LeadStatus':
			return I18n.t(`commonUtils.status.${property}`);

		case 'Status':
			return I18n.t(`commonUtils.status.${property}`);

		case 'IsTargetAccount':
			return I18n.t('commonUtils.confirm')[(+property === 1) ? 'yes' : 'no'];

		case 'OpportunityID':
			return getEntityDisplayName(columnName, property, entities, 'OpportunityName');

		case 'OpportunityOrganizationID':
			return getEntityDisplayName(columnName, property, entities, 'OpportunityOrganizationName');

		case 'OpportunityOwnerID':
			return getEntityDisplayName(columnName, property, entities, 'OpportunityOwnerName');

		case 'OpportunityAccountID':
			return getEntityDisplayName(columnName, property, entities, 'OpportunityAccountName');

		case 'CampaignObjective':
		case 'AdStatus':
		case 'AdCampaignStatus':
		case 'AdGroupStatus':
			return formatUnderscoredToReadable(property);

		case 'AdType':
			return is(property) ? I18n.t(`commonAnalytics.linkedInAdType.${property.toLowerCase()}`) : null;

		case 'SourceApplication':
		case 'PostSourceApp':
			return is(property) ? I18n.t(`commonAnalytics.postSourceApp.${property.toLowerCase()}`) : '(unknown)';

		case 'AuthorLocation':
		case 'ListeningCountry':
			return I18n.t('commonUtils.countries')[property] ?? property;

		case 'SentimentEmoji':
			return formatEmoji(property);

		case 'NumericalSentiment':
			return formatNumericEmoji(property);

		case 'NullableCountry':
			return I18n.t('commonUtils.countries')[property] ?? false;

		case 'PostType':
			return I18n.t('commonAnalytics.postType')[property] ?? I18n.t('post.postType')[property] ?? false;

		case 'LinkedinCountry':
		case 'LinkedinRegion':
			let geo = entities[columnName]?.[property] ?? property;

			if (typeof geo === 'string')
			{
				return geo;
			}
			else
			{
				return geo.Name ?? property;
			}

		case 'Industry':
			return INDUSTRY_OPTIONS[property]?.label ?? property;

		case 'Employees':
			return I18n.t('commonAnalytics.linkedInTargeting.companySizes')[property] ?? property;

		case 'Seniority':
			return I18n.t('commonAnalytics.linkedInTargeting.seniorities')[property] ?? property;

		case 'Function':
			return I18n.t('commonAnalytics.function')[property] ?? property;

		case 'PageType':
			return I18n.t('commonAnalytics.pageType')[property] ?? property;

		case 'Network':
		case 'Platform':
		case 'ListeningChannel':
		case 'SocialActivityNetwork':
			return networkNameFormatter(property);

		case 'IsAssigned':
			return I18n.t('commonUtils.state')[(+property === 1) ? 'assigned' : 'unassigned'];

		case 'TimeToFirstReplyCohort':
			return I18n.t(`commonAnalytics.timeFilter.${property}`);

		case 'ConversationTopic':
			if (!is(property)) {
				return '-';
			}

			return I18n.t('commonUtils.engagement.topics')[property] ?? I18n.t('commonUtils.engagement.topics.other');

		case 'ProfileIsVerified':
			switch (property)
			{
				case '1':
					return 'Yes';
				case '0':
					return 'No';
				default:
					return '-';
			}

		case 'Language':
			if (!property) {
				return '-';
			}

			return I18n.t('languageCodes')[property]?.['name'] ?? property;

		default:
			return property || '-';
	}
}

export function formatEmoji(str)
{
	switch (str)
	{
		case 'positive':
			return '😃';
		case 'negative':
			return '🙁';
		case 'neutral':
			return '😐';
		default:
			return false;
	}
}

export function formatNumericEmoji(property)
{
	if (!is.number(property))
	{
		return false;
	}

	if (property < -0.33)
	{
		return '🙁';
	}
	else if (property > 0.33)
	{
		return '😃';
	}
	else
	{
		return '😐';
	}
}

export function getEmojiIconName(emojiValue)
{
	switch (emojiValue)
	{
		case '😃':
			return 'face--satisfied';
		case '🙁':
			return 'face--dissatisfied';
		case '😐':
			return 'face--neutral';
		default:
			return null;
	}
}

export function getEmojiValue(emoji)
{
	switch (emoji)
	{
		case '😃':
			return  'positive';
		case '🙁':
			return  'negative';
		case '😐':
			return  'neutral';
		default:
			return null;
	}
}

export function formatMetricValue(name, value, isLabel = false)
{
	let config = MetricsConfig[name];

	if (!is(config))
	{
		return value;
	}

	if (config.percent)
	{
		return floatToPercentString(value, isLabel ? 2 : 0);
	}
	else if (config.isCurrency)
	{
		if (is(value) && !Number.isInteger(value))
		{
			value = roundFloat(value, 2);
		}

		return formatCurrency(value, config.currencySymbol);
	}
	else if (config.isSeconds)
	{
		return formatTimeSeconds(value);
	}
	else if (config.isNumericEmoji)
	{
		return formatNumericEmoji(value);
	}
	else
	{
		if (isLabel && !config.isFloatNumber)
		{
			return niceNumber(value);
		}
		else
		{
			if (!Number.isInteger(value))
			{
				value = roundFloat(value, 2);
			}

			return new Intl.NumberFormat().format(value);
		}
	}
}

export function formatCurrency(value, symbol = '')
{
	if (!is(symbol))
	{
		symbol = CommonService.getCurrencySymbol();
	}

	if (!is.number(value))
	{
		value = +value;
	}

	if (value < 10)
	{
		return symbol + value.toFixed(2);
	}

	return symbol + niceNumber(value);
}

export function formatLinkedInText(text)
{
	if (is.string(text))
	{
		return text.replaceAll('<REDACTED>', '<Anonymous Mention>');
	}
	else
	{
		return text;
	}
}