<script setup>
import LinkedInCrawler from './LinkedInCrawler.vue';
import AnalyticsCrawler from './AnalyticsCrawler.vue';
</script>

<template>
	<lobster-layout-section>
		<lobster-entities-container
			column
			size="large">
			<linked-in-crawler/>
			<analytics-crawler/>
		</lobster-entities-container>
	</lobster-layout-section>
</template>