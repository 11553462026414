<template>
	<lobster-dynamic-modal
		v-on="$listeners"
		v-bind="$attrs"
		aria-label="New report modal"
		class="report-settings-modal"
		size="large"
		:modal-title="title">

		<div v-if="!isEditMode" class="report-settings-modal__tabs">
			<lobster-abstract-button
				class="report-settings-modal__tab"
				:class="{'m_active': isSelection}"
				@click="isSelection = true">
				<b>
					Select Source
					<span v-if="!isSelection">({{ subjectType }})</span>
				</b>
			</lobster-abstract-button>
			<lobster-abstract-button
				class="report-settings-modal__tab"
				:class="{'m_active': !isSelection}"
				:disabled="!subjectType"
				@click="isSelection = false">
				<b>Configure Report and Add to Dashboard</b>
			</lobster-abstract-button>
		</div>

		<source-form
			v-if="isSelection"
			:subject-type.sync="subjectType"
			@submit="handleSourceSubmit"/>

		<report-form
			v-else
			:config="config"
			:is-edit="isEditMode"
			:subject-type="subjectType"
			:initial-settings="initialSettings"
			@back="handleBack"
			@submit="handleReportSubmit"/>
	</lobster-dynamic-modal>
</template>

<script>
import { ReportDataSourcesConfig } from '@analytics';
import SourceForm from './SourceForm';
import ReportForm from './ReportForm';


export default {
	name: 'ReportSettingsModal',
	components: {
		SourceForm,
		ReportForm,
	},
	props: {
		isEditMode: {
			type: Boolean,
			default: false
		},
		initialSettings: {
			type: Object,
			default: () => ({})
		}
	},
	data()
	{
		return {
			isSelection: !this.isEditMode,
			subjectType: this.initialSettings?.source || '',
			config: {
				barChart: {
					distributedFunction()
					{
						return false;
					}
				},
			}
		}
	},
	computed: {
		title()
		{
			if (this.isEditMode)
			{
				return `Edit Report (Source: ${this.subjectType})`;
			}
			else
			{
				return 'New Report';
			}
		}
	},
	methods: {
		handleBack()
		{
			this.isSelection = true;
		},
		handleSourceSubmit()
		{
			this.isSelection = false;
		},
		handleReportSubmit()
		{
			this.$emit('save');
			this.$emit('input', false);
		},
	}

}
</script>

<style lang="scss" scoped>
.report-settings-modal__tabs {
	flex-shrink: 0;
	display: flex;
	border-bottom: 1px solid $colorBorder;
	overflow: hidden;
}

.report-settings-modal__tab {
	height: 48px;
	flex-basis: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: inherit;
	position: relative;
	background-color: $colorLight;
	border: none;

	&:disabled {
		pointer-events: none;
	}

	&:hover,
	&.m_active {
		color: $colorPrimary;
	}

	&:not(:last-child) {
		&::after {
			content: '';
			position: absolute;
			right: -8px;
			top: -1px;
			width: 0;
			height: 0;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
			border-left: 18px solid $colorLight;
			z-index: 5;
		}
	}

	& + .report-settings-modal__tab {
		&::before {
			content: '';
			position: absolute;
			left: -9px;
			top: -1px;
			width: 0;
			height: 0;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
			border-left: 18px solid $colorBorder;
		}
	}
}
</style>