var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-dynamic-modal",
    _vm._g(
      _vm._b(
        {
          staticClass: "widget-modal",
          attrs: { size: "large", "modal-title": "Add Report" },
        },
        "lobster-dynamic-modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "widget-modal__container" }, [
        _c(
          "div",
          { staticClass: "widget-modal__sidebar" },
          [
            _c(
              "div",
              { staticClass: "widget-modal__sidebar-navigator" },
              [
                _c(
                  "lobster-button",
                  {
                    staticClass: "widget-modal__sidebar-navigator-button",
                    attrs: { "attached-left": "", secondary: "" },
                    on: {
                      click: function ($event) {
                        _vm.showFilters = false
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tSettings\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "lobster-button",
                  {
                    staticClass: "widget-modal__sidebar-navigator-button",
                    attrs: { "attached-right": "", secondary: "" },
                    on: {
                      click: function ($event) {
                        _vm.showFilters = true
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tFilters\n\t\t\t\t")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("widget-settings", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showFilters,
                  expression: "!showFilters",
                },
              ],
              attrs: {
                name: _vm.name,
                source: _vm.source,
                "chart-type": _vm.chartType,
                groups: _vm.groups,
                metrics: _vm.metrics,
                "additional-columns": _vm.additionalColumns,
                limit: _vm.limit,
                "order-by": _vm.orderBy,
              },
              on: {
                "update:name": function ($event) {
                  _vm.name = $event
                },
                "update:source": function ($event) {
                  _vm.source = $event
                },
                "update:chartType": function ($event) {
                  _vm.chartType = $event
                },
                "update:chart-type": function ($event) {
                  _vm.chartType = $event
                },
                "update:groups": function ($event) {
                  _vm.groups = $event
                },
                "update:metrics": function ($event) {
                  _vm.metrics = $event
                },
                "update:additionalColumns": function ($event) {
                  _vm.additionalColumns = $event
                },
                "update:additional-columns": function ($event) {
                  _vm.additionalColumns = $event
                },
                "update:limit": function ($event) {
                  _vm.limit = $event
                },
                "update:orderBy": function ($event) {
                  _vm.orderBy = $event
                },
                "update:order-by": function ($event) {
                  _vm.orderBy = $event
                },
              },
            }),
            _vm._v(" "),
            _c("analytics-filter", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              attrs: {
                "is-report-builder": "",
                "menu-view": false,
                "subject-type": _vm.source,
              },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget-modal__report" },
          [
            _c("report-chart-wrapper", {
              staticClass: "widget-modal__report-chart",
              attrs: {
                "subject-type": _vm.source,
                "date-period": _vm.datePeriod,
                metrics: _vm.metrics,
                groups: _vm.groups,
                "additional-columns": _vm.additionalColumns,
                limit: _vm.limit,
                "chart-type": _vm.chartType,
                filter: _vm.filter,
                "order-by": _vm.orderBy,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "widget-modal__footer" },
        [
          _c(
            "lobster-button",
            {
              attrs: { secondary: "", loading: _vm.isLoading },
              on: { click: _vm.handleSave },
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }