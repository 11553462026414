<template>
	<article
		class="gallery-report"
		:class="{ 'm_with-hidden-title': hideTitle }"
		:data-id="item.id">
		<header class="gallery-report__header">
			<div
				v-if="!hideTitle"
				class="gallery-report__title">
				<h2>
					<lobster-abstract-button
						v-if="isTitleButton"
						class="gallery-report__title-link"
						:to="currentReportLink"
						@click="handleTitleClick">
						{{ item.name }}
					</lobster-abstract-button>
					<span v-else>{{ item.name }}</span>
				</h2>
				<span
					v-tooltip="tooltipConfig"
					class="gallery-report__title-description">
					{{ item.description }}
				</span>
			</div>
			<report-actions-menu
				v-if="withMenu"
				with-detach
				class="gallery-report__actions"
				@edit-widget-click="handleEditWidget"
				@delete-widget-click="handleDeleteWidget">
				<lobster-action-button
					icon-name="overflow-menu--vertical"
					aria-label="Actions"/>
			</report-actions-menu>
		</header>

		<hr
			v-if="item.chartType !== ChartType.TOTAL_COMPARE"
			class="gallery-report__line" />

		<report-chart-wrapper
			ref="reportChart"
			class="gallery-report__body"
			nullstate-class="m_nullstate"
			is-dashboard-view
			:dashboardId="galleryId || null"
			:widgetId="galleryId ? item.id : null"
			:datePeriod="datePeriod"
			:class="`m_${item.chartType}`"
			:chartType="item.chartType"
			:metrics="item.metrics"
			:groups="item.groups"
			:orderBy="item.orderBy"
			:filter="item.filter"
			:labels="item.labels"
			:limit="itemLimit"
			:subjectType="item.source"
			:metadata="item.metadata"
			:report-description="item.description"
			:config="additionalConfig"
			@see-more="handleSeeMoreClick"/>
	</article>
</template>


<script>
import { merge_configs } from 'kraken-charts';
import ReportActionsMenu from '../../Report/ReportActionsMenu';
import ReportChartWrapper from '../../Report/ReportChartWrapper';
import ReportSettingsModal from '../../Report/ReportSettingsModal';
import { DatePeriodType } from '../../../../consts/DatePeriodType';
import { ChartType } from '@analytics';


export default {
	name: 'GalleryReport',
	components: {
		ReportChartWrapper,
		ReportActionsMenu,
	},
	data() {
		return {
			ChartType,
		}
	},
	props: {
		galleryId: String,
		item: Object,
		datePeriod: Object,
		withMenu: Boolean,
		reportLink: Object,
		isClickable: Boolean,
		config: {
			type: Object,
			default()
			{
				return {};
			}
		}
	},
	computed: {
		tooltipConfig()
		{
			return {
				content: this.item.description,
				smartTooltip: true,
				placement: 'bottom',
				classes: 'm_light',
				delay: {
					show: 1000,
					hide: 0
				}
			}
		},
		currentReportLink()
		{
			return {
				...this.reportLink,
			}
		},
		isTitleButton()
		{
			return this.isClickable || is(this.reportLink);
		},
		hideTitle()
		{
			if (this.item.chartType === ChartType.TOTAL_COMPARE_EXTENDED)
			{
				return true;
			}

			if (this.item.chartType === ChartType.TOTAL_COMPARE)
			{
				return +this.item.metadata.showHeadline === 0;
			}

			return  false;
		},
		itemLimit()
		{
			if (this.item.chartType === ChartType.WORD_CLOUD)
			{
				let size = this.item.w * this.item.h;

				if (size < 240 * 240 * 6)
				{
					return Math.min(50, this.item.limit);
				}
				else if (size < 240 * 240 * 8)
				{
					return Math.min(75, this.item.limit);
				}
				else if (size < 240 * 240 * 10)
				{
					return Math.min(100, this.item.limit);
				}
				else
				{
					return this.item.limit;
				}
			}

			return this.item.limit;
		},
		currentDatePeriod()
		{
			let query = {...this.$route.query};

			if (is(query.from && query.to))
			{
				return {
					from: query.from,
					to: query.to
				}
			}
			else if (is(query.type))
			{
				return {
					type: query.type
				}
			}
			else
			{
				return {
					type: DatePeriodType.LAST_30_DAYS
				}
			}
		},
		additionalConfig()
		{
			let subjectType = this.item?.source;
			let reportLink = this.reportLink;
			
			return merge_configs({
				barChart: {
					distributedFunction()
					{
						return false;
					}
				},
				tableConfig: {
					isClickable(columns, cells, entities)
					{
						if (is(reportLink))
						{
							return true;
						}

						if (subjectType === 'socialListening')
						{
							return true;
						}
						
						let columnKeys = new Set(columns.map(column => column.value));

						if ((subjectType === 'conversion' && columnKeys.has('LeadEmail')) ||
							(subjectType !== 'conversion' && columnKeys.has('PostlogID')) ||
							(subjectType === 'leadActivity' && columnKeys.has('LeadId')) ||
							(subjectType === 'brandMentions' && columnKeys.has('SDBPostID')) ||
							(subjectType === 'opportunity' && columnKeys.has('OpportunityID')) ||
							(subjectType === 'linkedInAds' && columnKeys.has('AdCreativeID')))
						{
							return true;
						}

						return false;
					},
				}
			}, this.config, this.$krakenConfig);
		},
	},
	methods: {
		handleEditWidget()
		{
			this.$showModal({
				component: ReportSettingsModal,
				bind: {
					isEditMode: true,
					initialSettings: {
						...this.item,
						dashboardId: this.galleryId,
						datePeriod: this.datePeriod
					}
				}
			});
		},
		async handleDeleteWidget()
		{
			let isConfirmed = await this.$dialog('This will delete the report for anyone who has access to this gallery. Delete it anyway?');

			if (isConfirmed)
			{
				this.$store.dispatch('reportGallery/deleteReport', {
					dashboardId: this.galleryId,
					widgetId: this.item.id,
				});
			}
		},
		handleTitleClick()
		{
			if (!this.isClickable)
			{
				return;
			}

			this.$emit('title-click', {
				datePeriod: this.currentDatePeriod
			});
		},
		handleSeeMoreClick()
		{
			if (!this.isClickable)
			{
				return;
			}

			this.$emit('see-more-click', {
				datePeriod: this.currentDatePeriod
			});
		},
		reload()
		{
			this.$refs.reportChart.reloadReportData();
		},
	}
};
</script>


<style lang="scss" scoped>
.gallery-report {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 24px;

	&:hover {
		.gallery-report__actions {
			visibility: visible;
		}
	}

	&.m_with-hidden-title {
		.gallery-report__header {
			margin-bottom: 0;
		}
	}
}

.gallery-report__header {
	display: flex;
	align-items: flex-start;
	flex-shrink: 0;
	margin-bottom: 25px;
}

.gallery-report__line {
	width: 100%;
	margin-bottom: 25px;
}

.gallery-report__title {
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
}

.gallery-report__title-link {
	color: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;

	&:hover {
		color: $colorPrimary;
	}
}

.gallery-report__title-description {
	font-family: $fontFamilyHeadline;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	font-size: 14px;
	line-height: 22px;
}

.gallery-report__actions {
	flex-shrink: 0;
	margin-left: auto;
	visibility: hidden;
	margin-right: -6px;
	margin-top: -6px;
	height: 0;
	position: relative;
	z-index: 10;
}

.gallery-report__body {
	flex-grow: 1;
	min-height: 0;
	display: flex;
	flex-direction: column;

	&.m_list:not(.m_nullstate) {
		margin: 0 -24px -23px;
	}
	
	&.m_bar-list {
		margin-bottom: -1px;
	}

	&.m_total-compare,
	&.m_total-compare-extended {
		margin-left: -24px;
		margin-right: -24px;
	}
}
</style>
