<template>
	<lobster-field 
		:label="label"
		:focus-targets="['lobster-tag-checkbox__icon', 'lobster-checkbox__input']">
		<lobster-select
			v-model="selectValue"
			:options="currentOptions"
			label="label"
			:reduce="item => item"
			@option:selecting="handleSelect"/>

		<draggable
			v-model="currentValue"
			class="draggable-select__values">
			<lobster-tag
				v-for="(val, index) in currentValue"
				:key="val.value"
				v-tooltip="validationConfig[val.value]"
				name="draggableValue"
				class="draggable-select__value"
				:class="{m_invalid: validationConfig[val.value]}"
				deletable
				@delete-click="handleDeleteTagClick(index)">
				<template #image>
					<slot name="image" v-bind="val"/>
				</template>
				{{ val.label }}
			</lobster-tag>
		</draggable>
	</lobster-field>
</template>

<script>
import draggable from 'vuedraggable'


export default {
	name: 'DraggableSelect',
	components: {
		draggable
	},
	props: {
		options: {
			type: Array,
			default()
			{
				return []
			}
		},
		value: {
			type: Array,
			required: true
		},
		label: String,
		validateValue: {
			type: Function,
			default: values => ({})
		}
	},
	data()
	{
		return {
			selectValue: null,
			currentValue: [],
			validationConfig: {}
		}
	},
	computed: {
		optionsByValue()
		{
			let res = {};
			
			for (let option of this.options)
			{
				res[option.value] = option;
			}
			
			return res;
		},
		currentOptions()
		{
			return this.options.filter(option => this.currentValue.findIndex(val => val.value === option.value) === -1);
		},
	},
	watch: {
		currentValue()
		{
			this.recalculateValue()
		}
	},
	methods: {
		recalculateValue()
		{
			this.validationConfig = this.validateValue(this.currentValue);

			let value = this.currentValue.map(option => option.value).filter(item => !this.$is(this.validationConfig[item]));

			this.$emit('input', value);

			this.$nextTick(() =>
			{
				this.selectValue = null;
			})
		},
		handleSelect(option)
		{
			this.currentValue.push(option);
		},
		handleDeleteTagClick(index)
		{
			this.$delete(this.currentValue, index);
		},
		reset()
		{
			this.currentValue = this.value.map(val => this.optionsByValue[val]);
		}
	},
	mounted()
	{
		this.reset();
	}
}
</script>

<style lang="scss">
.draggable-select__values {
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	margin-top: 6px;
}

.draggable-select__value {
	cursor: move;

	&.m_invalid {
		background-color: $colorDisabled;
	}
}
</style>