var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    {
      staticClass: "lead-account-entity",
      attrs: { center: "", "no-wrap": "", size: "small" },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.name || "-"))]),
      _vm._v(" "),
      _vm.isTargetAccount
        ? _c("lobster-status-icon", {
            staticClass: "no-shrink",
            attrs: { "icon-name": "star--filled", color: "warning" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }