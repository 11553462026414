<script setup>
import { useRoute, useRouter } from 'vue-router/composables';
import { onMounted, onUnmounted } from 'vue';
import AdminYuri from '../../utils/yuri/AdminYuri';
import { ErrorHandler } from '../../utils/yuri/ErrorHandler';
import { useToaster } from '../../hooks/toaster';


const route = useRoute();
const router = useRouter();
const toaster = useToaster(); 


function handleNavMenuClick()
{
	if (route.name !== 'linkedin-crawlers')
	{
		router.push({name: 'linkedin-crawlers'});
	}
}


onMounted(() =>
{
	$('a[data-menu="qa-tools"]').on('click', handleNavMenuClick);
	AdminYuri.registerHelper(new ErrorHandler(toaster));
});

onUnmounted(() =>
{
	$('a[data-menu="qa-tools"]').off('click', handleNavMenuClick);
});
</script>

<template>
	<fragment>
		<aside>
			<lobster-button
				inline-link
				:to="{ name: 'crawlers' }">
				Crawlers
			</lobster-button>
			<lobster-button
				inline-link
				:to="{ name: 'twitter' }">
				Twitter
			</lobster-button>
		</aside>
		<router-view class="inner"/>
		<lobster-provider/>
	</fragment>
</template>

<style scoped lang="scss">

</style>