var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "icon-tickbox",
      class: { m_card: _vm.card, "m_icon-card": _vm.iconCard },
    },
    [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            attrs: {
              name: _vm.name,
              "aria-label": _vm.ariaLabel,
              disabled: _vm.disabled,
              type: "checkbox",
            },
            domProps: {
              value: _vm.val,
              checked: Array.isArray(_vm.checked)
                ? _vm._i(_vm.checked, _vm.val) > -1
                : _vm.checked,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.val,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checked = $$c
                  }
                },
                _vm.onChange,
              ],
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            attrs: {
              name: _vm.name,
              "aria-label": _vm.ariaLabel,
              disabled: _vm.disabled,
              type: "radio",
            },
            domProps: { value: _vm.val, checked: _vm._q(_vm.checked, _vm.val) },
            on: {
              change: [
                function ($event) {
                  _vm.checked = _vm.val
                },
                _vm.onChange,
              ],
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            attrs: {
              name: _vm.name,
              "aria-label": _vm.ariaLabel,
              disabled: _vm.disabled,
              type: _vm.type,
            },
            domProps: { value: _vm.val, value: _vm.checked },
            on: {
              change: _vm.onChange,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.checked = $event.target.value
              },
            },
          }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "icon-tickbox__label" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.title
            ? _c(
                "b",
                { staticClass: "icon-tickbox__label-title" },
                [
                  _vm._t("title", function () {
                    return [
                      _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t"),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.caption
            ? _c(
                "span",
                { staticClass: "icon-tickbox__label-caption caption-text" },
                [_vm._v(_vm._s(_vm.caption))]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }