import { AbstractYuriHelper } from 'yuri-js';


export class ErrorHandler extends AbstractYuriHelper
{
	/**
	 * @param {Toaster} toaster
	 */
	constructor(toaster)
	{
		super();
		
		this._toaster = toaster;
	}
	
	
	execute(config)
	{
		let res = super.execute(config);
		
		res.onComplete(result =>
		{
			if (!result.isFailed())
			{
				return;
			}
			
			if (result.isConnectionError())
			{
				this._toaster.showError('Failed to connect with server. Check your internet connection and try again.');
			}
			else if (result.error.code === 401)
			{
				window.location.href = '/login';
			}
			else if (result.error.code === 403)
			{
				this._toaster.showError('You do not have permissions to access this resource');
			}
			else if (result.error.code === 404)
			{
				this._toaster.showError('Endpoint you are looking for was not found');
			}
			else if (result.error.code >= 500)
			{
				this._toaster.showError('An unexpected error has occurred, please try again or contact support');
			}
			else if (result.error.code === 400)
			{
				let body = result.getBody();
				
				if (body.Error)
				{
					this._toaster.showError(body.Error);
				}
				else if (body.Errors)
				{
					if (typeof body.Errors === 'string')
					{
						this._toaster.showError(body.Errors);
					}
					else
					{
						try
						{
							let key1 = Object.keys(body.Errors)[0];
							let key2 = Object.keys(body.Errors[key1])[0];
							
							this._toaster.showError(body.Errors[key1][key2]);
						}
						catch (_)
						{
							
						}
					}
				}
			}
		});
		
		return res;
	}
}