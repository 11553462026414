<template>
	<div class="report-form-chart">
		<header
			v-if="withHeader"
			class="report-form-chart__header">
			<lobster-action-button
				v-if="hasTableSwitch"
				class="report-form-chart__table-switch"
				icon-name="table--split"
				:active="isTableView"
				v-tooltip="tableSwitchTooltip"
				@click="handleSwitchClick"/>

			<label class="report-form-chart__title-wrapper invisible-box">
				<input
					v-model.trim="currentName"
					v-autowidth="{maxWidth: '100%', minWidth: '100px', comfortZone: 16}"
					aria-label="Report name"
					class="report-form-chart__title-input h2"
					type="text"
					maxlength="100"
					data-lpignore="true"/>
				<lobster-icon
					name="edit"
					class="invisible-box__actions description-text report-settings-modal__title-button"/>
			</label>
			<input
				v-model.trim="currentDescription"
				class="report-form-chart__description-input"
				type="text"
				maxlength="200"
				placeholder="Edit Description"/>
		</header>

		<report-chart-wrapper
			class="report-form-chart__chart"
			module-name="reportGallery"
			:chart-type="currentChartType"
			:subject-type="subjectType"
			:metrics="metrics"
			:groups="groups"
			:additional-columns="additionalColumns"
			:limit="currentLimit"
			:order-by="orderBy"
			:metadata="metadata"
			:filter="filter"
			:labels="labels"
			:date-period="datePeriod"
			:config="currentConfig"
			@edit-metric="handleEditMetric"/>
	</div>
</template>

<script>
import { merge_configs } from 'kraken-charts';
import ReportChartWrapper from './ReportChartWrapper';
import { ChartType } from '@analytics';


export default {
	name: 'ReportFormChart',
	components: {
		ReportChartWrapper
	},
	props: {
		chartType: String,
		metrics: {
			type: Array,
			default: () => ([])
		},
		groups: {
			type: Array,
			default: () => ([])
		},
		additionalColumns: {
			type: Array,
			default: () => ([])
		},
		subjectType: String,
		limit: [Number, String],
		orderBy: Array,
		metadata: {
			type: Object,
			default: () => ({})
		},
		filter: Object,
		datePeriod:  Object,
		labels: [Object, Array],

		name: String,
		description: String,
		config: Object
	},
	data()
	{
		return {
			isTableView: false,
		}
	},
	computed: {
		currentName: {
			get()
			{
				return this.name;
			},
			set(val)
			{
				this.$emit('update:name', val);
			}
		},
		currentDescription: {
			get()
			{
				return this.description;
			},
			set(val)
			{
				this.$emit('update:description', val);
			}
		},
		currentConfig()
		{
			return merge_configs({
				totalCompareConfig: {
					isEditMode: true
				}
			}, this.config, this.$krakenConfig);
		},
		currentChartType()
		{
			return this.isTableView ? ChartType.LIST : this.chartType;
		},
		currentLimit()
		{
			return this.isTableView ? 10 : this.limit;
		},
		withHeader()
		{
			return !(this.chartType === ChartType.TOTAL_COMPARE && +this.metadata.showHeadline === 0);
		},
		hasTableSwitch()
		{
			return this.chartType !== ChartType.FUNNEL && this.chartType !== ChartType.TOTAL_COMPARE;
		},
		tableSwitchTooltip()
		{
			if (this.isTableView)
			{
				return 'Hide table preview';
			}
			else
			{
				return 'Show table preview';
			}
		}
	},
	watch: {
		chartType()
		{
			this.isTableView = false;
		}
	},
	methods: {
		handleSwitchClick()
		{
			this.isTableView = !this.isTableView;
		},
		handleEditMetric(metric, metricText)
		{
			this.$emit('edit-metric', metric, metricText);
		}
	}
}
</script>

<style lang="scss" scoped>
.report-form-chart {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.report-form-chart__header {
	position: relative;
	padding: 16px 68px 16px 48px;
}

.report-form-chart__table-switch {
	position: absolute;
	top: 12px;
	right: 12px;
}


.report-form-chart__title-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.report-form-chart__title-input {
	text-align: center;
	border-color: transparent;
	padding: 0;

	&:focus + .report-settings-modal__title-button {
		visibility: hidden;
	}
}

.report-settings-modal__title-button {
	margin-right: -20px;

	&:hover {
		color: $colorTextPrimary;
	}
}

.report-form-chart__description-input {
	text-align: center;
	color: $colorTextSecondary;
	border-color: transparent;
	height: 20px;
	padding: 0;
	margin-left: auto;
	margin-right: auto;

	&:focus {
		position: relative;
	}
}

.report-form-chart__chart {
	flex-grow: 1;
	overflow: hidden;
	margin: 16px;
}
</style>