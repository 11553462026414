import TestRouter from './TestRouter';
import AnalyticsRouter from './AnalyticsRouter';
import QAToolsRouter from './QAToolsRouter';
import SocialBIRouter from './SocialBIRouter';
import ListeningRouter from "./ListeningRouter.js";


export default {
	'test-app': TestRouter,
	'analytics-app': AnalyticsRouter,
	'qa-tools-app': QAToolsRouter,
	'social-bi-app': SocialBIRouter,
	'listening-app': ListeningRouter
}