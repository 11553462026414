<script>
import { ChartType } from '../consts/ChartType';
import CommonReportWrapper from './CommonReportWrapper.vue';
import { getDashboardReportFilter } from '../helpers/FilterHelper';
import { merge_configs } from 'kraken-charts';
import { WidgetType } from '../consts/WidgetType';
import { TextWidget } from '../../utils';


export default {
	name: 'CommonDashboardReport',
	components: {
		TextWidget,
		CommonReportWrapper
	},
	props: {
		dashboardId: String,
		item: {
			type: Object,
			default()
			{
				return {};
			}
		},
		datePeriod: Object,
		dashboardFilter: {
			type: Object,
			default: () => ({})
		},
		config: {
			type: Object,
			default()
			{
				return {};
			}
		},
		isDashboardSaving: Boolean
	},
	data()
	{
		return {
			WidgetType,
			ChartType,
		}
	},
	computed: {
		hideTitle()
		{
			if (this.item.type === WidgetType.TEXT)
			{
				return true;
			}

			if (this.item.chartType === ChartType.TOTAL_COMPARE)
			{
				return +this.item.metadata.showHeadline === 0;
			}

			return false;
		},
		itemLimit()
		{
			if (this.item.chartType === ChartType.WORD_CLOUD)
			{
				const ITEM_SIDE = 240;

				let squareSize = this.item.w * this.item.h;

				if (squareSize < ITEM_SIDE * ITEM_SIDE * 6)
				{
					return Math.min(50, this.item.limit);
				}
				else if (squareSize < ITEM_SIDE * ITEM_SIDE * 8)
				{
					return Math.min(75, this.item.limit);
				}
				else if (squareSize < ITEM_SIDE * ITEM_SIDE * 10)
				{
					return Math.min(100, this.item.limit);
				}
				else
				{
					return this.item.limit;
				}
			}

			return this.item.limit;
		},
		currentFilter()
		{
			if (this.item.type === WidgetType.TEXT)
			{
				return null;
			}

			return getDashboardReportFilter(this.item.subjectType, this.dashboardFilter, this.item.filter);
		},
		additionalConfig()
		{
			return merge_configs(this.config, this.$krakenConfig);
		}
	},
	methods: {
		handleTableChartClick(row, columns, entities)
		{
			this.$emit('table-chart-click', row, columns, entities)
		},
		handleChartClick(item, entities)
		{
			this.$emit('chart-click', item, entities);
		},
		handleBubbleChartClick(item, entities)
		{
			this.$emit('bubble-chart-click', item, entities);
		},
		handleWordcloudChartClick(item, entities)
		{
			this.$emit('wordcloud-chart-click', item, entities);
		},
		handleFunnelChartClick()
		{
			this.$emit('funnel-chart-click');
		},
	}
}
</script>

<template>
	<article
		class="common-dashboard-report"
		:class="{ 'm_with-hidden-title': hideTitle }"
		:data-id="item.id">
		<header class="common-dashboard-report__header">
			<div
				v-if="!hideTitle"
				class="common-dashboard-report__title">
				<h2>
					<slot name="title">
						{{ item.name }}
					</slot>
				</h2>
				<lobster-text-entity
					smart-tooltip
					:line-clamp="1"
					:text="item.description"/>
			</div>
			<div
				v-if="$slots.actions"
				class="common-dashboard-report__actions">
				<slot name="actions"/>
			</div>
		</header>

		<hr
			v-if="(item.type !== WidgetType.TEXT) && (item.chartType !== ChartType.TOTAL_COMPARE)"
			class="common-dashboard-report__line" />

		<slot>
			<text-widget
				v-if="item.type === WidgetType.TEXT"
				:text-content="item.content"
				class="common-dashboard-report__body m_text kraken-report"/>

			<common-report-wrapper
				v-else
				is-dashboard-view
				class="common-dashboard-report__body"
				:class="`m_${item.chartType}`"
				nullstate-class="m_nullstate"
				:dashboardId="dashboardId || null"
				:widgetId="dashboardId ? item.id : null"
				:filter="currentFilter"
				:datePeriod="datePeriod"
				:chartType="item.chartType"
				:metrics="item.metrics || {}"
				:groups="item.groups"
				:orderBy="item.orderBy"
				:limit="itemLimit"
				:subjectType="item.subjectType"
				:metadata="item.metadata"
				:labels="item.labels"
				:report-description="item.description"
				:config="additionalConfig"
				:is-dashboard-saving="isDashboardSaving"
				@table-chart-click="handleTableChartClick"
				@chart-click="handleChartClick"
				@bubble-chart-click="handleBubbleChartClick"
				@wordcloud-chart-click="handleWordcloudChartClick"
				@funnel-chart-click="handleFunnelChartClick">
				<template #chart="data">
					<slot name="chart" v-bind="data"/>
				</template>
			</common-report-wrapper>
		</slot>
	</article>
</template>

<style scoped lang="scss">
.common-dashboard-report {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--spacing-L);

	&:hover {
		.common-dashboard-report__actions {
			visibility: visible;
		}
	}

	&.m_with-hidden-title {
		.common-dashboard-report__header {
			margin-bottom: 0;
		}
	}
}

.common-dashboard-report__header {
	display: flex;
	align-items: flex-start;
	flex-shrink: 0;
	margin-bottom: var(--spacing-L);
}

.common-dashboard-report__line {
	width: 100%;
}

.common-dashboard-report__title {
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
}

.common-dashboard-report__actions {
	flex-shrink: 0;
	margin-left: auto;
	visibility: hidden;
	margin-right: calc(-1 * var(--spacing-S));
	margin-top: calc(-1 * var(--spacing-S));
	height: 0;
	position: relative;
	z-index: 10;
}

.common-dashboard-report__body {
	flex-grow: 1;
	min-height: 0;
	display: flex;
	flex-direction: column;
	cursor: default;

	&.m_list:not(.m_nullstate) {
		margin: 0 calc(-1 * var(--spacing-L)) calc(-1 * var(--spacing-L) - 1px);
	}

	&.m_bar-list {
		margin-bottom: -1px;
	}

	&.m_total-compare,
	&.m_total-compare-extended {
		margin-left: calc(-1 * var(--spacing-L));
		margin-right: calc(-1 * var(--spacing-L));
	}

	&.m_text {
		margin: 0 calc(-1 * var(--spacing-L)) calc(-1 * var(--spacing-L));
		padding: 0 var(--spacing-L) var(--spacing-L);
		overflow: auto;
	}
}
</style>