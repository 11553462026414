var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-select", {
    staticClass: "date-select",
    attrs: {
      value: _vm.currentValue,
      clearable: false,
      searchable: false,
      options: _vm.currentOptions,
      reduce: (option) => option.value,
    },
    on: { "option:selecting": _vm.handleOptionSelecting },
    scopedSlots: _vm._u([
      {
        key: "selected-option",
        fn: function (value) {
          return [_vm._v("\n\t\t" + _vm._s(_vm.currentValueLabel) + "\n\t")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }