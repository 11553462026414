var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    {
      attrs: { placement: "bottom-start", trigger: "manual", open: _vm.isOpen },
      on: {
        "auto-hide": function ($event) {
          _vm.isOpen = false
        },
        "apply-hide": function ($event) {
          _vm.isOpen = false
        },
      },
      scopedSlots: _vm._u([
        {
          key: "popover",
          fn: function () {
            return [
              _c("date-picker", {
                attrs: { "date-period": _vm.value },
                on: { "dates-selected": _vm.handleCustomDateSelect },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("date-filter-select", {
        attrs: { "date-period": _vm.value, options: _vm.options },
        on: { "option-click": _vm.handleSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }