import DashboardsListSection from '../components/Analytics/Dashboards/DashboardsListSection';
import DashboardSection from '../components/Analytics/Dashboard/DashboardSection';
import SingleReportSection from '../components/Analytics/SingleReport/SingleReportSection';


export default function ()
{
	return {
		base: '/rnd-bi/',
		mode: 'history',
		routes: [
			{
				path: `/`,
				component: DashboardsListSection,
				name: 'dashboards-list'
			},
			{
				path: `/:id`,
				component: DashboardSection,
				name: 'dashboard',
				props: true
			},
			{
				path: `/:dashboardId/:id`,
				component: SingleReportSection,
				name: 'report',
				props: true
			},
		],
		linkActiveClass: 'm_active'
	}
};