<script>
import { DimensionsConfig } from '../configs/DimensionsConfig';
import { MetricsConfig } from '../configs/MetricsConfig';
import { formatNumericEmoji } from '../helpers/StringHelper';
import SentimentEntity from './Entities/SentimentEntity.vue';
import { CommonService } from '../services/CommonService';
import { NetworkEntity, CampaignEntity } from '../../utils';
import MessageEntity from './Entities/MessageEntity.vue';
import LinkEntity from './Entities/LinkEntity.vue';
import LeadAccountEntity from './Entities/LeadAccountEntity.vue';
import StageEntity from './Entities/StageEntity.vue';
import AdsEntity from './Entities/AdsEntity.vue';
import LeadStatusEntity from './Entities/LeadStatusEntity.vue';
import { getPostEntityOptions, getProfileEntityOptions } from '../helpers/ChartHelper';


export default {
	name: 'CommonTableCell',
	components: {
		AdsEntity,
		LeadStatusEntity,
		StageEntity,
		LeadAccountEntity,
		LinkEntity,
		CampaignEntity,
		NetworkEntity,
		SentimentEntity,
		MessageEntity
	},
	props: {
		row: {
			type: Object,
			required: true
		},
		column: {
			type: Object,
			required: true
		},
		entities: {
			type: Object,
			default()
			{
				return {};
			}
		},
	},
	data()
	{
		return {
			customFieldsConfig: CommonService.getCustomFieldsConfig()
		}
	},
	computed: {
		cells()
		{
			return this.row.cells;
		},
		entity()
		{
			return this.entities?.[this.cellName]?.[this.cellValue] ?? {};
		},
		cellName()
		{
			return this.column.value;
		},
		cellValue()
		{
			return this.cells[this.cellName].value;
		},
		cellLabel()
		{
			return this.cells[this.cellName].label;
		},
		dimensionConfig()
		{
			return DimensionsConfig[this.cellName] || {};
		},
		truncate()
		{
			if (this.column.isMetric)
			{
				return false;
			}

			return (this.isLink ||
				this.isCustomField ||
				!is(this.dimensionConfig) ||
				!this.dimensionConfig.isDate);
		},
		isCustomField()
		{
			return is(this.customFieldsConfig?.[this.cellName]);
		},
		isCustomView()
		{
			if (this.dimensionConfig.useEntity && !is(this.entity))
				return false;

			return this.dimensionConfig.isCustomView;
		},
		isNumericEmoji()
		{
			let metricConfig = MetricsConfig[this.column.value];
				
			return this.column.isMetric && metricConfig.isNumericEmoji;
		},
		isLink()
		{
			return this.dimensionConfig.isLink;
		},
		isNetwork()
		{
			return this.dimensionConfig.isNetwork;
		},
		isCampaign()
		{
			return this.dimensionConfig.isCampaign;
		},
		isMessage()
		{
			return this.dimensionConfig.isMessage;
		},
		isPost()
		{
			return this.dimensionConfig.isPost;
		},
		isProfile()
		{
			return this.dimensionConfig.isProfile;
		},
		postEntityOptions()
		{
			if (!this.isPost)
			{
				return null;
			}

			return getPostEntityOptions(this.cellName, this.entity);
		},
		profileEntityOptions()
		{
			if (!this.isProfile)
			{
				return null;
			}

			return getProfileEntityOptions(this.cellName, this.entity);
		},
		emojiValue()
		{
			return formatNumericEmoji(this.cellValue);
		}
	}
}
</script>

<template>
	<lobster-table-cell
		:tooltip="cellLabel"
		:truncate="truncate"
		:right="column.isMetric"
		:no-container="isCustomView"
		class="common-table-cell"
		:class="{ m_link: isLink }">
		<sentiment-entity
			v-if="isNumericEmoji"
			:value="emojiValue"/>

		<template v-else-if="column.isMetric">
			{{ cellLabel }}
		</template>

		<template v-else-if="isCustomView">
			<network-entity
				v-if="isNetwork"
				icon-size="large"
				:network="cellValue"/>

			<campaign-entity
				v-else-if="isCampaign"
				:name="entity.Name"
				:color="entity.Color"/>

			<message-entity
				v-else-if="isMessage"
				:message="entity.ChildMessage ? entity.ChildMessage : entity.Message"
				:network="entity.Network"
				class="common-table-cell__message"/>

			<lobster-post-entity
				v-else-if="isPost"
				:message="postEntityOptions.message"
				:network="postEntityOptions.network"
				:display-name="postEntityOptions.displayName"
				:thumbnail="postEntityOptions.thumbnail"
				:message-type="postEntityOptions.messageType"
				:image-url="postEntityOptions.imageUrl"
				:media="postEntityOptions.media"
				:video-preview="postEntityOptions.videoPreview"
				:hide-thumbnail="postEntityOptions.hideThumbnail"
				:user-name="postEntityOptions.userName"
				:user-url="postEntityOptions.userUrl"
				:show-username="postEntityOptions.showUsername"
				class="common-table-cell__post"/>

			<lobster-profile-entity
				v-else-if="isProfile"
				:network="profileEntityOptions.network"
				:name="profileEntityOptions.displayName"
				:subtitle="profileEntityOptions.subtitle"
				:thumbnail="profileEntityOptions.thumbnail"
				:gravatar="profileEntityOptions.gravatar"/>

			<ads-entity
				v-else-if="cellName === 'AdCreativeID'"
				:content="entity.Content"
				:status="entity.Status"/>

			<lead-status-entity
				v-else-if="cellName === 'LeadStatus'"
				:status="cellValue"/>

			<lead-account-entity
				v-else-if="cellName === 'LeadCompany'"
				:name="cellValue"
				:is-target-account="+cells.IsTargetAccount.value === 1"/>

			<stage-entity
				v-else-if="cellName === 'OpportunityStageName'"
				:stage-name="cellLabel"
				:stage-type="cells.OpportunityStageType.value"/>

			<template v-else>
				{{ cellLabel }}
			</template>
		</template>

		<link-entity
			v-else-if="isLink"
			:cell-value="cellValue"
			:cell-name="cellName"
			:cell-label="cellLabel"
			@click.stop/>

		<template v-else>
			{{ cellLabel }}
		</template>
	</lobster-table-cell>
</template>

<style scoped lang="scss">
.common-table-cell {
	white-space: nowrap;

	&.m_link {
		min-width: 250px;
	}
}

.common-table-cell__message,
.common-table-cell__post {
	min-width: 180px;
}
</style>