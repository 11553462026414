var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-form",
    {
      staticClass: "source-form",
      attrs: {
        "modal-view": "",
        "submit-button-text": "Next",
        "submit-disabled": !_vm.$is(_vm.currentSubjectType),
      },
      on: { submit: _vm.handleSubmit },
    },
    [
      _c("p", { staticClass: "no-margin" }, [
        _vm._v("\n\t\tSelect the source you want to use for the report.\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "lobster-entities-container",
        [
          _vm._l(_vm.ReportDataSourcesConfig, function (item, index) {
            return [
              _c("iconed-tickbox", {
                key: index,
                attrs: {
                  card: "",
                  violet: "",
                  name: "subjectType",
                  type: "radio",
                  val: index,
                  title: item.name,
                  disabled: item.disabled,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("lobster-icon", {
                            staticClass: "report-source-icon",
                            attrs: { name: item.iconName },
                          }),
                          _vm._v("\n\t\t\t\t\t" + _vm._s(index) + "\n\t\t\t\t"),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.currentSubjectType,
                  callback: function ($$v) {
                    _vm.currentSubjectType = $$v
                  },
                  expression: "currentSubjectType",
                },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }