<template>
	<lobster-form
		class="report-form"
		native-validate
		modal-view
		@submit="handleSubmit">
		<div class="report-form__container">
			<report-form-sidebar
				class="report-form__sidebar"
				with-visibility
				:filter.sync="filter"
				:chart-type.sync="chartType"
				:subject-type="subjectType"
				:groups.sync="groups"
				:metrics.sync="metrics"
				:additional-columns.sync="additionalColumns"
				:limit.sync="limit"
				:order-by.sync="orderBy"
				:metadata.sync="metadata"
				:visibility.sync="visibility"/>

			<report-form-chart
				class="report-form__chart"
				:name.sync="name"
				:description.sync="description"
				:config="currentConfig"
				:chart-type="chartType"
				:subject-type="subjectType"
				:metrics="metrics"
				:groups="groups"
				:additional-columns="additionalColumns"
				:limit="limit"
				:order-by="orderBy"
				:metadata="metadata"
				:labels="labels"
				:filter="filter"
				:date-period="datePeriod"
				@edit-metric="handleEditMetric"/>
		</div>

		<template #actions>
			<lobster-button
				v-if="withBack"
				secondary
				@click="handleBackClick">
				Back
			</lobster-button>
			<lobster-button
				class="report-form__submit-button"
				type="submit"
				:loading="isSubmitting"
				:disabled="!canSave">
				{{ isEdit ? 'Save' : 'Add' }}
			</lobster-button>
		</template>
	</lobster-form>
</template>

<script>
import { is } from 'plankton-esm';
import { merge_configs } from 'kraken-charts';
import ReportFormChart from './ReportFormChart.vue';
import ReportFormSidebar from './ReportFormSidebar.vue';
import { DatePeriodType } from '../../../consts/DatePeriodType';
import { ChartTypesConfig } from '../../../configs/ChartTypesConfig';
import { ChartType } from '@analytics';


export default {
	name: 'ReportForm',
	components: { ReportFormSidebar, ReportFormChart },
	props: {
		isEdit: Boolean,
		subjectType: String,
		initialSettings: {
			type: Object,
			default: () => ({})
		},
		config: Object
	},
	data()
	{
		let name = this.initialSettings.name ?? 'New Report';
		let y = this.initialSettings.y;
		let height = this.initialSettings.height;

		return {
			isSubmitting: false,
			chartType: this.initialSettings.chartType ?? ChartType.AREA_STACKED,
			metrics: this.initialSettings.metrics ?? [],
			groups: this.initialSettings.groups ?? [],
			additionalColumns: this.initialSettings.additionalColumns ?? [],
			limit: this.initialSettings.limit ?? null,
			orderBy: this.initialSettings.orderBy ?? [],
			filter: this.initialSettings.filter ?? { dimensions: [], tags: [], metrics: [] },
			metadata: this.initialSettings.metadata || {},
			labels: this.initialSettings.labels ?? {},

			defaultName: 'New Report',
			name,
			description: this.initialSettings.description ?? '',
			visibility: this.initialSettings.visibility ?? 'shared',

			dashboardId: this.initialSettings.dashboardId,
			widgetId: this.initialSettings.id,
			datePeriod: this.initialSettings.datePeriod ?? { type: DatePeriodType.LAST_30_DAYS },

			x: this.initialSettings.x,
			y,
			width: this.initialSettings.width,
			height,
		}
	},
	computed: {
		groupId()
		{
			return this.$route.params?.id;
		},
		withBack()
		{
			return !this.isEdit;
		},
		canSave()
		{
			let chartConfig = ChartTypesConfig[this.chartType];

			if (!is(chartConfig))
			{
				return false;
			}

			if (chartConfig.minMetrics > 0 && (!is.array(this.metrics) || this.metrics.length < chartConfig.minMetrics))
			{
				return false;
			}

			if (chartConfig.minGroups > 0 && (!is.array(this.groups) || this.groups.length < chartConfig.minGroups))
			{
				return false;
			}

			return true;
		},
		currentConfig()
		{
			return merge_configs(this.config, this.$krakenConfig);
		}
	},
	methods: {
		getHeight()
		{
			if (is.defined(this.height))
			{
				return this.height;
			}
			else
			{
				return this.chartType === ChartType.TOTAL_COMPARE ? 210 : 840;
			}
		},
		getWidth()
		{
			if (is.defined(this.width))
			{
				return this.width;
			}
			else
			{
				return this.chartType === ChartType.TOTAL_COMPARE ? 210 : 420;
			}
		},
		async handleSubmit()
		{
			this.isSubmitting = true;

			let params = {
				name: this.name || this.defaultName,
				description: this.description,
				type: 'report',
				source: this.subjectType,
				chartType: this.chartType,
				metrics: this.metrics,
				groups: this.groups,
				labels: this.labels,
				additionalColumns: this.additionalColumns,
				filter: this.filter,
				orderBy: this.orderBy,
				limit: this.limit,
				metadata: this.metadata,
				visibility: this.visibility
			}

			if (is(this.groupId))
			{
				params.x = this.x || 0;
				params.y = this.y || 0;
				params.height = this.getHeight();
				params.width = this.getWidth();
			}

			if (this.isEdit)
			{
				try
				{
					await this.$store.dispatch('reportGallery/updateReport', { reportId: this.initialSettings.id, reportData: params});

					this.$emit('submit');
				}
				finally
				{
					this.isSubmitting = false;
				}
			}
			else
			{
				if (this.name === this.defaultName)
				{
					let confirm = await this.$dialog('Are you sure you want to save this report without naming it?');

					if (!confirm)
					{
						this.isSubmitting = false;

						return;
					}
				}

				try
				{
					await this.$store.dispatch('reportGallery/createReport', { groupId: this.groupId, reportData: params});

					this.$emit('submit');
				}
				finally
				{
					this.isSubmitting = false;
				}
			}
		},
		handleBackClick()
		{
			this.$emit('back');
		},
		handleEditMetric(metric, metricText)
		{
			this.labels = {
				...this.labels,
				[metric]: metricText
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.report-form {
	min-width: 100%;
}

.report-form__container {
	display: flex;
	flex-grow: 1;
	border-bottom: 1px solid $colorBorder;
	margin: -16px;
	overflow: hidden;
}
.report-form__sidebar {
	width: 298px;
	flex-shrink: 0;

	border-right: 1px solid $colorBorder;
}

.report-form__chart {
	flex-grow: 1;
}
.report-form__submit-button {
	margin-left: auto;
}
</style>