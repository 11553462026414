<script>
import { is } from 'plankton-esm';
import FormatTextMenu from './FormatTextMenu.vue';
import FormatLinkMenu from './FormatLinkMenu.vue';
import { TextEditorConfig } from '../../../configs/TextEditorConfig';


export default {
	name: 'TextEditorToolbar',
	components: { FormatLinkMenu, FormatTextMenu },
	props: {
		quill: Object,
		maxTextLength: {
			type: Number,
			default: 10000
		},
		textLength: {
			type: Number,
			default: 0
		},
	},
	data()
	{
		return {
			isEmojiPickerOpened: false,
			activeFormat: {},
			warningTextLengthCounter: TextEditorConfig.warningTextLengthCounter
		}
	},
	computed: {
		textLengthCounter()
		{
			return this.maxTextLength - this.textLength;
		},

	},
	methods: {
		formatTextLengthCounter(counter)
		{
			return new Intl.NumberFormat().format(counter);
		},
		handleEmojiButtonClick()
		{
			this.quill.focus();
		},
		handleEmojiSelected(emoji)
		{
			this.quill.focus();

			let selection = this.quill.getSelection();

			this.quill.insertText(selection.index, emoji.native, 'user');
			this.quill.setSelection(selection.index + emoji.native.length);
		},
		handleFormatButtonClick(key, value)
		{
			let format = this.quill.getFormat();

			if (is.defined(value))
			{
				let valueToSet = (format[key] !== value) ? value : false;

				this.quill.format(key, valueToSet);
			}
			else
			{
				this.quill.format(key, !is(format[key]));
			}
		},
		handleTextChange()
		{
			this.activeFormat = this.quill.getFormat();
		},
		handleSelectionChange(range)
		{
			this.activeFormat = is(range) ? this.quill.getFormat() : {};
		},
	},
	mounted()
	{
		this.quill.on('selection-change', this.handleSelectionChange);
		this.quill.on('text-change', this.handleTextChange);
	}
}
</script>

<template>
	<lobster-entities-container
		size="small"
		center
		no-wrap>

		<format-text-menu
			:quill="quill"
			:active-format="activeFormat"
			@format-button-click="handleFormatButtonClick"/>

		<lobster-action-button
			icon-name="text--bold"
			:selected="activeFormat.bold"
			@click="handleFormatButtonClick('bold')"/>

		<lobster-action-button
			icon-name="text--italic"
			:selected="activeFormat.italic"
			@click="handleFormatButtonClick('italic')"/>

		<lobster-action-button
			icon-name="text--strikethrough"
			:selected="activeFormat.strike"
			@click="handleFormatButtonClick('strike')"/>

		<lobster-action-button
			icon-name="list--numbered"
			:selected="activeFormat.list === 'ordered'"
			@click="handleFormatButtonClick('list', 'ordered')"/>

		<lobster-action-button
			icon-name="list--bulleted"
			:selected="activeFormat.list === 'bullet'"
			@click="handleFormatButtonClick('list', 'bullet')"/>

		<format-link-menu
			:quill="quill"
			:active-format="activeFormat"/>

		<emoji-mart-picker
			:open.sync="isEmojiPickerOpened"
			@emoji="handleEmojiSelected">
			<lobster-action-button
				icon-name="face--satisfied"
				:selected="isEmojiPickerOpened"
				@click="handleEmojiButtonClick"/>
		</emoji-mart-picker>

		<div
			class="text-editor-toolbar__counter"
			:class="{
					'description-text': textLengthCounter > warningTextLengthCounter,
					'error-text': textLengthCounter < 0,
					'warning-text': (textLengthCounter > 0) && (textLengthCounter <= warningTextLengthCounter)
				}">
			{{ formatTextLengthCounter(textLengthCounter) }}
		</div>
	</lobster-entities-container>
</template>

<style scoped lang="scss">
.text-editor-toolbar__counter {
	margin-left: auto;
}
</style>