import { ReportDataSourcesConfig } from '../configs/ReportDataSourcesConfig';
import { getSourceDimensions } from './ChartHelper';


/**
 * @param {array} filters1
 * @param {array} filters2
 * @return{array}
 */
export function mergeDimensionFilters(filters1, filters2)
{
	let columns1 = new Set(filters1.map(filter => filter.column));
	let columns2 = new Set(filters2.map(filter => filter.column));
	let columns = new Set([...columns1, ...columns2]);
	let filters = [];
	
	for (let column of columns)
	{
		if (columns1.has(column))
		{
			filters.push(...filters1.filter(filter => filter.column === column));
		}
		else
		{
			filters.push(...filters2.filter(filter => filter.column === column));
		}
	}
	
	return filters;
}

export function getDashboardReportFilter(subjectType, dashboardFilter, reportFilter)
{
	let hasCustomFields = ReportDataSourcesConfig[subjectType].hasCustomFields;
	let sourceDimensions = getSourceDimensions(subjectType);

	// additional temp fix for listening
	if (subjectType === 'socialListening')
	{
		if (!sourceDimensions.includes('Keywords'))
		{
			sourceDimensions.push('Keywords');
		}
	}

	let dashboardDimensionsFilters = dashboardFilter.dimensions
		?.filter(item => sourceDimensions.includes(item.column)) || [];
	let dashboardTagsFilters = dashboardFilter.tags
		?.filter(item => sourceDimensions.includes(item.column) || (hasCustomFields && item.column === 'CustomFields')) || [];

	return {
		metrics: reportFilter.metrics,
		dimensions: mergeDimensionFilters(dashboardDimensionsFilters, reportFilter.dimensions),
		tags: mergeDimensionFilters(dashboardTagsFilters, reportFilter.tags),
	}
}
