var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-menu",
    {
      attrs: { open: _vm.isOpened },
      on: {
        "update:open": function ($event) {
          _vm.isOpened = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "popover",
          fn: function () {
            return [
              _c(
                "lobster-menu-list",
                [
                  _c(
                    "lobster-menu-item",
                    {
                      directives: [
                        { name: "close-popover", rawName: "v-close-popover" },
                      ],
                      staticClass: "format-text-menu__item",
                      attrs: { selected: _vm.activeFormat.header === 1 },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("format-button-click", "header", 1)
                        },
                      },
                    },
                    [
                      _c("h1", { staticClass: "no-margin" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("commonUtils.textEditor.toolbar.heading")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "lobster-menu-item",
                    {
                      directives: [
                        { name: "close-popover", rawName: "v-close-popover" },
                      ],
                      staticClass: "format-text-menu__item",
                      attrs: { selected: _vm.activeFormat.header === 2 },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("format-button-click", "header", 2)
                        },
                      },
                    },
                    [
                      _c("h2", { staticClass: "no-margin" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("commonUtils.textEditor.toolbar.subheading")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "lobster-menu-item",
                    {
                      directives: [
                        { name: "close-popover", rawName: "v-close-popover" },
                      ],
                      staticClass: "format-text-menu__item",
                      attrs: { selected: !_vm.$is(_vm.activeFormat.header) },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "format-button-click",
                            "header",
                            false
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.$t("commonUtils.textEditor.toolbar.normal")
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "lobster-abstract-button",
        {
          staticClass: "format-text-menu__trigger-button",
          class: { m_active: _vm.isOpened },
          on: { click: _vm.handleTriggerButtonClick },
        },
        [
          _c(
            "span",
            { staticClass: "format-text-menu__trigger-button-text" },
            [
              _vm.activeFormat.header === 1
                ? [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.$t("commonUtils.textEditor.toolbar.heading")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                : _vm.activeFormat.header === 2
                ? [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.$t("commonUtils.textEditor.toolbar.subheading")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                : [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.$t("commonUtils.textEditor.toolbar.normal")
                        ) +
                        "\n\t\t\t"
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("lobster-icon", {
            attrs: { name: "chevron--down", size: "small--s-s" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }