<template>
	<lobster-layout-body class="dashboard-section">
		<div class="dashboard-section__header">
			<h2>{{ dashboard.name }}</h2>
			<h3>{{ dashboard.description }}</h3>
		</div>
		<lobster-layout-action-bar>
			<date-period-picker
				v-if="showDatePeriod"
				v-model="datePeriod"/>
			<analytics-filter
				v-model="filters"
				:reports-subject-types="reportsSubjectTypes"/>
			<template #pagination>
				<lobster-button
					secondary
					:disabled="!isDashboardChanged && !isFiltersChanged && !isDatePeriodChanged"
					@click="handleSaveClick">
					Save
				</lobster-button>
				<lobster-button
					secondary
					@click="handleAddClick">
					Add Report
				</lobster-button>
			</template>
		</lobster-layout-action-bar>
		<lobster-layout-section 
			class="dashboard-section__main-section"
			:loading="isLoading">
			<kraken-dashboard
				:row-height="200"
				:widgets="dashboard.widgets"
				@widgets-changed="handleWidgetsChanged">
				<template #widget="{widget}">
					<dashboard-widget
						:date-period="datePeriod"
						:widget="widget"
						:dashboard-filters="filters"
					/>
				</template>
			</kraken-dashboard>
		</lobster-layout-section>
	</lobster-layout-body>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WidgetModal from '../WidgetModal/WidgetModal';
import DashboardWidget from './DashboardWidget';
import { FunctionHelpers } from 'lobster-ui';
import AnalyticsFilter from '../Filter/AnalyticsFilter';
import DatePeriodPicker from '../DatePeriodPicker/DatePeriodPicker';


export default {
	name: 'DashboardSection',
	components: {DatePeriodPicker, AnalyticsFilter, DashboardWidget, WidgetModal},
	props: {
		id: {
			type: String,
			required: true
		}
	},
	data()
	{
		return {
			showDatePeriod: false,
			isLoading: false
		}
	},
	computed: {
		...mapState({
			dashboard: state => state.analytics.currentDashboard,
			isDashboardChanged: state => state.analytics.isCurrentDashboardChanged
		}),
		reportsSubjectTypes()
		{
			if (!this.$is(this.dashboard?.widgets))
			{
				return [];
			}
			
			let subjectTypes = new Set();
			
			for (let widget of this.dashboard.widgets)
			{
				subjectTypes.add(widget.subjectType);
			}
			
			return [...subjectTypes];
		},
		filters: {
			get()
			{
				if (this.$is(this.$route.query.filters))
				{
					return JSON.parse(this.$route.query.filters);
				}
				else
				{
					return this.dashboard.filters;
				}
			},
			set(val)
			{
				this.$router.push({query: {filters: JSON.stringify(val)}});
			}
		},
		datePeriod: {
			get()
			{
				let query = this.$route.query;
				
				if (this.$is(query.type) || (this.$is(query.from) && this.$is(query.to)))
				{
					return {
						type: query.type,
						from: query.from,
						to: query.to
					}
				}
				else
				{
					return this.dashboard.datePeriod;
				}
			},
			set(val)
			{
				this.$router.push({
					query: {
						...this.$route.query,
						to: val.to,
						from: val.from,
						type: val.type
					}
				});
			}
		},
		isFiltersChanged()
		{
			return !FunctionHelpers.isEqual(this.filters, this.dashboard.filters);
		},
		isDatePeriodChanged()
		{
			return !FunctionHelpers.isEqual(this.datePeriod, this.dashboard.datePeriod);
		}
	},
	methods: {
		...mapActions({
			loadDashboard: 'analytics/loadDashboard',
			updateCurrentWidgets: 'analytics/updateCurrentWidgets',
			saveDashboard: 'analytics/saveCurrentDashboard'
		}),
		async load()
		{
			this.isLoading = true;
			
			try
			{
				await this.loadDashboard({id: this.id});
			}
			finally
			{
				this.isLoading = false;
			}
		},
		handleAddClick()
		{
			this.$showModal({
				component: WidgetModal,
				bind: {
					datePeriod: this.dashboard.datePeriod,
					dashboardId: this.id
				}
			})
		},
		async handleSaveClick()
		{
			let params = {};
			
			if (this.isFiltersChanged)
			{
				params.filters = this.filters;
			}
			
			if (this.isDatePeriodChanged)
			{
				params.datePeriod = this.datePeriod;
			}
			
			await this.saveDashboard(params);

			this.$router.push({query: {}})
		},
		handleWidgetsChanged(widgets)
		{
			if (FunctionHelpers.isEqual(this.dashboard.widgets, widgets))
			{
				return;
			}
			
			this.updateCurrentWidgets(widgets);
		}
	},
	created()
	{
		this.load();
	}
}
</script>

<style lang="scss" scoped>
.dashboard-section__header {
	padding: 0 24px;
}

.dashboard-section__main-section {
	flex-grow: 1;

	&::v-deep .lobster-layout-loading {
		background-color: transparentize($colorBgLight, 0.2);
	}
}

.dashboard-section {
	background-color: $colorBgLight;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 24px;
}

</style>