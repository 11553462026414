<template>
	<lobster-status-icon
		:icon-name="getLeadStatusIcon(status)"
		:color="getLeadStatusColor(status)"
		:text="statusText"
		expanded/>
</template>

<script>
export default {
	name: 'LeadStatusEntity',
	props: {
		status: String
	},
	computed: {
		statusText()
		{
			return this.$t('commonUtils.status.')[this.status] ?? this.status;
		}
	},
	methods: {
		getLeadStatusIcon(status)
		{
			switch (status)
			{
				case 'synced':
					return 'user--admin';
				case 'saved':
					return 'bookmark';
				case 'new':
					return 'user--avatar';
				case 'ignored':
					return 'view--off--filled';
				default:
					return null;
			}
		},
		getLeadStatusColor(status)
		{
			switch (status)
			{
				case 'synced':
					return 'success';
				case 'saved':
					return 'warning';
				case 'new':
					return 'primary';
				default:
					return null;
			}
		}

	}
}
</script>