export const ACTIVITY_TYPE = [
	{value: 'click', 			label: 'Click'},
	{value: 'conversion', 		label: 'Conversion'},
	{value: 'comment', 			label: 'Comment'},
	{value: 'reaction', 		label: 'Reaction'},
	{value: 'follow', 			label: 'Follow'},
	{value: 'unfollow', 		label: 'Unfollow'},
	{value: 'reply', 			label: 'Reply'},
	{value: 'direct_message', 	label: 'Direct Message'},
	{value: 'mention', 			label: 'Mention'},
	{value: 'retweet', 			label: 'Repost'},
	{value: 'quote', 			label: 'Quote'},
	{value: 'listed', 			label: 'Added to List'},
	{value: 'unlisted', 		label: 'Removed from List'},
	{value: 'lead_form_fill', 	label: 'Lead Ad Form Fill'}
];