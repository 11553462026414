<template>
	<lobster-entities-container column>
		<hr class="privacy-select__stroke">
		<lobster-entities-container
			center
			size="large">
			<lobster-checkbox
				inline
				type="radio"
				val="private"
				name="visibility"
				v-model="currentValue">
				<template #label>
				<span class="privacy-select__label-container">
					<lobster-icon name="view--off"/>
					Private
					<lobster-icon
						name="information"
						v-tooltip="'This report will show only on your list'"/>
				</span>
				</template>
			</lobster-checkbox>
			<lobster-checkbox
				inline
				type="radio"
				val="shared"
				name="visibility"
				v-model="currentValue">
				<template #label>
				<span class="privacy-select__label-container">
					<lobster-icon name="view"/>
					Public
					<lobster-icon
						name="information"
						v-tooltip="'This report will be visible for all project members'"/>
				</span>
				</template>
			</lobster-checkbox>
		</lobster-entities-container>
	</lobster-entities-container>
</template>

<script>
export default {
	name: 'PrivacySelect',
	props: {
		value: String
	},
	data()
	{
		return {}
	},
	computed: {
		currentValue: {
			get()
			{
				return this.value;
			},
			set(newVal)
			{
				this.$emit('input', newVal);
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.privacy-select__stroke {
	width: 100%;
}
.privacy-select__label-container {
	display: flex;
	align-items: center;
	gap: 4px;
}
</style>