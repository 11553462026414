var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-section",
    { attrs: { loading: _vm.isGalleryListLoading } },
    [
      _c(
        "lobster-table",
        { attrs: { "full-screen": "" } },
        [
          _c(
            "lobster-table-row",
            { attrs: { header: "" } },
            [
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\tName\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", { attrs: { tr: "" } }, [
                _vm._v("\n\t\t\t\tDescription\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\tPrivacy\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", [
                _vm._v("\n\t\t\t\tCreated On\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("lobster-table-column-title", { attrs: { small: "" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.currentGalleryList, function (gallery) {
            return _c(
              "lobster-table-row",
              {
                key: gallery.Id,
                attrs: { clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.handleRowClick(gallery)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm.canUpdate
                      ? {
                          key: "actions-cell",
                          fn: function () {
                            return [
                              _c("lobster-action-button", {
                                attrs: { "icon-name": "edit" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleEditClick(gallery)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("lobster-action-button", {
                                attrs: { deny: "", "icon-name": "trash-can" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDeleteClick(gallery)
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "lobster-table-cell",
                  { attrs: { truncate: "", tooltip: gallery.name } },
                  [
                    _c(
                      "lobster-button",
                      {
                        attrs: {
                          "inline-link": "",
                          to: {
                            name: "gallery",
                            params: {
                              id: gallery.id,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "lobster-search-highlight",
                          { attrs: { queries: _vm.search } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(gallery.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "lobster-table-cell",
                  { attrs: { truncate: "", tooltip: gallery.description } },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(gallery.description) + "\n\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "lobster-table-cell",
                  { staticClass: "gallery-table__privacy-column" },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(gallery.visibility) + "\n\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("lobster-table-cell", [
                  _vm._v("\n\t\t\t\t" + _vm._s(gallery.created) + "\n\t\t\t"),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }