<script>
import reportFailedState from '../assets/images/report-failed-state.svg';


export default {
	name: 'CommonReportNullstate',
	props: {
		isFailedState: Boolean,
		withBackground: {
			type: Boolean,
			default: false
		},
	},
	data()
	{
		return {
			reportFailedState
		}
	}
}
</script>

<template>
	<lobster-entities-container
		column
		no-wrap
		center
		class="common-report-nullstate"
		:class="{ 'm_with-background': withBackground }">
		<div class="common-report-nullstate__image">
			<slot name="image">
				<img
					v-if="isFailedState"
					:src="reportFailedState"
					alt="Failed state image"/>
				<lobster-icon
					v-else
					name="information"/>
			</slot>
		</div>

		<lobster-entities-container
			column
			no-wrap
			center
			size="small--s"
			class="common-report-nullstate__description">

			<slot>
				<h2 v-if="isFailedState">
					{{ $t('commonAnalytics.report.failedState') }}
				</h2>
			</slot>
		</lobster-entities-container>
	</lobster-entities-container>
</template>

<style scoped lang="scss">
.common-report-nullstate {
	height: 100%;
	width: 100%;
	justify-content: center;
	padding: var(--spacing-M);

	&.m_with-background {
		border-radius: var(--borderRadiusSecondary);
		background-color: var(--colorBgLight);
		padding: 0;
	}
}

.common-report-nullstate__image {
	min-height: 0;
	max-width: 50%;

	&:deep {
		img {
			max-width: 50%;
			margin: auto;
		}
	}
}

.common-report-nullstate__description {
	max-width: 50%;
	margin: 0;
	text-align: center;
}
</style>