import moment from 'moment';
import { BASE_NETWORKS, NETWORKS } from '../consts/Networks';
import { networkNameFormatter } from '@utils';
import {
	INDUSTRY_OPTIONS
} from '@analytics';
import { ACTIVITY_TYPE } from '../consts/ActivityType';
import { AD_CAMPAIGN_STATUS } from '../consts/AdCampaignStatus';
import { AD_GROUP_STATUS } from '../consts/AdGroupStatus';
import { COMPANY_SIZES, LEAD_COMPANY_SIZES } from '../consts/CompanySizes';
import { POST_TYPE } from '../consts/PostType';
import { PAGE_TYPES } from '../consts/PageTypes';
import { LEAD_STATUS } from '../consts/LeadStatus';
import { LANGUAGE_CODE } from '../consts/LanguageCode';
import { COUNTRY_CODE } from '../consts/CountryCode';
import { TIME_TO_FIRST_REPLY } from '../consts/TimeToFirstReply';
import { LISTENING_CHANNELS } from '../consts/ListeningChannels';


export function getNetworkOptions(networks = NETWORKS)
{
	return [ ...networks ].map(network => ({
			value: network,
			label: networkNameFormatter(network)
		}));
}

export function getMonthOptions()
{
	let months = moment.months();

	return months.map((value, index) => ({
		label: value,
		value: index + 1
	}));
}

export function getDayHourOptions()
{
	let dayHours = [];

	for (let i = 0; i <= 23; i++)
	{
		dayHours.push(moment.utc(i, 'HH:mm').format('h a'));
	}

	return dayHours.map((value, index) => ({
		label: value,
		value: index
	}));
}

export function getWeekDayOptions()
{
	return moment.weekdays(true).map((value, index) => ({
		label: value,
		value: index
	}));
}

export function getFunctionOptions()
{
	return [...Array(26).keys()].map(value => ({
		value: (value + 1).toString(),
		label: I18n.t('commonAnalytics.function')[value + 1] ?? (value + 1)
	}));
}

export function getSeniorityOptions()
{
	return [...Array(10).keys()].map(value => ({
		value: (value + 1).toString(),
		label: I18n.t('commonAnalytics.linkedInTargeting.seniorities')[value + 1] ?? (value + 1)
	}));
}

export function getPlatformOptions()
{
	return [
		...getNetworkOptions(BASE_NETWORKS),
		{
			label: 'Website',
			value: 'Website'
		}]
}

export function getListeningChannelOptions()
{
	return LISTENING_CHANNELS.map(item => ({
		value: item,
		label: networkNameFormatter(item)
	}))
}

export function getActivityOptions()
{
	return ACTIVITY_TYPE.map(value => ({
		value,
		label: I18n.t('commonAnalytics.activityType}')[value] ?? value
	}));
}

export function getAdCampaignStatusOptions()
{
	return AD_CAMPAIGN_STATUS.map(value => ({
		value,
		label: I18n.t('commonAnalytics.status}')[value] ?? value
	}));
}

export function getAdGroupStatusOptions()
{
	return AD_GROUP_STATUS.map(value => ({
		value,
		label: I18n.t('commonAnalytics.status}')[value] ?? value
	}));
}

export function getLeadCompanySizeOptions()
{
	return LEAD_COMPANY_SIZES.map(value => ({
		value,
		label: value
	}));
}

export function getCompanySizeOptions()
{
	COMPANY_SIZES.map(value => ({
		value,
		label: I18n.t('commonAnalytics.linkedInTargeting.companySizes')[value] ?? value
	}));
}

export function getPostTypeOptions()
{
	return POST_TYPE.map((value, index) => ({
		value,
		label: value[0].toUpperCase() + value.slice(1)
	}));
}

export function getIndustryOptions()
{
	return Object.values(INDUSTRY_OPTIONS);
}

export function getPageTypeOptions()
{
	return PAGE_TYPES.map(value => ({
		label: I18n.t('commonAnalytics.pageType')[value] ?? value,
		value
	}));
}

export function getLeadStatusOptions()
{
	return LEAD_STATUS.map(value => ({
		value,
		label: I18n.t(`commonUtils.status.${value}`)
	}));
}

export function getLanguageOptions()
{
	return [{
		value: 'und',
		label: I18n.t(`languageCodes.und.name`)
	}, ...Object.values(LANGUAGE_CODE).map(value => ({
		value,
		label: I18n.t(`languageCodes.${value}.name`)
	})).sort((a, b) => a.label > b.label ? 1: -1)];
}

export function getCountryOptions()
{
	return Object.values(COUNTRY_CODE).map(value => ({
		value,
		label: I18n.t(`commonUtils.countries.${value}`)
	})).sort((a, b) => a.label > b.label ? 1: -1);
}

export function getListeningCountryOptions()
{
	return [			{
		value: '',
		label: I18n.t(`commonUtils.countries.UND`)
	}, ...Object.values(COUNTRY_CODE).map(value => ({
		value,
		label: I18n.t(`commonUtils.countries.${value}`)
	})).sort((a, b) => a.label > b.label ? 1: -1)];
}

export function getTimeToFirstReplyOptions()
{
	return TIME_TO_FIRST_REPLY.map(value => ({
		value,
		label: I18n.t('commonAnalytics.timeFilter')[value] ?? value
	}));
}
