var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-body",
    { staticClass: "dashboard-section" },
    [
      _c("div", { staticClass: "dashboard-section__header" }, [
        _c("h2", [_vm._v(_vm._s(_vm.dashboard.name))]),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.dashboard.description))]),
      ]),
      _vm._v(" "),
      _c(
        "lobster-layout-action-bar",
        {
          scopedSlots: _vm._u([
            {
              key: "pagination",
              fn: function () {
                return [
                  _c(
                    "lobster-button",
                    {
                      attrs: {
                        secondary: "",
                        disabled:
                          !_vm.isDashboardChanged &&
                          !_vm.isFiltersChanged &&
                          !_vm.isDatePeriodChanged,
                      },
                      on: { click: _vm.handleSaveClick },
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "lobster-button",
                    {
                      attrs: { secondary: "" },
                      on: { click: _vm.handleAddClick },
                    },
                    [_vm._v("\n\t\t\t\tAdd Report\n\t\t\t")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.showDatePeriod
            ? _c("date-period-picker", {
                model: {
                  value: _vm.datePeriod,
                  callback: function ($$v) {
                    _vm.datePeriod = $$v
                  },
                  expression: "datePeriod",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("analytics-filter", {
            attrs: { "reports-subject-types": _vm.reportsSubjectTypes },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "lobster-layout-section",
        {
          staticClass: "dashboard-section__main-section",
          attrs: { loading: _vm.isLoading },
        },
        [
          _c("kraken-dashboard", {
            attrs: { "row-height": 200, widgets: _vm.dashboard.widgets },
            on: { "widgets-changed": _vm.handleWidgetsChanged },
            scopedSlots: _vm._u([
              {
                key: "widget",
                fn: function ({ widget }) {
                  return [
                    _c("dashboard-widget", {
                      attrs: {
                        "date-period": _vm.datePeriod,
                        widget: widget,
                        "dashboard-filters": _vm.filters,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }