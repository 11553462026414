<template>
	<span
		class="stage-entity caption-text"
		:class="{
			'm_open': stageType === 'Open',
			'm_lost': stageType === 'Lost',
			'm_won': stageType === 'Won'
		}">
		{{stageName}}
	</span>
</template>


<script>
export default {
	name: "StageEntity",
	props: ['stageType', 'stageName']
}
</script>


<style scoped lang="scss">
.stage-entity {
	color: $colorLight;
	background-color: $colorPrimary;
	text-align: left;
	font-weight: 600;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 4px 16px;
	border-radius: 12px;
	align-items: center;

	&.m_open {
		background-color: $colorPrimary;
	}

	&.m_lost {
		background-color: $colorError;
	}

	&.m_won {
		background-color: $colorSuccess;
	}
}
</style>