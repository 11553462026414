var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { attrs: { size: "small", center: "", "no-wrap": "" } },
    [
      _c("format-text-menu", {
        attrs: { quill: _vm.quill, "active-format": _vm.activeFormat },
        on: { "format-button-click": _vm.handleFormatButtonClick },
      }),
      _vm._v(" "),
      _c("lobster-action-button", {
        attrs: { "icon-name": "text--bold", selected: _vm.activeFormat.bold },
        on: {
          click: function ($event) {
            return _vm.handleFormatButtonClick("bold")
          },
        },
      }),
      _vm._v(" "),
      _c("lobster-action-button", {
        attrs: {
          "icon-name": "text--italic",
          selected: _vm.activeFormat.italic,
        },
        on: {
          click: function ($event) {
            return _vm.handleFormatButtonClick("italic")
          },
        },
      }),
      _vm._v(" "),
      _c("lobster-action-button", {
        attrs: {
          "icon-name": "text--strikethrough",
          selected: _vm.activeFormat.strike,
        },
        on: {
          click: function ($event) {
            return _vm.handleFormatButtonClick("strike")
          },
        },
      }),
      _vm._v(" "),
      _c("lobster-action-button", {
        attrs: {
          "icon-name": "list--numbered",
          selected: _vm.activeFormat.list === "ordered",
        },
        on: {
          click: function ($event) {
            return _vm.handleFormatButtonClick("list", "ordered")
          },
        },
      }),
      _vm._v(" "),
      _c("lobster-action-button", {
        attrs: {
          "icon-name": "list--bulleted",
          selected: _vm.activeFormat.list === "bullet",
        },
        on: {
          click: function ($event) {
            return _vm.handleFormatButtonClick("list", "bullet")
          },
        },
      }),
      _vm._v(" "),
      _c("format-link-menu", {
        attrs: { quill: _vm.quill, "active-format": _vm.activeFormat },
      }),
      _vm._v(" "),
      _c(
        "emoji-mart-picker",
        {
          attrs: { open: _vm.isEmojiPickerOpened },
          on: {
            "update:open": function ($event) {
              _vm.isEmojiPickerOpened = $event
            },
            emoji: _vm.handleEmojiSelected,
          },
        },
        [
          _c("lobster-action-button", {
            attrs: {
              "icon-name": "face--satisfied",
              selected: _vm.isEmojiPickerOpened,
            },
            on: { click: _vm.handleEmojiButtonClick },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "text-editor-toolbar__counter",
          class: {
            "description-text":
              _vm.textLengthCounter > _vm.warningTextLengthCounter,
            "error-text": _vm.textLengthCounter < 0,
            "warning-text":
              _vm.textLengthCounter > 0 &&
              _vm.textLengthCounter <= _vm.warningTextLengthCounter,
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.formatTextLengthCounter(_vm.textLengthCounter)) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }