var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "analytics-app inner" },
    [
      _c("router-view", { staticClass: "analytics-app__content" }),
      _vm._v(" "),
      _c("lobster-provider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }