<template>
	<v-popover
		placement="bottom-start"
		trigger="manual"
		:open="isDatepickerOpen"
		@auto-hide="isDatepickerOpen = false"
		@apply-hide="isDatepickerOpen = false">
		<date-select
			:value="value"
			:options="datePeriodOptions"
			@input="handleDateSelectInput"/>
		<template #popover>
			<date-picker
				v-if="isDatepickerOpen"
				:value="value"
				@input="handleDatePickerInput"
			/>
		</template>
	</v-popover>
</template>

<script>
import DateSelect from './DateSelect';
import DatePicker from './DatePicker';
import { DatePeriodTypes } from '../../../configs/DatePeriodTypes';


export default {
	name: "DatePeriodPicker",
	components: {DatePicker, DateSelect},
	props: {
		value: {
			type: Object,
			default()
			{
				return {}
			}
		}
,	},
	data()
	{
		return {
			isDatepickerOpen: false
		}
	},
	computed: {
		datePeriodOptions()
		{
			let options = [];
			
			for (let type in DatePeriodTypes)
			{
				options.push({
					value: type,
					label: DatePeriodTypes[type].name
				});
			}
			
			return options;
		}
	},
	methods: {
		handleDateSelectInput(value)
		{
			if (value === 'custom')
			{
				this.isDatepickerOpen = true;
			}
			else
			{
				this.$emit('input', {type: value});
			}
		},
		handleDatePickerInput(value)
		{
			this.isDatepickerOpen = false;
			
			this.$emit('input', value);
		}
	}
}
</script>

<style scoped>

</style>