var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-dynamic-modal",
    _vm._g(
      _vm._b(
        { attrs: { size: "medium", "modal-title": _vm.title } },
        "lobster-dynamic-modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "lobster-form",
        {
          attrs: {
            "modal-view": "",
            "submit-button-text": _vm.submitButtonText,
            "is-loading": _vm.isGallerySubmitting,
          },
          on: { submit: _vm.handleSubmitForm },
        },
        [
          _c("lobster-input", {
            attrs: { label: "Name", maxlength: "100", validators: "required" },
            model: {
              value: _vm.currentSettings.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.currentSettings,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "currentSettings.name",
            },
          }),
          _vm._v(" "),
          _c("lobster-textarea", {
            attrs: { label: "Description", maxlength: "200" },
            model: {
              value: _vm.currentSettings.description,
              callback: function ($$v) {
                _vm.$set(_vm.currentSettings, "description", $$v)
              },
              expression: "currentSettings.description",
            },
          }),
          _vm._v(" "),
          _c(
            "lobster-entities-container",
            { attrs: { "no-wrap": "", column: "", size: "small" } },
            [
              _c("lobster-checkbox", {
                attrs: {
                  type: "radio",
                  name: "visibility",
                  val: "unpublished",
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("lobster-icon", {
                          staticClass: "gallery-modal__private-icon",
                          attrs: { name: "view--off" },
                        }),
                        _vm._v(" "),
                        _c("b", [_vm._v("Not Published")]),
                        _vm._v(
                          "\n\t\t\t\t\t-  group won’t be available within the reports gallery.\n\t\t\t\t"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.currentSettings.visibility,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentSettings, "visibility", $$v)
                  },
                  expression: "currentSettings.visibility",
                },
              }),
              _vm._v(" "),
              _c("lobster-checkbox", {
                attrs: { type: "radio", name: "visibility", val: "published" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("lobster-icon", {
                          staticClass: "gallery-modal__private-icon",
                          attrs: { name: "view" },
                        }),
                        _vm._v(" "),
                        _c("b", [_vm._v("Published")]),
                        _vm._v(
                          "\n\t\t\t\t\t- group will be available to users within the reports gallery.\n\t\t\t\t"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.currentSettings.visibility,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentSettings, "visibility", $$v)
                  },
                  expression: "currentSettings.visibility",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }