export const ReportDataSourcesConfig = {
	post: {
		name: I18n.t(`commonAnalytics.subjectType.post.name`),
		description: I18n.t(`commonAnalytics.subjectType.post.description`),
		iconName: 'align-box--top-left',
		hasCustomFields: true,
		dimensions: [
			'CreatedDay',
			'CreatedWeekSunday',
			'CreatedWeek',
			'CreatedMonth',
			'CreatedQuarter',
			'CreatedFiscalQuarter',
			'CreatedYear',
			'CreatedFiscalYear',
			'BoardID',
			'CampaignID',
			'Channel',
			'ContentType',
			'CreatedDayHour',
			'CreatedWeekDay',
			'CreatedYearWeek',
			'CreatedYearMonth',
			'MessageID',
			'Network',
			'PostlogID',
			'ParentMessageID',
			'CredentialID',
			'Tags',
			'KeywordsAndHashtags',
			'TopicIDs',
			'UserID',
			'PostType',
			'SourceApplication',
			'CredentialTeamIDs',
			'LinkStripped',
			'MediaTags',
			'BoardRoleID',
			'SuggestedByUserID',
			'IsAdvocateCreated',
			'StoryID',
			'Thread'
		],
		metrics: [
			'IsMessageModified',
			'AdvocateEditRatio',
			'ClickThroughRate',
			'LinkClicks',
			'CommentsAdded',
			'Conversions',
			'EngagementAdded',
			'TotalEngagementRate',
			'ImpressionsAdded',
			'LikesAdded',
			'PlaysAdded',
			'ReachAdded',
			'SharesAdded',
			'IsPost',
			'DetailExpandsAdded',
			'MediaClicksAdded',
			'UserFollowsAdded',
			'VideoViewsAdded',
			'VideoViewersAdded',
			'VideoWatchtimeAdded',
			'VideoAverageWatchtimeAdded',
			'FullVideoWatchedRateAdded',
			'HashtagClicksAdded',
			'UserProfileClicksAdded',
			'UrlClicksAdded',
			'DislikesAdded',
			'SubscribersGainedAdded',
			'SubscribersLostAdded',
			'VideosAddedToPlaylistsAdded',
			'VideosRemovedFromPlaylistsAdded',
			'SavedAdded',
			'Replies',
			'AggregatedClicks'
		]
	},
	engagement: {
		name: I18n.t(`commonAnalytics.subjectType.engagement.name`),
		description: I18n.t(`commonAnalytics.subjectType.engagement.description`),
		iconName: 'thumbs-up',
		hasCustomFields: true,
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'BoardID',
			'CampaignID',
			'Channel',
			'ContentType',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'MessageID',
			'Network',
			'PostlogID',
			'ParentMessageID',
			'CredentialID',
			'Tags',
			'KeywordsAndHashtags',
			'TopicIDs',
			'UserID',
			'PostType',
			'SourceApplication',
			'CredentialTeamIDs',
			'LinkStripped',
			'MediaTags',
			'BoardRoleID',
			'SuggestedByUserID',
			'IsAdvocateCreated',
			'StoryID',
			'Thread'
		],
		metrics: [
			'IsMessageModified',
			'AdvocateEditRatio',
			'ClickThroughRate',
			'LinkClicks',
			'CommentsAdded',
			'Conversions',
			'EngagementAdded',
			'TotalEngagementRate',
			'ImpressionsAdded',
			'LikesAdded',
			'PlaysAdded',
			'ReachAdded',
			'SharesAdded',
			'IsPost',
			'DetailExpandsAdded',
			'MediaClicksAdded',
			'UserFollowsAdded',
			'VideoViewsAdded',
			'VideoViewersAdded',
			'VideoWatchtimeAdded',
			'VideoAverageWatchtimeAdded',
			'FullVideoWatchedRateAdded',
			'HashtagClicksAdded',
			'UserProfileClicksAdded',
			'UrlClicksAdded',
			'DislikesAdded',
			'SubscribersGainedAdded',
			'SubscribersLostAdded',
			'VideosAddedToPlaylistsAdded',
			'VideosRemovedFromPlaylistsAdded',
			'SavedAdded',
			'Replies',
			'AggregatedClicks'
		]
	},
	profile: {
		name: I18n.t(`commonAnalytics.subjectType.profile.name`),
		description: I18n.t(`commonAnalytics.subjectType.profile.description`),
		iconName: 'user--avatar',
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'Network',
			'CredentialID',
			'CredentialTeamIDs',
			'ProfileSource',
			'BoardIDs'
		],
		metrics: [
			'Followers',
			'FollowersAdded',
			'Mentions',
			'PageViews',
			'PageViewsUniqueAdded',
			'PageViewsMobileAdded',
			'PageViewsDesktopAdded'		
		]
	},
	clicks: {
		name: I18n.t(`commonAnalytics.subjectType.clicks.name`),
		description: I18n.t(`commonAnalytics.subjectType.clicks.description`),
		iconName: 'cursor--1',
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'BoardID',
			'CampaignID',
			'Channel',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'MessageID',
			'Network',
			'PostlogID',
			'CredentialID',
			'UserID',
			'PostSourceApp',
			'CredentialTeamIDs',
			'LinkStripped',
			'GeoCountry',
			'GeoCity',
			'GeoOrganization',
			'DeviceType',
			'DeviceBrowserName',
			'DeviceOSName'
		],
		metrics: [
			'IsClick'
		]
	},
	conversion: {
		name: I18n.t(`commonAnalytics.subjectType.conversion.name`),
		description: I18n.t(`commonAnalytics.subjectType.conversion.description`),
		iconName: 'money',
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'BoardID',
			'CampaignID',
			'Channel',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'MessageID',
			'Network',
			'PostlogID',
			'CredentialID',
			'UserID',
			'PostSourceApp',
			'CredentialTeamIDs',
			'LinkStripped',
			'GeoCountry',
			'GeoCity',
			'GeoOrganization',
			'DeviceType',
			'DeviceBrowserName',
			'DeviceOSName',
			'ConversionTagID',
			'ConversionUrl',
			'LeadName',
			'LeadEmail',
			'LeadAccount'
		],
		metrics: [
			'IsConversion',
			'ConversionValue'
		]
	},
	userSessions: {
		name: I18n.t(`commonAnalytics.subjectType.userSessions.name`),
		description: I18n.t(`commonAnalytics.subjectType.userSessions.description`),
		iconName: 'hourglass',
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'UserID',
			'Country',
			'City',
			'Device',
			'Browser',
			'OS',
			'Domain',
			'Application',
			'StartDate'
		],
		metrics: [
			'IsSession',
			'IsFirstDomainSession'
		]
	},
	leadActivity: {
		name: I18n.t(`commonAnalytics.subjectType.leadActivities.name`),
		description: I18n.t(`commonAnalytics.subjectType.leadActivities.description`),
		iconName: 'user--activity',
		dimensions: [
			'ActivityDate',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'FiscalYear',
			'Year',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'Network',
			'ActivityType',
			'ActivityId',
			'LeadId',
			'IsTargetAccount',
			'LeadCompany',
			'LeadCompanySize',
			'LeadCompanyIndustry',
			'LeadStatus',
			'BoardId',
			'CampaignId',
			'PostCreatedBy',
			'PostlogId',
			'ProfileId',
			'DayHour',
			'Channel',
			'TopicIDs',
			'TeamIDs',
			'Tags'
		],
		metrics: [
			'Count'
		]
	},
	brandMentions: {
		name: I18n.t(`commonAnalytics.subjectType.brandMentions.name`),
		description: I18n.t(`commonAnalytics.subjectType.brandMentions.description`),
		iconName: 'at',
		dimensions: [
			'Date',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'ContentType',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'Network',
			'Sentiment',
			'Hashtags',
			'Keywords',
			'SDBPostID',
			'ProfileID',
			'MentionedCredentialID',
			'Source',
			'Tags'
		],
		metrics: [
			'CommentsCount',
			'LikesCount',
			'SharesCount',
			'Engagement',
			'IsMention',
			'Reach'
		]
	},
	linkedInAds: {
		name: I18n.t(`commonAnalytics.subjectType.linkedInAds.name`),
		description: I18n.t(`commonAnalytics.subjectType.linkedInAds.description`),
		iconName: 'logo--linkedin',
		dimensions: [
			'MetricDate',
			'WeekSunday',
			'Week',
			'Month',
			'Quarter',
			'FiscalQuarter',
			'Year',
			'FiscalYear',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'SDBID',
			'AdAccountID',
			'AdCampaignID',
			'AdCampaignStatus',
			'AdGroupID',
			'AdGroupStatus',
			'CampaignObjective',
			'AdCreativeID',
			'AdType',
			'AdStatus',
			'TeamIDs'
		],
		metrics: [
			'Reach',
			'Clicks',
			'Comments',
			'CommentLikes',
			'CostUsd',
			'Impressions',
			'Follows',
			'Likes',
			'Shares',
			'VideoViews',
			'VideoViewsFirstQ',
			'VideoViewsMid',
			'VideoViewsThirdQ',
			'VideoViewsComplete',
			'Engagements',
			'FullScreenPlays',
			'Reactions',
			'EngagementRate',
			'AvgCPC',
			'AvgCPF',
			'AvgCPM',
			'AvgCTR',
			'AvgVPI'
		]
	},
	linkedInDemographics:{
		name: I18n.t(`commonAnalytics.subjectType.linkedInDemographics.name`),
		description: I18n.t(`commonAnalytics.subjectType.linkedInDemographics.description`),
		iconName: 'logo--linkedin',
		dimensions: [
			'LinkedinProfileId',
			'Seniority',
			'LinkedinCountry',
			'Function',
			'Industry',
			'LinkedinRegion',
			'Employees',
			'Device',
			'PageType'
		],
		metrics: [
			'Followers',
			'PageViews',
			'PageViewsUnique',
			'PageViewsMobile',
			'PageViewsDesktop',
		]
	},
	opportunity: {
		name: I18n.t(`commonAnalytics.subjectType.opportunity.name`),
		description: I18n.t(`commonAnalytics.subjectType.opportunity.description`),
		iconName: 'currency',
		dimensions: [
			'OpportunityClosedDate',
			'OpportunityClosedWeekSunday',
			'OpportunityClosedWeek',
			'OpportunityClosedMonth',
			'OpportunityClosedQuarter',
			'OpportunityClosedFiscalQuarter',
			'OpportunityClosedYear',
			'OpportunityClosedFiscalYear',
			'OpportunityClosedWeekDay',
			'OpportunityClosedYearWeek',
			'OpportunityClosedYearMonth',
			'SocialActivityDate',
			'SocialActivityWeekSunday',
			'SocialActivityWeek',
			'SocialActivityMonth',
			'SocialActivityQuarter',
			'SocialActivityFiscalQuarter',
			'SocialActivityYear',
			'SocialActivityFiscalYear',
			'SocialActivityWeekDay',
			'SocialActivityYearWeek',
			'SocialActivityYearMonth',
			'LeadID',
			'IsTargetAccount',
			'LeadCompany',
			'LeadStatus',
			'SocialActivityType',
			'SocialActivityID',
			'SocialActivityNetwork',
			'Currency',
			'OpportunityID',
			'OpportunityOrganizationID',
			'OpportunityOwnerID',
			'OpportunityOwnerRole',
			'OpportunityAccountID',
			'OpportunityStageName',
			'OpportunityStageType',
			'OpportunityType',
			'BoardId',
			'CampaignId',
			'PostCreatedBy',
			'PostlogId',
			'Channel',
			'TopicIDs',
			'Tags'
		],
		metrics: [
			'Amount',
			'IsOpportunity',
			'IsSocialActivity'
		]
	},
	inbox: {
		name: I18n.t(`commonAnalytics.subjectType.inbox.name`),
		description: I18n.t(`commonAnalytics.subjectType.inbox.description`),
		iconName: 'box',
		dimensions: [
			'CreatedDay',
			'CreatedMonth',
			'CreatedQuarter',
			'CreatedFiscalQuarter',
			'CreatedYear',
			'CreatedFiscalYear',
			'CreatedWeekDay',
			'CreatedYearWeek',
			'ClosedDay',
			'ClosedMonth',
			'ClosedQuarter',
			'ClosedFiscalQuarter',
			'ClosedYear',
			'ClosedFiscalYear',
			'ClosedWeekDay',
			'ClosedYearWeek',
			'ItemType',
			'IsAssigned',
			'Network',
			'AssigneeID',
			'Status',
			'SDBAuthorID',
			'SDBPostID',
			'CredentialID',
			'TimeToFirstReplyCohort',
			'TimeToFirstReplyGrade',
			'CannedResponses',
			'Tags',
			'ConversationTopic',
		],
		metrics: [
			'Conversations',
			'Replies',
			'AvgReplies',
			'CloseRate',
			'ReOpenCount',
			'ReOpensRate',
			'AvgTimeToFirstReply',
			'AvgTimeToClose',
			'InteractionsAdded',
			'AvgInteractions'
		]
	},
	socialListening: {
		name: I18n.t(`commonAnalytics.subjectType.socialListening.name`),
		description: I18n.t(`commonAnalytics.subjectType.socialListening.description`),
		iconName: 'social--listening',
		dimensions: [
			'Date',
			'Week',
			'Month',
			'Year',
			'Quarter',
			'WeekDay',
			'YearWeek',
			'YearMonth',
			'PipelineID',
			'ItemID',
			'ListeningChannel',
			'Language',
			'PostType',
			'ProfileID',
			'ProfileIsVerified',
			'Links',
			'Themes',
			'AuthorLocation',
			'ListeningCountry',
			'Sentiment',
			'AllTags'
		],
		templateDimensions: [
			'RemoteCreated',
			'ItemType',
			'WebType',
			'WebDomain',
			'URL',
			'Text',
			'AuthorName',
			'ProfileType',
			'ProfileName',
			'ProfileUsername',
			'ProfileUri',
			'ProfileThumbnail',
			'ProfileGender',
			'Hashtags',
			'Cashtags',
			'Mentions',
			'LinkDomains',
			'Emojis',
			'Keywords',
			'Medias',
			'People',
			'Organizations',
			'Locations',
			'SentimentEmoji',
		],
		metrics: [
			'ProfileFollowers',
			'Likes',
			'Shares',
			'Comments',
			'Reach',
			'Engagement',
			'ItemCount',
			'ProfileCount',
		],
		templateMetrics: [
			'ProfileFriends',
			'ProfilePosts',
			'ProfileLikedPosts',
			'Impressions',
			'NumericalSentiment'
		]
	}
};