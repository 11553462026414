import { FilterTypes } from 'lobster-ui';


export const AnalyticsFilterConfig = {
	BoardID: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Board',
		url: '/analytics-autocomplete/board',
		multiple: true,
		isDimension: true,
	},
	ListID: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'List',
		url: '/analytics-autocomplete/list',
		multiple: true,
		isDimension: true,
		getLabel: option => `${option.BoardName} - ${option.Name}`
	},
	MemberIDs: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Members',
		url: '/analytics-autocomplete/members',
		multiple: true,
		isDimension: true,
	},
	Labels: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Labels',
		url: '/analytics-autocomplete/labels',
		multiple: true,
		isDimension: true
	},
	AllTags: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Tags',
		url: '/analytics-autocomplete/all-tags',
		multiple: true,
		isDimension: true
	},
	BoardTags: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Board Tags',
		url: '/analytics-autocomplete/board-tags',
		multiple: true,
		isDimension: true
	},
	ListTags: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'List Tags',
		url: '/analytics-autocomplete/list-tags',
		multiple: true,
		isDimension: true
	},
	CardTags: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'Card Tags',
		url: '/analytics-autocomplete/card-tags',
		multiple: true,
		isDimension: true
	},
	UserTags: {
		type: FilterTypes.AUTOCOMPLETE,
		label: 'User Tags',
		url: '/analytics-autocomplete/user-tags',
		multiple: true,
		isDimension: true
	},
	
	IsDueDateComplete: {
		type: FilterTypes.SELECT,
		options: [
			{
				label: 'Complete',
				value: '1'
			},
			{
				label: 'Not Complete',
				value: '0'
			},
		
		],
		getValue: item => [item.value.toString()],
		label: 'Due Date',
		isMetric: false,
		isDimension: true,
		isTag: false,
	},
	IsDeleted: {
		type: FilterTypes.SELECT,
		options: [
			{
				label: 'Deleted',
				value: '1'
			},
			{
				label: 'Not Deleted',
				value: '0'
			},
		
		],
		getValue: item => [item.value.toString()],
		label: 'Deleted',
		isMetric: false,
		isDimension: true,
		isTag: false,
	},
	
	Count: {
		label: 'Cards Count',
		
		type: FilterTypes.COMPARE,
		
		isMetric: true,
		isDimension: false,
		isTag: false,
	},
	Score: {
		label: 'Score',
		
		type: FilterTypes.COMPARE,
		
		isMetric: true,
		isDimension: false,
		isTag: false,
	},
	OpenTime: {
		label: 'Open Time',
		
		type: FilterTypes.COMPARE,
		
		isMetric: true,
		isDimension: false,
		isTag: false,
	}
}