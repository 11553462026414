export const DatePeriodTypes = {
	last_7_days: {
		name: 'Last 7 Days'
	},
	last_30_days: {
		name: 'Last 30 Days'
	},
	last_60_days: {
		name: 'Last 60 Days'
	},
}

export const SocialBIDatePeriodTypes = {
	"last_7_days": "Last 7 Days",
	"last_30_days": "Last 30 Days",
	"last_60_days": "Last 60 Days",
	"last_90_days": "Last 90 Days",
	"last_120_days": "Last 120 Days",
	"current_week_sunday": "Current Week",
	"current_week_monday": "Current Week",
	"last_week_sunday": "Last Week",
	"last_week_monday": "Last Week",
	"current_last_week_sunday": "Current & Last Week",
	"current_last_week_monday": "Current & Last Week",
	"current_month": "Current Month",
	"last_month": "Last Month",
	"last_3_months": "Last 3 Months",
	"last_6_months": "Last 6 Months",
	"last_12_months": "Last 12 Months",
	"current_last_month": "Current & Last Month",
	"current_last_3_months": "Current & Last 3 Months",
	"current_last_6_months": "Current & Last 6 Months",
	"current_last_12_months": "Current & Last 12 Months",
	"current_quarter": "Current Quarter",
	"last_quarter": "Last Quarter",
	"current_last_quarter": "Current & Last Quarter",
	"current_fiscal_quarter": "Current FQ",
	"last_fiscal_quarter": "Last FQ",
	"current_last_fiscal_quarter": "Current & Last FQ",
	"current_year": "Current Year",
	"last_year": "Last Year",
	"current_last_year": "Current & Last Year",
	"current_fiscal_year": "Current FY",
	"last_fiscal_year": "Last FY",
	"current_last_fiscal_year": "Current & Last FY",
	"custom": "Custom Date"
}