<template>
	<div
		v-if="notEnoughData"
		class="report-chart-wrapper__nullstate">
		<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134">
			<g fill="none" fill-rule="evenodd">
				<circle cx="67" cy="67" r="67" fill="#F3F5F8"/>
				<path fill="#102248" d="M84.5 77.938h4.375v10.937H84.5V77.937zM75.75 67h4.375v21.875H75.75V67zM56.062 88.875c-6.037-.007-10.93-4.9-10.937-10.938H49.5c0 3.625 2.938 6.563 6.563 6.563 3.624 0 6.562-2.938 6.562-6.563 0-3.624-2.938-6.562-6.563-6.562V67C62.103 67 67 71.897 67 77.938c0 6.04-4.897 10.937-10.938 10.937zm37.188-52.5h-52.5c-2.415.002-4.373 1.96-4.375 4.375v52.5c.002 2.415 1.96 4.373 4.375 4.375h52.5c2.415-.004 4.371-1.96 4.375-4.375v-52.5c-.002-2.415-1.96-4.373-4.375-4.375zm0 19.688H62.625V40.75H93.25v15.313zm-35-15.313v15.313h-17.5V40.75h17.5zm-17.5 52.5V60.437h52.5l.004 32.813H40.75z" opacity=".2"/>
			</g>
		</svg>
		<h3 class="report-chart__nullstate-text">No metrics are available for this time frame.</h3>
	</div>

	<lobster-loader 
		v-else-if="isLoading"
		class="report-chart-wrapper__loader"/>

	<div
		v-else
		class="report-chart-wrapper">
		<lobster-loader class="report-chart-wrapper__pagination-loader" v-if="isPaginationLoading"/>

		<kraken-report
			class="report-chart-wrapper__chart"
			ref="krakenReport"
			:class="{m_loading: isPaginationLoading}"
			:report-data="reportData"
			:chart-type="chartType"
			:groups="groups"
			:metrics="metrics"
			:additional-columns="additionalColumns"
			:metadata="currentMetadata"
			:limit="limit"
			:with-pagination="withPagination"
			:with-order="withOrder"
			:page.sync="currentPage"
			:order-by="currentOrderBy"
			:order-dir="currentOrderDir"
			:config="additionalConfig"
			@prev="handlePrev"
			@next="handleNext"
			@sort="handleSort"
			@edit-metric="handleEditMetric">
			<template #kraken-item-list__item="data">
				<slot name="item-list__item" v-bind="data"/>
			</template>
			<template #kraken-table-chart__drill-down="{row, columns, entities}">
				<drill-down-wrapper 
					:row="row"
					:columns="columns" 
					:entities="entities"
					:date-period="datePeriod"
					:subject-type="subjectType"
					:filter="filter"
					:parent-groups="groups"
					:parent-metrics="metrics"
					:order-by="orderBy"/>
			</template>
			<template #chart="{}">
				<kraken-report
					v-if="chartType === ChartType.EXTENDED_LIST"
					class="report-chart-wrapper__extended-list"
					ref="extendedListReport"
					:report-data="reportData"
					:chart-type="ChartType.LIST"
					:groups="groups"
					:metrics="metrics"
					:additional-columns="additionalColumns"
					:limit="limit"
					:with-pagination="withPagination"
					:with-order="withOrder"
					:page.sync="currentPage"
					:order-by="currentOrderBy"
					:order-dir="currentOrderDir"
					:config="extendedListConfig"
					@prev="handlePrev"
					@next="handleNext"
					@sort="handleSort">
					<template #kraken-item-list__item="data">
						<slot name="item-list__item" v-bind="data"/>
					</template>
					<template #kraken-table-chart__drill-down="{row, columns, entities}">
						<drill-down-wrapper
							:row="row"
							:columns="columns"
							:entities="entities"
							:date-period="datePeriod"
							:subject-type="subjectType"
							:filter="filter"
							:parent-groups="groups"
							:parent-metrics="metrics"
							:order-by="orderBy"/>
					</template>
				</kraken-report>
			</template>
		</kraken-report>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { FunctionHelpers } from 'lobster-ui';
import { merge_configs } from 'kraken-charts';
import { ChartTypesConfig } from '../../../configs/ChartTypesConfig';
import DrillDownWrapper from './DrilldownWrapper';
import { ChartType } from '@analytics';


export default {
	name: 'ReportChartWrapper',
	components: {
		DrillDownWrapper
	},
	props: {
		dashboardId: String,
		widgetId: [Number, String],
		datePeriod: {
			type: Object,
		},
		chartType: {
			type: String,
		},
		groups: {
			type: Array,
		},
		metrics: {
			type: Array,
		},
		additionalColumns: Array,
		subjectType: {
			type: String,
		},
		limit: [Number, String],
		orderBy: Array,
		filter: Object,
		metadata: {
			type: Object,
			default: () => ({})
		},

		withPagination: Boolean,
		withOrder: Boolean,
		withTotals: Boolean,
		config: Object,
		labels: [Object, Array],
	},
	data()
	{
		return {
			isLoading: false,
			isPaginationLoading: false,
			reportData: {},
			currentPage: 0,
			currentOrder: {},
			ChartType
		}
	},
	computed: {
		notEnoughData()
		{
			let chartConfig = ChartTypesConfig[this.chartType];

			if (!is(chartConfig))
			{
				return true;
			}

			if (chartConfig.minMetrics > 0 && (!is.array(this.metrics) || this.metrics.length < chartConfig.minMetrics))
			{
				return true;
			}

			if (chartConfig.minGroups > 0 && (!is.array(this.groups) || this.groups.length < chartConfig.minGroups))
			{
				return true;
			}

			return false;
		},
		currentOrderBy()
		{
			if (!this.withOrder)
			{
				return;
			}
			
			if (is(this.currentOrder))
			{
				return this.currentOrder.field;
			}
			
			if (!is(this.orderBy[0]))
			{
				return;
			}
			
			return this.orderBy[0].field;
		},
		currentOrderDir()
		{
			if (!this.withOrder)
			{
				return;
			}

			if (is(this.currentOrder))
			{
				return this.currentOrder.direction === 'descending' ? 0 : 1;
			}
			
			if (!is(this.orderBy[0]))
			{
				return;
			}

			return this.orderBy[0].direction === 'descending' ? 0 : 1;
		},
		extendedListConfig()
		{
			return merge_configs({
				tableConfig: {
					withDrilldown(columns)
					{
						return true;
					}
				}
			}, this.config, this.$krakenConfig)
		},
		currentMetadata()
		{
			let metadata = {...this.metadata};

			if (metadata.isExtended === '0')
			{
				metadata.isExtended = 0;
			}

			return metadata;
		},
		/** @type { KrakenConfig } */
		additionalConfig()
		{
			return merge_configs(this.config, {
				extraData: {
					labels: this.labels,
					datePeriod: this.datePeriod
				}
			}, this.$krakenConfig);
		},
	},
	watch: {
		dashboardId(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		widgetId(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		datePeriod(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		chartType(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		groups(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		metrics(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		additionalColumns(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		subjectType(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		limit(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		orderBy(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		filter(newVal, oldVal)
		{
			if (!FunctionHelpers.isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		metadata(newVal, oldVal)
		{
			if (newVal.isExtended !== oldVal.isExtended)
			{
				this.debouncedLoadReportData();
			}
		}
	},
	methods: {
		...mapActions({
			queryWidget: 'reportGallery/queryWidget'
		}),
		async queryReport(params)
		{
			let reportData = await this.queryWidget(params);

			if (is.array(reportData.Data))
			{
				if (reportData.Data.length > 1000)
				{
					this.$toaster.showWarn('Got more than 1000 records');

					reportData.Data = reportData.Data.slice(0, 1000);
				}
				else if (reportData.Data.length === 1000)
				{
					console.error('Got 1000 records');
				}
			}

			this.reportData = reportData;
		},
		getParams()
		{
			let params = {
				id: '',
				widgetId: '',
				datePeriod: this.datePeriod,
				chartType: this.chartType,
				subjectType: this.subjectType,
				orderBy: this.orderBy,
				additionalColumns: this.additionalColumns,
				metrics: this.metrics,
				groups: this.groups,
				filter: this.filter,
				withTotals: this.withTotals,
				withCursor: this.withPagination,
				limit: this.limit,
				metadata: JSON.stringify(this.currentMetadata)
			}

			if (!ChartTypesConfig[this.chartType]?.isTable)
			{
				if (is.string(params.limit))
				{
					params.limit = +this.limit;
				}

				if (!is.number(params.limit) || params.limit > 1000)
				{
					params.limit = 1000;
				}
			}
			else
			{
				if (this.withPagination && !is(params.limit))
				{
					params.limit = 20;
				}
				
				if (this.withOrder && is(this.currentOrder))
				{
					params.orderBy = [this.currentOrder];
				}
			}
			
			return params;
		},
		async loadReportData()
		{
			if (this.notEnoughData)
			{
				return;
			}
			
			this.isLoading = true;
			this.currentPage = 0;
			
			try
			{	
				await this.queryReport(this.getParams());
			}
			finally
			{
				this.isLoading = false;
				
				if (this.chartType === this.ChartType.EXTENDED_LIST)
				{
					this.$nextTick(() => this.$refs.extendedListReport.$refs.list.handleDrillDownClick(0));
				}
			}
		},
		async reportReload(additionalParams = {})
		{
			if (this.notEnoughData)
			{
				return;
			}
			
			this.isPaginationLoading = true;

			try
			{				
				let params = this.getParams();
				
				params = {
					...params,
					...additionalParams
				}
				
				await this.queryReport(params);
			}
			finally
			{
				this.isPaginationLoading = false;
			}
		},
		debouncedLoadReportData()
		{
			this.isLoading = true;
			
			this.debouncedReportDataLoader()
		},
		handleNext(page)
		{
			if (is(this.reportData?.Cursor?.Next))
			{
				this.reportReload({
					next: this.reportData.Cursor.Next
				})
			}		
		},
		handlePrev(page)
		{
			if (is(this.reportData?.Cursor?.Prev))
			{
				this.reportReload({
					prev: this.reportData.Cursor.Prev
				})
			}
		},
		handleSort(sortBy, dir)
		{
			this.currentOrder = {
				field: sortBy,
				direction: dir === 0 ? 'descending' : 'ascending'
			}
			
			this.currentPage = 0;

			this.reportReload();
		},
		handleEditMetric(metric, metricText)
		{
			this.$emit('edit-metric', metric, metricText);
		},
	},
	mounted()
	{
		this.loadReportData()
	},
	created()
	{
		this.debouncedReportDataLoader = FunctionHelpers.debounce(this.loadReportData, 0);
	}
}
</script>

<style lang="scss" scoped>
.report-chart-wrapper {
	display: flex;
	flex-grow: 1;
	min-height: 0;
}

.report-chart-wrapper__nullstate {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

div.report-chart-wrapper__loader {
	align-self: center;
	flex-direction: row;
}

.report-chart-wrapper__extended-list {
	max-height: 100%;
	margin: 12px -16px -16px;
}

.report-chart-wrapper__chart {
	flex-grow: 1;
	width: 100%;
	min-height: 0;
	min-width: 0;
	
	&.m_loading {
		visibility: hidden;
		flex-grow: 0;
		width: 0;
	}
}
</style>