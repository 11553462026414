import { render, staticRenderFns } from "./QAToolsApp.vue?vue&type=template&id=c4d162be&scoped=true&"
import script from "./QAToolsApp.vue?vue&type=script&setup=true&lang=js&"
export * from "./QAToolsApp.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4d162be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/yegor/Code/oktopost/admin/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c4d162be')) {
      api.createRecord('c4d162be', component.options)
    } else {
      api.reload('c4d162be', component.options)
    }
    module.hot.accept("./QAToolsApp.vue?vue&type=template&id=c4d162be&scoped=true&", function () {
      api.rerender('c4d162be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/QATools/QAToolsApp.vue"
export default component.exports