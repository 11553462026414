var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-form-chart" },
    [
      _vm.withHeader
        ? _c(
            "header",
            { staticClass: "report-form-chart__header" },
            [
              _vm.hasTableSwitch
                ? _c("lobster-action-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tableSwitchTooltip,
                        expression: "tableSwitchTooltip",
                      },
                    ],
                    staticClass: "report-form-chart__table-switch",
                    attrs: {
                      "icon-name": "table--split",
                      active: _vm.isTableView,
                    },
                    on: { click: _vm.handleSwitchClick },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "report-form-chart__title-wrapper invisible-box",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.currentName,
                        expression: "currentName",
                        modifiers: { trim: true },
                      },
                      {
                        name: "autowidth",
                        rawName: "v-autowidth",
                        value: {
                          maxWidth: "100%",
                          minWidth: "100px",
                          comfortZone: 16,
                        },
                        expression:
                          "{maxWidth: '100%', minWidth: '100px', comfortZone: 16}",
                      },
                    ],
                    staticClass: "report-form-chart__title-input h2",
                    attrs: {
                      "aria-label": "Report name",
                      type: "text",
                      maxlength: "100",
                      "data-lpignore": "true",
                    },
                    domProps: { value: _vm.currentName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.currentName = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("lobster-icon", {
                    staticClass:
                      "invisible-box__actions description-text report-settings-modal__title-button",
                    attrs: { name: "edit" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.currentDescription,
                    expression: "currentDescription",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "report-form-chart__description-input",
                attrs: {
                  type: "text",
                  maxlength: "200",
                  placeholder: "Edit Description",
                },
                domProps: { value: _vm.currentDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.currentDescription = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("report-chart-wrapper", {
        staticClass: "report-form-chart__chart",
        attrs: {
          "module-name": "reportGallery",
          "chart-type": _vm.currentChartType,
          "subject-type": _vm.subjectType,
          metrics: _vm.metrics,
          groups: _vm.groups,
          "additional-columns": _vm.additionalColumns,
          limit: _vm.currentLimit,
          "order-by": _vm.orderBy,
          metadata: _vm.metadata,
          filter: _vm.filter,
          labels: _vm.labels,
          "date-period": _vm.datePeriod,
          config: _vm.currentConfig,
        },
        on: { "edit-metric": _vm.handleEditMetric },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }