export function roundFloat(value = 0, decimals = 2)
{
	return Math.round((value + Number.EPSILON) * 10**decimals) / 10**decimals;
}

export function niceNumber(num)
{
	num = parseInt(num);

	let minified, divided;

	if (isNaN(num) || num < 0)
	{
		return '-';
	}

	if (num < 1000)
	{
		return num.toString();
	}

	if (num < 100000)
	{
		divided = num / 1000;
		minified = (Math.round(10 * divided) / 10).toFixed(1);

		return new Intl.NumberFormat().format(minified) + 'K';
	}

	if (num < 1000000)
	{
		divided = num / 1000;
		minified = (Math.round(divided)).toFixed(0);

		return new Intl.NumberFormat().format(minified) + 'K';
	}

	if (num < 10000000)
	{
		divided = num / 1000000;
		minified = (Math.round(100 * divided) / 100).toFixed(2);

		return new Intl.NumberFormat().format(minified) + 'M';
	}

	divided = num / 1000000;
	minified = (Math.round(10 * divided) / 10).toFixed(1);

	return new Intl.NumberFormat().format(minified) + 'M';
}