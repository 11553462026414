import moment from 'moment';
import { DatePeriodType } from '../consts/DatePeriodType';


export default class DateHelper
{
	static formatUserDateUTC(date, hideCurrentYear = true)
	{
		let m = window.moment(date);
		let format 	= 'MMM D, YYYY';
		
		if (!m.isValid())
		{
			return '-';
		}
		
		if (hideCurrentYear)
		{
			let now = window.moment.utc();
			
			if (m.isSame(now, 'year'))
			{
				format = 'MMM DD';
			}
		}
		
		return m.format(format);
	}
	
	static formatUserDate(date, hideCurrentYear = true)
	{
		const offset 	= window.Oktopost.App.modules('Oktopost.TimezoneModule').getTimezoneOffsetInSeconds();
		const m 		= window.moment.utc(date).utcOffset(offset/60/60);
		let format 	= 'MMM D, YYYY';
		
		if (!m.isValid())
		{
			return '-';
		}
		
		if (hideCurrentYear)
		{
			var now = window.moment.utc().utcOffset(offset/60/60);
			
			if (m.isSame(now, 'year'))
			{
				format = 'MMM DD';
			}
		}
		
		return m.format(format);
	}
	
	static formatTimeSeconds(time)
	{
		time = Math.round(time);
		let seconds = time % 60;
		time = (time - seconds) / 60;

		if (seconds < 10)
		{
			seconds = '0' + seconds;
		}
		
		if (time === 0)
		{
			return `00:${seconds}`;
		}
		
		let minutes = time % 60;
		time = (time - minutes) / 60;

		if (minutes < 10)
		{
			minutes = '0' + minutes;
		}


		if (time === 0)
		{
			return `${minutes}:${seconds}`;
		}
		
		return `${time}:${minutes}:${seconds}`;
	}

	static ifDateIn(date, days)
	{
		return window.moment(date).isBefore(moment().add(days, 'days'))
	}

	static ifDatePast(date)
	{
		return window.moment(date).isBefore(moment())
	}

	static getConvertedDatePeriod(type)
	{
		switch (type)
		{
			case DatePeriodType.LAST_7_DAYS:
				return {
					from: window.moment().add(-6, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_30_DAYS:
				return {
					from: window.moment().add(-29, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_60_DAYS:
				return {
					from: window.moment().add(-59, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_90_DAYS:
				return {
					from: window.moment().add(-89, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_120_DAYS:
				return {
					from: window.moment().add(-119, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_WEEK_SUNDAY:
				return {
					from: window.moment().startOf('week').add(0, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_WEEK_SUNDAY:
				return {
					from: window.moment().add(-1, 'week').startOf('week').add(0, 'day').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'week').endOf('week').add(0, 'day').format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_LAST_WEEK_SUNDAY:
				return {
					from: window.moment().add(-1, 'week').startOf('week').add(0, 'day').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_MONTH:
				return {
					from: window.moment().startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_MONTH:
				return {
					from: window.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_3_MONTHS:
				return {
					from: window.moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_6_MONTHS:
				return {
					from: window.moment().add(-6, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_12_MONTHS:
				return {
					from: window.moment().add(-12, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_LAST_MONTH:
				return {
					from: window.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_LAST_3_MONTHS:
				return {
					from: window.moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')				};

			case DatePeriodType.CURRENT_LAST_6_MONTHS:
				return {
					from: window.moment().add(-6, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')				};

			case DatePeriodType.CURRENT_LAST_12_MONTHS:
				return {
					from: window.moment().add(-12, 'month').startOf('month').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')				};

			case DatePeriodType.CURRENT_QUARTER:
				return {
					from: window.moment().startOf('quarter').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_QUARTER:
				return {
					from: window.moment().add(-1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_LAST_QUARTER:
				return {
					from: window.moment().add(-1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_FISCAL_QUARTER:
			{
				let from = window.moment().startOf('quarter');

				if (from.isAfter(window.moment()))
				{
					from.add(-1, 'quarter');
				}

				return {
					from: from.format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};
			}

			case DatePeriodType.LAST_FISCAL_QUARTER:
			{
				let to = window.moment().startOf('quarter');

				if (to.isAfter(window.moment()))
				{
					to.add(-1, 'quarter');
				}

				let from = to.clone().add(-1, 'quarter');

				to.add(-1, 'day');

				return {
					from: from.format('YYYY-MM-DD'),
					to:   to.format('YYYY-MM-DD')
				};
			}

			case DatePeriodType.CURRENT_LAST_FISCAL_QUARTER:
				let from = window.moment().startOf('quarter');

				if (from.isAfter(window.moment()))
				{
					from.add(-2, 'quarter');
				}
				else
				{
					from.add(-1, 'quarter');
				}

				return {
					from: from.format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_YEAR:
				return {
					from: window.moment().startOf('year').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.LAST_YEAR:
				return {
					from: window.moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
					to:   window.moment().add(-1, 'year').endOf('year').format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_LAST_YEAR:
				return {
					from: window.moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};

			case DatePeriodType.CURRENT_FISCAL_YEAR:
			{
				let from = window.moment().month(0).startOf('month');

				if (from.isAfter(window.moment()))
				{
					from.add(-1, 'year');
				}

				return {
					from: from.format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};
			}

			case DatePeriodType.LAST_FISCAL_YEAR:
			{
				let to = window.moment().month(0).startOf('month');

				if (to.isAfter(window.moment()))
				{
					to.add(-1, 'year');
				}

				let from = to.clone().add(-1, 'year');

				to.add(-1, 'day');

				return {
					from: from.format('YYYY-MM-DD'),
					to:  to.format('YYYY-MM-DD')
				};
			}

			case DatePeriodType.CURRENT_LAST_FISCAL_YEAR:
			{
				let from = window.moment().month(0).startOf('month');

				if (from.isAfter(window.moment()))
				{
					from.add(-1, 'year');
				}

				from.add(-1, 'year');

				return {
					from: from.format('YYYY-MM-DD'),
					to:   window.moment().format('YYYY-MM-DD')
				};
			}

			default:
				return null;

		}
	}
}

export function convertToSeconds(value, metric)
{
	if (!value)
	{
		return 0;
	}

	if (!metric)
	{
		return value;
	}

	switch (metric)
	{
		case 'minutes':
			return value * 60;
		case 'hours':
			return value * 60 * 60;
		case 'days':
			return value * 60 * 60 * 24;
		default:
			return 0;
	}
}

export function convertFromSeconds(value, metric)
{
	if (!value)
	{
		return 0;
	}

	if (!metric)
	{
		return value;
	}

	switch (metric)
	{
		case 'minutes':
			return value / 60;
		case 'hours':
			return value / 60 / 60;
		case 'days':
			return value / 60 / 60 / 24;
		default:
			return 0;
	}
}