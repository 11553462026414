import { ACTIVITY_NETWORKS, BASE_NETWORKS } from '../consts/Networks';
import { CHANNELS } from '../consts/Channels';
import { CONTENT_TYPE } from '../consts/ContentType';
import { POST_SOURCE } from '../consts/PostSource';
import { MENTION_SOURCE } from '../consts/MentionSource';
import { SENTIMENT } from '../consts/Sentiment';
import { DEVICE_TYPE } from '../consts/DeviceType';
import { DOMAIN } from '../consts/Domain';
import { WEB_TYPES } from '../consts/WebTypes';
import { APPLICATION } from '../consts/Application';
import { SOURCE_APPLICATION } from '../consts/SourceApplication';
import { GRADE_POINTS } from '../consts/GradePoints';
import { FilterTypes } from '../consts/FilterTypes';
import {
	getNetworkOptions,
	getMonthOptions,
	getDayHourOptions,
	getWeekDayOptions,
	getFunctionOptions,
	getSeniorityOptions,
	getPlatformOptions,
	getListeningChannelOptions,
	getActivityOptions,
	getAdCampaignStatusOptions,
	getAdGroupStatusOptions,
	getLeadCompanySizeOptions,
	getCompanySizeOptions,
	getPostTypeOptions,
	getIndustryOptions,
	getPageTypeOptions,
	getLeadStatusOptions,
	getLanguageOptions,
	getCountryOptions,
	getListeningCountryOptions,
	getTimeToFirstReplyOptions
} from '../helpers/OptionsHelper';


/**
 * @typedef DimensionsFiltersConfig
 * @property {string} [labelParam] for autocomplete, name of label field in item
 * @property {string} [valueParam] for autocomplete, name of value field in item
 */
/**
 * @type {Object<string, DimensionsFiltersConfig>}
 */
export const DimensionsFiltersConfig = {
	OpportunityClosedWeekDay: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getWeekDayOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	OpportunityClosedYearWeek: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
		getValue: val => {
			if (!val) return [];
			if (val < 1 || val > 54) return [];

			return [+val];
		},
		getLabel: val => val.length ? +val[0] : null,
	},
	OpportunityClosedYearMonth: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getMonthOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	SocialActivityWeekDay: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getWeekDayOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	SocialActivityYearWeek: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
		getValue: val => {
			if (!val) return [];
			if (val < 1 || val > 54) return [];

			return [+val];
		},
		getLabel: val => val.length ? +val[0] : null,
	},
	SocialActivityYearMonth: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getMonthOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	Channel: {
		type: FilterTypes.SELECT,
		options: CHANNELS,
		getValue: item => [item]
	},
	ContentType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: CONTENT_TYPE
	},
	WeekDay: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getWeekDayOptions,
		getValue: item => +item.value,
		numeric: true,

		isAdditionalDataNeeded: true,
	},
	YearWeek: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
		getValue: val => {
			if (!val) return [];
			if (val < 1 || val > 54) return [];

			return [+val];
		},
		getLabel: val => val.length ? +val[0] : null,
	},
	YearMonth: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getMonthOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	CreatedDayHour: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getDayHourOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	CreatedWeekDay: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getWeekDayOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	CreatedYearWeek: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
		getValue: val => {
			if (!val) return [];
			if (val < 1 || val > 54) return [];

			return [+val];
		},
		getLabel: val => val.length ? +val[0] : null,
	},
	CreatedYearMonth: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getMonthOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	Network: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: () => getNetworkOptions(BASE_NETWORKS),
		getValue: item => item.value
	},
	Tags: {
		type: FilterTypes.TAGS
	},
	KeywordsAndHashtags: {
		type: FilterTypes.TAGS
	},
	Topics: {
		type: FilterTypes.TAGS
	},
	PostSource: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: POST_SOURCE
	},
	PostSourceApp: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: SOURCE_APPLICATION,
		getValue: item => item.value
	},
	SourceApplication: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: SOURCE_APPLICATION,
		getValue: item => item.value
	},
	Source: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: MENTION_SOURCE
	},
	LinkStripped: {
		type: FilterTypes.TAGGABLE,
		multiple: true
	},
	GeoOrganization: {
		type: FilterTypes.TAGS
	},
	DeviceType: {
		type: FilterTypes.SELECT,
		options: DEVICE_TYPE,
		getValue: item => [item]
	},
	LeadAccount: {
		type: FilterTypes.TAGS
	},
	Platform: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getPlatformOptions,
		getValue: item => item.value
	},
	Sentiment: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: SENTIMENT.map(value => ({
			value,
			label: I18n.t('commonAnalytics.sentiment')[value] ?? 'Unknown'
		})),
		getValue: item => item.value
	},
	Hashtags: {
		type: FilterTypes.TAGS
	},
	Keywords: {
		type: FilterTypes.TAGS,

		isListeningFilter: true
	},
	Device: {
		type: FilterTypes.SELECT,
		options: DEVICE_TYPE,
		getValue: item => [item]
	},
	Domain: {
		type: FilterTypes.SELECT,
		options: DOMAIN,
		getValue: item => [item]
	},
	Application: {
		type: FilterTypes.SELECT,
		options: APPLICATION,
		getValue: item => [item]
	},
	ActivityType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getActivityOptions,
		getValue: item => item.value
	},
	IsTargetAccount: {
		type: FilterTypes.SELECT,
		options: [{
			value: '1',
			label: I18n.t('commonUtils.confirm.yes')
		}, {
			value: '0',
			label: I18n.t('commonUtils.confirm.no')
		}],
		getValue: item => [item.value]
	},
	LeadCompany: {
		type: FilterTypes.TAGS
	},
	LeadStatus: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getLeadStatusOptions,
		getValue: item => item.value
	},
	LeadCompanySize: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getLeadCompanySizeOptions,
		getValue: item => item.value
	},
	LeadCompanyIndustry: {
		type: FilterTypes.SELECT,
		searchable: true,
		multiple: true,
		options: getIndustryOptions,
		getValue: item => item.label
	},
	SocialActivityType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getActivityOptions,
		getValue: item => item.value
	},
	SocialActivityNetwork: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: () => getNetworkOptions(ACTIVITY_NETWORKS),
		getValue: item => item.value
	},
	DayHour: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getDayHourOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	AdCampaignStatus: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getAdCampaignStatusOptions,
		getValue: item => item.value,
	},
	AdGroupStatus: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getAdGroupStatusOptions,
		getValue: item => item.value
	},
	MediaTags: {
		type: FilterTypes.TAGS,
		tagsFilter: true,
		isTagsRelated: true,
	},
	ProfileSource: {
		type: FilterTypes.SELECT,
		options: [{
			label: 'App',
			value: 'App'
		}, {
			label: 'Board',
			value: 'Board' }],
		getValue: item => [item.value]
	},
	WebType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: WEB_TYPES
	},
	Language: {
		type: FilterTypes.SELECT,
		multiple: true,
		searchable: true,
		options: getLanguageOptions,
		getValue: item => item.value,

		isListeningFilter: true,
	},
	PostType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getPostTypeOptions,
		getValue: item => item.value
	},
	AuthorLocation: {
		type: FilterTypes.SELECT,
		multiple: true,
		searchable: true,
		options: getCountryOptions,
		getValue: item => item.value,

		isListeningFilter: true,
	},
	ListeningCountry: {
		type: FilterTypes.SELECT,
		multiple: true,
		searchable: true,
		options: getListeningCountryOptions,
		getValue: item => item.value,

		isListeningFilter: true,
	},
	ListeningChannel: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getListeningChannelOptions,
		getValue: item => item.value,

		isListeningFilter: true,
	},
	AllTags: {
		 type: FilterTypes.TAGS
	},
	IsAdvocateCreated: {
		type: FilterTypes.SELECT,
		options: [{
			label: 'Created by Advocates',
			value: '1'
		}, {
			label: 'Created by Board Admins',
			value: '0' }],
		getValue: item => [item.value.toString()]
	},
	Seniority: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getSeniorityOptions,
		getValue: item => item.value
	},
	Function: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getFunctionOptions,
		getValue: item => item.value
	},
	Industry: {
		type: FilterTypes.SELECT,
		searchable: true,
		multiple: true,
		options: getIndustryOptions,
		getValue: item => item.id
	},
	Employees: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getCompanySizeOptions,
		getValue: item => item.value
	},
	PageType: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getPageTypeOptions,
		getValue: item => item.value,
		getLabel: item => item.label
	},
	CustomFields: {
		type: FilterTypes.TAGS,
		isCustomField: true,
	},
	ClosedMonth: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getMonthOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	ClosedQuarter: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
	},
	ClosedFiscalQuarter: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
	},
	ClosedWeekDay: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getWeekDayOptions,
		getValue: item => +item.value,
		numeric: true,
	},
	ClosedYearWeek: {
		type: FilterTypes.FREE_TEXT,
		inputType: 'number',
		getValue: val => {
			if (!val) return [];
			if (val < 1 || val > 54) return [];

			return [+val];
		},
		getLabel: val => val.length ? +val[0] : null,
	},
	IsAssigned: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: [{
			label: I18n.t('commonUtils.state.assigned'),
			value: 1
		}, {
			label: I18n.t('commonUtils.state.unassigned'),
			value: 0
		}],
		getValue: item => item.value,
	},
	Status: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: [{
			label: I18n.t('commonUtils.status.open'),
			value: 'open'
		}, {
			label: I18n.t('commonUtils.status.closed'),
			value: 'closed'
		}],
		getValue: item => item.value,
	},
	TimeToFirstReplyCohort: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: getTimeToFirstReplyOptions,
		getValue: item => item.value,
	},
	TimeToFirstReplyGrade: {
		type: FilterTypes.SELECT,
		multiple: true,
		options: GRADE_POINTS,
	},
	TrackerID: {
		type: null,
	},
	SentimentEmoji: {
		type: null
	},
	WebDomain: {
		type: null,
	},
	LinkDomains: {
		type: null,
	},
	Emojis: {
		type: null,
	},
	ProfileIsVerified: {
		type: FilterTypes.SELECT,
		options: [{
			label: '-',
			value: ''
		}, {
			value: '1',
			label: I18n.t('confirm.yes')
		}, {
			value: '0',
			label: I18n.t('confirm.no')
		}],
		getValue: item => [item.value]
	},
	Links: {
		type: FilterTypes.TAGGABLE,
		multiple: true
	},
};