var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-date-picker", {
    attrs: { "is-range": "", "is-required": "", "max-date": _vm.maxDate },
    model: {
      value: _vm.currentValue,
      callback: function ($$v) {
        _vm.currentValue = $$v
      },
      expression: "currentValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }