import { Network } from '@utils';


export const NETWORKS = Object.freeze([
	Network.FACEBOOK,
	Network.INSTAGRAM,
	Network.LINKED_IN,
	Network.YOUTUBE,
	Network.TWITTER,
	Network.TIKTOK,
	Network.WECHAT,
	Network.XING
]);

export const BASE_NETWORKS = Object.freeze([
	Network.FACEBOOK,
	Network.INSTAGRAM,
	Network.LINKED_IN,
	Network.YOUTUBE,
	Network.TWITTER,
	Network.TIKTOK
]);

export const ACTIVITY_NETWORKS = Object.freeze([
	Network.FACEBOOK,
	Network.INSTAGRAM,
	Network.LINKED_IN,
	Network.YOUTUBE,
	Network.TWITTER,
	Network.WECHAT,
	Network.XING
]);
