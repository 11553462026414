var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fragment",
    [
      _c(
        "aside",
        [
          _c(
            "lobster-button",
            { attrs: { "inline-link": "", to: { name: "backfill" } } },
            [_vm._v("\n\t\t\tBackfill\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view", { staticClass: "inner" }),
      _vm._v(" "),
      _c("lobster-provider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }