var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    {
      staticClass: "network-entity",
      attrs: {
        "no-wrap": "",
        center: "",
        size: _vm.iconSize === "small" ? "small--s" : "small",
      },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.$is(_vm.network)
        ? _c("lobster-network-icon", {
            attrs: { name: _vm.network, size: _vm.iconSize },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "network-entity__label" },
        [
          _vm._t("label", function () {
            return [_vm._v("\n\t\t\t" + _vm._s(_vm.networkLabel) + "\n\t\t")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }