var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-dynamic-modal",
    _vm._g(
      _vm._b(
        { attrs: { "modal-title": "Create Dashboard" } },
        "lobster-dynamic-modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "lobster-form",
        {
          attrs: {
            "modal-view": "",
            "submit-button-text": "Save",
            "is-loading": _vm.isLoading,
          },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c("lobster-input", {
            attrs: {
              label: "Name",
              required: "",
              validators: _vm.RequiredValidator,
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm._v(" "),
          _c("lobster-input", {
            attrs: { label: "Description" },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _vm._v(" "),
          _c("lobster-select", {
            attrs: {
              "field-label": "Privacy",
              options: _vm.visibilityOptions,
              reduce: (option) => option.value,
            },
            model: {
              value: _vm.visibility,
              callback: function ($$v) {
                _vm.visibility = $$v
              },
              expression: "visibility",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }