var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fragment",
    [
      _c("h2", [_vm._v(" Analytics Crawler")]),
      _vm._v(" "),
      _c(
        "lobster-form",
        {
          attrs: { "submit-button-text": "Schedule" },
          on: { submit: _setup.handleSubmit },
        },
        [
          _c("lobster-input", {
            attrs: { label: "Remote Id of target", validators: "required" },
            model: {
              value: _setup.remoteId,
              callback: function ($$v) {
                _setup.remoteId = $$v
              },
              expression: "remoteId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }