<template>
	<lobster-search-filter
		search-disabled
		v-model="currentFilters"
		:menu-view="menuView"
		:show-group-labels="menuView"
		:show-flat-groups="$is(subjectType)"
		:filters="filters"
	/>
</template>

<script>
import { AnalyticsFilterConfig } from '../../../configs/AnalyticsFiltersConfig';
import { has_group, has_metric, SourceConfig } from '../../../configs/SourceConfig';
import { FilterTypes } from 'lobster-ui';
import { is } from 'plankton-esm';
import { get_autocomplete_load_function, get_load_by_id_function } from '../../../helpers/AnalyticsFilterHelper';


export default {
	name: "AnalyticsFilter",
	props: {
		value: {
			type: Object,
			default: () => ({})
		},
		menuView: {
			type: Boolean,
			default: true
		},
		isReportBuilder: {
			type: Boolean,
			default: false
		},
		isReportFilter: {
			type: Boolean,
			default: false
		},
		subjectType: String,
		reportsSubjectTypes: {
			type: Array,
			default: () => []
		},
	},
	computed: {
		filters()
		{
			let filters = {};
			
			if (is(this.subjectType))
			{
				let filterNames = this.getSourceFilters(this.subjectType);

				let dimensionsFilters = {};
				let metricsFilters = {};
				
				for (let filterName of filterNames)
				{
					let filterConfig = AnalyticsFilterConfig[filterName];
					
					if (filterConfig.isDimension)
					{
						dimensionsFilters[filterName] = this.getFilterConfig(filterName);
					}
					else if (filterConfig.isMetric)
					{
						metricsFilters[filterName] = this.getFilterConfig(filterName);
					}
				}
				
				if (this.isReportBuilder)
				{
					filters.dimensions = {
						children: dimensionsFilters,
						group: true,
						filterLabel: 'Dimensions'
					}
					
					filters.metrics = {
						children: metricsFilters,
						group: true,
						filterLabel: 'Metrics'
					}
				}
				else if (this.isReportFilter)
				{
					filters = {...dimensionsFilters}
				}
			}
			else if (is(this.reportsSubjectTypes))
			{
				for (let subjectType of this.reportsSubjectTypes)
				{
					let subjectFilters = {};
					let filterNames = this.getSourceFilters(subjectType);

					for (let filterName of filterNames)
					{
						let filterConfig = AnalyticsFilterConfig[filterName];

						if (filterConfig.isDimension || filterConfig.isTags)
						{
							subjectFilters[filterName] = this.getFilterConfig(filterName);
						}
					}
					
					filters[subjectType] = {
						group: true,
						filterLabel: SourceConfig[subjectType].name,
						children: subjectFilters
					}					
				}
			}
			
			return filters;
		},
		currentFilters: {
			get()
			{
				let filters = {};
				
				if (is(this.subjectType))
				{
					if (this.isReportBuilder)
					{
						filters = this.unmapReportBuilderFilterValue(this.value);
					}
					else if (this.isReportFilter)
					{
						filters = this.unmapReportFilterValue(this.value);
					}
				}
				else if (is(this.reportsSubjectTypes))
				{
					for (let reportType of this.reportsSubjectTypes)
					{
						filters[reportType] = this.unmapDashboardFilterValue(this.value[reportType], reportType);
					}
				}

				return {
					filters
				}
			},
			set(val)
			{
				let filters = {};

				if (is(this.subjectType))
				{
					filters = this.mapFilterValue(val.filters, this.subjectType);
				}
				else if (is(this.reportsSubjectTypes))
				{
					for (let reportType of this.reportsSubjectTypes)
					{
						filters[reportType] = this.mapFilterValue(val.filters[reportType], reportType);
					}
				}

				this.$emit('input', filters);
			}
		}
	},
	methods: {
		getSourceFilters(source)
		{
			if (!is(SourceConfig[source]))
			{
				return [];
			}
			
			let filters = [];

			for (let filterName of SourceConfig[source].filters)
			{
				if (!is(AnalyticsFilterConfig[filterName]))
				{
					continue;
				}

				filters.push(filterName)
			}
			
			return filters;
		},
		getFilterConfig(filterName)
		{
			let filterConfig = AnalyticsFilterConfig[filterName];
			let filter = {};
			
			if (!is(filterConfig))
			{
				return {}
			}
			
			filter.type = filterConfig.type;
			filter.filterLabel = filterConfig.label;
			
			if (filterConfig.type === FilterTypes.AUTOCOMPLETE)
			{
				filter.getOptions = get_autocomplete_load_function(filterConfig.url);
				filter.loadElementsByValue = get_load_by_id_function(filterConfig.url);
				filter.getLabel = option => option.Name;
				filter.getValue = option => option.ID;
			}
			else if (filterConfig.type === FilterTypes.SELECT)
			{
				if (typeof filterConfig.options === 'function')
				{
					filter.options = filterConfig.options();
				}
				else
				{
					filter.options = filterConfig.options;
				}
			}
			
			filter.multiple = filterConfig.multiple;
			filter.searchable = filterConfig.searchable;

			if (is.defined(filterConfig.getValue))
			{
				filter.getValue = filterConfig.getValue;
			}

			if (is.defined(filterConfig.getLabel))
			{
				filter.getLabel = filterConfig.getLabel;
			}

			if (is.defined(filterConfig.filter))
			{
				filter.filter = filterConfig.filter;
			}
			
			if (is.defined(filterConfig.icon))
			{
				filter.icon = filterConfig.icon;
			}
			
			return filter;
		},
		
		mapFilterValue(value, subjectType)
		{
			let filters = {
				dimensions: [],
				tags: [],
				metrics: []
			};

			for (let name in value)
			{
				if (!value.hasOwnProperty(name) || is.empty(value[name]) || is.undefined(AnalyticsFilterConfig[name]))
				{
					continue;
				}

				if (AnalyticsFilterConfig[name].isMetric && has_metric(subjectType, name))
				{
					filters.metrics.push(this.mapMetricsFilter(name, value[name]));

				}
				else if (AnalyticsFilterConfig[name].isTag && has_group(subjectType, name))
				{
					filters.tags = filters.tags.concat(this.mapTagsFilter(name, value[name]));

				}
				else if (AnalyticsFilterConfig[name].isDimension && has_group(subjectType, name))
				{
					filters.dimensions.push(this.mapDimensionFilter(name, value[name]));
				}
			}

			return filters;
		},
		mapMetricsFilter(name, value)
		{
			return {
				metric:   name,
				operator: value.operator,
				value:    value.value
			}
		},
		mapTagsFilter(name, value)
		{
			return value.map(filter => is(filter.value) ? ({
				column: name,
				operator: filter.operator,
				value: filter.value.split(', ')
			}) : ({
				column: name,
				operator: filter.operator
			}));
		},
		mapDimensionFilter(name, value)
		{
			return {
				column: name,
				values: this.$array(value)
			}
		},

		unmapReportFilterValue(value)
		{
			let hasFilter = (column) => this.filters.hasOwnProperty(column);

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapReportBuilderFilterValue(value)
		{
			let hasFilter = (column, type) =>
			{
				type = type === 'tags' ? 'dimensions' : type;

				return this.filters[type]?.children?.hasOwnProperty(column);
			};

			return this.unmapFilterValue(value, hasFilter);
		},
		unmapDashboardFilterValue(value, subjectType)
		{
			let hasFilter = (column) => this.filters[subjectType]?.children?.hasOwnProperty(column);

			return this.unmapFilterValue(value, hasFilter);
		},

		unmapFilterValue(value, hasFilter)
		{
			let filters = {};
			let {dimensions, tags, metrics} = this.sanitizeFilterValue(value);

			for (let filter of dimensions)
			{
				if (!hasFilter(filter.column, 'dimensions'))
					continue;

				filters[filter.column] = filter.values;
			}

			for (let filter of tags)
			{
				if (!hasFilter(filter.column, 'tags'))
					continue;

				if (!filters.hasOwnProperty(filter.column))
				{
					filters[filter.column] = []
				}

				filters[filter.column].push(this.unmapTagsFilter(filter));
			}

			for (let filter of metrics)
			{
				if (!hasFilter(filter.metric, 'metrics'))
					continue;

				filters[filter.metric] = this.unmapMetricsFilter(filter);
			}

			return filters;
		},
		
		unmapTagsFilter(filter)
		{
			if (is(filter.value))
			{
				return {
					operator: filter.operator,
					value: filter.value.join(', ')
				};
			}
			else
			{
				return {
					operator: filter.operator
				};
			}
		},
		unmapMetricsFilter(filter)
		{
			return {
				operator: filter.operator,
				value: filter.value
			}
		},
		
		sanitizeFilterValue(value)
		{
			let dimensions = [];
			let tags = [];
			let metrics = [];

			try {
				dimensions = JSON.parse(JSON.stringify(value.dimensions));
			}
			catch (e) {}

			try {
				tags = JSON.parse(JSON.stringify(value.tags));
			}
			catch (e) {}

			try {
				metrics = JSON.parse(JSON.stringify(value.metrics));
			}
			catch (e) {}

			return {dimensions, tags, metrics};
		},
	}
}
</script>

<style scoped>

</style>