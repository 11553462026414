import { DatePeriodType } from '../consts/DatePeriodType';
import moment from 'moment';
import { DimensionsConfig } from '../configs/DimensionsConfig';
import { is } from 'plankton-esm';
import { Network } from '../../utils';
import { CommonService } from '../services/CommonService';
import { ReportDataSourcesConfig } from '../configs/ReportDataSourcesConfig';
import { MetricsConfig } from '../configs/MetricsConfig';


export function getConvertedDatePeriod(type)
{
	let reportingCalendarConfig = CommonService.getReportingCalendarConfig();

	switch (type)
	{
		case DatePeriodType.LAST_7_DAYS:
			return {
				from: moment().add(-6, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_30_DAYS:
			return {
				from: moment().add(-29, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_60_DAYS:
			return {
				from: moment().add(-59, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_90_DAYS:
			return {
				from: moment().add(-89, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_120_DAYS:
			return {
				from: moment().add(-119, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_WEEK_SUNDAY:
			return {
				from: moment().startOf('week').add(reportingCalendarConfig.weekStart, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_WEEK_SUNDAY:
			return {
				from: moment().add(-1, 'week').startOf('week').add(reportingCalendarConfig.weekStart, 'day').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'week').endOf('week').add(reportingCalendarConfig.weekStart, 'day').format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_LAST_WEEK_SUNDAY:
			return {
				from: moment().add(-1, 'week').startOf('week').add(reportingCalendarConfig.weekStart, 'day').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_MONTH:
			return {
				from: moment().startOf('month').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_MONTH:
			return {
				from: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_3_MONTHS:
			return {
				from: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_6_MONTHS:
			return {
				from: moment().add(-6, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_12_MONTHS:
			return {
				from: moment().add(-12, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_LAST_MONTH:
			return {
				from: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_LAST_3_MONTHS:
			return {
				from: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')				};

		case DatePeriodType.CURRENT_LAST_6_MONTHS:
			return {
				from: moment().add(-6, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')				};

		case DatePeriodType.CURRENT_LAST_12_MONTHS:
			return {
				from: moment().add(-12, 'month').startOf('month').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')				};

		case DatePeriodType.CURRENT_QUARTER:
			return {
				from: moment().startOf('quarter').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_QUARTER:
			return {
				from: moment().add(-1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_LAST_QUARTER:
			return {
				from: moment().add(-1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_FISCAL_QUARTER:
		{
			let quarterOffset = (reportingCalendarConfig.fiscalYearStart - 1) % 3;
			let from = moment().startOf('quarter').add(quarterOffset, 'month');

			if (from.isAfter(moment()))
			{
				from.add(-1, 'quarter');
			}

			return {
				from: from.format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};
		}

		case DatePeriodType.LAST_FISCAL_QUARTER:
		{
			let quarterOffset = (reportingCalendarConfig.fiscalYearStart - 1) % 3;
			let to = moment().startOf('quarter').add(quarterOffset, 'month');

			if (to.isAfter(moment()))
			{
				to.add(-1, 'quarter');
			}

			let from = to.clone().add(-1, 'quarter');

			to.add(-1, 'day');

			return {
				from: from.format('YYYY-MM-DD'),
				to:   to.format('YYYY-MM-DD')
			};
		}

		case DatePeriodType.CURRENT_LAST_FISCAL_QUARTER:
			let quarterOffset = (reportingCalendarConfig.fiscalYearStart - 1) % 3;
			let from = moment().startOf('quarter').add(quarterOffset, 'month');

			if (from.isAfter(moment()))
			{
				from.add(-2, 'quarter');
			}
			else
			{
				from.add(-1, 'quarter');
			}

			return {
				from: from.format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_YEAR:
			return {
				from: moment().startOf('year').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.LAST_YEAR:
			return {
				from: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
				to:   moment().add(-1, 'year').endOf('year').format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_LAST_YEAR:
			return {
				from: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};

		case DatePeriodType.CURRENT_FISCAL_YEAR:
		{
			let from = moment().month(reportingCalendarConfig.fiscalYearStart - 1).startOf('month');

			if (from.isAfter(moment()))
			{
				from.add(-1, 'year');
			}

			return {
				from: from.format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};
		}

		case DatePeriodType.LAST_FISCAL_YEAR:
		{
			let to = moment().month(reportingCalendarConfig.fiscalYearStart - 1).startOf('month');

			if (to.isAfter(moment()))
			{
				to.add(-1, 'year');
			}

			let from = to.clone().add(-1, 'year');

			to.add(-1, 'day');

			return {
				from: from.format('YYYY-MM-DD'),
				to:  to.format('YYYY-MM-DD')
			};
		}

		case DatePeriodType.CURRENT_LAST_FISCAL_YEAR:
		{
			let from = moment().month(reportingCalendarConfig.fiscalYearStart - 1).startOf('month');

			if (from.isAfter(moment()))
			{
				from.add(-1, 'year');
			}

			from.add(-1, 'year');

			return {
				from: from.format('YYYY-MM-DD'),
				to:   moment().format('YYYY-MM-DD')
			};
		}

		default:
			return null;

	}
}

export function getAllGroupValues(groupBy, datePeriod)
{
	let reportingCalendarConfig = CommonService.getReportingCalendarConfig();

	let dateStart = moment(datePeriod.from);
	let dateEnd = moment(datePeriod.to);
	let groupValues = [];
	let dates = [];

	switch (groupBy)
	{
		case 'WeekDay':
		case 'CreatedWeekDay':
		case 'OpportunityClosedWeekDay':
		case 'SocialActivityWeekDay':
			while (dateEnd > dateStart || dateStart.format('d') === dateEnd.format('d')) {
				groupValues.push(dateStart.format('d'));
				dateStart.add(1, 'day');
			}

			return Array.from(Array(7).keys())
			.map(item => ((item + reportingCalendarConfig.weekStart) % 7).toString())
			.filter(item => groupValues.indexOf(item) !== -1);

		case 'DayHour':
		case 'CreatedDayHour':
			return Array.from(Array(24).keys()).map(item => item.toString());

		case 'YearMonth':
		case 'CreatedYearMonth':
		case 'OpportunityClosedYearMonth':
		case 'SocialActivityYearMonth':
			while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M'))
			{
				groupValues.push(dateStart.format('M'));
				dateStart.add(1,'month');
			}

			return Array.from(Array(12).keys())
				.map(item => (item + 1).toString())
				.filter(item => groupValues.indexOf(item) !== -1);
		case 'Date':
		case 'CreatedDay':
		case 'ActivityDate':
		case 'MetricDate':
		case 'RemoteCreated':
		case 'OpportunityClosedDate':
		case 'SocialActivityDate':
		{
			let current = dateStart;

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'days');
			}

			return dates;
		}

		case 'WeekSunday':
		case 'CreatedWeekSunday':
		case 'OpportunityClosedWeekSunday':
		case 'SocialActivityWeekSunday':
		{
			let current = dateStart.startOf('week');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'weeks');
			}

			return dates;
		}

		case 'Week':
		case 'CreatedWeek':
		case 'OpportunityClosedWeek':
		case 'SocialActivityWeek':
		{
			let current = dateStart.startOf('week');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'weeks');
			}

			return dates;
		}

		case 'Month':
		case 'CreatedMonth':
		case 'OpportunityClosedMonth':
		case 'SocialActivityMonth':
		{
			let current = dateStart.startOf('month');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'months');
			}

			return dates;
		}

		case 'Quarter':
		case 'CreatedQuarter':
		case 'OpportunityClosedQuarter':
		case 'SocialActivityQuarter':
		{
			let current = dateStart.startOf('quarter');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'quarters');
			}

			return dates;
		}

		case 'FiscalQuarter':
		case 'CreatedFiscalQuarter':
		case 'OpportunityClosedFiscalQuarter':
		case 'SocialActivityFiscalQuarter':
		{
			let current = dateStart.startOf('quarter').add((reportingCalendarConfig.fiscalYearStart - 1) % 3, 'months');

			if (current.isAfter(dateEnd))
			{
				current.add(-1, 'quarter');
			}

			while (current <= moment(datePeriod.to))
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'quarters');
			}

			return dates;
		}

		case 'Year':
		case 'CreatedYear':
		case 'OpportunityClosedYear':
		case 'SocialActivityYear':
		{
			let current = dateStart.startOf('year');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'years');
			}

			return dates;
		}
		case 'FiscalYear':
		case 'CreatedFiscalYear':
		case 'OpportunityClosedFiscalYear':
		case 'SocialActivityFiscalYear':
		{
			let current = dateStart.startOf('year').add((reportingCalendarConfig.fiscalYearStart - 1), 'months');

			while (current <= dateEnd)
			{
				dates.push(moment(current).format('YYYY-MM-DD'));
				current = moment(current).add(1, 'years');
			}

			return dates;
		}

		case 'Sentiment':
			return ['negative', 'neutral', 'positive'];
	}
}

export function getModifiedData(data = [])
{
	let reportingCalendarConfig = CommonService.getReportingCalendarConfig();

	if (reportingCalendarConfig.weekStart === 0 || !is.array(data))
	{
		return data;
	}

	return data.map(item =>
	{
		let newItem = {};

		for (let key in item)
		{
			if (DimensionsConfig[key]?.isWeekDay)
			{
				newItem[key] = `${(parseInt(item[key]) + 6) % 7}`;
			}
		}

		return {
			...item,
			...newItem
		}
	});
}

export function getModifiedFilter(filter)
{
	let reportingCalendarConfig = CommonService.getReportingCalendarConfig();

	if (!is(filter) || !is(filter.dimensions) || reportingCalendarConfig.weekStart === 0)
	{
		return filter;
	}

	let dimensionsFilters = filter.dimensions.map(dimension =>
	{
		let values = dimension.values;

		if (DimensionsConfig[dimension.column]?.isWeekDay)
		{
			values = values.map(value => `${(+value + 1) % 7}`);
		}

		return {
			...dimension,
			values
		}
	});

	return {
		...filter,
		dimensions: dimensionsFilters,
	}
}

function mergeEntities(oldEntities, newEntities)
{
	let entities = {};
	let keys = new Set([ ...Object.keys(oldEntities), ...Object.keys(newEntities) ]);

	for (let key of keys)
	{
		let keyEntities = {};

		if (is.object.notEmpty(oldEntities[key]))
		{
			keyEntities = {
				...keyEntities,
				...oldEntities[key]
			}
		}

		if (is.object.notEmpty(newEntities[key]))
		{
			keyEntities = {
				...keyEntities,
				...newEntities[key]
			}
		}

		entities[key] = keyEntities;
	}

	return entities;
}

export function mergeReportData(oldData, newData)
{
	let data = {};

	data.Data = [
		...oldData.Data,
		...newData.Data
	];
	data.Entities = mergeEntities(oldData.Entities, newData.Entities);
	data.Cursor = newData.Cursor;
	data.Pagination = newData.Pagination;

	return data;
}

export function getPostEntityOptions(cellName, entity)
{
	switch (cellName)
	{
		case 'PostlogID':
		case 'PostlogId':
			return {
				message: entity.ChildMessage ?? entity.Message,
				network: entity.Network,
				displayName: entity.DisplayName,
				thumbnail: entity.ImageLink,
				messageType: entity.Type,
				imageUrl: entity.ImageUrl,
				media: entity.Media,
				videoPreview: entity.VideoPreview
			}
		case 'StoryID':
			return {
				message: entity.Title,
				displayName: entity.DisplayName,
				messageType: entity.Type,
				imageUrl: entity.ImageUrl,
				media: entity.Media,
				videoPreview: entity.VideoPreview,
				hideThumbnail: true
			}
		case 'Thread':
			return {
				message: entity.Message,
				network: entity.Network,
				displayName: entity.DisplayName,
				thumbnail: entity.ImageLink,
				imageUrl: entity.ImageUrl,
			}
		case 'ItemID':
			let isTwitter = entity.Channel === Network.TWITTER;

			return {
				message: entity.Message,
				network: entity.Channel,
				displayName: isTwitter ? entity.DisplayName : entity.Site,
				userName: isTwitter ? entity.Username : entity.DisplayName,
				userUrl: isTwitter ? entity.ProfileUri : null,
				thumbnail: entity.ImageLink,
				messageType: entity.Type,
				imageUrl: entity.ImageUrl,
				media: entity.Media,
				videoPreview: entity.VideoPreview,
				showUsername: true
			}
		case 'RemoteID':
			return {
				message: entity.Text,
				network: entity.Platform,
				displayName: entity.ProfileName,
				thumbnail: entity.ProfileThumbnail
			}
		case 'SDBPostID':
		case 'ConversationID':
			return {
				message: entity.Text,
				network: entity.Network,
				displayName: entity.Name,
				thumbnail: entity.Picture
			}
		case 'CannedResponses':
			return {
				message: entity.Content,
				displayName: entity.Name,
				showUsername: true,
				hideThumbnail: true,
			}

		default:
			return {};
	}
}

export function getProfileEntityOptions(cellName, entity)
{
	switch (cellName)
	{
		case 'CredentialID':
		case 'MentionedCredentialID':
		case 'ProfileId':
		case 'LinkedinProfileId':
			return {
				thumbnail: entity.ImageLink,
				network: entity.Network,
				displayName:  entity.DisplayName
			}
		case 'ProfileRemoteID':
			return {
				thumbnail: entity.ProfileThumbnail,
				network: entity.Platform,
				displayName:  entity.ProfileName
			}
		case 'UserID':
		case 'AssigneeID':
			return {
				displayName: entity.Name,
				thumbnail: entity.PictureUrl,
				gravatar: entity.Email,
				subtitle: entity.Email,
			}
		case 'LeadId':
		case 'LeadID':
			return {
				thumbnail: entity.PrimaryPhoto,
				displayName: entity.PrimaryName
			}
		case 'ProfileID':
		case 'SDBID':
		case 'SDBAuthorID':
			return {
				thumbnail: entity.Picture,
				network: entity.Network,
				displayName: entity.Name
			}
		case 'OpportunityOwnerID':
			return {
				displayName: entity.OpportunityOwnerName,
				gravatar: entity.OpportunityOwnerEmail
			}
		default:
			return {};
	}
}

export function fillZeroMetricValues(tabla, group, metric)
{
	let dimensionConfig = DimensionsConfig[group];
	let order = 1;

	if (dimensionConfig.numericSort && dimensionConfig.numericSort === 'desc')
	{
		order = -1;
	}

	let lastIndex = 0;
	let lastValue = 0;
	let data = [...tabla.data].sort((a, b) =>
	{
		if (a[group] > b[group])
		{
			return order;
		}
		else
		{
			return -order;
		}
	});

	for (let i = data.length - 1; i >= 0; i--)
	{
		if (data[i][metric] > 0)
		{
			lastIndex = i;
			lastValue = data[i].Followers;
			break;
		}
	}

	for (let i = lastIndex; i < data.length; i++)
	{
		data[i].Followers = lastValue;
	}
}

export function getSourceDimensions(subjectType)
{
	if (!is(ReportDataSourcesConfig[subjectType]?.dimensions))
	{
		return [];
	}

	let sourceDimensions = [ ...ReportDataSourcesConfig[subjectType].dimensions ];
	let isTemplateAccount = CommonService.getIsTemplateAccount();

	if (isTemplateAccount && is(ReportDataSourcesConfig[subjectType]?.templateDimensions))
	{
		sourceDimensions = [
			...sourceDimensions,
			...ReportDataSourcesConfig[subjectType].templateDimensions
		]
	}

	return sourceDimensions;
}

export function getSourceMetrics(subjectType)
{
	if (!is(ReportDataSourcesConfig[subjectType]?.metrics))
	{
		return [];
	}

	let sourceMetrics = [ ...ReportDataSourcesConfig[subjectType].metrics ];
	let isTemplateAccount = CommonService.getIsTemplateAccount();

	if (isTemplateAccount && is(ReportDataSourcesConfig[subjectType]?.templateMetrics))
	{
		sourceMetrics = [
			...sourceMetrics,
			...ReportDataSourcesConfig[subjectType].templateMetrics
		]
	}

	return sourceMetrics.sort((a, b) =>
	{
		if (MetricsConfig[a].name > MetricsConfig[b].name)
		{
			return 1;
		}
		if (MetricsConfig[a].name < MetricsConfig[b].name)
		{
			return -1;
		}
		else
		{
			return 0;
		}
	});
}