<template>
	<lobster-select
		class="date-select"
		:value="currentValue"
		:clearable="false"
		:searchable="false"
		:options="currentOptions"
		:reduce="option => option.value"
		@option:selecting="handleOptionSelecting">
		<template #selected-option="value">
			{{ currentValueLabel }}
		</template>
	</lobster-select>
</template>

<script>
import { DatePeriodTypes } from '../../../configs/DatePeriodTypes';
import { format_date_uts } from 'kraken-charts/src/helpers/FormattersHelper';


export default {
	name: 'DateSelect',
	props: {
		value: {
			type: Object,
			default()
			{
				return {}
			}
		},
		options: {
			type: Array,
			default()
			{
				return []
			}
		}
	},
	data()
	{
		return {

		}
	},
	computed: {
		currentValue()
		{
			if (this.$is(this.value.from) && this.$is(this.value.to))
			{
				return 'custom'
			}
			
			return this.value.type;
		},
		currentValueLabel()
		{
			if (this.$is(this.value.from) && this.$is(this.value.to))
			{
				let start = format_date_uts(this.value.from);
				let end = format_date_uts(this.value.to);

				return `${start} - ${end}`;
			}
			
			return DatePeriodTypes[this.value.type]?.name ?? this.value.type;
		},
		currentOptions()
		{
			let custom = {
				value: 'custom',
				label: 'Custom Date'
			};
			
			return [custom, ...this.options];
		}
	},
	methods: {
		handleOptionSelecting(option)
		{
			this.$emit('input', option.value)
		}
	}
}
</script>

<style lang="scss" scoped>
.date-select {
	width: 220px;
}
</style>