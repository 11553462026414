<template>
    <div class="date-picker">
        <div class="date-picker__row">
            <div class="date-picker__col">
                <div class="date-picker__col-header">
                    <span>Start Date</span>
                    <span>{{formatUserDateUTC(startDate, false)}}</span>
                </div>
                <v-date-picker
                    ref="startDatePicker"
                    v-model="startDate"
                    :max-date="endDate"
                    is-required
                />
            </div>
            <div class="date-picker__col">
                <div class="date-picker__col-header">
                    <span>End Date</span>
                    <span>{{formatUserDateUTC(endDate, false)}}</span>
                </div>
                <v-date-picker
                    ref="endDatePicker"
                    v-model="endDate"
                    :min-date="startDate"
                    is-required
                />
            </div>
        </div>
        <lobster-menu-footer class="date-picker__footer">
            <lobster-button
                secondary
                v-close-popover>
				Cancel
            </lobster-button>
            <lobster-button
                @click="handleDatesSelected">
                Set
            </lobster-button>
        </lobster-menu-footer>
    </div>
</template>


<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import DateHelper from '../../../helpers/DateHelper';


export default {
    props: ['datePeriod'],
	name: 'DatePeriod',
    components:
        {
			VDatePicker,
            DateHelper
        },
    data()
    {
        return {
            selectedStartDate: null,
            selectedEndDate: null
        }
    },
    watch: {
        datePeriod()
        {
            if (is(this.datePeriod.from))
            {
                this.selectedStartDate = new Date(window.moment(this.datePeriod.from).format());
            }
            else
            {
                let d = new Date();
                d.setMonth(d.getMonth() - 1);
                this.selectedStartDate = d;
            }

            if (is(this.datePeriod.to))
            {
                this.selectedEndDate = new Date(window.moment(this.datePeriod.to).format());
            }
            else
            {
                this.selectedEndDate = new Date();
            }
        }
    },
    computed:
        {
            startDate:
                {
                    get()
                    {
                        if (this.selectedStartDate)
                        {
                            return this.selectedStartDate;
                        }
                        else if (is(this.datePeriod.from))
                        {
                            return new Date(window.moment(this.datePeriod.from).format());
                        }
                        else
                        {
                            let d = new Date();
                            d.setMonth(d.getMonth() - 1);
                            return d;
                        }
                    },
                    set(date) { this.selectedStartDate = date }
                },
            endDate:
                {
                    get()
                    {
                        if (this.selectedEndDate)
                        {
                            return this.selectedEndDate;
                        }
                        else if (is(this.datePeriod.to))
                        {
                            return new Date(window.moment(this.datePeriod.to).format());
                        }
                        else
                        {
                            return new Date();
                        }
                    },
                    set(date) { this.selectedEndDate = date }
                }
        },
    created()
    {
        this.formatUserDateUTC = DateHelper.formatUserDateUTC;
    },
    methods: {
        handleDatesSelected() {
            this.selectedStartDate = this.startDate;
            this.selectedEndDate = this.endDate;

            this.$emit('dates-selected', this.selectedStartDate, this.selectedEndDate);
        }
    }
};
</script>


<style lang="scss" scoped>
.date-picker {
    width: 580px;

    &:deep(.vc-container) {
		--blue-600: #{$colorPrimary};
        border: none;
    }

    &:deep(.vc-title) {
        font-family: $fontFamilyPrimary;
        padding: 0 8px;
    }

    &:deep(.vc-arrows-container) {
        padding: 8px 5px;
    }

    &:deep(.vc-header) {
        padding: 10px 10px 0;
    }

    &:deep(.vc-weekday) {
        font-family: $fontFamilyPrimary;
        color: $colorTextSecondary;
    }

    &:deep(.vc-day-content) {
        font-size: 14px;
        font-family: $fontFamilyPrimary;
        line-height: 22px;
        color: $colorTextPrimary;
        width: 30px;
        height: 30px;
        margin: 3px auto;
    }

    &:deep(.vc-day-content.is-disabled) {
        color: $colorDisabled;
    }
}

.date-picker__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-picker__col {
    padding: 16px 16px 0;
    flex-shrink: 0;
}

.date-picker__col-header {
    display: flex;
    justify-content: space-between;
    font-size: $fontSizeCaption;
}

.date-picker__footer {
	justify-content: space-between;
}


@media (max-width: $mobileWidth) {
	.date-picker {
		width: 100%;
	}

	.date-picker__row {
		flex-direction: column;
		align-items: center;
	}
}
</style>

<style lang="scss">


.vc-container {
    border: none;

    font-family: inherit;
    --arrows-padding: 8px 5px;
    --arrow-font-size: 26px;
    --weekday-padding: 5px 0;
    --day-content-margin: 3px auto;
    --day-min-height: 30px;
    --day-content-width: 30px;
    --day-content-height: 30px;

    .vc-grid-container {
        width: 100%;
        display: -ms-grid;

        &.vc-weeks {
            padding: 5px 6px 7px 6px;
        }

        .vc-day {
            &.is-today {
                .vc-day-content {
                    background-color: $colorPrimaryTint90;
                    font-weight: 600;

                    &:hover {
                        background-color: $colorBgLight;
                    }
                }

                .vc-highlights + .vc-day-content {
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.vc-day-content {
    min-height: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.1rem auto;

    &:hover {
        background-color: $colorBgLight;
    }
}

.vc-border {
    border: none;
}

.vc-text-gray-900 {
    color: $colorTextPrimary
}

.vc-text-gray-500 {
    color: $colorTextSecondary;
}

.vc-text-gray-400 {
    color: $colorTextSecondary;
}

.vc-bg-blue-600 {
    color: $colorPrimary;
}
</style>