<script setup>
import {onMounted, ref} from 'vue';
import { useToaster } from '../../../hooks/toaster';
import AdminYuri from '../../../utils/yuri/AdminYuri';


const toggle = ref();
const toaster = useToaster();

let currentStatus = ref(false);

onMounted(() =>
{
    getCurrentStatus();
});

async function handleSubmit(value)
{
    try
    {
       await AdminYuri.post('/qa-tools/toggle-twitter-activity', { toggle: value});
    }
    catch
    {
       currentStatus.value = !value;
    }
}

async function getCurrentStatus()
{
    let res = await AdminYuri.get('/qa-tools/get-twitter-activity-status');
    currentStatus.value = res.Status;
}

</script>

<template>
    <lobster-switch
        v-model="currentStatus"
        class="twitter-toggle"
        label="Toggle Twitter Activity"
        @input="handleSubmit"/>
</template>

<style scoped lang="scss">
    .twitter-toggle {
        max-width: 400px;
    }
</style>