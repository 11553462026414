export const INDUSTRY_OPTIONS = Object.freeze({
	"1": {
		"id": "1",
		"label": "Defense and Space Manufacturing"
	},
	"3": {
		"id": "3",
		"label": "Computer Hardware Manufacturing"
	},
	"4": {
		"id": "4",
		"label": "Software Development"
	},
	"5": {
		"id": "5",
		"label": "Computer Networking Products"
	},
	"6": {
		"id": "6",
		"label": "Technology, Information and Internet"
	},
	"7": {
		"id": "7",
		"label": "Semiconductor Manufacturing"
	},
	"8": {
		"id": "8",
		"label": "Telecommunications"
	},
	"9": {
		"id": "9",
		"label": "Law Practice"
	},
	"10": {
		"id": "10",
		"label": "Legal Services"
	},
	"11": {
		"id": "11",
		"label": "Business Consulting and Services"
	},
	"12": {
		"id": "12",
		"label": "Biotechnology Research"
	},
	"13": {
		"id": "13",
		"label": "Medical Practices"
	},
	"14": {
		"id": "14",
		"label": "Hospitals and Health Care"
	},
	"15": {
		"id": "15",
		"label": "Pharmaceutical Manufacturing"
	},
	"16": {
		"id": "16",
		"label": "Veterinary Services"
	},
	"17": {
		"id": "17",
		"label": "Medical Equipment Manufacturing"
	},
	"18": {
		"id": "18",
		"label": "Personal Care Product Manufacturing"
	},
	"19": {
		"id": "19",
		"label": "Retail Apparel and Fashion"
	},
	"20": {
		"id": "20",
		"label": "Sporting Goods Manufacturing"
	},
	"21": {
		"id": "21",
		"label": "Tobacco Manufacturing"
	},
	"22": {
		"id": "22",
		"label": "Retail Groceries"
	},
	"23": {
		"id": "23",
		"label": "Food and Beverage Manufacturing"
	},
	"24": {
		"id": "24",
		"label": "Computers and Electronics Manufacturing"
	},
	"25": {
		"id": "25",
		"label": "Manufacturing"
	},
	"26": {
		"id": "26",
		"label": "Furniture and Home Furnishings Manufacturing"
	},
	"27": {
		"id": "27",
		"label": "Retail"
	},
	"28": {
		"id": "28",
		"label": "Entertainment Providers"
	},
	"29": {
		"id": "29",
		"label": "Gambling Facilities and Casinos"
	},
	"30": {
		"id": "30",
		"label": "Travel Arrangements"
	},
	"31": {
		"id": "31",
		"label": "Hospitality"
	},
	"32": {
		"id": "32",
		"label": "Restaurants"
	},
	"33": {
		"id": "33",
		"label": "Spectator Sports"
	},
	"34": {
		"id": "34",
		"label": "Food and Beverage Services"
	},
	"35": {
		"id": "35",
		"label": "Movies, Videos and Sound"
	},
	"36": {
		"id": "36",
		"label": "Broadcast Media Production and Distribution"
	},
	"37": {
		"id": "37",
		"label": "Museums, Historical Sites, and Zoos"
	},
	"38": {
		"id": "38",
		"label": "Artists and Writers"
	},
	"39": {
		"id": "39",
		"label": "Performing Arts"
	},
	"40": {
		"id": "40",
		"label": "Recreational Facilities"
	},
	"41": {
		"id": "41",
		"label": "Banking"
	},
	"42": {
		"id": "42",
		"label": "Insurance"
	},
	"43": {
		"id": "43",
		"label": "Financial Services"
	},
	"44": {
		"id": "44",
		"label": "Real Estate"
	},
	"45": {
		"id": "45",
		"label": "Investment Banking"
	},
	"46": {
		"id": "46",
		"label": "Investment Management"
	},
	"47": {
		"id": "47",
		"label": "Accounting"
	},
	"48": {
		"id": "48",
		"label": "Construction"
	},
	"49": {
		"id": "49",
		"label": "Wholesale Building Materials"
	},
	"50": {
		"id": "50",
		"label": "Architecture and Planning"
	},
	"51": {
		"id": "51",
		"label": "Civil Engineering"
	},
	"52": {
		"id": "52",
		"label": "Aviation and Aerospace Component Manufacturing"
	},
	"53": {
		"id": "53",
		"label": "Motor Vehicle Manufacturing"
	},
	"54": {
		"id": "54",
		"label": "Chemical Manufacturing"
	},
	"55": {
		"id": "55",
		"label": "Machinery Manufacturing"
	},
	"56": {
		"id": "56",
		"label": "Mining"
	},
	"57": {
		"id": "57",
		"label": "Oil and Gas"
	},
	"58": {
		"id": "58",
		"label": "Shipbuilding"
	},
	"59": {
		"id": "59",
		"label": "Utilities"
	},
	"60": {
		"id": "60",
		"label": "Textile Manufacturing"
	},
	"61": {
		"id": "61",
		"label": "Paper and Forest Product Manufacturing"
	},
	"62": {
		"id": "62",
		"label": "Railroad Equipment Manufacturing"
	},
	"63": {
		"id": "63",
		"label": "Farming"
	},
	"64": {
		"id": "64",
		"label": "Ranching"
	},
	"65": {
		"id": "65",
		"label": "Dairy Product Manufacturing"
	},
	"66": {
		"id": "66",
		"label": "Fisheries"
	},
	"67": {
		"id": "67",
		"label": "Primary and Secondary Education"
	},
	"68": {
		"id": "68",
		"label": "Higher Education"
	},
	"69": {
		"id": "69",
		"label": "Education Administration Programs"
	},
	"70": {
		"id": "70",
		"label": "Research Services"
	},
	"71": {
		"id": "71",
		"label": "Armed Forces"
	},
	"72": {
		"id": "72",
		"label": "Legislative Offices"
	},
	"73": {
		"id": "73",
		"label": "Administration of Justice"
	},
	"74": {
		"id": "74",
		"label": "International Affairs"
	},
	"75": {
		"id": "75",
		"label": "Government Administration"
	},
	"76": {
		"id": "76",
		"label": "Executive Offices"
	},
	"77": {
		"id": "77",
		"label": "Law Enforcement"
	},
	"78": {
		"id": "78",
		"label": "Public Safety"
	},
	"79": {
		"id": "79",
		"label": "Public Policy Offices"
	},
	"80": {
		"id": "80",
		"label": "Advertising Services"
	},
	"81": {
		"id": "81",
		"label": "Newspaper Publishing"
	},
	"82": {
		"id": "82",
		"label": "Book and Periodical Publishing"
	},
	"83": {
		"id": "83",
		"label": "Printing Services"
	},
	"84": {
		"id": "84",
		"label": "Information Services"
	},
	"85": {
		"id": "85",
		"label": "Libraries"
	},
	"86": {
		"id": "86",
		"label": "Environmental Services"
	},
	"87": {
		"id": "87",
		"label": "Freight and Package Transportation"
	},
	"88": {
		"id": "88",
		"label": "Individual and Family Services"
	},
	"89": {
		"id": "89",
		"label": "Religious Institutions"
	},
	"90": {
		"id": "90",
		"label": "Civic and Social Organizations"
	},
	"91": {
		"id": "91",
		"label": "Consumer Services"
	},
	"92": {
		"id": "92",
		"label": "Truck Transportation"
	},
	"93": {
		"id": "93",
		"label": "Warehousing and Storage"
	},
	"94": {
		"id": "94",
		"label": "Airlines and Aviation"
	},
	"95": {
		"id": "95",
		"label": "Maritime Transportation"
	},
	"96": {
		"id": "96",
		"label": "IT Services and IT Consulting"
	},
	"97": {
		"id": "97",
		"label": "Market Research"
	},
	"98": {
		"id": "98",
		"label": "Public Relations and Communications Services"
	},
	"99": {
		"id": "99",
		"label": "Design Services"
	},
	"100": {
		"id": "100",
		"label": "Non-profit Organizations"
	},
	"101": {
		"id": "101",
		"label": "Fundraising"
	},
	"102": {
		"id": "102",
		"label": "Strategic Management Services"
	},
	"103": {
		"id": "103",
		"label": "Writing and Editing"
	},
	"104": {
		"id": "104",
		"label": "Staffing and Recruiting"
	},
	"105": {
		"id": "105",
		"label": "Professional Training and Coaching"
	},
	"106": {
		"id": "106",
		"label": "Venture Capital and Private Equity Principals"
	},
	"107": {
		"id": "107",
		"label": "Political Organizations"
	},
	"108": {
		"id": "108",
		"label": "Translation and Localization"
	},
	"109": {
		"id": "109",
		"label": "Computer Games"
	},
	"110": {
		"id": "110",
		"label": "Events Services"
	},
	"111": {
		"id": "111",
		"label": "Retail Art Supplies"
	},
	"112": {
		"id": "112",
		"label": "Appliances, Electrical, and Electronics Manufacturing"
	},
	"113": {
		"id": "113",
		"label": "Online Audio and Video Media"
	},
	"114": {
		"id": "114",
		"label": "Nanotechnology Research"
	},
	"115": {
		"id": "115",
		"label": "Musicians"
	},
	"116": {
		"id": "116",
		"label": "Transportation, Logistics, Supply Chain and Storage"
	},
	"117": {
		"id": "117",
		"label": "Plastics Manufacturing"
	},
	"118": {
		"id": "118",
		"label": "Computer and Network Security"
	},
	"119": {
		"id": "119",
		"label": "Wireless Services"
	},
	"120": {
		"id": "120",
		"label": "Alternative Dispute Resolution"
	},
	"121": {
		"id": "121",
		"label": "Security and Investigations"
	},
	"122": {
		"id": "122",
		"label": "Facilities Services"
	},
	"123": {
		"id": "123",
		"label": "Outsourcing and Offshoring Consulting"
	},
	"124": {
		"id": "124",
		"label": "Wellness and Fitness Services"
	},
	"125": {
		"id": "125",
		"label": "Alternative Medicine"
	},
	"126": {
		"id": "126",
		"label": "Media Production"
	},
	"127": {
		"id": "127",
		"label": "Animation and Post-production"
	},
	"128": {
		"id": "128",
		"label": "Leasing Non-residential Real Estate"
	},
	"129": {
		"id": "129",
		"label": "Capital Markets"
	},
	"130": {
		"id": "130",
		"label": "Think Tanks"
	},
	"131": {
		"id": "131",
		"label": "Philanthropic Fundraising Services"
	},
	"132": {
		"id": "132",
		"label": "E-Learning Providers"
	},
	"133": {
		"id": "133",
		"label": "Wholesale"
	},
	"134": {
		"id": "134",
		"label": "Wholesale Import and Export"
	},
	"135": {
		"id": "135",
		"label": "Industrial Machinery Manufacturing"
	},
	"136": {
		"id": "136",
		"label": "Photography"
	},
	"137": {
		"id": "137",
		"label": "Human Resources Services"
	},
	"138": {
		"id": "138",
		"label": "Retail Office Equipment"
	},
	"139": {
		"id": "139",
		"label": "Mental Health Care"
	},
	"140": {
		"id": "140",
		"label": "Graphic Design"
	},
	"141": {
		"id": "141",
		"label": "International Trade and Development"
	},
	"142": {
		"id": "142",
		"label": "Beverage Manufacturing"
	},
	"143": {
		"id": "143",
		"label": "Retail Luxury Goods and Jewelry"
	},
	"144": {
		"id": "144",
		"label": "Renewable Energy Semiconductor Manufacturing"
	},
	"145": {
		"id": "145",
		"label": "Glass, Ceramics and Concrete Manufacturing"
	},
	"146": {
		"id": "146",
		"label": "Packaging and Containers Manufacturing"
	},
	"147": {
		"id": "147",
		"label": "Automation Machinery Manufacturing"
	},
	"148": {
		"id": "148",
		"label": "Government Relations Services"
	},
	"150": {
		"id": "150",
		"label": "Horticulture"
	},
	"201": {
		"id": "201",
		"label": "Farming, Ranching, Forestry"
	},
	"256": {
		"id": "256",
		"label": "Ranching and Fisheries"
	},
	"298": {
		"id": "298",
		"label": "Forestry and Logging"
	},
	"332": {
		"id": "332",
		"label": "Oil, Gas, and Mining"
	},
	"341": {
		"id": "341",
		"label": "Coal Mining"
	},
	"345": {
		"id": "345",
		"label": "Metal Ore Mining"
	},
	"356": {
		"id": "356",
		"label": "Nonmetallic Mineral Mining"
	},
	"382": {
		"id": "382",
		"label": "Electric Power Transmission, Control, and Distribution"
	},
	"383": {
		"id": "383",
		"label": "Electric Power Generation"
	},
	"384": {
		"id": "384",
		"label": "Hydroelectric Power Generation"
	},
	"385": {
		"id": "385",
		"label": "Fossil Fuel Electric Power Generation"
	},
	"386": {
		"id": "386",
		"label": "Nuclear Electric Power Generation"
	},
	"387": {
		"id": "387",
		"label": "Solar Electric Power Generation"
	},
	"388": {
		"id": "388",
		"label": "Environmental Quality Programs"
	},
	"389": {
		"id": "389",
		"label": "Geothermal Electric Power Generation"
	},
	"390": {
		"id": "390",
		"label": "Biomass Electric Power Generation"
	},
	"397": {
		"id": "397",
		"label": "Natural Gas Distribution"
	},
	"398": {
		"id": "398",
		"label": "Water, Waste, Steam, and Air Conditioning Services"
	},
	"400": {
		"id": "400",
		"label": "Water Supply and Irrigation Systems"
	},
	"404": {
		"id": "404",
		"label": "Steam and Air-Conditioning Supply"
	},
	"406": {
		"id": "406",
		"label": "Building Construction"
	},
	"408": {
		"id": "408",
		"label": "Residential Building Construction"
	},
	"413": {
		"id": "413",
		"label": "Nonresidential Building Construction"
	},
	"419": {
		"id": "419",
		"label": "Utility System Construction"
	},
	"428": {
		"id": "428",
		"label": "Subdivision of Land"
	},
	"431": {
		"id": "431",
		"label": "Highway, Street, and Bridge Construction"
	},
	"435": {
		"id": "435",
		"label": "Specialty Trade Contractors"
	},
	"436": {
		"id": "436",
		"label": "Building Structure and Exterior Contractors"
	},
	"453": {
		"id": "453",
		"label": "Building Equipment Contractors"
	},
	"460": {
		"id": "460",
		"label": "Building Finishing Contractors"
	},
	"481": {
		"id": "481",
		"label": "Animal Feed Manufacturing"
	},
	"495": {
		"id": "495",
		"label": "Sugar and Confectionery Product Manufacturing"
	},
	"504": {
		"id": "504",
		"label": "Fruit and Vegetable Preserves Manufacturing"
	},
	"521": {
		"id": "521",
		"label": "Meat Products Manufacturing"
	},
	"528": {
		"id": "528",
		"label": "Seafood Product Manufacturing"
	},
	"529": {
		"id": "529",
		"label": "Baked Goods Manufacturing"
	},
	"562": {
		"id": "562",
		"label": "Breweries"
	},
	"564": {
		"id": "564",
		"label": "Distilleries"
	},
	"598": {
		"id": "598",
		"label": "Apparel Manufacturing"
	},
	"615": {
		"id": "615",
		"label": "Fashion Accessories Manufacturing"
	},
	"616": {
		"id": "616",
		"label": "Leather Product Manufacturing"
	},
	"622": {
		"id": "622",
		"label": "Footwear Manufacturing"
	},
	"625": {
		"id": "625",
		"label": "Women's Handbag Manufacturing"
	},
	"679": {
		"id": "679",
		"label": "Oil and Coal Product Manufacturing"
	},
	"690": {
		"id": "690",
		"label": "Chemical Raw Materials Manufacturing"
	},
	"703": {
		"id": "703",
		"label": "Artificial Rubber and Synthetic Fiber Manufacturing"
	},
	"709": {
		"id": "709",
		"label": "Agricultural Chemical Manufacturing"
	},
	"722": {
		"id": "722",
		"label": "Paint, Coating, and Adhesive Manufacturing"
	},
	"727": {
		"id": "727",
		"label": "Soap and Cleaning Product Manufacturing"
	},
	"743": {
		"id": "743",
		"label": "Plastics and Rubber Product Manufacturing"
	},
	"763": {
		"id": "763",
		"label": "Rubber Products Manufacturing"
	},
	"773": {
		"id": "773",
		"label": "Clay and Refractory Products Manufacturing"
	},
	"779": {
		"id": "779",
		"label": "Glass Product Manufacturing"
	},
	"784": {
		"id": "784",
		"label": "Wood Product Manufacturing"
	},
	"794": {
		"id": "794",
		"label": "Lime and Gypsum Products Manufacturing"
	},
	"799": {
		"id": "799",
		"label": "Abrasives and Nonmetallic Minerals Manufacturing"
	},
	"807": {
		"id": "807",
		"label": "Primary Metal Manufacturing"
	},
	"840": {
		"id": "840",
		"label": "Fabricated Metal Products"
	},
	"849": {
		"id": "849",
		"label": "Cutlery and Handtool Manufacturing"
	},
	"852": {
		"id": "852",
		"label": "Architectural and Structural Metal Manufacturing"
	},
	"861": {
		"id": "861",
		"label": "Boilers, Tanks, and Shipping Container Manufacturing"
	},
	"871": {
		"id": "871",
		"label": "Construction Hardware Manufacturing"
	},
	"873": {
		"id": "873",
		"label": "Spring and Wire Product Manufacturing"
	},
	"876": {
		"id": "876",
		"label": "Turned Products and Fastener Manufacturing"
	},
	"883": {
		"id": "883",
		"label": "Metal Treatments"
	},
	"887": {
		"id": "887",
		"label": "Metal Valve, Ball, and Roller Manufacturing"
	},
	"901": {
		"id": "901",
		"label": "Agriculture, Construction, Mining Machinery Manufacturing"
	},
	"918": {
		"id": "918",
		"label": "Commercial and Service Industry Machinery Manufacturing"
	},
	"923": {
		"id": "923",
		"label": "HVAC and Refrigeration Equipment Manufacturing"
	},
	"928": {
		"id": "928",
		"label": "Metalworking Machinery Manufacturing"
	},
	"935": {
		"id": "935",
		"label": "Engines and Power Transmission Equipment Manufacturing"
	},
	"964": {
		"id": "964",
		"label": "Communications Equipment Manufacturing"
	},
	"973": {
		"id": "973",
		"label": "Audio and Video Equipment Manufacturing"
	},
	"983": {
		"id": "983",
		"label": "Measuring and Control Instrument Manufacturing"
	},
	"994": {
		"id": "994",
		"label": "Magnetic and Optical Media Manufacturing"
	},
	"998": {
		"id": "998",
		"label": "Electric Lighting Equipment Manufacturing"
	},
	"1005": {
		"id": "1005",
		"label": "Household Appliance Manufacturing"
	},
	"1029": {
		"id": "1029",
		"label": "Transportation Equipment Manufacturing"
	},
	"1042": {
		"id": "1042",
		"label": "Motor Vehicle Parts Manufacturing"
	},
	"1080": {
		"id": "1080",
		"label": "Household and Institutional Furniture Manufacturing"
	},
	"1090": {
		"id": "1090",
		"label": "Office Furniture and Fixtures Manufacturing"
	},
	"1095": {
		"id": "1095",
		"label": "Mattress and Blinds Manufacturing"
	},
	"1128": {
		"id": "1128",
		"label": "Wholesale Motor Vehicles and Parts"
	},
	"1137": {
		"id": "1137",
		"label": "Wholesale Furniture and Home Furnishings"
	},
	"1153": {
		"id": "1153",
		"label": "Wholesale Photography Equipment and Supplies"
	},
	"1157": {
		"id": "1157",
		"label": "Wholesale Computer Equipment"
	},
	"1166": {
		"id": "1166",
		"label": "Wholesale Metals and Minerals"
	},
	"1171": {
		"id": "1171",
		"label": "Wholesale Appliances, Electrical, and Electronics"
	},
	"1178": {
		"id": "1178",
		"label": "Wholesale Hardware, Plumbing, Heating Equipment"
	},
	"1187": {
		"id": "1187",
		"label": "Wholesale Machinery"
	},
	"1206": {
		"id": "1206",
		"label": "Wholesale Recyclable Materials"
	},
	"1208": {
		"id": "1208",
		"label": "Wholesale Luxury Goods and Jewelry"
	},
	"1212": {
		"id": "1212",
		"label": "Wholesale Paper Products"
	},
	"1221": {
		"id": "1221",
		"label": "Wholesale Drugs and Sundries"
	},
	"1222": {
		"id": "1222",
		"label": "Wholesale Apparel and Sewing Supplies"
	},
	"1230": {
		"id": "1230",
		"label": "Wholesale Footwear"
	},
	"1231": {
		"id": "1231",
		"label": "Wholesale Food and Beverage"
	},
	"1250": {
		"id": "1250",
		"label": "Wholesale Raw Farm Products"
	},
	"1257": {
		"id": "1257",
		"label": "Wholesale Chemical and Allied Products"
	},
	"1262": {
		"id": "1262",
		"label": "Wholesale Petroleum and Petroleum Products"
	},
	"1267": {
		"id": "1267",
		"label": "Wholesale Alcoholic Beverages"
	},
	"1285": {
		"id": "1285",
		"label": "Internet Marketplace Platforms"
	},
	"1292": {
		"id": "1292",
		"label": "Retail Motor Vehicles"
	},
	"1309": {
		"id": "1309",
		"label": "Retail Furniture and Home Furnishings"
	},
	"1319": {
		"id": "1319",
		"label": "Retail Appliances, Electrical, and Electronic Equipment"
	},
	"1324": {
		"id": "1324",
		"label": "Retail Building Materials and Garden Equipment"
	},
	"1339": {
		"id": "1339",
		"label": "Food and Beverage Retail"
	},
	"1359": {
		"id": "1359",
		"label": "Retail Health and Personal Care Products"
	},
	"1370": {
		"id": "1370",
		"label": "Retail Gasoline"
	},
	"1407": {
		"id": "1407",
		"label": "Retail Musical Instruments"
	},
	"1409": {
		"id": "1409",
		"label": "Retail Books and Printed News"
	},
	"1423": {
		"id": "1423",
		"label": "Retail Florists"
	},
	"1424": {
		"id": "1424",
		"label": "Retail Office Supplies and Gifts"
	},
	"1431": {
		"id": "1431",
		"label": "Retail Recyclable Materials & Used Merchandise"
	},
	"1445": {
		"id": "1445",
		"label": "Online and Mail Order Retail"
	},
	"1481": {
		"id": "1481",
		"label": "Rail Transportation"
	},
	"1495": {
		"id": "1495",
		"label": "Ground Passenger Transportation"
	},
	"1497": {
		"id": "1497",
		"label": "Urban Transit Services"
	},
	"1504": {
		"id": "1504",
		"label": "Interurban and Rural Bus Services"
	},
	"1505": {
		"id": "1505",
		"label": "Taxi and Limousine Services"
	},
	"1512": {
		"id": "1512",
		"label": "School and Employee Bus Services"
	},
	"1517": {
		"id": "1517",
		"label": "Shuttles and Special Needs Transportation Services"
	},
	"1520": {
		"id": "1520",
		"label": "Pipeline Transportation"
	},
	"1532": {
		"id": "1532",
		"label": "Sightseeing Transportation"
	},
	"1573": {
		"id": "1573",
		"label": "Postal Services"
	},
	"1594": {
		"id": "1594",
		"label": "Technology, Information and Media"
	},
	"1600": {
		"id": "1600",
		"label": "Periodical Publishing"
	},
	"1602": {
		"id": "1602",
		"label": "Book Publishing"
	},
	"1611": {
		"id": "1611",
		"label": "Movies and Sound Recording"
	},
	"1623": {
		"id": "1623",
		"label": "Sound Recording"
	},
	"1625": {
		"id": "1625",
		"label": "Sheet Music Publishing"
	},
	"1633": {
		"id": "1633",
		"label": "Radio and Television Broadcasting"
	},
	"1641": {
		"id": "1641",
		"label": "Cable and Satellite Programming"
	},
	"1644": {
		"id": "1644",
		"label": "Telecommunications Carriers"
	},
	"1649": {
		"id": "1649",
		"label": "Satellite Telecommunications"
	},
	"1673": {
		"id": "1673",
		"label": "Credit Intermediation"
	},
	"1678": {
		"id": "1678",
		"label": "Savings Institutions"
	},
	"1696": {
		"id": "1696",
		"label": "Loan Brokers"
	},
	"1713": {
		"id": "1713",
		"label": "Securities and Commodity Exchanges"
	},
	"1720": {
		"id": "1720",
		"label": "Investment Advice"
	},
	"1725": {
		"id": "1725",
		"label": "Insurance Carriers"
	},
	"1737": {
		"id": "1737",
		"label": "Insurance Agencies and Brokerages"
	},
	"1738": {
		"id": "1738",
		"label": "Claims Adjusting, Actuarial Services"
	},
	"1742": {
		"id": "1742",
		"label": "Funds and Trusts"
	},
	"1743": {
		"id": "1743",
		"label": "Insurance and Employee Benefit Funds"
	},
	"1745": {
		"id": "1745",
		"label": "Pension Funds"
	},
	"1750": {
		"id": "1750",
		"label": "Trusts and Estates"
	},
	"1757": {
		"id": "1757",
		"label": "Real Estate and Equipment Rental Services"
	},
	"1759": {
		"id": "1759",
		"label": "Leasing Residential Real Estate"
	},
	"1770": {
		"id": "1770",
		"label": "Leasing Real Estate Agents and Brokers"
	},
	"1779": {
		"id": "1779",
		"label": "Equipment Rental Services"
	},
	"1786": {
		"id": "1786",
		"label": "Consumer Goods Rental"
	},
	"1798": {
		"id": "1798",
		"label": "Commercial and Industrial Equipment Rental"
	},
	"1810": {
		"id": "1810",
		"label": "Professional Services"
	},
	"1855": {
		"id": "1855",
		"label": "IT System Design Services"
	},
	"1862": {
		"id": "1862",
		"label": "Marketing Services"
	},
	"1905": {
		"id": "1905",
		"label": "Holding Companies"
	},
	"1909": {
		"id": "1909",
		"label": "Industry Associations"
	},
	"1911": {
		"id": "1911",
		"label": "Professional Organizations"
	},
	"1912": {
		"id": "1912",
		"label": "Administrative and Support Services"
	},
	"1916": {
		"id": "1916",
		"label": "Office Administration"
	},
	"1923": {
		"id": "1923",
		"label": "Executive Search Services"
	},
	"1925": {
		"id": "1925",
		"label": "Temporary Help Services"
	},
	"1931": {
		"id": "1931",
		"label": "Telephone Call Centers"
	},
	"1938": {
		"id": "1938",
		"label": "Collection Agencies"
	},
	"1956": {
		"id": "1956",
		"label": "Security Guards and Patrol Services"
	},
	"1958": {
		"id": "1958",
		"label": "Security Systems Services"
	},
	"1965": {
		"id": "1965",
		"label": "Janitorial Services"
	},
	"1981": {
		"id": "1981",
		"label": "Waste Collection"
	},
	"1986": {
		"id": "1986",
		"label": "Waste Treatment and Disposal"
	},
	"1999": {
		"id": "1999",
		"label": "Education"
	},
	"2012": {
		"id": "2012",
		"label": "Secretarial Schools"
	},
	"2018": {
		"id": "2018",
		"label": "Technical and Vocational Training"
	},
	"2019": {
		"id": "2019",
		"label": "Cosmetology and Barber Schools"
	},
	"2020": {
		"id": "2020",
		"label": "Flight Training"
	},
	"2025": {
		"id": "2025",
		"label": "Fine Arts Schools"
	},
	"2027": {
		"id": "2027",
		"label": "Sports and Recreation Instruction"
	},
	"2029": {
		"id": "2029",
		"label": "Language Schools"
	},
	"2040": {
		"id": "2040",
		"label": "Physicians"
	},
	"2045": {
		"id": "2045",
		"label": "Dentists"
	},
	"2048": {
		"id": "2048",
		"label": "Chiropractors"
	},
	"2050": {
		"id": "2050",
		"label": "Optometrists"
	},
	"2054": {
		"id": "2054",
		"label": "Physical, Occupational and Speech Therapists"
	},
	"2060": {
		"id": "2060",
		"label": "Family Planning Centers"
	},
	"2063": {
		"id": "2063",
		"label": "Outpatient Care Centers"
	},
	"2069": {
		"id": "2069",
		"label": "Medical and Diagnostic Laboratories"
	},
	"2074": {
		"id": "2074",
		"label": "Home Health Care Services"
	},
	"2077": {
		"id": "2077",
		"label": "Ambulance Services"
	},
	"2081": {
		"id": "2081",
		"label": "Hospitals"
	},
	"2091": {
		"id": "2091",
		"label": "Nursing Homes and Residential Care Facilities"
	},
	"2112": {
		"id": "2112",
		"label": "Services for the Elderly and Disabled"
	},
	"2115": {
		"id": "2115",
		"label": "Community Services"
	},
	"2122": {
		"id": "2122",
		"label": "Emergency and Relief Services"
	},
	"2125": {
		"id": "2125",
		"label": "Vocational Rehabilitation Services"
	},
	"2128": {
		"id": "2128",
		"label": "Child Day Care Services"
	},
	"2130": {
		"id": "2130",
		"label": "Performing Arts and Spectator Sports"
	},
	"2133": {
		"id": "2133",
		"label": "Theater Companies"
	},
	"2135": {
		"id": "2135",
		"label": "Dance Companies"
	},
	"2139": {
		"id": "2139",
		"label": "Circuses and Magic Shows"
	},
	"2142": {
		"id": "2142",
		"label": "Sports Teams and Clubs"
	},
	"2143": {
		"id": "2143",
		"label": "Racetracks"
	},
	"2159": {
		"id": "2159",
		"label": "Museums"
	},
	"2161": {
		"id": "2161",
		"label": "Historical Sites"
	},
	"2163": {
		"id": "2163",
		"label": "Zoos and Botanical Gardens"
	},
	"2167": {
		"id": "2167",
		"label": "Amusement Parks and Arcades"
	},
	"2179": {
		"id": "2179",
		"label": "Golf Courses and Country Clubs"
	},
	"2181": {
		"id": "2181",
		"label": "Skiing Facilities"
	},
	"2190": {
		"id": "2190",
		"label": "Accommodation Services"
	},
	"2194": {
		"id": "2194",
		"label": "Hotels and Motels"
	},
	"2197": {
		"id": "2197",
		"label": "Bed-and-Breakfasts, Hostels, Homestays"
	},
	"2212": {
		"id": "2212",
		"label": "Caterers"
	},
	"2214": {
		"id": "2214",
		"label": "Mobile Food Services"
	},
	"2217": {
		"id": "2217",
		"label": "Bars, Taverns, and Nightclubs"
	},
	"2225": {
		"id": "2225",
		"label": "Repair and Maintenance"
	},
	"2226": {
		"id": "2226",
		"label": "Vehicle Repair and Maintenance"
	},
	"2240": {
		"id": "2240",
		"label": "Electronic and Precision Equipment Maintenance"
	},
	"2247": {
		"id": "2247",
		"label": "Commercial and Industrial Machinery Maintenance"
	},
	"2253": {
		"id": "2253",
		"label": "Reupholstery and Furniture Repair"
	},
	"2255": {
		"id": "2255",
		"label": "Footwear and Leather Goods Repair"
	},
	"2258": {
		"id": "2258",
		"label": "Personal and Laundry Services"
	},
	"2259": {
		"id": "2259",
		"label": "Personal Care Services"
	},
	"2272": {
		"id": "2272",
		"label": "Laundry and Drycleaning Services"
	},
	"2282": {
		"id": "2282",
		"label": "Pet Services"
	},
	"2318": {
		"id": "2318",
		"label": "Household Services"
	},
	"2353": {
		"id": "2353",
		"label": "Health and Human Services"
	},
	"2358": {
		"id": "2358",
		"label": "Public Health"
	},
	"2360": {
		"id": "2360",
		"label": "Public Assistance Programs"
	},
	"2366": {
		"id": "2366",
		"label": "Air, Water, and Waste Program Management"
	},
	"2368": {
		"id": "2368",
		"label": "Conservation Programs"
	},
	"2369": {
		"id": "2369",
		"label": "Housing and Community Development"
	},
	"2374": {
		"id": "2374",
		"label": "Community Development and Urban Planning"
	},
	"2375": {
		"id": "2375",
		"label": "Economic Programs"
	},
	"2391": {
		"id": "2391",
		"label": "Military and International Affairs"
	},
	"2401": {
		"id": "2401",
		"label": "Operations Consulting"
	},
	"2458": {
		"id": "2458",
		"label": "Data Infrastructure and Analytics"
	},
	"2468": {
		"id": "2468",
		"label": "Electrical Equipment Manufacturing"
	},
	"2489": {
		"id": "2489",
		"label": "Wind Electric Power Generation"
	},
	"2500": {
		"id": "2500",
		"label": "Wineries"
	},
	"2934": {
		"id": "2934",
		"label": "Landscaping Services"
	},
	"3065": {
		"id": "3065",
		"label": "Courts of Law"
	},
	"3068": {
		"id": "3068",
		"label": "Correctional Institutions"
	},
	"3070": {
		"id": "3070",
		"label": "Fire Protection"
	},
	"3081": {
		"id": "3081",
		"label": "Housing Programs"
	},
	"3085": {
		"id": "3085",
		"label": "Transportation Programs"
	},
	"3086": {
		"id": "3086",
		"label": "Utilities Administration"
	},
	"3089": {
		"id": "3089",
		"label": "Space Research and Technology"
	},
	"3095": {
		"id": "3095",
		"label": "Oil Extraction"
	},
	"3096": {
		"id": "3096",
		"label": "Natural Gas Extraction"
	},
	"3099": {
		"id": "3099",
		"label": "Embedded Software Products"
	},
	"3100": {
		"id": "3100",
		"label": "Mobile Computing Software Products"
	},
	"3101": {
		"id": "3101",
		"label": "Desktop Computing Software Products"
	},
	"3102": {
		"id": "3102",
		"label": "IT System Custom Software Development"
	},
	"3103": {
		"id": "3103",
		"label": "IT System Operations and Maintenance"
	},
	"3104": {
		"id": "3104",
		"label": "IT System Installation and Disposal"
	},
	"3105": {
		"id": "3105",
		"label": "IT System Training and Support"
	},
	"3106": {
		"id": "3106",
		"label": "IT System Data Services"
	},
	"3107": {
		"id": "3107",
		"label": "IT System Testing and Evaluation"
	},
	"3124": {
		"id": "3124",
		"label": "Internet News"
	},
	"3125": {
		"id": "3125",
		"label": "Blogs"
	},
	"3126": {
		"id": "3126",
		"label": "Interior Design"
	},
	"3127": {
		"id": "3127",
		"label": "Social Networking Platforms"
	},
	"3128": {
		"id": "3128",
		"label": "Business Intelligence Platforms"
	},
	"3129": {
		"id": "3129",
		"label": "Business Content"
	},
	"3130": {
		"id": "3130",
		"label": "Data Security Software Products"
	},
	"3131": {
		"id": "3131",
		"label": "Mobile Gaming Apps"
	},
	"3132": {
		"id": "3132",
		"label": "Internet Publishing"
	},
	"3133": {
		"id": "3133",
		"label": "Media and Telecommunications"
	},
	"3134": {
		"id": "3134",
		"label": "Blockchain Services"
	},
	"3186": {
		"id": "3186",
		"label": "Retail Art Dealers"
	},
	"3187": {
		"id": "3187",
		"label": "Defense & Space"
	},
	"3188": {
		"id": "3188",
		"label": "Veterinary"
	},
	"3189": {
		"id": "3189",
		"label": "Cosmetics"
	},
	"3190": {
		"id": "3190",
		"label": "Apparel and Fashion"
	},
	"3191": {
		"id": "3191",
		"label": "Consumer Electronics"
	},
	"3192": {
		"id": "3192",
		"label": "Consumer Goods"
	},
	"3193": {
		"id": "3193",
		"label": "Furniture"
	},
	"3194": {
		"id": "3194",
		"label": "Leisure, Travel & Tourism"
	},
	"3196": {
		"id": "3196",
		"label": "Fine Art"
	},
	"3197": {
		"id": "3197",
		"label": "Building Materials"
	},
	"3198": {
		"id": "3198",
		"label": "Automotive"
	},
	"3199": {
		"id": "3199",
		"label": "Dairy"
	},
	"3200": {
		"id": "3200",
		"label": "Education Management"
	},
	"3201": {
		"id": "3201",
		"label": "Public Policy"
	},
	"3202": {
		"id": "3202",
		"label": "Non-profit Organization Management"
	},
	"3203": {
		"id": "3203",
		"label": "Program Development"
	},
	"3204": {
		"id": "3204",
		"label": "Arts and Crafts"
	},
	"3205": {
		"id": "3205",
		"label": "Music"
	},
	"3206": {
		"id": "3206",
		"label": "Outsourcing/Offshoring"
	},
	"3207": {
		"id": "3207",
		"label": "Health, Wellness and Fitness"
	},
	"3208": {
		"id": "3208",
		"label": "E-Learning"
	},
	"3209": {
		"id": "3209",
		"label": "Import and Export"
	},
	"3210": {
		"id": "3210",
		"label": "Human Resources"
	},
	"3211": {
		"id": "3211",
		"label": "Business Supplies and Equipment"
	},
	"3213": {
		"id": "3213",
		"label": "Renewables & Environment"
	},
	"3214": {
		"id": "3214",
		"label": "Online Media"
	},
	"3215": {
		"id": "3215",
		"label": "Animation"
	},
	"3216": {
		"id": "3216",
		"label": "Industrial Automation"
	},
	"3217": {
		"id": "3217",
		"label": "Medical Devices"
	},
	"3218": {
		"id": "3218",
		"label": "Semiconductors"
	},
	"3219": {
		"id": "3219",
		"label": "Commercial Real Estate"
	},
	"3220": {
		"id": "3220",
		"label": "Wine and Spirits"
	},
	"3221": {
		"id": "3221",
		"label": "Mechanical or Industrial Engineering"
	},
	"3222": {
		"id": "3222",
		"label": "Transportation/Trucking/Railroad"
	},
	"3223": {
		"id": "3223",
		"label": "Packaging and Containers"
	},
	"3224": {
		"id": "3224",
		"label": "Food Production"
	},
	"3225": {
		"id": "3225",
		"label": "Sporting Goods"
	},
	"3226": {
		"id": "3226",
		"label": "Aviation & Aerospace"
	},
	"3227": {
		"id": "3227",
		"label": "Design"
	},
	"3228": {
		"id": "3228",
		"label": "Tobacco"
	},
	"3229": {
		"id": "3229",
		"label": "Warehousing"
	},
	"3230": {
		"id": "3230",
		"label": "Philanthropy"
	},
	"3231": {
		"id": "3231",
		"label": "Information Technology and Services"
	},
	"3232": {
		"id": "3232",
		"label": "Government Relations"
	},
	"3233": {
		"id": "3233",
		"label": "Research"
	},
	"3234": {
		"id": "3234",
		"label": "Computer Networking"
	},
	"3235": {
		"id": "3235",
		"label": "Computer Hardware"
	},
	"3236": {
		"id": "3236",
		"label": "Maritime"
	},
	"3237": {
		"id": "3237",
		"label": "Entertainment"
	},
	"3238": {
		"id": "3238",
		"label": "Biotechnology"
	},
	"3239": {
		"id": "3239",
		"label": "Paper and Forest Products"
	},
	"3240": {
		"id": "3240",
		"label": "Renewable Energy Power Generation"
	},
	"3241": {
		"id": "3241",
		"label": "Renewable Energy Equipment Manufacturing"
	},
	"3242": {
		"id": "3242",
		"label": "Engineering Services"
	},
	"3243": {
		"id": "3243",
		"label": "Services for Renewable Energy"
	}
});