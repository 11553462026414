<template>
	<lobster-select
		v-model="currentType"
		:disabled="disabled"
		:options="optionsWithCustom"
		:clearable="false"
		:searchable="false"
		label="label"
		:reduce="item => item.value"
		class="m_wide date-filter-select"
		ref="select"
		@option:selecting="item => $emit('option-click', item.value)">
		<template #selected-option="{value, label}">
			{{!(datePeriod.from && datePeriod.to) ? SocialBIDatePeriodTypes[datePeriod.type] : `${formatUserDateUTC(datePeriod.from)} - ${formatUserDateUTC(datePeriod.to)}`}}
		</template>
	</lobster-select>
</template>

<script>
import DateHelper from '../../../helpers/DateHelper';
import { SocialBIDatePeriodTypes } from '../../../configs/DatePeriodTypes';


export default {
	props: {
		datePeriod: {
			type: Object,
			default: () => ({})
		},
		options: {
			type: Array,
			default: () => ([])
		},
		disabled: {
			type: Boolean,
			default: false
		},
		customLast: {
			type: Boolean,
			default: false
		}
	},
	data()
	{
		return {
			SocialBIDatePeriodTypes,
		}
	},
	computed: {
		currentType: {
			get()
			{
				return this.datePeriod.type || 'custom';
			},
			set(newValue)
			{

			}
		},
		optionsWithCustom()
		{
			let custom = {
				value: 'custom',
				label: 'Custom'
			};

			return this.customLast ? [...this.options, custom] : [custom, ...this.options];
		}
	},
	created()
	{
		this.formatUserDateUTC = DateHelper.formatUserDateUTC;
	}
}
</script>

<style lang="scss" scoped>
	.date-filter-select {
		min-width: 180px;
		flex-shrink: 0;
	}

	.date-filter-select__icon {
		margin-right: 8px;
		color: $colorTextSecondary;
	}
</style>