var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    { attrs: { column: "" } },
    [
      _c("hr", { staticClass: "privacy-select__stroke" }),
      _vm._v(" "),
      _c(
        "lobster-entities-container",
        { attrs: { center: "", size: "large" } },
        [
          _c("lobster-checkbox", {
            attrs: {
              inline: "",
              type: "radio",
              val: "private",
              name: "visibility",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "privacy-select__label-container" },
                      [
                        _c("lobster-icon", { attrs: { name: "view--off" } }),
                        _vm._v("\n\t\t\t\tPrivate\n\t\t\t\t"),
                        _c("lobster-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "This report will show only on your list",
                              expression:
                                "'This report will show only on your list'",
                            },
                          ],
                          attrs: { name: "information" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.currentValue,
              callback: function ($$v) {
                _vm.currentValue = $$v
              },
              expression: "currentValue",
            },
          }),
          _vm._v(" "),
          _c("lobster-checkbox", {
            attrs: {
              inline: "",
              type: "radio",
              val: "shared",
              name: "visibility",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "privacy-select__label-container" },
                      [
                        _c("lobster-icon", { attrs: { name: "view" } }),
                        _vm._v("\n\t\t\t\tPublic\n\t\t\t\t"),
                        _c("lobster-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                "This report will be visible for all project members",
                              expression:
                                "'This report will be visible for all project members'",
                            },
                          ],
                          attrs: { name: "information" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.currentValue,
              callback: function ($$v) {
                _vm.currentValue = $$v
              },
              expression: "currentValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }