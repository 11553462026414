var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("lobster-switch", {
    staticClass: "twitter-toggle",
    attrs: { label: "Toggle Twitter Activity" },
    on: { input: _setup.handleSubmit },
    model: {
      value: _setup.currentStatus,
      callback: function ($$v) {
        _setup.currentStatus = $$v
      },
      expression: "currentStatus",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }