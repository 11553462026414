<script>
export default {
	name: 'LinkEntity',
	props: {
		cellName: String,
		cellLabel: String,
		cellValue: String,
	},
	computed: {
		linkUrl()
		{
			if (!is(this.cellValue))
			{
				return null;
			}

			return `${(this.cellName === 'LinkStripped') ? '//': ''}${this.cellLabel}`
		}
	}
}
</script>

<template>
	<lobster-button
		v-if="$is(linkUrl)"
		inline-link
		:href="linkUrl"
		class="ext-link"
		target="_blank">
		{{ cellLabel }}
	</lobster-button>
	<span v-else>
		{{ cellLabel }}
	</span>
</template>