var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-menu",
    {
      attrs: { placement: "left-start", title: "Report Actions" },
      scopedSlots: _vm._u([
        {
          key: "popover",
          fn: function () {
            return [
              _c(
                "lobster-menu-list",
                [
                  _c(
                    "lobster-menu-item",
                    {
                      directives: [
                        { name: "close-popover", rawName: "v-close-popover" },
                      ],
                      attrs: { "icon-name": "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("edit-widget-click")
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tEdit\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "lobster-menu-item",
                    {
                      directives: [
                        { name: "close-popover", rawName: "v-close-popover" },
                      ],
                      attrs: { deny: "", "icon-name": "trash-can" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-widget-click")
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tDelete\n\t\t\t")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }