var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-select", {
    ref: "select",
    staticClass: "m_wide date-filter-select",
    attrs: {
      disabled: _vm.disabled,
      options: _vm.optionsWithCustom,
      clearable: false,
      searchable: false,
      label: "label",
      reduce: (item) => item.value,
    },
    on: { "option:selecting": (item) => _vm.$emit("option-click", item.value) },
    scopedSlots: _vm._u([
      {
        key: "selected-option",
        fn: function ({ value, label }) {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  !(_vm.datePeriod.from && _vm.datePeriod.to)
                    ? _vm.SocialBIDatePeriodTypes[_vm.datePeriod.type]
                    : `${_vm.formatUserDateUTC(
                        _vm.datePeriod.from
                      )} - ${_vm.formatUserDateUTC(_vm.datePeriod.to)}`
                ) +
                "\n\t"
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.currentType,
      callback: function ($$v) {
        _vm.currentType = $$v
      },
      expression: "currentType",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }