<script>
export default {
	name: 'FormatTextMenu',
	props: {
		quill: Object,
		activeFormat: {
			type: Object,
			default()
			{
				return {};
			}
		}
	},
	data()
	{
		return {
			isOpened: false
		}
	},
	methods: {
		handleTriggerButtonClick()
		{
			this.quill.focus();
		},
	}
}
</script>

<template>
	<lobster-menu :open.sync="isOpened">
		<lobster-abstract-button
			class="format-text-menu__trigger-button"
			:class="{ m_active: isOpened }"
			@click="handleTriggerButtonClick">
			<span class="format-text-menu__trigger-button-text">
				<template v-if="activeFormat.header === 1">
					{{ $t('commonUtils.textEditor.toolbar.heading') }}
				</template>
				<template v-else-if="activeFormat.header === 2">
					{{ $t('commonUtils.textEditor.toolbar.subheading') }}
				</template>
				<template v-else>
					{{ $t('commonUtils.textEditor.toolbar.normal') }}
				</template>
			</span>

			<lobster-icon
				name="chevron--down"
				size="small--s-s"/>
		</lobster-abstract-button>

		<template #popover>
			<lobster-menu-list>
				<lobster-menu-item
					v-close-popover
					:selected="activeFormat.header === 1"
					class="format-text-menu__item"
					@click="$emit('format-button-click', 'header', 1)">
					<h1 class="no-margin">{{ $t('commonUtils.textEditor.toolbar.heading') }}</h1>
				</lobster-menu-item>
				<lobster-menu-item
					v-close-popover
					:selected="activeFormat.header === 2"
					class="format-text-menu__item"
					@click="$emit('format-button-click', 'header', 2)">
					<h2 class="no-margin">{{ $t('commonUtils.textEditor.toolbar.subheading') }}</h2>
				</lobster-menu-item>
				<lobster-menu-item
					v-close-popover
					:selected="!$is(activeFormat.header)"
					class="format-text-menu__item"
					@click="$emit('format-button-click', 'header', false)">
					{{ $t('commonUtils.textEditor.toolbar.normal') }}
				</lobster-menu-item>
			</lobster-menu-list>
		</template>
	</lobster-menu>
</template>

<style lang="scss" scoped>
@import "../../../scss/mixins";


.format-text-menu__trigger-button {
	display: flex;
	align-items: center;
	gap: var(--spacing-XS);
	height: var(--size-M);
	padding: 0 var(--spacing-XS);
	cursor: pointer;
	color: var(--colorTextSecondary);
	width: 80px;
	white-space: nowrap;

	@media (hover: hover) {
		&:hover,
		&:active {
			color: var(--colorPrimary);
		}
	}

	&.m_active {
		color: var(--colorPrimary);
	}
}

.format-text-menu__trigger-button-text {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.format-text-menu__item {
	&:deep {
		@include text-editor-typography;
	}
}
</style>