var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    {
      staticClass: "ads-entity",
      attrs: { center: "", "no-wrap": "", size: "small" },
    },
    [
      _vm.$is(_vm.mediaType)
        ? _c("lobster-media-preview", {
            staticClass: "no-shrink",
            attrs: {
              size: "small",
              "message-type": _vm.mediaType,
              preview: _vm.imgUrl,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ads-entity__message-wrapper" },
        [
          _vm.$is(_vm.content)
            ? _c(
                "lobster-entities-container",
                { attrs: { column: "", "no-wrap": "", size: "small" } },
                [
                  _vm.contentTitle
                    ? _c("lobster-text-entity", {
                        staticClass: "h3",
                        attrs: { text: _vm.contentTitle, "line-clamp": "1" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("lobster-text-entity", {
                    staticClass: "caption-text",
                    attrs: {
                      emojify: "",
                      text: _vm.contentMessage || "-",
                      "line-clamp": "2",
                    },
                  }),
                ],
                1
              )
            : _c(
                "p",
                { staticClass: "ads-entity__message" },
                [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.$t("commonAnalytics.adsNullstate.title"))
                    ),
                  ]),
                  _vm._v(
                    ".\n\t\t\t" +
                      _vm._s(_vm.$t("commonAnalytics.adsNullstate.caption")) +
                      "\n\t\t\t"
                  ),
                  _c(
                    "lobster-button",
                    {
                      attrs: {
                        "inline-link": "",
                        href: _vm.$newKnowledgebaseLink("159"),
                        "data-elevio-article": "159",
                        "data-elevio-style": "nothing",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.$t("commonAnalytics.adsNullstate.linkText")
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("lobster-tag", { staticClass: "ads-entity__status" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.status.slice(0, 1).toUpperCase() +
                _vm.status.slice(1).toLowerCase()
            ) +
            "\n\t"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }