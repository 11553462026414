<template>
	<div class="trello-card">
		<lobster-loader
			v-if="isLoading"
			class="trello-card__loader"/>
		<div class="trello-card__content" 
			 :class="{m_loading: isLoading}"
			 ref="container">
			
		</div>
	</div>
</template>

<script>
import { is } from 'plankton-esm';


export default {
	name: "TrelloCard",
	props: {
		id: String
	},
	data()
	{
		return {
			isLoading: false
		}
	},
	watch: {
		id: {
			immediate: true,
			handler()
			{
				this.drawCard()
			}
		}
	},
	methods: {
		drawCard()
		{
			this.isLoading = true;
			
			if (!is(this.$refs.container))
			{
				this.$nextTick(this.drawCard);
				
				return;
			}
			
			
			if (this.$refs.container.children.length > 0)
			{
				for (let child of this.$refs.container.children)
				{
					this.$refs.container.removeChild(child);
				}
			}
			
			window.TrelloCards.create(this.id, this.$refs.container, {
				compact: true,
				onLoad: () => this.isLoading = false
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.trello-card {
	width: 316px;
	min-height: 150px;

	position: relative;
}

.trello-card__loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.trello-card__content {
	&.m_loading {
		visibility: hidden;
	}
}
</style>