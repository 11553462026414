var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-section",
    { staticClass: "report-gallery-section" },
    [
      _vm.isFirstLoading
        ? _c("lobster-loader")
        : _vm.galleryList.length
        ? [
            _c(
              "lobster-layout-action-bar",
              [
                _vm.canUpdate
                  ? _c(
                      "lobster-button",
                      { on: { click: _vm.handleNewGalleryClick } },
                      [_vm._v("\n\t\t\t\tNew Report Group\n\t\t\t")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("lobster-search-filter", {
                  attrs: { "filters-disabled": "", placeholder: "Search" },
                  model: {
                    value: _vm.searchFilter,
                    callback: function ($$v) {
                      _vm.searchFilter = $$v
                    },
                    expression: "searchFilter",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("gallery-table", { attrs: { search: _vm.searchFilter.search } }),
          ]
        : _c(
            "lobster-layout-nullstate",
            { attrs: { message: "Groups", description: _vm.nullstateText } },
            [
              _vm.canUpdate
                ? _c(
                    "lobster-button",
                    { on: { click: _vm.handleNewGalleryClick } },
                    [_vm._v("\n\t\t\tNew Report Group\n\t\t")]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }