<script setup>
import {onMounted, onUnmounted} from "vue";
import AdminYuri from "../../utils/yuri/AdminYuri.js";
import {ErrorHandler} from "../../utils/yuri/ErrorHandler.js";
import {useToaster} from "../../hooks/toaster.js";


const toaster = useToaster();


function handleNavMenuClick()
{
	if (route.name !== 'backfill')
	{
		router.push({name: 'backfill'});
	}}


onMounted(() =>
{
	$('a[data-menu="listening"]').on('click', handleNavMenuClick);
	AdminYuri.registerHelper(new ErrorHandler(toaster));
});

onUnmounted(() =>
{
	$('a[data-menu="listening"]').off('click', handleNavMenuClick);
});
</script>

<template>
	<fragment>
		<aside>
			<lobster-button
				inline-link
				:to="{ name: 'backfill' }">
				Backfill
			</lobster-button>
		</aside>
		<router-view class="inner"/>
		<lobster-provider/>
	</fragment>
</template>

<style scoped lang="scss">

</style>