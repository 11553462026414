export const SOURCE_APPLICATION = [
    {
        value: 'ADV_WEB',
        label: 'Social Advocacy Web App'
    },
    {
        value: 'ADV_MOB',
        label: 'Social Advocacy Mobile App'
    },
    {
        value: 'ADV_TMS',
        label: 'Social Advocacy Teams App'
    },
    {
        value: 'ADV_SF',
        label: 'Social Advocacy Salesforce Package'
    },
    {
        value: 'ADV_ALL',
        label: 'Social Advocacy: All'
    },
    {
        value: 'EXT',
        label: 'Browser Extension'
    },
    {
        value: 'APP_WEB',
        label: 'Web App'
    },
    {
        value: 'APP_MOB',
        label: 'Mobile App'
    },
    {
        value: 'APP_ALL',
        label: 'App: All'
    },
    {
        value: 'NATV',
        label: 'Native'
    },
    {
        value: 'API',
        label: 'API'
    },
    {
        value: 'OTHER_ALL',
        label: 'Other: All'
    },
];