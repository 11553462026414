<template>
	<lobster-icon
		v-if="emojiIconName"
		:name="emojiIconName"
		class="emoji-entity"
		:class="{
			m_positive: value === '😃',
			m_negative: value === '🙁',
			m_neutral: value === '😐',
		}"/>
	<span v-else>
		-
	</span>
</template>

<script>
import { getEmojiIconName } from '../../helpers/StringHelper';


export default {
	name: 'SentimentEntity',
	props: {
		value: String
	},
	computed: {
		emojiIconName()
		{
			return getEmojiIconName(this.value);
		}
	}
}
</script>

<style scoped lang="scss">
.emoji-entity {
	&.m_positive {
		color: $colorSuccess;
	}

	&.m_negative {
		color: $colorError;
	}

	&.m_neutral {
		color: $colorWarning;
	}
}
</style>