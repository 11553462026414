var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-search-filter", {
    attrs: {
      "search-disabled": "",
      "not-strict": "",
      disabled: _vm.disabled,
      "menu-view": _vm.menuView,
      "show-group-labels": _vm.menuView,
      "show-flat-groups": _vm.$is(_vm.subjectType),
      filters: _vm.filters,
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function ({ option, options, filterName, filter }) {
          return [
            _vm.profileFilters.includes(filterName)
              ? _c("network-entity", {
                  attrs: {
                    network: option.Network,
                    label: option.DisplayName || option.Name || "Unknown",
                  },
                })
              : filterName === "Network" ||
                filterName === "SocialActivityNetwork" ||
                filterName === "ListeningChannel"
              ? _c("network-entity", {
                  attrs: { network: option.value, label: option.label },
                })
              : filterName === "SDBID"
              ? _c("network-entity", {
                  attrs: { network: "linkedin", label: option.Name },
                })
              : filterName === "CampaignID"
              ? _c("campaign-entity", {
                  attrs: { name: option.Name, color: option.Color },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.currentFilters,
      callback: function ($$v) {
        _vm.currentFilters = $$v
      },
      expression: "currentFilters",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }