import Quill from 'quill';


export function registerLink()
{
	const Inline = Quill.import('blots/inline');

	class LinkBlot extends Inline
	{
		static blotName = 'link';
		static tagName = 'a';

		static create(url)
		{
			let node = super.create();

			node.setAttribute('href', url);
			node.setAttribute('target', '_blank');

			return node;
		}

		static formats(node)
		{
			return node.getAttribute('href');
		}
	}

	Quill.register(LinkBlot, true);
}

export function registerHeader()
{
	const Header = Quill.import('formats/header');

	class HeaderBlot extends Header
	{
		static create(value)
		{
			switch (value)
			{
				case 1:
					return document.createElement('h1');
				default:
					return document.createElement('h2');
			}
		}
	}

	Quill.register(HeaderBlot, true);
}