var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-layout-section",
    { staticClass: "gallery-section" },
    [
      _vm.isFirstLoading
        ? _c("lobster-loader")
        : _vm.currentGroup && _vm.currentGallery
        ? [
            _c("div", { staticClass: "gallery-section__header" }, [
              _c("h2", [_vm._v(_vm._s(_vm.currentGroup.name))]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.currentGroup.description))]),
            ]),
            _vm._v(" "),
            _c(
              "lobster-layout-action-bar",
              [
                _c("date-period-select", {
                  model: {
                    value: _vm.datePeriod,
                    callback: function ($$v) {
                      _vm.datePeriod = $$v
                    },
                    expression: "datePeriod",
                  },
                }),
                _vm._v(" "),
                _vm.canUpdate
                  ? _c(
                      "lobster-button",
                      { on: { click: _vm.handleNewReportClick } },
                      [_vm._v("\n\t\t\t\tNew Report\n\t\t\t")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gallery-section__grid-wrapper" },
              [
                _c("kraken-dashboard", {
                  attrs: {
                    widgets: _vm.currentGallery,
                    "margin-x": 24,
                    "margin-y": 24,
                    config: _vm.additionalConfig,
                    "row-height": 270,
                  },
                  on: { "widgets-changed": _vm.handleWidgetsChanged },
                  scopedSlots: _vm._u([
                    {
                      key: "widget",
                      fn: function ({ widget }) {
                        return [
                          _c("gallery-report", {
                            attrs: {
                              "with-menu": _vm.canUpdate,
                              "gallery-id": widget.groupId,
                              item: widget,
                              "date-period": _vm.datePeriod,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        : _c(
            "lobster-layout-nullstate",
            {
              attrs: {
                message: "Build Your Group",
                description:
                  "Add the first report to your group to get started.",
              },
            },
            [
              _vm.canUpdate
                ? _c(
                    "lobster-button",
                    { on: { click: _vm.handleNewReportClick } },
                    [_vm._v("\n\t\t\tNew Report\n\t\t")]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }