var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-text-entity", {
    staticClass: "text-widget",
    attrs: {
      emojify: "",
      "save-breaks": "",
      text: _vm.textContent,
      sanitize: _vm.sanitize,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }