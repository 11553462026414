export function isEqual(obj1, obj2)
{
	if (obj1 === null || obj2 === null)
	{
		return obj1 === obj2;
	}

	if (Array.isArray(obj1))
	{
		if (!Array.isArray(obj2))
		{
			return false;
		}

		if (obj1.length !== obj2.length)
		{
			return false;
		}

		for (let i = 0; i < obj1.length; i++)
		{
			if (!isEqual(obj1[i], obj2[i]))
			{
				return  false;
			}
		}

		return true;
	}

	if (Array.isArray(obj2))
	{
		return false;
	}


	if (typeof obj1 === 'object')
	{
		if (typeof obj2 !== 'object')
		{
			return false;
		}

		if (!isEqual(Object.keys(obj1).sort(), Object.keys(obj2).sort()))
		{
			return false;
		}

		for (let key in obj1)
		{
			if (!obj1.hasOwnProperty(key))
			{
				if (obj2.hasOwnProperty(key))
				{
					return false;
				}

				continue;
			}

			if (!isEqual(obj1[key], obj2[key]))
			{
				return false;
			}
		}

		return true;
	}

	if (typeof obj2 === 'object')
	{
		return false;
	}

	return obj1 === obj2;
}

export function deepCopy(obj)
{
	return JSON.parse(JSON.stringify(obj));
}

export function debounce(func, wait)
{
	let timeout;

	return (...args) =>
	{
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	}
}