<template>
	<div class="report-form-sidebar">
		<sidebar-menu
			:filter-view.sync="isFilterView"
			:filters-count="filtersCount"/>
		<analytics-filters
			v-if="isFilterView"
			is-report-builder
			class="report-form-sidebar__section"
			:menu-view="false"
			:subject-type="subjectType"
			:groups="currentGroups"
			:metrics="currentMetrics"
			:additional-columns="currentAdditionalColumns"
			v-model="currentFilter"/>
		<sidebar-settings
			v-else
			class="report-form-sidebar__section"
			:subject-type="subjectType"
			:with-visibility="withVisibility"
			:groups.sync="currentGroups"
			:metrics.sync="currentMetrics"
			:additional-columns.sync="currentAdditionalColumns"
			:chart-type.sync="currentChartType"
			:limit.sync="currentLimit"
			:order-by.sync="currentOrderBy"
			:metadata.sync="currentMetadata"
			:visibility.sync="currentVisibility"/>
	</div>
</template>

<script>
import SidebarMenu from './SidebarMenu.vue';
import AnalyticsFilters from '../Filters/AnalyticsFilters';
import SidebarSettings from './SidebarSettings.vue';


export default {
	name: 'ReportFormSidebar',
	components: {
		SidebarSettings,
		AnalyticsFilters,
		SidebarMenu
	},
	props: {
		withVisibility: Boolean,
		subjectType: String,
		filter: {
			type: Object,
			default: () => ({})
		},
		metrics: {
			type: Array,
			required: true
		},
		groups: {
			type: Array,
			required: true
		},
		additionalColumns: {
			type: Array,
			required: true
		},
		orderBy: {
			type: Array,
			default: () => ([])
		},
		metadata: {
			type: Object,
			default: () => ({})
		},
		limit: [Number, String],
		chartType: String,
		visibility: String
	},
	data()
	{
		return {
			isFilterView: false,
			filtersCount: 0
		}
	},
	computed: {
		currentFilter: {
			get()
			{
				return this.filter;
			},
			set(val)
			{
				this.$emit('update:filter', val);
			}
		},
		currentMetrics: {
			get()
			{
				return this.metrics;
			},
			set(val)
			{
				this.$emit('update:metrics', val);
			}
		},
		currentGroups: {
			get()
			{
				return this.groups;
			},
			set(val)
			{
				this.$emit('update:groups', val);
			}
		},
		currentAdditionalColumns: {
			get()
			{
				return this.additionalColumns;
			},
			set(val)
			{
				this.$emit('update:additional-columns', val);
			}
		},
		currentChartType: {
			get()
			{
				return this.chartType;
			},
			set(val)
			{
				this.$emit('update:chart-type', val);
			}
		},
		currentOrderBy: {
			get()
			{
				return this.orderBy;
			},
			set(val)
			{
				this.$emit('update:order-by', val);
			}
		},
		currentLimit: {
			get()
			{
				return this.limit;
			},
			set(val)
			{
				this.$emit('update:limit', val);
			}
		},
		currentMetadata: {
			get()
			{
				return this.metadata;
			},
			set(val)
			{
				this.$emit('update:metadata', val);
			}
		},
		currentVisibility: {
			get()
			{
				return this.visibility;
			},
			set(val)
			{
				this.$emit('update:visibility', val);
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.report-form-sidebar {
	display: flex;
	flex-direction: column;
}

.report-form-sidebar__section {
	padding: 16px;
	min-height: 0;
	overflow-y: auto;
	overflow-x: hidden;
	flex-grow: 1;
}
</style>