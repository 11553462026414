var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$is(_vm.linkUrl)
    ? _c(
        "lobster-button",
        {
          staticClass: "ext-link",
          attrs: { "inline-link": "", href: _vm.linkUrl, target: "_blank" },
        },
        [_vm._v("\n\t" + _vm._s(_vm.cellLabel) + "\n")]
      )
    : _c("span", [_vm._v("\n\t" + _vm._s(_vm.cellLabel) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }