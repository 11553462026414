import CrawlersPage from '../components/QATools/Crawlers/CrawlersPage.vue';
import TwitterPage from '../components/QATools/Twitter/TwitterPage.vue';

export default function ()
{
	return {
		base: '/qa-tools/',
		mode: 'history',
		routes: [
			{
				path: '/',
				redirect: 'crawlers'
			},
			{
				path: `/crawlers`,
				component: CrawlersPage,
				name: 'crawlers'
			},
			{
				path: `/twitter`,
				component: TwitterPage,
				name: 'twitter'
			},
		],
		linkActiveClass: 'm_active'
	}
};