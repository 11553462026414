var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lobster-entities-container",
    {
      staticClass: "campaign-entity",
      attrs: { "no-wrap": "", center: "", size: "small--s" },
    },
    [
      _c("i", {
        staticClass: "campaign-entity__icon",
        style: `color: ${_vm.color}`,
      }),
      _vm._v(" "),
      _vm.id
        ? _c(
            "lobster-abstract-button",
            {
              staticClass: "campaign-entity__name m_link",
              class: [_vm.openInSameTab ? "direct-link" : "ext-link"],
              attrs: { href: _vm.campaignLink },
            },
            [
              _vm._t("name", function () {
                return [
                  _c("lobster-text-entity", {
                    attrs: {
                      "smart-tooltip": "",
                      text: _vm.name,
                      "line-clamp": 1,
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _c(
            "span",
            { staticClass: "campaign-entity__name" },
            [
              _vm._t("name", function () {
                return [
                  _c("lobster-text-entity", {
                    attrs: {
                      "smart-tooltip": "",
                      text: _vm.name,
                      "line-clamp": 1,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }