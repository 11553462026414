<script>
import Quill from 'quill';
import { sanitize } from '../../helpers/StringHelper';
import { TextEditorConfig } from '../../configs/TextEditorConfig';
import TextEditorToolbar from './Toolbar/TextEditorToolbar.vue';
import { registerHeader, registerLink } from '../../helpers/TextEditorHelper';


export default {
	name: 'TextEditor',
	components: { TextEditorToolbar },
	props: {
		value: {
			type: String,
			default: ''
		},
		placeholder: String,
		maxTextLength: {
			type: Number,
			default: 10000
		},
	},
	data()
	{
		return {
			textContent: '',
			textLength: 0,
			quill: null,
		}
	},
	methods: {
		handleTextChange()
		{
			let allFormatAttributes = this.quill.getContents().ops.map(item => Object.keys(item.attributes || {}));
			let formattingUsed = Array.from(new Set(allFormatAttributes.flat()));

			this.textLength = this.quill.getLength() - 1;
			this.textContent = (this.quill.getText().trim().length > 0) ? this.quill.root.innerHTML : '';

			this.$emit('input', this.textContent);
			this.$emit('content-changed', {
				contentLength: is(this.textContent) ? this.textLength : 0,
				formattingUsed
			});
		}
	},
	async mounted()
	{
		registerLink();
		registerHeader();

		this.quill = new Quill(this.$refs.editor, {
			placeholder: this.placeholder || this.$t('commonUtils.textEditor.placeholder'),
			formats: TextEditorConfig.formats
		});
		this.quill.on('text-change', this.handleTextChange);

		this.textContent = sanitize(this.value, TextEditorConfig.sanitizeOptions);

		this.quill.root.innerHTML = this.textContent;

		await this.$sleep(700);

		this.quill.focus();
	},
	beforeDestroy()
	{
		this.quill = null;
	}
}
</script>

<template>
	<lobster-entities-container
		class="text-editor"
		column
		no-wrap>
		<div ref="editor"/>

		<text-editor-toolbar
			v-if="$is(quill)"
			:quill="quill"
			:text-length="textLength"
			:max-text-length="maxTextLength"/>
	</lobster-entities-container>
</template>

<style scoped lang="scss">
@import "../../scss/mixins";


.text-editor {
	height: 100%;

	&:deep {
		.ql-container {
			margin: 0 calc(-1 * var(--spacing-M));
			height: 368px;
			flex-grow: 1;
			overflow: hidden;
		}

		.ql-clipboard {
			left: -100000px;
			height: 1px;
			overflow-y: hidden;
			position: absolute;
			top: 50%;

			p {
				margin: 0;
				padding: 0;
			}
		}

		.ql-editor {
			@include text-editor-typography;

			outline: none;
			tab-size: 4;
			-moz-tab-size: 4;
			white-space: pre-wrap;
			word-wrap: break-word;
			padding: 0 var(--spacing-M);
			overflow: auto;
			height: 100%;

			> * {
				cursor: text;
			}
		}

		.ql-hidden {
			display: none;
		}

		.ql-blank {
			&::before {
				content: attr(data-placeholder);
				pointer-events: none;
				position: absolute;
				color: var(--colorTextSecondary);
				font-size: var(--fontSizeBody);
				font-style: normal;
				white-space: nowrap;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>