<template>
	<lobster-entities-container
		no-wrap
		center
		size="small--s"
		class="campaign-entity">
		<i class="campaign-entity__icon" :style="`color: ${color}`"/>
		<lobster-abstract-button
			v-if="id"
			class="campaign-entity__name m_link"
			:class="[openInSameTab ? 'direct-link' : 'ext-link']"
			:href="campaignLink">
			<slot name="name">
				<lobster-text-entity
					smart-tooltip
					:text="name"
					:line-clamp="1"/>
			</slot>
		</lobster-abstract-button>
		<span v-else class="campaign-entity__name">
			<slot name="name">
				<lobster-text-entity
					smart-tooltip
					:text="name"
					:line-clamp="1"/>
			</slot>
		</span>
	</lobster-entities-container>
</template>

<script>
export default {
	name: 'CampaignEntity',
	props: {
		color: String,
		name: String,
		id: String,
		openInSameTab: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		campaignLink()
		{
			if (!is(this.id))
			{
				return null;
			}

			return this.$_getCampaignLink(this.id);
		}
	}
}
</script>

<style lang="scss" scoped>
.campaign-entity__icon {
	width: 8px;
	height: 8px;
	margin-left: 2px;
	margin-right: 2px;
	background-color: currentColor;
	border-radius: 50%;
	flex-shrink: 0;
}

.campaign-entity__name {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&.m_link {
		color: inherit;

		&:hover,
		&:active,
		&:visited {
			color: inherit;
		}

		&:hover {
			text-decoration: underline;
			text-decoration-color: $colorTextSecondary;
		}
	}
}
</style>