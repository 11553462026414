export const MetricsConfig = {
	Count: {
		name: 'Cards'
	},
	Score: {
		name: 'Score'
	},
	OpenTime: {
		name: 'Open Time'
	},
	AvgOpenTime: {
		name: 'Avg Open Time'
	},
	AvgOpenTimeHour: {
		name: 'Avg Open Time in Hours'
	},
	AvgOpenTimeDay: {
		name: 'Avg Open Time in Days'
	}
}