var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fragment",
    [
      _c("h2", [_vm._v("LinkedIn Crawler")]),
      _vm._v(" "),
      _c(
        "lobster-form",
        {
          attrs: { "submit-button-text": "Schedule" },
          on: { submit: _setup.handleSubmit },
        },
        [
          _c("lobster-input", {
            attrs: {
              label: "Profile/Page id",
              validators: "required",
              caption: "Only ID, without urn part",
            },
            model: {
              value: _setup.linkedInId,
              callback: function ($$v) {
                _setup.linkedInId = $$v
              },
              expression: "linkedInId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }