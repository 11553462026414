var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lobster-status-icon", {
    attrs: {
      "icon-name": _vm.getLeadStatusIcon(_vm.status),
      color: _vm.getLeadStatusColor(_vm.status),
      text: _vm.statusText,
      expanded: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }