<template>
	<div class="analytics-app inner">
		<router-view class="analytics-app__content"/>
		<lobster-provider/>
	</div>
</template>

<script>
import AnalyticsModule from '../../store/AnalyticsModule';
import { ErrorHandler } from '../../utils/yuri/ErrorHandler';


export default {
	name: "AnalyticsApp",
	methods: {
		handleNavMenuClick() {
			if (this.$route.name !== 'dashboards-list')
			{
				this.$router.push({name: 'dashboards-list'});
			}
		}	
	},
	beforeCreate()
	{
		if (this.$store.hasModule('analytics'))
		{
			this.$store.unregisterModule('analytics');
		}

		this.$store.registerModule('analytics', AnalyticsModule);
	},
	mounted()
	{
		this.$yuri.registerHelper(new ErrorHandler(this.$toaster));
		$('a[data-menu="rnd-bi"]').on('click', this.handleNavMenuClick);
	},
	destroyed()
	{
		$('a[data-menu="rnd-bi"]').off('click', this.handleNavMenuClick);
	}
}
</script>

<style lang="scss" scoped>
.analytics-app {
	padding: 0;
}

.analytics-app__content {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
</style>