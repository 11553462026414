var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-form-sidebar" },
    [
      _c("sidebar-menu", {
        attrs: {
          "filter-view": _vm.isFilterView,
          "filters-count": _vm.filtersCount,
        },
        on: {
          "update:filterView": function ($event) {
            _vm.isFilterView = $event
          },
          "update:filter-view": function ($event) {
            _vm.isFilterView = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.isFilterView
        ? _c("analytics-filters", {
            staticClass: "report-form-sidebar__section",
            attrs: {
              "is-report-builder": "",
              "menu-view": false,
              "subject-type": _vm.subjectType,
              groups: _vm.currentGroups,
              metrics: _vm.currentMetrics,
              "additional-columns": _vm.currentAdditionalColumns,
            },
            model: {
              value: _vm.currentFilter,
              callback: function ($$v) {
                _vm.currentFilter = $$v
              },
              expression: "currentFilter",
            },
          })
        : _c("sidebar-settings", {
            staticClass: "report-form-sidebar__section",
            attrs: {
              "subject-type": _vm.subjectType,
              "with-visibility": _vm.withVisibility,
              groups: _vm.currentGroups,
              metrics: _vm.currentMetrics,
              "additional-columns": _vm.currentAdditionalColumns,
              "chart-type": _vm.currentChartType,
              limit: _vm.currentLimit,
              "order-by": _vm.currentOrderBy,
              metadata: _vm.currentMetadata,
              visibility: _vm.currentVisibility,
            },
            on: {
              "update:groups": function ($event) {
                _vm.currentGroups = $event
              },
              "update:metrics": function ($event) {
                _vm.currentMetrics = $event
              },
              "update:additionalColumns": function ($event) {
                _vm.currentAdditionalColumns = $event
              },
              "update:additional-columns": function ($event) {
                _vm.currentAdditionalColumns = $event
              },
              "update:chartType": function ($event) {
                _vm.currentChartType = $event
              },
              "update:chart-type": function ($event) {
                _vm.currentChartType = $event
              },
              "update:limit": function ($event) {
                _vm.currentLimit = $event
              },
              "update:orderBy": function ($event) {
                _vm.currentOrderBy = $event
              },
              "update:order-by": function ($event) {
                _vm.currentOrderBy = $event
              },
              "update:metadata": function ($event) {
                _vm.currentMetadata = $event
              },
              "update:visibility": function ($event) {
                _vm.currentVisibility = $event
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }