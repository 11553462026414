import { Network } from '../consts/Network';
import sanitizeHtml from 'sanitize-html';


export function capitalize(str)
{
	return str[0].toUpperCase() + str.substring(1);
}

export function truncate(text = '', size, truncateWith)
{
	truncateWith = truncateWith || '\u2026';

	if (text.length <= size)
	{
		return text;
	}

	return text.substring(0, size).split(' ').slice(0, -1).join(' ') + ' ' + truncateWith;
}

export function shorten(str)
{
	return truncate(str, 40);
}

export function sanitize(str = '', options)
{
	return sanitizeHtml(str, {
		allowedTags: [],
		disallowedTagsMode: 'escape',
		...options
	});
}

export function networkNameFormatter(network)
{
	const renamedNetworks = {
		[`${Network.TWITTER}`]: 'X (Twitter)'
	}

	return renamedNetworks[network] ?? network;
}

export function toReadable(string = '')
{
	string = string.slice(0, 1).toUpperCase() + string.slice(1);

	return string.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/-/g, ' ');
}

export function formatUnderscoredToReadable(string = '')
{
	return string.split('_').map(item => item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
}

export function floatToPercentString(value, decimals = 1)
{
	let formattedValue = (value * 100).toFixed(decimals);

	return `${formattedValue}%`;
}