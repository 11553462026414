import TestPage from '../components/Test/TestPage';


export default function ()
{	
	return {
		base: '/test/vue/',
		mode: 'history',
		routes: [
			{
				path: `/`,
				component: TestPage,
				name: 'root'
			},
			{
				path: `/test-page`,
				component: TestPage,
				name: 'test-page'
			},
		],
		linkActiveClass: 'm_active'
	}	
};