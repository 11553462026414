var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "common-report-wrapper",
      class: {
        [`${_vm.loadingClass}`]: _vm.$is(_vm.loadingClass) && _vm.isLoading,
        [`${_vm.nullstateClass}`]:
          _vm.$is(_vm.nullstateClass) && _vm.isNullstate,
      },
    },
    [
      _vm.isLoading
        ? _c("lobster-loader")
        : _vm.isFailed
        ? _c("common-report-nullstate", { attrs: { "is-failed-state": "" } })
        : _c("kraken-report", {
            staticClass: "common-report-wrapper__chart",
            attrs: {
              "report-data": _vm.modifiedData,
              "chart-type": _vm.chartType,
              groups: _vm.groups,
              metrics: _vm.metrics,
              "additional-columns": _vm.additionalColumns,
              metadata: _vm.currentMetadata,
              limit: _vm.limit,
              "report-description": _vm.reportDescription,
              config: _vm.additionalConfig,
              "with-order": _vm.withOrder,
              "with-pagination": _vm.withPagination,
              page: _vm.currentPage,
              "order-by": _vm.currentOrderBy,
              "order-dir": _vm.currentOrderDir,
              "is-embedded": _vm.isEmbedded,
            },
            on: {
              "update:limit": function ($event) {
                _vm.limit = $event
              },
              prev: _vm.handlePrev,
              next: _vm.handleNext,
              sort: _vm.handleSort,
              "count-updated": _vm.handleCountUpdated,
              "table-chart-click": _vm.handleTableChartClick,
              "chart-click": _vm.handleChartClick,
              "bubble-chart-click": _vm.handleBubbleChartClick,
              "wordcloud-chart-click": _vm.handleWordcloudChartClick,
              "funnel-chart-click": _vm.handleFunnelChartClick,
              "edit-metric": _vm.handleEditMetric,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "kraken-table-chart__table-cell",
                  fn: function ({ row, column, entities }) {
                    return [
                      _vm._t(
                        "kraken-table-chart__table-cell",
                        function () {
                          return [
                            _c("common-table-cell", {
                              attrs: {
                                row: row,
                                column: column,
                                entities: entities,
                              },
                            }),
                          ]
                        },
                        null,
                        { row, column, entities }
                      ),
                    ]
                  },
                },
                {
                  key: "kraken-table-chart__drill-down",
                  fn: function (data) {
                    return [
                      _vm._t(
                        "kraken-table-chart__drill-down",
                        null,
                        null,
                        data
                      ),
                    ]
                  },
                },
                {
                  key: "kraken-table-action-bar__actions",
                  fn: function (data) {
                    return [
                      _vm._t(
                        "kraken-table-action-bar__actions",
                        null,
                        null,
                        data
                      ),
                    ]
                  },
                },
                {
                  key: "chart",
                  fn: function (data) {
                    return [
                      _vm.withInfiniteScroll
                        ? _c(
                            "lobster-infinite-scroll",
                            {
                              attrs: {
                                "has-more-items": _vm.hasMoreItems,
                                loading: _vm.isInfinityScrollLoading,
                              },
                              on: { "load-more": _vm.handleLoadMore },
                            },
                            [_vm._t("chart", null, null, data)],
                            2
                          )
                        : _vm._t("chart", null, null, data),
                    ]
                  },
                },
                {
                  key: "kraken-nullstate",
                  fn: function () {
                    return [
                      _vm._t("nullstate", function () {
                        return [
                          _c(
                            "common-report-nullstate",
                            { attrs: { "with-background": "" } },
                            [
                              _c("h2", { staticClass: "h3" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t(
                                        "commonAnalytics.report.nullstate.title"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "description-text no-margin" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t(
                                          "commonAnalytics.report.nullstate.description"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "kraken-compare-nullstate",
                  fn: function () {
                    return [
                      _vm._t("kraken-compare-nullstate", function () {
                        return [_c("div")]
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }