<template>
	<lobster-layout-body class="dashboards-list-page">
		<lobster-layout-action-bar>
			<lobster-button @click="handleCreateDashboard">
				Create Dashboard
			</lobster-button>
		</lobster-layout-action-bar>
		<dashboards-list/>
	</lobster-layout-body>
</template>

<script>
import DashboardModal from './DashboardModal';
import DashboardsList from './DashboardsList';


export default {
	name: "DashboardsListPage",
	components: {DashboardsList, DashboardModal},
	methods: {
		handleCreateDashboard()
		{
			this.$showModal({
				component: DashboardModal
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.dashboards-list-page {
	padding: 25px 0;
}
</style>