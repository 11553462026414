<script>
import { ChartType } from '../consts/ChartType';
import { merge_configs } from 'kraken-charts';
import { debounce, isEqual } from '../../utils';
import { getModifiedData, mergeReportData } from '../helpers/ChartHelper';
import { CommonService } from '../services/CommonService';
import CommonTableCell from './CommonTableCell.vue';
import { WidgetType } from '../consts/WidgetType';
import CommonReportNullstate from './CommonReportNullstate.vue';


export default {
	name: 'CommonReportWrapper',
	components: { CommonReportNullstate, CommonTableCell },
	props: {
		dashboardId: String,
		widgetId: String,
		datePeriod:  Object,
		chartType: String,
		metrics: {
			type: Array,
			default: () => ([])
		},
		groups: {
			type: Array,
			default: () => ([])
		},
		additionalColumns: {
			type: Array,
			default: () => ([])
		},
		subjectType: String,
		limit: [Number, String],
		orderBy: Array,
		filter: Object,
		metadata: {
			type: Object,
			default()
			{
				return {};
			}
		},
		isDashboardView: Boolean,
		withInteractions: Boolean,
		withPagination: Boolean,
		withOrder: Boolean,
		withTotals: Boolean,
		labels: Object,
		isEmbedded: Boolean,
		config: {
			type: Object,
			default()
			{
				return {};
			}
		},
		drilldownConfig: {
			type: Object,
			default()
			{
				return {};
			}
		},
		reportName: String,
		reportDescription: String,
		loadingClass: String,
		nullstateClass: String,
		isDashboardSaving: Boolean,
		withInfiniteScroll: Boolean
	},
	data()
	{
		return {
			reportData: {},
			currentPage: 0,
			isLoading: false,
			isFailed: false,
			selectedOrderBy: undefined,
			selectedOrderDir: undefined,
			ChartType,
			isInfinityScrollLoading: false
		}
	},
	computed: {
		modifiedData()
		{
			return {
				...this.reportData,
				Data: getModifiedData(this.reportData?.Data)
			};
		},
		isNullstate()
		{
			return !is(this.modifiedData?.Data);
		},
		currentMetadata()
		{
			let metadata = {...this.metadata};

			if (metadata.isExtended === '0')
			{
				metadata.isExtended = 0;
			}

			return metadata;
		},
		/** @type { KrakenConfig } */
		additionalConfig()
		{
			if (this.isEmbedded)
			{
				return merge_configs(this.config, this.$krakenConfig);
			}
			else
			{
				return merge_configs(this.config, {
					extraData: {
						labels: this.labels,
						datePeriod: this.datePeriod
					}
				}, this.$krakenConfig);
			}
		},
		currentOrderBy()
		{
			if (!this.withOrder)
			{
				return;
			}

			if (is(this.selectedOrderBy) || this.selectedOrderBy === null)
			{
				return this.selectedOrderBy;
			}

			if (is.array.notEmpty(this.orderBy))
			{
				return this.orderBy[0].field;
			}
		},
		currentSelectedOrderDir()
		{
			if (is(this.selectedOrderBy))
			{
				return this.selectedOrderDir ?? this.$krakenConfig.tableConfig.getDefaultOrderDir(this.selectedOrderBy);
			}
		},
		currentOrderDir()
		{
			if (!this.withOrder)
			{
				return;
			}

			if (is(this.selectedOrderBy) || this.selectedOrderBy === null)
			{
				return this.currentSelectedOrderDir;
			}

			if (is.array.notEmpty(this.orderBy))
			{
				return this.orderBy[0].direction === 'descending' ? 0 : 1;
			}
		},
		hasMoreItems()
		{
			if (!this.withInfiniteScroll)
			{
				return false;
			}

			return is(this.modifiedData?.Cursor?.Next);
		},
	},
	watch: {
		dashboardId(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		widgetId(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		datePeriod(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		chartType(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		groups(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		metrics(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		additionalColumns(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		subjectType(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		limit(newVal, oldVal)
		{
			if (!isEqual(newVal, oldVal))
			{
				this.debouncedLoadReportData();
			}
		},
		orderBy: {
			deep: true,
			handler(newVal, oldVal)
			{
				if (!isEqual(newVal, oldVal))
				{
					this.debouncedLoadReportData();
				}
			}
		},
		filter: {
			deep: true,
			handler(newVal, oldVal)
			{
				if (!isEqual(newVal, oldVal))
				{
					this.debouncedLoadReportData();
				}
			}
		},
		metadata(newVal, oldVal)
		{
			if (newVal.isExtended !== oldVal.isExtended)
			{
				this.debouncedLoadReportData();
			}
		},
	},
	methods: {
		handlePrev()
		{
			if (is(this.modifiedData?.Cursor?.Prev))
			{
				this.currentPage--;

				this.loadReportData({
					prev: this.modifiedData.Cursor.Prev
				});
			}
		},
		handleNext()
		{
			if (is(this.modifiedData?.Cursor?.Next))
			{
				this.currentPage++;

				this.loadReportData({
					next: this.modifiedData.Cursor.Next
				});
			}
		},
		handleCountUpdated()
		{
			this.currentPage = 0;
			this.loadReportData({});
		},
		handleSort(orderBy, orderDir)
		{
			this.selectedOrderBy = orderBy;
			this.selectedOrderDir = orderDir;

			this.reloadReportData();
		},
		async getQueryWidget(additionalParams = {})
		{
			let params = {
				datePeriod: this.datePeriod,
				type: WidgetType.REPORT,
				chartType: this.chartType,
				subjectType: this.subjectType,
				orderBy: this.orderBy,
				additionalColumns: this.additionalColumns,
				metrics: this.metrics,
				groups: this.groups,
				filter: this.filter,
				withTotals: this.withTotals,
				withCursor: this.withPagination,
				limit: this.limit,
				format: 'flat',
				metadata: JSON.stringify(this.currentMetadata)
			};

			if (is(this.widgetId))
			{
				params.widgetId = this.widgetId;
			}

			if (is(this.dashboardId))
			{
				params.dashboardId = this.dashboardId;
			}

			if (this.withOrder && is(this.selectedOrderBy))
			{
				params.orderBy = [{
					field: this.selectedOrderBy,
					direction: this.currentSelectedOrderDir === 0 ? 'descending' : 'ascending'
				}];
			}

			return CommonService.getQueryWidget({
				...params,
				...additionalParams
			});
		},
		async loadReportData(additionalParams = {})
		{
			this.isLoading = true;

			try
			{
				this.reportData = await this.getQueryWidget(additionalParams);

				this.isFailed = false;
			}
			catch (e)
			{
				this.isFailed = true;
			}
			finally
			{
				this.isLoading = false;
			}
		},
		async reloadReportData()
		{
			if (this.isDashboardSaving)
			{
				let unwatchIsDashboardSaving = this.$watch('isDashboardSaving', (newVal) =>
				{
					if (!is(newVal))
					{
						this.reloadReportData();
						unwatchIsDashboardSaving();
					}
				});

				return;
			}

			this.isLoading = true;
			this.currentPage = 0;

			try
			{
				this.reportData = await this.getQueryWidget();
				this.isFailed = false;
			}
			catch (e)
			{
				this.isFailed = true;
			}
			finally
			{
				this.isLoading = false;
			}
		},
		debouncedLoadReportData()
		{
			this.isLoading = true;

			this.debouncedReloadReportData()
		},
		async handleLoadMore()
		{
			if (!this.withInfiniteScroll)
			{
				return;
			}

			if (!this.hasMoreItems)
			{
				return;
			}

			this.isInfinityScrollLoading = true;

			try
			{
				let newReportData = await this.getQueryWidget({
					next: this.modifiedData.Cursor.Next
				});

				this.reportData = mergeReportData(this.modifiedData, newReportData);
			}
			finally
			{
				this.isInfinityScrollLoading = false;
			}
		},
		handleTableChartClick(row, columns, entities)
		{
			this.$emit('table-chart-click', row, columns, entities)
		},
		handleChartClick(item)
		{
			this.$emit('chart-click', item, this.reportData.Entities);
		},
		handleBubbleChartClick(item)
		{
			this.$emit('bubble-chart-click', item, this.reportData.Entities);
		},
		handleWordcloudChartClick(item)
		{
			this.$emit('wordcloud-chart-click', item, this.reportData.Entities);
		},
		handleFunnelChartClick()
		{
			this.$emit('funnel-chart-click');
		},
		handleEditMetric(metric, metricText)
		{
			this.$emit('edit-metric', metric, metricText);
		}
	},
	created()
	{
		this.debouncedReloadReportData = debounce(this.reloadReportData, 0);
		this.loadReportData();
	}
}
</script>

<template>
	<div
		class="common-report-wrapper"
		:class="{
			[`${loadingClass}`]: $is(loadingClass) && isLoading,
			[`${nullstateClass}`]: $is(nullstateClass) && isNullstate,
		}">
		<lobster-loader v-if="isLoading"/>

		<common-report-nullstate
			v-else-if="isFailed"
			is-failed-state/>

		<kraken-report
			v-else
			class="common-report-wrapper__chart"
			:report-data="modifiedData"
			:chart-type="chartType"
			:groups="groups"
			:metrics="metrics"
			:additional-columns="additionalColumns"
			:metadata="currentMetadata"
			:limit.sync="limit"
			:report-description="reportDescription"
			:config="additionalConfig"
			:with-order="withOrder"
			:with-pagination="withPagination"
			:page="currentPage"
			:order-by="currentOrderBy"
			:order-dir="currentOrderDir"
			:is-embedded="isEmbedded"
			@prev="handlePrev"
			@next="handleNext"
			@sort="handleSort"
			@count-updated="handleCountUpdated"
			@table-chart-click="handleTableChartClick"
			@chart-click="handleChartClick"
			@bubble-chart-click="handleBubbleChartClick"
			@wordcloud-chart-click="handleWordcloudChartClick"
			@funnel-chart-click="handleFunnelChartClick"
			@edit-metric="handleEditMetric">
			<template #kraken-table-chart__table-cell="{ row, column, entities }">
				<slot name="kraken-table-chart__table-cell" v-bind="{ row, column, entities }">
					<common-table-cell
						:row="row"
						:column="column"
						:entities="entities"/>
				</slot>
			</template>

			<template #kraken-table-chart__drill-down="data">
				<slot name="kraken-table-chart__drill-down" v-bind="data"/>
			</template>

			<template #kraken-table-action-bar__actions="data">
				<slot name="kraken-table-action-bar__actions" v-bind="data"/>
			</template>

			<template #chart="data">
				<lobster-infinite-scroll
					v-if="withInfiniteScroll"
					:has-more-items="hasMoreItems"
					:loading="isInfinityScrollLoading"
					@load-more="handleLoadMore">
					<slot name="chart" v-bind="data"/>
				</lobster-infinite-scroll>

				<slot
					v-else
					name="chart"
					v-bind="data"/>
			</template>
			
			<template #kraken-nullstate>
				<slot name="nullstate">
					<common-report-nullstate with-background>
						<h2 class="h3">
							{{ $t('commonAnalytics.report.nullstate.title') }}
						</h2>

						<p class="description-text no-margin">
							{{ $t('commonAnalytics.report.nullstate.description') }}
						</p>
					</common-report-nullstate>
				</slot>
			</template>

			<template #kraken-compare-nullstate>
				<slot name="kraken-compare-nullstate">
					<div></div>
				</slot>
			</template>
		</kraken-report>
	</div>
</template>

<style lang="scss" scoped>
.common-report-wrapper {
	display: flex;
}

.common-report-wrapper__chart {
	min-height: 0;
	flex-grow: 1;
	width: 100%;
}
</style>
