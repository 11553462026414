export const PAGETYPES = [
	{"value":"about", "label":"About"},
	{"value":"insights", "label":"Insights"},
	{"value":"jobs", "label":"Jobs"},
	{"value":"lifeAt", "label":"Life At"},
	{"value":"overview", "label":"Overview"},
	{"value":"people", "label":"People"},
	{"value":"products", "label":"Products"}
];

export const PAGE_TYPES = Object.freeze([
	'0',
	'about',
	'insights',
	'jobs',
	'lifeAt',
	'overview',
	'people',
	'products'
]);